import { nextMonth, firstDateOfMonth } from "../utils/date_utils";
import { PAYSLIP_GENERATION_DAY } from "../utils/constants";

const getSalariesFromRequestCards = (cards) => {
  const activeCards = cards.filter((card) => isActiveCard(card));
  const salaryRequests = activeCards.map((cardTarget) => salaryRequestFromCard(cardTarget));

  return getSalariesFromSalaryRequests(salaryRequests);
};

const salaryRequestFromCard = (card) => {
  const grossAmount = card.querySelector(".salary-gross-amount").value;
  const date = new Date(card.querySelector(".salary-date").value);

  return { grossAmount, date };
};

const isActiveCard = (card) => {
  return !card.querySelector(".delete-salary-request-input").value;
};

const getSalariesFromSalaryRequests = (salaryRequests) => {
  let { date: salaryStartDate, grossAmount } = salaryRequests[0];
  const salaryEndDate = currentSalaryMonthDate();
  const salariesRange = buildSalariesRange(salaryStartDate, salaryEndDate);
  const pastSalaries = [];
  let currentSalary;

  salariesRange.forEach((date) => {
    const foundRequest = retrieveSalaryRequest(date, salaryRequests);
    if (foundRequest) {
      grossAmount = foundRequest.grossAmount;
    }
    const salary = { grossAmount, date };

    if (isPastSalary(salary)) {
      pastSalaries.push(salary);
    } else {
      currentSalary = salary;
    }
  });

  return { pastSalaries, currentSalary };
};

const buildSalariesRange = (startDate, endDate) => {
  let date = startDate;
  const dates = [];

  while (date < endDate) {
    dates.push(date);
    date = nextMonth(date);
  }

  return dates;
};

const isPastSalary = (salary) => {
  const currentSalaryDate = currentSalaryMonthDate();

  return (
    firstDateOfMonth(salary.date.getMonth(), salary.date.getFullYear()).getTime() <
    firstDateOfMonth(currentSalaryDate.getMonth(), currentSalaryDate.getFullYear()).getTime()
  );
};

const retrieveSalaryRequest = (salaryDate, salaryRequests) => {
  return salaryRequests.find((request) => request.date.toDateString() === salaryDate.toDateString());
};

const currentSalaryMonthDate = () => {
  const currentDate = new Date();
  const date = currentDate.getDate() < PAYSLIP_GENERATION_DAY ? currentDate : nextMonth(currentDate);
  return firstDateOfMonth(date.getMonth(), date.getFullYear());
};

export { getSalariesFromRequestCards, getSalariesFromSalaryRequests, currentSalaryMonthDate };
