import { handleHttpResponse } from "../utils/http_utils";
import { updateSalesInvoicesTableRows } from "../datatables/sales_invoices_datatable";
import { flashAlert, notifyError } from "../components/flash_alert";
import { closeModal, closeSidePanel } from "../utils/misc";

const formattedQuote = (details) => {
  const { id, gross_total, vat_total, net_total, settlement_amount, currency_symbol, notes, has_instalment_invoices } =
    JSON.parse(details);

  return {
    id: id,
    grossTotal: gross_total,
    vatTotal: vat_total,
    netTotal: net_total,
    settlementAmount: settlement_amount,
    currencySymbol: currency_symbol,
    notes: notes,
    hasInstalmentInvoices: has_instalment_invoices,
  };
};

const formattedInstalment = (details) => {
  const { gross_total, reference } = JSON.parse(details).instalment_invoice;

  return {
    grossTotal: gross_total,
    reference: reference,
  };
};

const handlePartialInvoiceGenerationFromQuoteResponse = (payload, modalId) => {
  const { message } = JSON.parse(payload.response);

  handleHttpResponse({
    payload: payload,
    onSuccess: (response) => {
      const salesInvoices = JSON.parse(response).sales_invoices;
      updateSalesInvoicesTableRows(salesInvoices);

      flashAlert(message);
      closeModal(modalId);
      closeSidePanel();
    },
    onError: () => {
      notifyError(message);
    },
  });
};

export { formattedQuote, formattedInstalment, handlePartialInvoiceGenerationFromQuoteResponse };
