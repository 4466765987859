import { Controller } from "@hotwired/stimulus";
import { initDropzone, initDropzoneBasicEvents, toggleDropzoneValidity } from "../../components/dropzone";
import $ from "jquery";

export default class extends Controller {
  static targets = ["filePreviewsContainer", "uploadImgLabel", "dragAndDropContainer", "submitBtn"];

  connect() {
    this._initDropzone();
  }

  validateSubmission(e) {
    e.preventDefault();
    this.submitBtnTarget.disabled = true;

    if (this._isValidForm()) {
      this.dropzone.processQueue();
    } else {
      toggleDropzoneValidity(this.dropzone, false);
    }
  }

  _isValidForm() {
    return (
      $(this.element).parsley().validate() && this.dropzone.getQueuedFiles().length === this.dropzone.options.maxFiles
    );
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.element.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
    };
  }

  _initCustomEvents() {
    this.dropzone.on("addedfile", () => {
      this._setSubmitBtnAvailability();
    });

    this.dropzone.on("removedfile", () => {
      this._setSubmitBtnAvailability();
    });

    this.dropzone.on("success", () => {
      window.location.reload();
    });
  }

  _setSubmitBtnAvailability() {
    this.submitBtnTarget.disabled = this.dropzone.files.length === 0;
  }
}
