import { Controller } from "@hotwired/stimulus";
import { toggleClass } from "../../utils/misc";
import { numberToCurrency } from "../../utils/number_utils";

export default class extends Controller {
  static targets = [
    "step",
    "transactionRow",
    "transactionsNotJustifiedMessage",
    "shareholdersTotalAmount",
    "purchaseTotalAmount",
    "shareholdersAllocationDescription",
    "purchaseAllocationDescription",
  ];

  initialize() {
    this.currentStep = 0;
  }

  nextStep() {
    this._displayNewStep(this.currentStep, this.currentStep + 1);
    this.currentStep += 1;
  }

  previousStep() {
    this._displayNewStep(this.currentStep, this.currentStep - 1);
    this.currentStep -= 1;
  }

  validateTransactions() {
    const transactions = this._transactions();
    const allTransactionsJustified = this._allTransactionsJustified(transactions);
    toggleClass(this.transactionsNotJustifiedMessageTarget, "hidden", !allTransactionsJustified);
    if (!allTransactionsJustified) {
      return;
    }

    this._updateConfirmationAmounts(transactions);
    this.nextStep();
  }

  transactionsValidated() {
    document.dispatchEvent(new CustomEvent("bankTransactions:lostReceiptsConfirmed"));
  }

  _stepFor(stepNumber) {
    return this.stepTargets.find((target, index) => index === stepNumber);
  }

  _displayNewStep(currentStepNumber, nextStepNumber) {
    this._stepFor(currentStepNumber).classList.remove("current");
    this._stepFor(nextStepNumber).classList.add("current");
  }

  _allTransactionsJustified(transactions) {
    return transactions.filter((transaction) => transaction.accounting_status === "incomplete").length === 0;
  }

  _transactions() {
    return this.transactionRowTargets.map((transactionRow) => JSON.parse(transactionRow.dataset.transactionValues));
  }

  _totalAmountFor(transactions, accountingStatus) {
    const sum = transactions.reduce((accumulator, currentTransaction) => {
      if (currentTransaction.accounting_status !== accountingStatus) {
        return accumulator;
      }

      return accumulator - currentTransaction.value;
    }, 0);
    return sum;
  }

  _updateConfirmationAmounts(transactions) {
    const shareholdersAccountTotalAmount = this._totalAmountFor(transactions, "shareholders_account");
    toggleClass(this.shareholdersAllocationDescriptionTarget, "hidden", shareholdersAccountTotalAmount !== 0);
    this.shareholdersTotalAmountTarget.innerHTML = numberToCurrency(shareholdersAccountTotalAmount);

    const purchasesTotalAmount = this._totalAmountFor(transactions, "purchase");
    toggleClass(this.purchaseAllocationDescriptionTarget, "hidden", purchasesTotalAmount !== 0);
    this.purchaseTotalAmountTarget.innerHTML = numberToCurrency(purchasesTotalAmount);
  }
}
