import { Controller } from "@hotwired/stimulus";
import { fillFlatpickrInput, isFlatpickrInput } from "../utils/date_utils";

/**
 * Change the value of an input field based on clicks on elements
 */

export default class extends Controller {
  static targets = ["input"];

  update(e) {
    if (isFlatpickrInput(this.inputTarget)) {
      fillFlatpickrInput(this.inputTarget, e.currentTarget.dataset.chosenValue);
    } else {
      this.inputTarget.value = e.currentTarget.dataset.chosenValue;
    }
  }
}
