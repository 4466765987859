import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";

export default class extends Controller {
  static targets = ["deletionConfirmationBtn"];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this.deletionConfirmationBtnTarget.href = event.relatedTarget.dataset.deletionUrl;
    });
  }

  onDeletionResponse(e) {
    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: (response) => {
        this._toggleModals(JSON.parse(response));
      },
      onComplete: () => {
        window.$(this.element).modal("hide");
      },
    });
  }

  _toggleModals(response) {
    if (response.deleted) {
      const deletedBankId = response.deleted_bank_id;
      const bankAccountConnectionModal = document.querySelector(
        `.bank-accounts-connection-modal[data-bank-id="${deletedBankId}"]`
      );
      const deletedBankCard = document.querySelector(`.bank[data-bank-id="${deletedBankId}"]`);
      window.$(bankAccountConnectionModal).modal("hide");
      window.$("#bankDeletionSuccessModal").modal("show");
      deletedBankCard.remove();
    } else {
      window.$("#bankDeletionDeniedModal").modal("show");
    }
  }
}
