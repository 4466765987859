import $ from "jquery";

const toggleSidePanel = () => {
  const sidePanel = document.getElementById("side-panel");

  // NOTE(Jonathan Jalal): As we need to check "event.pointerId" inside this event listener, we have to listen to a pointer event instead of the click event
  // That's because the click event is a MouseEvent on Safari (while it is a PointerEvent on Chrome), and "event.pointerId" is undefined for MouseEvent instances
  // Here we listen to pointerdown, which is a PointerEvent on all browsers.
  // Check this PR for more context: https://github.com/acasi-io/acasi/pull/3167
  document.addEventListener("pointerdown", (event) => {
    if (!isSidePanelDisplayed(sidePanel)) {
      return;
    }

    // This guard clause should be before shouldLeaveSidePanelOpen as the close button is an element of the side panel
    if (isSidePanelCloseButton(event.target)) {
      closeSidePanel();
    }

    if (shouldLeaveSidePanelOpen(event.target)) {
      return;
    }

    const isUserClickEvent = event.pointerId !== -1;
    if (isUserClickEvent && isClickable(event.target)) {
      closeSidePanel();
    }
  });
};

const isSidePanelDisplayed = (sidePanel) => {
  return sidePanel.classList.contains("show-side-panel");
};

const shouldLeaveSidePanelOpen = (element) => {
  const isWithinSidePanel = element.closest("#side-panel");
  const isWithinModal = element.closest(".modal") || element.closest(".swal-overlay");

  return isWithinSidePanel || isWithinModal;
};

const isClickable = (element) => {
  return !["pointer", "text"].includes($(element).css("cursor"));
};

const closeSidePanel = () => {
  document.dispatchEvent(new CustomEvent("sidePanel:close"));
  document.dispatchEvent(new CustomEvent("datatable:sidePanelClosed"));
};

const isSidePanelCloseButton = (element) => {
  const sidePanelCloseBtn = document.getElementById("side-panel-close-btn");
  return element === sidePanelCloseBtn || sidePanelCloseBtn.contains(element);
};

export { toggleSidePanel, isClickable };
