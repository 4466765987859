import { Controller } from "@hotwired/stimulus";
import SalesInvoiceLine from "../../sales_invoices/sales_invoice_line";
import { amountFormatted } from "../../utils/number_utils";
import { selectedOptionFor } from "../../utils/inputs_utils";
import { nanoid } from "nanoid";

// Helps compute net / gross totals for a sales invoice line
export default class extends Controller {
  static targets = [
    "quantity",
    "unitPrice",
    "vatRates",
    "discountType",
    "currencyDiscountType",
    "discountValue",
    "netTotal",
    "grossTotal",
  ];
  static values = {
    currencyInputId: String,
  };

  initialize() {
    this.element[this.identifier] = this;
    this._initializeEvents();
    this._setSalesInvoiceLineCustomId();
  }

  disconnect() {
    document.dispatchEvent(new CustomEvent("salesInvoice:updateMainTotals"));
  }

  updateLineTotals() {
    document.dispatchEvent(new CustomEvent("salesInvoice:updateLineTotals"));
  }

  _initSalesInvoiceLine() {
    const quantity = +this.quantityTarget.value;
    const unitPrice = +this.unitPriceTarget.value;
    const vatRate = this.vatRatesTarget.value;
    const discountType = this._activeElement(this.discountTypeTargets).value || "";
    const discountValue = parseFloat(this.discountValueTarget.value) || 0;

    const invoiceLine = new SalesInvoiceLine();
    invoiceLine.quantity = quantity;
    invoiceLine.unitPrice = unitPrice;
    invoiceLine.vatRate = vatRate;
    invoiceLine.discountType = discountType;
    invoiceLine.discountValue = discountValue;
    return invoiceLine;
  }

  _initializeEvents() {
    this._updateLineTotals();
    document.addEventListener("salesInvoice:updateLineTotals", () => {
      this._updateLineTotals();
    });
  }

  _updateLineTotals() {
    const salesInvoiceLine = this._initSalesInvoiceLine();
    const currencySymbol = selectedOptionFor(document.getElementById(this.currencyInputIdValue)).dataset.symbol;
    this.netTotalTarget.value = amountFormatted(salesInvoiceLine.netTotal(), currencySymbol);
    this.grossTotalTarget.value = amountFormatted(salesInvoiceLine.grossTotal(), currencySymbol);
    this.currencyDiscountTypeTarget.innerText = currencySymbol;
    document.dispatchEvent(new CustomEvent("salesInvoice:updateMainTotals"));
  }

  _activeElement(targets) {
    return targets.find((target) => target.classList.value.includes("active"));
  }

  _setSalesInvoiceLineCustomId() {
    // Setting a uniq custom ID to current controller, so only the controller belonging
    // to the clicked sales invoice line is triggered, on salesInvoice:catalogItemSelected event
    this.element.dataset.lineId = nanoid();
  }
}
