import { Controller } from "@hotwired/stimulus";
import { isVatConsistent } from "../../purchase_invoice/control_vat_consistency";
import { launchASweetAlert } from "../../components/init_sweet_alert";
import { STANDARD_VAT_RATE } from "../../utils/constants";
import { toggleClass } from "../../utils/misc";
import { isInputFilled } from "../../react_components/design_system/input/utils";
import { handleHttpResponse } from "../../utils/http_utils";
import { flashAlert, notifyUpdate } from "../../components/flash_alert";
import { removeTableRow } from "../../components/tables/remove_table_row";

export default class extends Controller {
  static targets = ["amount", "vat", "vatAlert", "problematicPurchase"];

  toggleVatAlert(e) {
    const vatInput = e.target.value;
    toggleClass(this.vatAlertTarget, "hidden", isInputFilled(vatInput));
  }

  // TODO(David Michel): replace w/ a custom parsley validator if possible
  validateSubmission(e) {
    if (!isVatConsistent(this.vatTarget.value, this.amountTarget.value)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.vatTarget.classList.add("border-red");
      launchASweetAlert({
        title: `Le montant de TVA renseigné semble incohérent car supérieur à ${STANDARD_VAT_RATE * 100}%.`,
      });
    }
  }

  purchaseUpdated(e) {
    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: (response) => {
        const purchaseInvoices = JSON.parse(response).purchase_invoices;
        if (this.hasProblematicPurchaseTarget && this._isProblematicPurchaseResolved(purchaseInvoices[0])) {
          flashAlert("Bravo ! Vous avez validé cet achat");
          removeTableRow(purchaseInvoices[0].id);
        } else {
          document.dispatchEvent(
            new CustomEvent("purchaseInvoice:updated", { detail: { purchaseInvoices: purchaseInvoices } })
          );
          notifyUpdate();
        }
      },
    });
  }

  _isProblematicPurchaseResolved(purchaseInvoice) {
    return purchaseInvoice.error.length === 0;
  }
}
