import { interpolate } from "../../utils/string_utils";
import { updatePurchaseInvoicesTableRows } from "../../datatables/purchase_invoices_datatable";

export const handlePurchaseDatatableRow = (data) => {
  const row = document.querySelector(`.row-${data.id}`);

  if (row) {
    row.querySelector(".supplier-spinner").classList.add("hidden");

    if (!data.error) {
      updatePurchaseInvoicesTableRows([data]);
    } else {
      const errorHtml = interpolate(document.getElementById("purchase-error-message"), {
        error_message: data.error_text,
      });
      row.querySelector(".supplier-column > [data-supplier]").insertAdjacentHTML("afterbegin", errorHtml);
    }
  }
};
