import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";

const FISCAL_POWER_KINDS = {
  AUTOMOBILE: "auto",
  BIG_TWO_WHEELS: "big_two_wheels",
};

export default class extends Controller {
  static targets = ["automobileFiscalPowerSelection", "bigTwoWheelsFiscalPowerSelection", "fiscalPowerSelection"];

  displayFiscalPower(event) {
    let templateContent = "";
    if (event.target.value === FISCAL_POWER_KINDS.AUTOMOBILE) {
      templateContent = interpolate(this.automobileFiscalPowerSelectionTarget);
    } else if (event.target.value === FISCAL_POWER_KINDS.BIG_TWO_WHEELS) {
      templateContent = interpolate(this.bigTwoWheelsFiscalPowerSelectionTarget);
    } else {
      templateContent = "N/A";
    }
    this.fiscalPowerSelectionTarget.innerHTML = templateContent;
  }
}
