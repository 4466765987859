import { Controller } from "@hotwired/stimulus";
import { resolveTodo } from "../../todo_list/todo_resolver";

export default class extends Controller {
  static values = {
    todoId: Number,
  };

  completed() {
    resolveTodo(this.todoIdValue);
  }
}
