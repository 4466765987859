import { Controller } from "@hotwired/stimulus";
import { amountFormatted } from "../../utils/number_utils";
import { flashAlert } from "../../components/flash_alert";
import { updateBankTransactionsTableRows } from "../../datatables/bank_transactions_datatable";
import { Dropzone } from "dropzone";

export default class extends Controller {
  static targets = [
    "transactionDate",
    "transactionWording",
    "transactionAmount",
    "fileName",
    "fileInput",
    "attachDocumentForm",
  ];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      const element = event.relatedTarget;
      const dropzone = Dropzone.forElement(this.attachDocumentFormTarget);

      this.transactionDateTarget.innerText = element.dataset.date;
      this.transactionWordingTarget.innerText = element.dataset.wording;
      this.transactionAmountTarget.innerText = amountFormatted(element.dataset.amount, "€");
      this.attachDocumentFormTarget.action = element.dataset.url;
      dropzone.options.url = element.dataset.url;
      dropzone.removeAllFiles(true);
    });
  }

  onFileAttached(e) {
    window.$(`#${this.element.id}`).modal("hide");
    const data = JSON.parse(e.detail[0].response);
    flashAlert(data.message);
    updateBankTransactionsTableRows([data.bank_transaction]);
  }
}
