import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { hideElements, showElements, toggleClass } from "../../utils/misc";
import {
  CUSTOMER_LOCATION,
  CUSTOMER_TYPE,
  isProfessionalCustomerSubjectedToTax,
  setNewCountryList,
  vatLegalTextOption,
} from "../../customers/creation_form_utils";
import { interpolate } from "../../utils/string_utils";
import { selectOptionFor } from "../../utils/inputs_utils";

const STEPS = {
  INITIAL: 0,
  BUSINESS_CUSTOMER_SEARCH: 2,
  CUSTOMER_MAIN_INFORMATION: 3,
  FOREIGN_PROFESSIONAL_CUSTOMER_VAT_SETTINGS: 4,
  CUSTOMER_SETTINGS: 5,
};

export default class extends Controller {
  static targets = [
    "country",
    "sirenWrapper",
    "vatNumberWrapper",
    "vatNumber",
    "foreignVatSettingText",
    "foreignVatSettingTextTemplate",
    "recoveryCostsCompensationWrapper",
    "step",
    "nextStepBtn",
    "previousStepBtn",
    "defaultVatSettingTrue",
    "vatLegalText",
    "vatExempted",
  ];

  static values = {
    businessType: String,
  };

  connect() {
    this._groupSectionFields();

    this.stepId = STEPS.INITIAL;

    const { modalId } = this.element.dataset;
    window.$(`#${modalId}`).on("hide.bs.modal", () => {
      this._reinitializeForm();
    });
  }

  get form() {
    return this.element;
  }

  _reinitializeForm() {
    hideElements([this.previousStepBtnTarget]);
    showElements([this.nextStepBtnTarget]);

    this._changeStep(this.stepId, STEPS.INITIAL);

    this.form.reset();
  }

  previousStep() {
    let nextStep = this.stepId - 1;

    if (this.stepId === STEPS.CUSTOMER_MAIN_INFORMATION && this.isIndividualCustomer) {
      nextStep = STEPS.INITIAL;
    }

    if (this.stepId === STEPS.CUSTOMER_SETTINGS && this._skipForeignProfessionalCustomerVatSettings()) {
      nextStep = STEPS.CUSTOMER_MAIN_INFORMATION;
    }

    this._changeStep(this.stepId, nextStep);
  }

  nextStep() {
    $(this.form)
      .parsley()
      .whenValidate({ group: `group-${this.stepId}` })
      .done(() => {
        let nextStep = this.stepId + 1;

        if (this.stepId === STEPS.INITIAL && this.isIndividualCustomer) {
          nextStep = STEPS.CUSTOMER_MAIN_INFORMATION;
        }

        if (this.stepId === STEPS.CUSTOMER_MAIN_INFORMATION) {
          if (this._skipForeignProfessionalCustomerVatSettings()) {
            nextStep = STEPS.CUSTOMER_SETTINGS;
          } else {
            this._setForeignCustomerVatSettingTexts();
          }
        }

        if (this.stepId === STEPS.FOREIGN_PROFESSIONAL_CUSTOMER_VAT_SETTINGS) {
          this._setDefaultVatSettings();
        }

        this._changeStep(this.stepId, nextStep);
      });
  }

  onCustomerTypeChange(event) {
    switch (event.target.value) {
      case CUSTOMER_TYPE.INDIVIDUAL: {
        this.sirenWrapperTarget.classList.add("hidden");
        this.vatNumberWrapperTarget.classList.add("hidden");
        this.recoveryCostsCompensationWrapperTarget.classList.add("hidden");
        break;
      }
      case CUSTOMER_TYPE.BUSINESS: {
        this.recoveryCostsCompensationWrapperTarget.classList.remove("hidden");
        break;
      }
    }

    this.isIndividualCustomer = event.target.value === CUSTOMER_TYPE.INDIVIDUAL;

    const { location } = event.target.dataset;
    if (location) {
      this._setCountryList(location);
    }
    this.nextStep();
  }

  onLocationChange(event) {
    const location = event.target.value;

    switch (location) {
      case CUSTOMER_LOCATION.FRANCE: {
        this.sirenWrapperTarget.classList.remove("hidden");
        this.vatNumberWrapperTarget.classList.remove("hidden");
        break;
      }
      case CUSTOMER_LOCATION.EUROPE: {
        this.sirenWrapperTarget.classList.add("hidden");
        this.vatNumberWrapperTarget.classList.remove("hidden");
        break;
      }
      case CUSTOMER_LOCATION.NON_EUROPE: {
        this.sirenWrapperTarget.classList.add("hidden");
        this.vatNumberWrapperTarget.classList.add("hidden");
        break;
      }
    }

    this.location = location;

    this._setCountryList(location);
    this.nextStep();
  }

  _setDefaultVatSettings() {
    let vatLegalText = "";
    const isDefaultVatSetting = this.defaultVatSettingTrueTarget.checked;
    this.vatExemptedTarget.checked = isDefaultVatSetting;

    if (isDefaultVatSetting) {
      vatLegalText = vatLegalTextOption(this.location, this.businessTypeValue);
    }
    selectOptionFor(this.vatLegalTextTarget, vatLegalText);
  }

  _setCountryList(location) {
    const { countriesUrl } = this.countryTarget.dataset;
    setNewCountryList(countriesUrl, location);
  }

  skipSearch() {
    this._changeStep(this.stepId, STEPS.CUSTOMER_MAIN_INFORMATION);
  }

  _changeStep(currentStep, nextStep) {
    this._handleNextStepButtonDisplay(nextStep);
    this._handlePreviousStepButtonDisplay(nextStep);

    this._findStep(currentStep).classList.add("hidden");
    this._findStep(nextStep).classList.remove("hidden");
    this.stepId = nextStep;
  }

  _findStep(stepId) {
    return this.stepTargets.find((target) => target.dataset.stepId === stepId.toString());
  }

  _groupSectionFields() {
    this.stepTargets.forEach((section, index) => {
      $(section).find(":input").attr("data-parsley-group", `group-${index}`);
    });
  }

  _handlePreviousStepButtonDisplay(stepId) {
    toggleClass(this.previousStepBtnTarget, "hidden", stepId !== STEPS.INITIAL);
  }

  _handleNextStepButtonDisplay(stepId) {
    toggleClass(
      this.nextStepBtnTarget,
      "hidden",
      [
        STEPS.BUSINESS_CUSTOMER_SEARCH,
        STEPS.CUSTOMER_MAIN_INFORMATION,
        STEPS.FOREIGN_PROFESSIONAL_CUSTOMER_VAT_SETTINGS,
      ].includes(stepId)
    );
  }

  _skipForeignProfessionalCustomerVatSettings() {
    if (this.isIndividualCustomer) {
      return true;
    }
    return !isProfessionalCustomerSubjectedToTax(this.countryTarget.value, this.location, this.vatNumberTarget.value);
  }

  _setForeignCustomerVatSettingTexts() {
    const country = this.countryTarget.value;
    this.foreignVatSettingTextTargets.forEach((textTarget) => {
      const template = this.foreignVatSettingTextTemplateTargets.find((templateTarget) => {
        const { name, customerLocation } = templateTarget.dataset;
        return textTarget.dataset.name === name && customerLocation === this.location;
      });
      textTarget.innerHTML = interpolate(template, { country: country });
    });
  }
}
