import { initDatatable } from "./datatable_factory";
import { dataTableFunctions } from "./datatable_functions";
import { compiledTemplate, interpolate, setTemplatePartial } from "../utils/string_utils";
import { dateWithAbbrMonth } from "../utils/date_utils";
import { registerNumberToCurrencyHelper } from "../utils/handlebars_helpers";
import { amountFilterRange, computePeriodDateRange } from "./datatable_filters";
import { inputValueById } from "../utils/inputs_utils";
import { i18nTranslations } from "../utils/i18n_utils";

const initSalesDatatable = (datatableElement, isSalesInvoice) => {
  const columns = datatableColumns(datatableElement);
  const columnIndexes = dataTableFunctions().columnIndexes({ columns: columns });

  registerNumberToCurrencyHelper();

  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[columnIndexes[columnNames.date], "desc"]],
    columns: datatableColumns(isSalesInvoice),
    setRequestData: setRequestData(),
    onComplete: onComplete(datatableElement),
    onDraw: onDraw(),
    onCreatedRow: onCreatedRow(),
    exportable: true,
  };
  initDatatable(payload);
};

const updateSalesInvoicesTableRows = (salesInvoices) => {
  document.dispatchEvent(new CustomEvent("datatable:reloadRows", { detail: salesInvoices }));
  document.dispatchEvent(new CustomEvent("datatable:refreshSidePanel"));
};

const columnNames = {
  input: "input",
  id: "id",
  customer: "customer",
  date: "date",
  due_date: "due_date",
  status: "status",
  amount: "amount",
};

const setRequestData = () => {
  return (data) => {
    const columnIndexes = dataTableFunctions().columnIndexes(data);

    dataTableFunctions().setColumnFilter(data, columnIndexes[columnNames.amount], amountFilter());

    data.period = computePeriodDateRange();
    data.type = inputValueById("type-filter");
    data.status = inputValueById("status-filter");
    data.currency = inputValueById("currency-filter");
    data.sales_search = document.getElementById("sales-search").value;

    if (document.getElementById("global-search-filtered-id").checked) {
      data.global_search_filtered_id = document.getElementById("global-search-filtered-id").value;
    }
  };
};

const amountFilter = () => {
  const rangeStartFilter = document.getElementById("sale-amount-min");
  if (rangeStartFilter) {
    return amountFilterRange(
      {
        amountEqInput: document.getElementById("sale-amount-eq"),
        amountLtInput: document.getElementById("sale-amount-lt"),
        amountGtInput: document.getElementById("sale-amount-gt"),
        amountBwInput: document.getElementById("sale-amount-bw"),
      },
      document.getElementById("sale-amount-min").value,
      document.getElementById("sale-amount-max").value
    );
  } else {
    return "";
  }
};

const datatableColumns = (isSalesInvoice) => {
  return [
    {
      data: columnNames.input,
      orderable: false,
      className: "select-column checkbox-element",
      render: (data, type, row) => {
        return interpolate(document.getElementById("select-item-checkbox-template"), {
          id: row.id,
        });
      },
    },
    {
      data: columnNames.customer,
      orderable: true,
      searchable: true,
      className: "customer-column",
      render: (data, type, row) => {
        const { name, reference, invoice_url, draft, credit_note, instalment_invoice, automatically_created } = data;

        setTemplatePartial("noteIcon", document.getElementById("note-icon-template"));

        return compiledTemplate(document.getElementById("sales-invoice-customer-column-template"), {
          name: name,
          reference: reference,
          invoice_url: invoice_url,
          draft: draft,
          quote: !isSalesInvoice,
          credit_note: credit_note,
          instalment_invoice: instalment_invoice,
          automatically_created: automatically_created,
          note: row.note,
          id: row.id,
        });
      },
    },
    {
      data: columnNames.date,
      orderable: true,
      searchable: true,
      className: "date-column",
      render: (data) => {
        if (!data) {
          return "";
        }
        return dateWithAbbrMonth(data);
      },
    },
    {
      data: columnNames.due_date,
      orderable: true,
      searchable: true,
      className: "due-date-column",
      render: (data, type, row) => {
        const { due_date, delay } = row;
        if (!due_date) {
          return "";
        }
        const daysDelayed = +delay;

        return compiledTemplate(document.getElementById("sales-invoice-due-date-column-template"), {
          due_date: dateWithAbbrMonth(due_date),
          delayed: daysDelayed !== 0,
          delay: daysDelayed,
          quote: !isSalesInvoice,
        });
      },
    },
    {
      data: columnNames.status,
      orderable: false,
      className: "status-column",
      render: (data, type, row) => {
        const { amounts } = row;
        const grossAmount = +amounts.gross_amount;
        const options = {
          status: invoiceStatus(isSalesInvoice, data, grossAmount),
          badge: invoiceStatusBadge(data),
          instalment: data === "instalment_cashed_in",
        };

        return compiledTemplate(document.getElementById("sales-invoice-status-column-template"), options);
      },
    },
    {
      data: columnNames.amount,
      orderable: true,
      className: "amount-column",
      render: (data, type, row) => {
        const { amounts } = row;
        const { gross_amount, original_amount, currency, vat, foreign_currency } = amounts;

        setTemplatePartial("amountWithConversion", document.getElementById("amount-with-conversion-template"));
        return compiledTemplate(document.getElementById("sales-invoice-amount-column-template"), {
          gross_amount: gross_amount,
          original_amount: original_amount,
          currency: currency,
          vat: vat,
          foreign_currency_amount: foreign_currency,
        });
      },
    },
    {
      data: columnNames.id,
      orderable: false,
      className: "ids-column",
      visible: false,
    },
  ];
};

const onComplete = (datatableElement) => {
  return () => {
    const search = document.getElementById("sales-search");
    dataTableFunctions().manageSearchBar(search, datatableElement.id);
    manualMatchingResponse();
  };
};

const manualMatchingResponse = () => {
  document.addEventListener("matching:updated", (e) => {
    const salesInvoices = e.detail.sales_invoices;
    updateSalesInvoicesTableRows(salesInvoices);
  });
};

const onDraw = () => {
  return () => {
    dataTableFunctions().manageFilteredSelection();
    window.dispatchEvent(new Event("initTooltip"));
  };
};

const onCreatedRow = () => {
  return (row, data) => {
    $(row).addClass(`accounted-${data.accounted}`);
  };
};

const invoiceStatusBadge = (status) => {
  if (["cashed", "instalment_issued", "settlement_issued"].includes(status)) {
    return "badge-success";
  } else if (["cancelled", "draft"].includes(status)) {
    return "badge-gray";
  } else if (status === "accepted") {
    return "badge-accepted";
  } else if (status === "rejected") {
    return "badge-rejected";
  } else if (status === "sent") {
    return "badge-sent";
  } else {
    return "badge-pending";
  }
};

const invoiceStatus = (isSalesInvoice, status, grossAmount) => {
  if (isSalesInvoice) {
    const displayStatus = salesInvoiceDisplayStatus(status, grossAmount);
    return i18nTranslations().t(displayStatus, { scope: "sales_invoices.status" });
  } else {
    return i18nTranslations().t(status, { scope: "quotes.status" });
  }
};

const salesInvoiceDisplayStatus = (status, grossAmount) => {
  if (status === "cashed" && grossAmount < 0) {
    return "deducted";
  }

  if (["unpaid", "instalment_cashed_in"].includes(status)) {
    return "pending";
  }

  return status;
};

export { initSalesDatatable, updateSalesInvoicesTableRows, salesInvoiceDisplayStatus };
