const checkboxWithImage = () => {
  document.querySelectorAll(".checkbox-as-label.with-image").forEach((checkbox) => {
    const checkboxInput = checkbox.querySelector("input[type='checkbox']");
    const checkboxLabel = checkbox.querySelector("label");
    const checkboxImg = checkbox.querySelector("img");
    const standardImg = checkboxImg.src;
    const hoverImg = checkboxImg.dataset.imgHoverPath;
    const checkedImg = checkboxImg.dataset.imgCheckedPath;

    checkboxLabel.addEventListener("mouseover", () => {
      if (checkboxInput.checked) {
        return;
      }

      checkboxImg.src = hoverImg;
    });

    checkboxLabel.addEventListener("mouseout", () => {
      if (checkboxInput.checked) {
        return;
      }

      checkboxImg.src = standardImg;
    });

    checkboxInput.addEventListener("input", () => {
      if (checkboxInput.checked) {
        checkboxImg.src = checkedImg;
      } else {
        checkboxImg.src = hoverImg;
      }
    });
  });
};

export { checkboxWithImage };
