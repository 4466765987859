// This files contains extra validators coming from the src/extra source code of the parsley library.
// It can also contains various other custom validators to be reused.

import { convertFrenchDateToISO, isDateValid, isFrenchFormattedDate } from "./date_utils";
import { APPLICATION_TIME_ZONE } from "./constants";
import { allEmailsValid } from "./misc";

const parseRequirement = (requirement) => {
  if (isNaN(+requirement)) {
    return parseFloat(document.querySelector(requirement).value);
  } else {
    return +requirement;
  }
};

const parseDateRequirement = (requirement) => {
  let date = requirement;
  if (!isDateValid(new Date(requirement))) {
    date = document.querySelector(requirement).value;
  }
  // We use convertFrenchDateToISO here to ensure that in the context of custom date validators, both the value and the requirement are based on the same timezone
  return convertFrenchDateToISO(new Date(date).toLocaleDateString("fr-FR", { timeZone: APPLICATION_TIME_ZONE }));
};

export const initLessThanOrEqualToValidator = () => {
  window.Parsley.addValidator("lte", {
    validateString: function (value, requirement) {
      return parseFloat(value) <= parseRequirement(requirement);
    },
    priority: 32,
  });
};

export const initDateAfterOrEqualToValidator = () => {
  window.Parsley.addValidator("dgte", {
    validateString: function (value, requirement) {
      /**
       * NOTE(Jonathan Jalal): In this validator and the dlte validator, we have an issue on iOS devices with Chrome related to flatpickr :
       * the flatpickr altInput is ignored, and the hidden date input is displayed instead
       * Upon selecting a date from that input, its value has the standard date format (Y-m-d) instead of the French format (dd/mm/yyyy)
       *
       * That's why we need to make sure that the selected date has the correct format in both validators
       */
      let formattedValue = new Date(value);
      if (isFrenchFormattedDate(value)) {
        formattedValue = convertFrenchDateToISO(value);
      }
      return formattedValue >= parseDateRequirement(requirement);
    },
    priority: 32,
  });
};

export const initDateBeforeOrEqualToValidator = () => {
  window.Parsley.addValidator("dlte", {
    validateString: function (value, requirement) {
      let formattedValue = new Date(value);
      if (isFrenchFormattedDate(value)) {
        formattedValue = convertFrenchDateToISO(value);
      }
      return formattedValue <= parseDateRequirement(requirement);
    },
    priority: 32,
  });
};

export const initExcludedFromValidator = () => {
  window.Parsley.addValidator("excludedFrom", {
    validateString: function (value, requirement) {
      // When using this validator, we need to prepend and append the '|' symbol to our requirement,
      // which is why we slice it below.
      const requirementArray = requirement.slice(1, -1).split(",");
      return !requirementArray.includes(value);
    },
    priority: 32,
  });
};

export const setMultipleEmailsValidator = () => {
  window.Parsley.addValidator("multipleEmails", {
    validateString: (value) => {
      return allEmailsValid(value);
    },
    messages: {
      fr: "Doit être un email valide, ou plusieurs emails valides séparés par des virgules",
    },
    priority: 32,
  });
};

export const initNonZeroValidator = () => {
  window.Parsley.addValidator("nonZero", {
    validateString: function (value) {
      return value !== 0;
    },
    messages: {
      fr: "Ne peut être 0",
    },
    priority: 32,
  });
};

export const initRequiredIfValidator = () => {
  window.Parsley.addValidator("requiredIf", {
    validateString: (value, requirement) => {
      const [selector, requiredValue] = requirement.split(":");
      const requiredElement = document.querySelector(selector);
      if (requiredElement.value !== requiredValue) {
        return true;
      }

      return value !== "";
    },
    messages: {
      fr: "Ce champ est requis",
    },
    priority: 32,
  });
};

export const standardErrorsContainer = () => {
  return {
    errorsContainer: function (ParsleyField) {
      if (ParsleyField.$element[0].type === "select-one") {
        return ParsleyField.$element.parent();
      }
    },
  };
};
