import { Controller } from "@hotwired/stimulus";
import { fileUploadFunctions } from "../components/file_upload_functions";

export default class extends Controller {
  static targets = ["emptyImg", "emptyHoverImg", "input", "previews", "noFileElement", "fileLabel", "submit"];

  manageDragEnter(event) {
    event.preventDefault();
    this.emptyImgTarget.classList.add("hidden");
    this.emptyHoverImgTarget.classList.remove("hidden");
  }

  manageDragLeave(event) {
    event.preventDefault();
    this.emptyImgTarget.classList.remove("hidden");
    this.emptyHoverImgTarget.classList.add("hidden");
  }

  acceptFile(event) {
    event.preventDefault();
  }

  dropFile(event) {
    event.preventDefault();
    const dataTransfer = event.dataTransfer;
    const droppedFiles = dataTransfer.files;

    const permittedNumberOfFiles = this.inputTarget.dataset.numberOfFiles;
    if (!fileUploadFunctions().numberOfFilesValid(permittedNumberOfFiles, droppedFiles.length)) {
      fileUploadFunctions().alertInvalidFileNumber(permittedNumberOfFiles);
      return;
    }

    const filesFormat = Array.from(droppedFiles).map((file) => file.type);
    if (!fileUploadFunctions().filesFormatValid(filesFormat)) {
      fileUploadFunctions().alertInvalidFileFormat();
      return;
    }

    this.inputTarget.files = droppedFiles;
    const inputEvent = new Event("input");
    this.inputTarget.dispatchEvent(inputEvent);
  }

  displayPreview() {
    const files = this.inputTarget.files;

    if (files.length === 0) {
      this.noFileElementTarget.classList.remove("hidden");
      this.emptyImgTarget.classList.remove("hidden");
      this.emptyHoverImgTarget.classList.add("hidden");
      this.previewsTarget.classList.add("hidden");
      this.submitTarget.disabled = true;
    } else {
      this.noFileElementTarget.classList.add("hidden");
      this.previewsTarget.classList.remove("hidden");
      this.submitTarget.disabled = false;
      Array.from(files).forEach((file) => {
        const fileUrl = URL.createObjectURL(file);
        this.previewsTarget.innerHTML = fileUploadFunctions().filePreview(fileUrl, file.type);
      });
    }

    this.fileLabelTarget.innerText = fileUploadFunctions().fileLabelText(files.length);
  }
}
