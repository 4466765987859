const switchButton = () => {
  document.querySelectorAll(".switch-button").forEach(function (element) {
    element.addEventListener("click", function (event) {
      const divSwitchButton = element.closest(".div-switch-button");
      divSwitchButton.classList.toggle("on");
      if (divSwitchButton.classList.contains("on")) {
        divSwitchButton.querySelector(".button-in-switch").style.left = `${
          divSwitchButton.querySelector(".switch-button").getBoundingClientRect().width -
          divSwitchButton.querySelector(".switch-button").getBoundingClientRect().height +
          2
        }px`;
        divSwitchButton.querySelector("input").attributes["value"].value = divSwitchButton.querySelector(
          ".switch-on"
        ).attributes["value"].value;
      } else {
        divSwitchButton.querySelector(".button-in-switch").style.left = "2px";
        divSwitchButton.querySelector("input").attributes["value"].value = divSwitchButton.querySelector(
          ".switch-off"
        ).attributes["value"].value;
      }
    });
  });
};

export { switchButton };
