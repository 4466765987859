import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";
import { updateSalesInvoicesTableRows } from "../../datatables/sales_invoices_datatable";
import { errorMessages, flashAlert } from "../../components/flash_alert";

export default class extends Controller {
  afterAjax(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const salesInvoices = JSON.parse(response).sales_invoices;
        updateSalesInvoicesTableRows(salesInvoices);
      },
      onError: (response, status) => {
        this._handleErrors(response, status);
      },
    });
  }

  _handleErrors(response, status) {
    const data = JSON.parse(response);

    let message = errorMessages(status);
    if (data.message) {
      message = data.message;
    }

    flashAlert(message);
  }
}
