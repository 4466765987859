const initCardForm = () => {
  const paymentMethodForm = document.querySelector(".stripe-add-card-form");
  if (paymentMethodForm) {
    const stripe = Stripe(envVariables["stripe_pk"]);
    const elements = stripe.elements();
    const style = {
      base: {
        color: "#13216A",
        fontSize: "14px",
      },
    };

    const options = {
      style: style,
    };

    const cardElement = elements.create("card", options);

    cardElement.mount("#card-element");

    paymentMethodElementErrorEvent(cardElement, paymentMethodForm);

    const cardButton = document.getElementById("card-button");
    const clientSecret = cardButton.dataset.secret;

    cardButton.addEventListener("click", function () {
      formSubmitDisplay(paymentMethodForm);

      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        })
        .then(function (result) {
          if (result.error) {
            errorSetup(result, paymentMethodForm);
          } else {
            successSetup(result);
          }
        });
    });
  }
};

const initSEPAForm = () => {
  const paymentMethodForm = document.querySelector(".stripe-add-sepa-form");
  if (paymentMethodForm) {
    const stripe = Stripe(envVariables["stripe_pk"]);
    const elements = stripe.elements();
    const style = {
      base: {
        color: "#13216A",
        fontSize: "14px",
        "::placeholder": {
          color: "#aab7c4",
        },
        ":-webkit-autofill": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
        ":-webkit-autofill": {
          color: "#fa755a",
        },
      },
    };

    const options = {
      style: style,
      supportedCountries: ["SEPA"],
      placeholderCountry: "FR",
    };

    // Create an instance of the IBAN Element
    const iban = elements.create("iban", options);

    // Add an instance of the IBAN Element into the `iban-element` <div>
    iban.mount("#iban-element");

    paymentMethodElementErrorEvent(iban, paymentMethodForm);

    const accountholderName = paymentMethodForm.querySelector("#accountholder-name");
    const email = paymentMethodForm.querySelector("#email");
    const submitButton = paymentMethodForm.querySelector("#submit-sepa-button");
    const clientSecret = submitButton.dataset.secret;

    paymentMethodForm.addEventListener("submit", function (event) {
      formSubmitDisplay(paymentMethodForm);

      event.preventDefault();
      stripe
        .confirmSepaDebitSetup(clientSecret, {
          payment_method: {
            sepa_debit: iban,
            billing_details: {
              name: accountholderName.value,
              email: email.value,
            },
          },
        })
        .then(function (result) {
          if (result.error) {
            errorSetup(result, paymentMethodForm);
          } else {
            successSetup(result);
          }
        });
    });
  }
};

const displayError = (error, form) => {
  const errorMessageElement = form.querySelector(".error-message");
  if (!errorMessageElement) {
    return;
  }

  errorMessageElement.textContent = error ? error.message : "";
};

const paymentMethodElementErrorEvent = (paymentMethodElement, form) => {
  paymentMethodElement.on("change", ({ error }) => {
    displayError(error, form);
  });
};

const formSubmitDisplay = (form) => {
  form.querySelector(".submit-payment-method-button").classList.add("hidden");
  form.querySelector(".loading-image").classList.remove("hidden");
};

const errorSetup = (result, form) => {
  form.querySelector(".payment-method-element").classList.add("StripeElement--invalid");
  form.querySelector(".submit-payment-method-button").classList.remove("hidden");
  form.querySelector(".loading-image").classList.add("hidden");
  displayError(result.error, form);
};

const successSetup = (result) => {
  document.getElementById("payment-method-id").value = result["setupIntent"]["payment_method"];
  Rails.fire(document.getElementById("form-add-payment-method"), "submit");
};

// This needs to be called here !
initCardForm();
initSEPAForm();

export { initCardForm, initSEPAForm };
