import { updateDueDate } from "./sales_invoices/compute_due_date";

const initSalesNewSelect2 = () => {
  const salesPage = document.querySelector(".sales-show-card");
  if (salesPage) {
    $(".dropdown-select2").select2({
      dropdownAutoWidth: true,
    });

    // A l'edit d'une sales invoice, on add 'hidden' au span select2
    const salesInvoiceLines = Array.from(document.querySelectorAll(".mandatory-sales-invoice-line"));
    salesInvoiceLines.forEach((line) => {
      Array.from(line.getElementsByTagName("select")).forEach((el) => {
        if (el.value === "free") {
          el.nextElementSibling.classList.add("hidden");
        }
      });
    });

    const freeInputs = document.querySelectorAll(".invoice_client_setting_field");
    freeInputs.forEach((t) => {
      t.addEventListener("keyup", function (e) {
        if (e.key === "Escape") {
          const select = t.parentElement.parentElement.getElementsByTagName("select")[0];
          $([select]).val("0");
          $([select]).trigger("change");
        }
      });
    });

    $(".dropdown-select2").on("select2:open", function () {
      const results = document.querySelector(".select2-results__options");
      results.style.maxHeight = "600px";
    });

    $(".dropdown-select2").on("change", function () {
      if (this.parentNode.parentNode.parentNode.id !== "new_customer_setting") {
        if (this.id === "collection_payment_terms" && this.value !== "free") {
          updateDueDate(this.value);
        }

        if (this.value === "free" || this.value === "Autres (préciser)") {
          this.parentElement.parentElement.getElementsByTagName("input")[0].classList.remove("hidden");
          this.classList.add("hidden");
          this.nextElementSibling.classList.add("hidden");
          this.parentElement.style.marginBottom = 0;
          //si c'est un element d'une ligne alors on met le name du select dans l'input
          if (this.classList.contains("invoice_client_line_field_collection")) {
            if (this.name !== "") {
              this.parentElement.parentElement.getElementsByTagName("input")[0].name = this.name;
              this.name = "";
            }
          }
        } else {
          if (this.id === "invoice_collection_clients") {
            if (this.value >= 1) {
              //on ajoute dans la form caché l'id du user dans l'input correspondant
              document.getElementById("sale_invoice_form_customer_id").value = this.value;
              document.getElementById("invoice_hidden_btn_clients").click();
            } else {
              document.getElementById("invoice_div_client").innerHTML = "";
            }
          } else {
            if (this.parentElement.parentElement.getElementsByTagName("input")[0]) {
              this.parentElement.parentElement.getElementsByTagName("input")[0].classList.add("hidden");
            }
          }
        }
        if (this.id === "collection_misc_terms" && this.selectedIndex > 0) {
          document.getElementById(this.id.replace("collection", "field")).classList.remove("hidden");
          this.classList.add("hidden");
          this.nextElementSibling.classList.add("hidden");
          this.parentElement.style.marginBottom = 0;
          if (this.value === "Autres (préciser)") {
            document.getElementById(this.id.replace("collection", "field")).value = "";
          } else {
            document.getElementById(this.id.replace("collection", "field")).value = this.value;
          }
        }
      }
    });
  }
};

export { initSalesNewSelect2 };
