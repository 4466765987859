const preventEnterOnSocialOnboarding = () => {
  const payslipOnboardingPage = document.getElementById("payslip_info_fulfillment_main_page");
  if (payslipOnboardingPage) {
    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
  }
};

export { preventEnterOnSocialOnboarding };
