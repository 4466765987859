import { inputIsValid } from "../components/input_validation";
import { inputToValidate } from "../utils/inputs_utils";

const checkInputsBeforeCpaLetterCreation = () => {
  const createCpaLetterBtn = document.getElementById("create-cpa-cancellation-letter");
  if (createCpaLetterBtn) {
    createCpaLetterBtn.addEventListener("click", (e) => {
      const inputsNeededToCreate = [
        "last-name",
        "first-name",
        "email",
        "name",
        "street-address",
        "zip-address",
        "city-address",
        "cancellation-date",
      ].map((name) => document.getElementById(`accounting-data-cpa-${name}`));

      // prevent default event if inputs needed are not all valid
      const invalidInputs = inputsNeededToCreate.filter((input) => !inputIsValid(input));
      if (invalidInputs.length > 0) {
        e.preventDefault();
        invalidInputs.forEach((input) => {
          inputToValidate(input).classList.add("not-valid");
        });
        document.getElementById("save-error-message").classList.remove("hidden");
      } else {
        document.getElementById("save-error-message").classList.add("hidden");
      }
    });
  }
};

export { checkInputsBeforeCpaLetterCreation };
