const changeValueOfCell = (
  row,
  columnClassName,
  newHtml,
  selectorForAnimation,
  animationClass = "animate__bounceIn"
) => {
  if (row) {
    const column = row.querySelector(`.${columnClassName}`);
    column.innerHTML = newHtml;
    const columnToAnimate = selectorForAnimation === "" ? column : column.querySelector(selectorForAnimation);
    if (!columnToAnimate) {
      return;
    }

    columnToAnimate.classList.add("animate__animated", animationClass);
    column.addEventListener("animationend", () => {
      columnToAnimate.classList.remove("animate__animated", animationClass);
    });
  }
};

export { changeValueOfCell };
