const matchingModalFunctions = () => {
  const publicFunctions = {};

  publicFunctions.targetShouldToggleMatchingSelection = (target) => {
    return !!target.closest(".matching-cta");
  };

  publicFunctions.descriptionLine = (target) => {
    return target.querySelector(".line-item");
  };

  publicFunctions.formElement = () => {
    return document.getElementById("matching-modal-form");
  };

  return publicFunctions;
};

export { matchingModalFunctions };
