const initProfilesSelect2 = () => {
  $(".account-select-2").select2({
    minimumResultsForSearch: -1,
    placeholder: "Sélectionner",
  });

  $(".account-select-2").on("select2:open", function (e) {
    document.querySelector(".select2-results__options").style.maxHeight = "210px";
  });

  $(".account-select-2").on("select2:select", function (e) {
    this.dispatchEvent(new Event("input"));
  });

  $(".account-select-2.search-select2").select2({
    language: {
      noResults: function () {
        return "Aucun résultat";
      },
    },
    placeholder: "Selectionner",
  });
};

export { initProfilesSelect2 };
