import { Controller } from "@hotwired/stimulus";
import { initGlobalSearch, GLOBAL_SEARCH_MIN_CHAR_NUMBER } from "../components/global_search";
import { isEnterKey } from "../utils/inputs_utils";

export default class extends Controller {
  static targets = ["searchInput"];

  connect() {
    initGlobalSearch(this.searchInputTarget);
  }

  validateSubmission(e) {
    if (isEnterKey(e.keyCode) && this.searchInputTarget.value.length < GLOBAL_SEARCH_MIN_CHAR_NUMBER) {
      e.preventDefault();
    }
  }
}
