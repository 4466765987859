import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "completenessContainer"];

  displayForm(e) {
    const shareholderId = e.target.dataset.shareholderId;
    this.formTargets.find((form) => form.dataset.shareholderId === shareholderId).classList.remove("hidden");
    if (this.hasCompletenessContainerTarget) {
      this.completenessContainerTarget.classList.remove("hidden");
    }
  }
}
