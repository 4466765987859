import { roundToTwoDecimals } from "../utils/number_utils";

export default class SalesInvoice {
  set discountType(_discountType) {
    this._discountType = _discountType;
  }

  set discountValue(_discountValue) {
    this._discountValue = _discountValue;
  }

  netTotalBeforeDiscount(lines) {
    const { linesNetTotal } = this._computeLineData(lines);
    return roundToTwoDecimals(linesNetTotal);
  }

  netTotalAfterDiscount(netTotalBeforeDiscount) {
    let totalAfterDiscount = netTotalBeforeDiscount;
    if (this._discountValue) {
      if (this._discountType === "percent") {
        totalAfterDiscount = (1 - this._discountValue / 100) * netTotalBeforeDiscount;
      } else {
        totalAfterDiscount = netTotalBeforeDiscount - this._discountValue;
      }
    }
    return roundToTwoDecimals(totalAfterDiscount);
  }

  vatTotal(lines) {
    const { vatAmount, linesNetTotal } = this._computeLineData(lines);

    return roundToTwoDecimals(vatAmount * (1 - this._globalDiscountRatio(linesNetTotal)));
  }

  grossTotal(lines) {
    const totalBeforeDiscount = this.netTotalBeforeDiscount(lines);
    const totalAfterDiscount = this.netTotalAfterDiscount(totalBeforeDiscount);
    const vatTotal = this.vatTotal(lines);

    return roundToTwoDecimals(totalAfterDiscount + vatTotal);
  }

  _globalDiscountRatio(linesNetTotal) {
    const isValidDiscount = this._discountValue && linesNetTotal !== 0;
    const isPercentageDiscount = this._discountType === "percent";

    if (isValidDiscount && isPercentageDiscount) {
      return this._discountValue / 100;
    }

    if (isValidDiscount) {
      return this._discountValue / linesNetTotal;
    }

    return 0;
  }

  _computeLineData(lines) {
    return lines.reduce(
      function (acc, line) {
        acc.vatAmount += line.netTotal() * line._vatRate;
        acc.linesNetTotal += line.netTotal();
        return acc;
      },
      { vatAmount: 0, linesNetTotal: 0 }
    );
  }
}
