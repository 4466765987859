import { French } from "flatpickr/dist/l10n/fr.js";
import flatpickr from "flatpickr";

const initFrenchCalendarNoFuture = (dateInputSelector, options = {}) => {
  const basicOptions = {
    locale: French,
    dateFormat: "d/m/Y",
    maxDate: "today",
    allowInput: false,
  };
  const flatpickrOptions = { ...basicOptions, ...options };

  flatpickr(dateInputSelector, flatpickrOptions);
};

export { initFrenchCalendarNoFuture };
