import { Controller } from "@hotwired/stimulus";
import { flashAlert } from "../../components/flash_alert";
import { pluralize } from "../../utils/string_utils";

export default class extends Controller {
  connect() {
    const companyId = this.element.dataset.companyId;
    App.cable.subscriptions.create(
      { channel: "PurchaseZipFileImportedChannel", company_id: companyId },
      {
        received: (data) => {
          const table = $("#purchases_datatable").DataTable();
          table.draw();

          const message = this._buildFlashMessage(data.file_name, data.nb_created, data.nb_duplicates);
          flashAlert(message);
        },
      }
    );
  }

  _buildFlashMessage(fileName, nbOfPurchasesCreated, nbOfDuplicates) {
    let message = `L'import du fichier ${fileName} est terminé.`;

    if (nbOfPurchasesCreated === 0) {
      message += " Aucune facture d'achat n'a pu être importée. ";
    } else {
      message += ` ${pluralize(nbOfPurchasesCreated, "facture")} d'achat ${"importée".pluralize(
        nbOfPurchasesCreated
      )} avec succès. `;
    }

    if (nbOfDuplicates > 0) {
      message += this._duplicatesMessage(nbOfDuplicates);
    }

    return message;
  }

  _duplicatesMessage(nbOfDuplicates) {
    if (nbOfDuplicates === 1) {
      return "Une facture avait déjà été importée";
    } else if (nbOfDuplicates > 1) {
      return ` ${nbOfDuplicates} factures avaient déjà été importées`;
    }
  }
}
