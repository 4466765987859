import { Controller } from "@hotwired/stimulus";
import { initDropzone, initDropzoneBasicEvents } from "../../components/dropzone";
import { toggleClass } from "../../utils/misc";

export default class extends Controller {
  static targets = ["filePreviewsContainer", "uploadImgLabel", "submitBtn", "dragAndDropContainer"];

  connect() {
    this._initDropzone();
  }

  validateSubmission(e) {
    e.preventDefault();
    this.dropzone.processQueue();
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.element.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
      paramName: "attachment_files",
      maxFiles: 10,
    };
  }

  _initCustomEvents() {
    this.dropzone.on("addedfiles", () => {
      this._toggleSubmitBtn();
    });

    this.dropzone.on("removedfile", () => {
      this._toggleSubmitBtn();
    });

    this.dropzone.on("success", (e) => {
      // A form submitted with dropzone does not listen to ajax events
      // So we are dispatching this event manually as the dropzone success event corresponds to an ajax:success if the form was submitted natively
      this.element.dispatchEvent(new CustomEvent("ajax:success", { detail: [e.xhr] }));
    });
  }

  _toggleSubmitBtn() {
    const hasAcceptedFiles = this.dropzone.files.length > 0;
    toggleClass(this.submitBtnTarget, "hidden", hasAcceptedFiles);
  }
}
