import { Controller } from "@hotwired/stimulus";
import { isStringTrue, updateTooltipTitle } from "../../utils/misc";

export default class extends Controller {
  static targets = ["displayStatusIcon"];

  onDisplayStatusToggle(event) {
    let message;
    const statusIcon = this.displayStatusIconTarget;
    const accountVisibilityStatus = event.detail[0].visible;

    if (isStringTrue(accountVisibilityStatus)) {
      statusIcon.classList.remove("account-hidden");
      message = "Ce compte est actuellement affiché.";
    } else {
      statusIcon.classList.add("account-hidden");
      message = "Ce compte est actuellement masqué.";
    }

    updateTooltipTitle(statusIcon, message);
  }
}
