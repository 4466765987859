import { Controller } from "@hotwired/stimulus";
import { employerSalaryCost } from "../../utils/business_utils";
import { numberToCurrency } from "../../utils/number_utils";

export default class extends Controller {
  static targets = [
    "totalCompanyCost",
    "salaryRequestContainer",
    "destroyInput",
    "grossAmountInput",
    "salaryRequestDateInput",
    "deletedDateInput",
  ];

  connect() {
    this.updateCompanyCost();
  }

  updateCompanyCost() {
    const grossAmount = this.grossAmountInputTarget.value;
    const companyCost = employerSalaryCost(grossAmount);
    this.totalCompanyCostTarget.value = numberToCurrency(companyCost);
  }

  delete() {
    this.destroyInputTarget.value = "true";
    this.deletedDateInputTarget.value = this.salaryRequestDateInputTarget.value;
    this.salaryRequestContainerTarget.remove();
  }
}
