import { Controller } from "@hotwired/stimulus";
import { computeNumberOfFiles } from "../../documents/number_of_files_computations";

export default class extends Controller {
  connect() {
    computeNumberOfFiles();

    document.addEventListener("documents:folderUpdated", () => {
      computeNumberOfFiles();
    });
  }
}
