import { Controller } from "@hotwired/stimulus";
import { errorMessages, notifyError } from "../components/flash_alert";

export default class extends Controller {
  static targets = ["loader", "container"];

  connect() {
    window.$(this.element).one("show.bs.modal", () => {
      this._toggleElements();
      fetch(this.element.dataset.url, {
        headers: { accept: "text/html_fragment" },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            return null;
          }
        })
        .then((data) => {
          if (data) {
            this.containerTarget.innerHTML = data;
          } else {
            notifyError(errorMessages());
            window.$(this.element).modal("hide");
          }
          this._toggleElements();
        });
    });
  }

  _toggleElements() {
    this.loaderTarget.classList.toggle("hidden");
    this.containerTarget.classList.toggle("hidden");
  }
}
