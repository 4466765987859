import { Controller } from "@hotwired/stimulus";
import { fetchStream } from "../utils/turbo_utils";

export default class extends Controller {
  static targets = ["loader", "container"];

  connect() {
    // this is needed because of different setup for bootstrap in front and back office
    let element = $(this.element);
    if (!element.modal) {
      element = window.$(this.element);
    }

    element.on("show.bs.modal", (e) => {
      const url = e.relatedTarget.dataset.url;

      this._loadModal(url);
    });
  }

  _loadModal(url) {
    this._toggleElements();
    fetchStream(url).then(() => this._toggleElements());
  }

  _toggleElements() {
    this.loaderTarget.classList.toggle("hidden");
    this.containerTarget.classList.toggle("hidden");
  }
}
