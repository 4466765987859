import { Controller } from "@hotwired/stimulus";
import { fetchStream } from "../../utils/turbo_utils";
import { interpolate } from "../../utils/string_utils";

export default class extends Controller {
  static targets = ["loaderTemplate", "turboFrameElement"];

  connect() {
    window.$(this.element).on("show.bs.modal", (e) => {
      // We add loader into turbo frame element on modal opening that will be updated once fetch is made
      this.turboFrameElementTarget.innerHTML = interpolate(this.loaderTemplateTarget);
      const url = e.relatedTarget.dataset.sourceUrl;
      fetchStream(url);
    });
  }
}
