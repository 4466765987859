import { Controller } from "@hotwired/stimulus";
import { flashAlert, notifyError } from "../components/flash_alert";
import { handleHttpResponse } from "../utils/http_utils";

export default class extends Controller {
  afterAjax(e) {
    const successMessage = e.params.successMessage || "Paramètres sauvegardés";

    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: () => {
        flashAlert(successMessage);
      },
      onError: () => {
        notifyError();
      },
    });
  }
}
