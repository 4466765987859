import { Controller } from "@hotwired/stimulus";
import { formattedDate } from "../../utils/date_utils";
import { numberToCurrency } from "../../utils/number_utils";
import { externalLink } from "../../utils/misc";
import { interpolate } from "../../utils/string_utils";
import { initDatatable } from "../../datatables/datatable_factory";
import SeeDocumentImg from "../../../assets/images/tables_picto/Eyesblue.svg";
import { isDatatableUninitialized } from "../../datatables/datatable_functions";

export default class extends Controller {
  initialize() {
    if (isDatatableUninitialized(this.element)) {
      this._initDatatable();
    }
  }

  _initDatatable() {
    const payload = {
      datatableElement: this.element,
      orderColumns: [[2, "asc"]],
      columns: this._datatableColumns(),
    };
    initDatatable(payload);
  }

  _datatableColumns() {
    return [
      {
        data: "input",
        orderable: false,
        className: "select-column checkbox-element",
        render: (data, type, row) =>
          interpolate(document.getElementById("select-item-checkbox-template"), { id: row.id }),
      },
      { data: "id", orderable: false, className: "ids-column", visible: false },
      { data: "company", orderable: true, className: "company-column" },
      { data: "initial_value", orderable: false, className: "amount-column", render: (data) => numberToCurrency(data) },
      { data: "current_value", orderable: false, className: "amount-column", render: (data) => numberToCurrency(data) },
      { data: "date", orderable: false, className: "date-column", render: (data) => formattedDate(data) },
      {
        data: "document",
        orderable: false,
        className: "document-column no-selection",
        render: (data) => {
          const img = `<img class='no-selection' src='${SeeDocumentImg}'>`;
          return externalLink(data, img, "no-selection").outerHTML;
        },
      },
    ];
  }
}
