import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["currentAllowanceRow", "rowsContainer", "loaderContainer"];

  connect() {
    if (this.hasCurrentAllowanceRowTarget) {
      this.rowsContainerTarget.scrollTop =
        this.currentAllowanceRowTarget.offsetTop - this.rowsContainerTarget.offsetTop;
    }
  }

  toggleLoader() {
    this.loaderContainerTarget.classList.toggle("hidden");
  }
}
