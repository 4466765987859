const openFileUploadOnDocumentCardClick = () => {
  const documentCards = document.querySelectorAll(".document-card");
  if (documentCards) {
    documentCards.forEach((card) => {
      card.addEventListener("click", (e) => {
        document.getElementById(e.currentTarget.dataset.target).click();
      });
    });
  }
};

const changeThumbnailAtNewUpload = () => {
  const filesInputs = document.querySelectorAll("#profile_fulfillment_modal input[type='file']");
  if (filesInputs) {
    filesInputs.forEach((fileInput) => {
      fileInput.addEventListener("change", (e) => {
        let fileType = e.currentTarget.files[0].type.split("/");
        fileType = fileType[fileType.length - 1];
        const thumbnail = document.querySelector(`[data-target='${e.currentTarget.id}'] .document-thumbnail`);
        if (fileType == "pdf") {
          thumbnail.innerHTML = "<embed src='" + URL.createObjectURL(e.currentTarget.files[0]) + "'>";
        } else {
          thumbnail.innerHTML = "<img src='" + URL.createObjectURL(e.currentTarget.files[0]) + "'>";
        }
        thumbnail.classList.add("user-doc");
      });
    });
  }
};

export { openFileUploadOnDocumentCardClick, changeThumbnailAtNewUpload };
