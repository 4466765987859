import { showWaitingImage, hideWaitingImage } from "../manage_waiting_image_on_load.js";

const changeTabOnClick = () => {
  const pageTabs = document.querySelectorAll(".page-content .page-tab");
  if (pageTabs) {
    pageTabs.forEach((tab) => {
      tab.addEventListener("click", (event) => {
        pageTabs.forEach((t) => {
          t.classList.remove("active");
        });
        event.currentTarget.classList.add("active");
        showWaitingImage();
        event.currentTarget.querySelector("a").click();
      });
    });
  }
};

const showTabContent = (htmlContent) => {
  document.querySelector(".tab-content").innerHTML = htmlContent;
  hideWaitingImage();
};

const changeUrl = (newUrl) => {
  window.history.replaceState("", "", newUrl);
};

export { changeTabOnClick, showTabContent, changeUrl };
