import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { errorMessages, flashAlert, notifyError } from "../../components/flash_alert";
import { initCreationCustomFormValidators } from "../../creation/custom_validators";
import { displayCreationFormInvalidInputs } from "../../creation/display_invalid_inputs";
import { redirectTo } from "../../utils/misc";

export default class extends Controller {
  static targets = ["globalFormErrorMessage", "form"];

  connect() {
    initCreationCustomFormValidators();
  }

  onSave(e) {
    const inputs = this._inputsToBeCheckedOnSave();
    const invalidInputs = this._invalidInputs(inputs);

    if (invalidInputs.length > 0) {
      displayCreationFormInvalidInputs(invalidInputs, this.globalFormErrorMessageTarget);
      e.preventDefault();
    }
  }

  onNextStep(e) {
    const nextBtn = e.currentTarget;
    const inputs = this._inputsToBeCheckedOnNextStep();
    const invalidInputs = this._invalidInputs(inputs);

    // This button acts as a link, which redirects the user upon successful form submission
    if (invalidInputs.length > 0) {
      displayCreationFormInvalidInputs(invalidInputs, this.globalFormErrorMessageTarget);
    } else if (this.element.action) {
      const formData = $(this.element).serialize(); // serializes the form's elements.
      $.ajax({
        type: "POST",
        url: `${this.element.action}?next_step=true`,
        data: formData,
        success: () => {
          // Properly redirects after successfully saving the form data
          redirectTo(nextBtn.dataset.redirect);
        },
        error: (data) => {
          const response = JSON.parse(data.responseText);
          if ("message" in response) {
            flashAlert(response.message);
          } else {
            notifyError(errorMessages(data.status));
          }
        },
      });
    } else {
      redirectTo(nextBtn.dataset.redirect);
    }
  }

  _inputsToBeCheckedOnSave() {
    return this._allInputs().filter((input) => input.value.trim() !== "" && input.type !== "radio");
  }

  _inputsToBeCheckedOnNextStep() {
    return this._allInputs();
  }

  _invalidInputs(inputs) {
    return inputs.filter((input) => !input.disabled && !$(input).parsley().isValid());
  }

  _allInputs() {
    return this.formTargets.flatMap((form) => [...form.elements]);
  }
}
