import { launchASweetAlert } from "../components/init_sweet_alert";

const displayFilePreview = (file, fileInput) => {
  const preview = file.querySelector(".file-preview");
  const noFileInfos = file.querySelector(".no-file");
  const deleteAction = file.querySelector(".delete-document");
  const showDocument = file.querySelector(".view-document");
  const dropArea = file.querySelector(".drop-area");
  dropArea.classList.remove("not-valid");

  if (fileInput.files && fileInput.files[0]) {
    dropArea.dataset.hasFile = "true";
    noFileInfos.classList.add("hidden");
    preview.classList.remove("hidden");

    const fileItem = fileInput.files[0];
    let fileType = fileItem.type.split("/");
    fileType = fileType[fileType.length - 1];
    if (fileType === "pdf") {
      preview.innerHTML = `<embed src=${URL.createObjectURL(fileItem)}><p>${fileItem.name}</p>`;
    } else {
      preview.innerHTML = `<img src=${URL.createObjectURL(fileItem)}><p>${fileItem.name}</p>`;
    }

    fileInput.previousElementSibling.innerText = "Modifier";
    if (deleteAction) {
      deleteAction.classList.remove("hidden");
    }
    file.querySelector(".valid-input-icon").classList.remove("hidden");
    showDocument.attributes["href"].value = URL.createObjectURL(fileItem);
    showDocument.classList.remove("hidden");
  } else {
    dropArea.dataset.hasFile = "false";
    noFileInfos.classList.remove("hidden");
    preview.classList.add("hidden");
    preview.innerHTML = "";
    fileInput.previousElementSibling.innerText = "Charger le document";
    deleteAction.classList.add("hidden");
    file.querySelector(".valid-input-icon").classList.add("hidden");
    showDocument.classList.add("hidden");
  }
};

const manageProfileDragHover = (dropArea, dropImg, type) => {
  if (type === "hover") {
    dropImg.src = dropImg.dataset.hoverImg; // TODO: #deprecated looks like its not used
    dropArea.classList.add("dropping");
  } else {
    dropImg.src = dropImg.dataset.standardImg; // TODO: #deprecated looks like its not used
    dropArea.classList.remove("dropping");
  }
};

const fileDropped = (event, fileInput, dropArea, noFileImage) => {
  manageProfileDragHover(dropArea, noFileImage, "");

  const dt = event.dataTransfer;
  const newFiles = dt.files;

  if (newFiles.length > 1) {
    launchASweetAlert({
      title: "Un seul fichier est accepté",
      button: "ok",
    });
    return;
  } else if (!newFiles[0].type.includes("image") && !newFiles[0].type.includes("pdf")) {
    launchASweetAlert({
      title: "Seuls les fichiers au format jpg, png, jpeg et pdf sont acceptés",
      button: "ok",
    });
    return;
  }

  fileInput.files = newFiles;

  const customEvent = new Event("input");
  fileInput.dispatchEvent(customEvent);
};

export { displayFilePreview, fileDropped, manageProfileDragHover };
