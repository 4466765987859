import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";
import { updatePurchaseInvoicesTableRows } from "../../datatables/purchase_invoices_datatable";

export default class extends Controller {
  afterAjax(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const purchaseInvoices = JSON.parse(response).purchase_invoices;
        updatePurchaseInvoicesTableRows(purchaseInvoices);
      },
    });
  }
}
