import $ from "jquery";
import { initDropzone, initDropzoneBasicEvents, toggleDropzoneValidity } from "../components/dropzone";
import { handlePdfUploadFilePreview } from "./pdf_upload_file_preview";

const salesPdfUploadDropzoneElement = (controllerElement, targets) => {
  const { filePreviewsContainer, dragAndDropContainer, uploadImgLabel } = targets;
  const dropzone = initDropzone(controllerElement.id, {}, uploadOptions(filePreviewsContainer, dragAndDropContainer));
  initDropzoneBasicEvents(dropzone, uploadImgLabel, dragAndDropContainer);
  initCustomDropzoneEvents(dropzone);
  return dropzone;
};

const initCustomDropzoneEvents = (dropzone) => {
  dropzone.on("addedfile", (file) => {
    handlePdfUploadFilePreview(dropzone, file);
  });

  dropzone.on("removedfile", () => {
    handlePdfUploadFilePreview(dropzone, null);
  });
};

const uploadOptions = (filePreviewsContainer, dragAndDropContainer) => {
  return {
    previewsContainer: filePreviewsContainer,
    paramName: "sales_invoice[file]",
    acceptedFiles: ".pdf",
    clickable: `#${dragAndDropContainer.id}`,
  };
};

const submitDropzoneForm = (controllerElement, dropzoneElement) => {
  const isFormValid = $(controllerElement).parsley().validate();
  if (isFormValid) {
    if (dropzoneElement.files.length === dropzoneElement.options.maxFiles) {
      dropzoneElement.processQueue();
    } else {
      toggleDropzoneValidity(dropzoneElement, false);
    }
  }
};

export { salesPdfUploadDropzoneElement, submitDropzoneForm };
