import { Controller } from "@hotwired/stimulus";
import {
  clearInputValue,
  dateRangeLabel,
  dispatchFilterSelectedEvent,
  dispatchFilterUpdatedEvent,
} from "../../datatables/datatable_filters";
import { toggleClass } from "../../utils/misc";

export default class extends Controller {
  static targets = ["startDate", "endDate", "periodInput", "errorMessage"];

  rangeFilled() {
    [...this.periodInputTargets].forEach((input) => clearInputValue(input));
  }

  periodSelected() {
    [this.startDateTarget, this.endDateTarget].forEach((input) => clearInputValue(input));
  }

  filterChanged(event) {
    const isPeriodSelected = this.periodInputTargets.find((target) => target.checked);
    const isRangeFilled = !!this.startDateTarget.value || !!this.endDateTarget.value;

    const isFilterValid = isPeriodSelected || isRangeFilled;
    toggleClass(this.errorMessageTarget, "hidden", !isFilterValid);

    if (isFilterValid) {
      if (event.params.action === "update") {
        this._filterUpdated();
      } else {
        this._filterSelected(isPeriodSelected);
      }
    }
  }

  _filterSelected(isPeriodSelected) {
    const { filterName, filterLabel } = this.element.dataset;
    const filterData = [
      { id: this.startDateTarget.id, value: this.startDateTarget.value },
      { id: this.endDateTarget.id, value: this.endDateTarget.value },
    ];
    if (isPeriodSelected) {
      filterData.push({ id: isPeriodSelected.id });
    }

    dispatchFilterSelectedEvent({
      name: filterName,
      translatedValue: this._translatedValue(),
      label: filterLabel,
      data: filterData,
    });
  }

  _filterUpdated() {
    const { filterName } = this.element.dataset;
    dispatchFilterUpdatedEvent({ name: filterName, translatedValue: this._translatedValue() });
  }

  _translatedValue() {
    const startDate = this.startDateTarget.value;
    const endDate = this.endDateTarget.value;

    let value = dateRangeLabel(startDate, endDate);
    const selectedPeriodInput = this.periodInputTargets.find((input) => input.checked);
    if (selectedPeriodInput) {
      value = selectedPeriodInput.dataset.translatedValue;
    }
    return value;
  }
}
