const manageWaitingImageOnLoad = () => {
  const waitingImage = document.getElementById("waiting-image");
  const waitingImageBuddy = document.querySelector("#waiting-image + div");
  if (waitingImage && waitingImageBuddy) {
    window.addEventListener("load", () => {
      [waitingImage, waitingImageBuddy].forEach((el) => {
        el.classList.toggle("fade-out");
      });
      if (document.querySelector(".table-content")) {
        document.querySelector(".page-container").classList.add("page-table-container");
      }
      if (document.querySelector(".dashboard-cards")) {
        document.querySelector(".page-container").classList.add("dashboard-cards-container");
      }
      waitingImage.addEventListener("transitionend", () => {
        waitingImage.remove();
      });
    });
  }
};

const showWaitingImage = () => {
  const waitingImage = document.getElementById("waiting-image");
  const waitingImageBuddy = document.querySelector("#waiting-image + div");
  if (waitingImage && waitingImageBuddy) {
    waitingImage.style.display = "block";
    waitingImage.classList.remove("fade-out");
    waitingImageBuddy.style.display = "none";
    waitingImageBuddy.classList.add("fade-out");
  }
};

const hideWaitingImage = () => {
  const waitingImage = document.getElementById("waiting-image");
  const waitingImageBuddy = document.querySelector("#waiting-image + div");
  if (waitingImage && waitingImageBuddy) {
    waitingImage.classList.add("fade-out");
    waitingImageBuddy.classList.remove("fade-out");
    waitingImage.addEventListener("transitionend", () => {
      waitingImage.style.display = "none";
      waitingImageBuddy.style.display = "block";
    });
  }
};

export { manageWaitingImageOnLoad, showWaitingImage, hideWaitingImage };
