import { Controller } from "@hotwired/stimulus";
import { updateBankTransactionsTableRows } from "../../datatables/bank_transactions_datatable";
import { handleHttpResponse } from "../../utils/http_utils";

export default class extends Controller {
  afterAjax(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const bankTransactions = JSON.parse(response).bank_transactions;
        updateBankTransactionsTableRows(bankTransactions);
      },
    });
  }

  reloadTransactions(event) {
    updateBankTransactionsTableRows(event.detail);
  }

  reloadSingleRow(e) {
    const bankTransaction = e.detail.bank_transaction;
    updateBankTransactionsTableRows([bankTransaction]);
  }
}
