import { TURBO_STREAM_ACCEPT_HEADER } from "./constants";

export const fetchStream = (url) => {
  // Call a turbo-stream programmatically, similarly to how one would lazy-load a turbo-frame
  // See here for more context : https://github.com/hotwired/turbo/issues/34#issuecomment-821881266
  document.dispatchEvent(new Event("turbo:before-fetch-request"));
  const promise = fetch(url, {
    headers: {
      Accept: TURBO_STREAM_ACCEPT_HEADER,
    },
  })
    .then((r) => r.text())
    .then((html) => Turbo.renderStreamMessage(html))
    .then(() => document.dispatchEvent(new Event("turbo:before-fetch-response")));
  return promise;
};
