const flashAlert = (message, type = FLASH_TYPES.NOTICE.type) => {
  if (type === FLASH_TYPES.ALERT.type) {
    toastr.error(message);
  } else {
    toastr.success(message);
  }
  document.querySelector(".toast").classList.add("custom-alert");
};

const flashMessages = {
  basicUpdate: "Informations sauvegardées",
  basicError: "Une erreur s'est produite",
  cancel: "Les modifications saisies ont bien été annulées",
  forbidden: "Vous n'êtes pas autorisé à effectuer cette action",
  internalServerError:
    "Oups, il y a un problème avec cette fonctionnalité pour le moment. Nous avons été prévenus mais n'hésitez pas à nous prévenir par mail via coach@acasi.io",
};

const notifyCancel = () => {
  flashAlert(flashMessages.cancel);
};

const notifyUpdate = () => {
  flashAlert(flashMessages.basicUpdate);
};

const notifyError = (message = flashMessages.basicError) => {
  flashAlert(message, FLASH_TYPES.ALERT.type);
};

const errorMessages = (errorStatus) => {
  if (errorStatus === 403) return flashMessages.forbidden;

  if (errorStatus === 500) return flashMessages.internalServerError;

  return flashMessages.basicError;
};

const FLASH_TYPES = {
  NOTICE: { type: "notice" },
  ALERT: { type: "alert" },
};

export { flashAlert, flashMessages, notifyCancel, notifyUpdate, notifyError, errorMessages };
