import { Controller } from "@hotwired/stimulus";

const OPENED_DROPDOWN_CLASS = "opened";

export default class extends Controller {
  static targets = ["dropdownElementTrigger", "dropdownListContainer", "dropdownList"];

  toggleDropdown(e) {
    const elements = [this.dropdownElementTriggerTarget, this.dropdownListContainerTarget];
    if (this._shouldOpenDropdown(e.target)) {
      elements.forEach((element) => element.classList.add(OPENED_DROPDOWN_CLASS));
    } else {
      elements.forEach((element) => element.classList.remove(OPENED_DROPDOWN_CLASS));
    }
  }

  _shouldOpenDropdown(eventTarget) {
    if (!this._clickedOnDropdownTriggerElement(eventTarget)) {
      return false;
    }

    return !this.dropdownElementTriggerTarget.classList.contains(OPENED_DROPDOWN_CLASS);
  }

  _clickedOnDropdownTriggerElement(eventTarget) {
    return !!eventTarget.closest(`#${this.dropdownElementTriggerTarget.id}`);
  }
}
