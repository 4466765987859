const isObjectFilled = (obj) => {
  if (obj === null) return false;

  return obj instanceof FileList ? obj.length > 0 : Object.keys(obj).length > 0;
};

export const isInputFilled = (value) => {
  switch (typeof value) {
    case "string":
      return value.length > 0;
    case "number":
      return !isNaN(value);
    case "object":
      return isObjectFilled(value);
    default:
      return false;
  }
};
