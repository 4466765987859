import { Controller } from "@hotwired/stimulus";
import { initSalesDatatable, updateSalesInvoicesTableRows } from "../../datatables/sales_invoices_datatable";
import { isDatatableUninitialized } from "../../datatables/datatable_functions";
import { isStringTrue } from "../../utils/misc";

export default class extends Controller {
  initialize() {
    if (isDatatableUninitialized(this.element)) {
      const isSalesInvoice = isStringTrue(this.element.dataset.isSalesInvoice);
      initSalesDatatable(this.element, isSalesInvoice);

      document.addEventListener("salesInvoice:updated", (e) => {
        updateSalesInvoicesTableRows(e.detail.salesInvoices);
      });
    }
  }
}
