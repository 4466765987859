const showTabsDropdownOnClick = () => {
  const tabs = document.querySelectorAll(".mobile-page-content .page-tab");
  const activeTab = document.querySelector(".mobile-page-content .page-tab.active");
  if (tabs && activeTab && tabs.length > 1) {
    activeTab.addEventListener("click", (e) => {
      e.preventDefault();
      const pageTabs = activeTab.parentNode;
      pageTabs.classList.toggle("deployed");
      pageTabs.nextElementSibling.classList.toggle("fade");
    });
  }
};

const hideTabsDropdown = () => {
  const pageTabs = document.querySelector(".mobile-page-content .page-tabs");
  pageTabs.classList.remove("deployed");
  pageTabs.nextElementSibling.classList.remove("fade");
};

const addBorderRadiusToLastVisibleTab = () => {
  const tabs = document.querySelectorAll(".mobile-page-content .page-tab");
  tabs.forEach((tab) => {
    tab.classList.remove("border-bottom-radius");
  });
  if (tabs && tabs.length > 1) {
    if (tabs[tabs.length - 1].classList.contains("active")) {
      tabs[tabs.length - 2].classList.add("border-bottom-radius");
    } else {
      tabs[tabs.length - 1].classList.add("border-bottom-radius");
    }
  }
};

const placeActiveTabInFirstPlace = () => {
  const activeTab = document.querySelector(".mobile-page-content .page-tabs .active");
  const divTabs = document.querySelector(".mobile-page-content .page-tabs");
  activeTab.remove();
  divTabs.insertAdjacentHTML("afterbegin", `<div class="page-tab active">${activeTab.innerHTML}</div>`);
};

const changeTab = () => {
  const tabs = document.querySelectorAll(".mobile-page-content .page-tab");
  if (tabs && tabs.length > 1) {
    tabs.forEach((tab) => {
      if (!tab.classList.contains("active")) {
        tab.addEventListener("click", (e) => {
          e.currentTarget.querySelector("a").click();
          tabs.forEach((t) => {
            t.classList.remove("active");
          });
          tab.classList.add("active");
          placeActiveTabInFirstPlace();
          hideTabsDropdown();
          addBorderRadiusToLastVisibleTab();
          // clone  to remove all eventListeners
          const pageTabs = document.querySelector(".mobile-page-content .page-tabs");
          const pageTabsClone = pageTabs.cloneNode(true);
          pageTabs.parentNode.replaceChild(pageTabsClone, pageTabs);
          showTabsDropdownOnClick();
          changeTab();
        });
      }
    });
  }
};

export { showTabsDropdownOnClick, addBorderRadiusToLastVisibleTab, changeTab };
