const basicQuestionsModalValidation = (form) => {
  const mandatoryInputs = form.querySelectorAll(".mandatory");
  if (mandatoryInputs.length > 0) {
    checkValidations(mandatoryInputs);

    mandatoryInputs.forEach((input) => {
      input.addEventListener("change", (e) => {
        e.stopImmediatePropagation();
        checkValidations(mandatoryInputs);
      });
    });
  }
};

const checkValidations = (inputs) => {
  const submitBtn = document.querySelector("#basic_questions_modal .global-submit-btn");
  if (Array.from(inputs).every(inputFilled)) {
    submitBtn.classList.remove("disabled");
  } else {
    submitBtn.classList.add("disabled");
  }
};

const inputFilled = (currentValue) => {
  if (currentValue.type === "text" || currentValue.type === "hidden") {
    return currentValue.value != "";
  } else if (currentValue.type === "radio") {
    const radioName = currentValue.name;
    const radioBtns = document.querySelectorAll(`[name='${radioName}']`);
    return radioChecked(radioBtns);
  }
};

const radioChecked = (radioBtns) => {
  let inputChecked = false;
  radioBtns.forEach((radioBtn) => {
    if (radioBtn.checked) {
      inputChecked = true;
    }
  });
  return inputChecked;
};

export { basicQuestionsModalValidation };
