// Using browser detection is rarely a good idea!
// It is better to try to rely on feature detection, so think twice before using those methods
// You can read this doc for more details: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent

// Probably not perfect, as browsers are constantly spoofing user-agents...
// But it looked like the least worst solution according to https://stackoverflow.com/a/65048052
export const isSafari = () => {
  return navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);
};
