import { Controller } from "@hotwired/stimulus";
import { dispatchFilterSelectedEvent, dispatchFilterUpdatedEvent } from "../../datatables/datatable_filters";
import { toggleClass } from "../../utils/misc";
import { isEnterKey } from "../../utils/inputs_utils";
import { numberToCurrency } from "../../utils/number_utils";

const FILTER_VALUES = {
  GREATER_THAN: "gt",
  LESS_THAN: "lt",
  EQUAL_TO: "eq",
  BETWEEN: "bw",
};

export default class extends Controller {
  static targets = ["form", "rangeChoiceInput", "rangeStart", "rangeEnd", "errorMessage"];

  connect() {
    const selectedRangeInput = this._selectedRangeInput();
    if (selectedRangeInput) {
      toggleClass(this.rangeEndTarget, "hidden", this._shouldShowRangeEndInput(selectedRangeInput));
    }
  }

  toggleRangeInputs(event) {
    toggleClass(this.rangeEndTarget, "hidden", this._shouldShowRangeEndInput(event.target));
    this.rangeStartTarget.focus();
  }

  filterInputChanged(event) {
    if (isEnterKey(event.keyCode)) {
      this.filterChanged(event);
    }
  }

  filterChanged(event) {
    const isFilterValid = this.formTarget.checkValidity();
    toggleClass(this.errorMessageTarget, "hidden", !isFilterValid);

    if (isFilterValid) {
      if (event.params.action === "update") {
        this._filterUpdated();
      } else {
        this._filterSelected();
      }
    }
  }

  _filterSelected() {
    const { filterName, filterLabel } = this.element.dataset;
    const filterData = [
      { id: this._selectedRangeInput()?.id },
      { id: this.rangeStartTarget.id, value: this.rangeStartTarget.value },
      { id: this.rangeEndTarget.id, value: this.rangeEndTarget.value },
    ];

    dispatchFilterSelectedEvent({
      name: filterName,
      translatedValue: this._translatedValue(),
      label: filterLabel,
      data: filterData,
    });
  }

  _filterUpdated() {
    const { filterName } = this.element.dataset;
    dispatchFilterUpdatedEvent({ name: filterName, translatedValue: this._translatedValue() });
  }

  _translatedValue() {
    const { filterLabel } = this.element.dataset;
    const selectedRangeInput = this._selectedRangeInput();
    const rangeStart = numberToCurrency(this.rangeStartTarget.value);
    const rangeEnd = numberToCurrency(this.rangeEndTarget.value);

    let value = `${filterLabel} `;
    if (selectedRangeInput.value === FILTER_VALUES.GREATER_THAN) {
      value += `> ${rangeStart}`;
    } else if (selectedRangeInput.value === FILTER_VALUES.LESS_THAN) {
      value += `< ${rangeStart}`;
    } else if (selectedRangeInput.value === FILTER_VALUES.EQUAL_TO) {
      value += `= ${rangeStart}`;
    } else {
      value += `entre ${rangeStart} et ${rangeEnd}`;
    }

    return value;
  }

  _selectedRangeInput() {
    return this.rangeChoiceInputTargets.find((input) => input.checked);
  }

  _shouldShowRangeEndInput(rangeInput) {
    return rangeInput.value === FILTER_VALUES.BETWEEN;
  }
}
