import { Controller } from "@hotwired/stimulus";
import { dispatchFilterSelectedEvent, dispatchFilterUpdatedEvent } from "../../datatables/datatable_filters";

export default class extends Controller {
  static targets = ["radioInput"];

  filterChanged(event) {
    if (event.params.action === "update") {
      this._filterUpdated(event);
    } else {
      this._filterSelected(event);
    }
  }

  _filterSelected(event) {
    const { filterName, filterLabel } = this.element.dataset;
    const { translatedValue } = event.target.dataset;
    const filterData = [{ id: event.target.id }];

    dispatchFilterSelectedEvent({
      name: filterName,
      translatedValue: translatedValue,
      label: filterLabel,
      data: filterData,
    });
  }

  _filterUpdated(event) {
    const { filterName } = this.element.dataset;
    const { translatedValue } = event.target.dataset;

    dispatchFilterUpdatedEvent({ name: filterName, translatedValue: translatedValue });
  }
}
