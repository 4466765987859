import { Controller } from "@hotwired/stimulus";
import { flashAlert } from "../components/flash_alert";

export default class extends Controller {
  static values = {
    message: String,
    type: String,
  };

  connect() {
    flashAlert(this.messageValue, this.typeValue);
  }
}
