import { toggleClass } from "../utils/misc";

const computeNumberOfFiles = () => {
  computeNumberOfFilesForFolders(".main_folder", "hidden");
  computeNumberOfFilesForFolders(".second_folder", "without_file");
};

const computeNumberOfFilesForFolders = (folderClassName, classToToggle) => {
  const folderElements = document.querySelectorAll(folderClassName);
  folderElements.forEach((folderElement) => {
    const counterElement = folderElement.querySelector(`${folderClassName}_count`);
    if (counterElement) {
      const numberOfFiles = folderElement.querySelectorAll(".document").length;
      counterElement.innerText = numberOfFiles;
      toggleClass(counterElement, classToToggle, numberOfFiles !== 0);
    }
  });
};

export { computeNumberOfFiles };
