import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["actionsMenu"];

  showMenu(event) {
    this.actionsMenuTarget.classList.remove("hidden");
    event.stopImmediatePropagation();
  }
}
