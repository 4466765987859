const acsInit = () => {
  if (!envVariables["anytime_disabled"]) {
    const acs = document.createElement("script");
    document.body.appendChild(acs);
    acs.type = "text/javascript";
    acs.src = `//connect.anyti.me/${envVariables["anytime_partner_id"]}/fr/client.js`;
    acs.onload = function () {
      AC.add("onTokenCreated", function () {
        swal({
          title: "Bravo, vous avez connecté votre compte Anytime ! Celui-ci apparaîtra dans quelques instants.",
          buttons: { OK: true },
          className: "custom-swal-modal",
        });
        document.getElementById("anytime-connect-btn").innerText = "En cours de connexion...";
        document.getElementById("anytime-connect-btn").style.pointerEvents = "none";
        document.getElementById("anytime-connect-btn").style.setProperty("text-decoration", "none", "important");
      });
      document.getElementById("anytime-connect-btn").innerText = "Se connecter";
    };
  }
};

export { acsInit };
