import { Controller } from "@hotwired/stimulus";
import { flashAlert } from "../components/flash_alert";
import { updateBankTransactionsTableRows } from "../datatables/bank_transactions_datatable";
import { closeModal } from "../utils/misc";

export default class extends Controller {
  static targets = ["securityEquity", "submitBtn"];

  connect() {
    window.$(this.element).on("show.bs.modal", () => {
      this.securityEquityTargets.forEach((equity) => (equity.checked = false));
      this.checkSubmissionAvailability();
    });
  }

  checkSubmissionAvailability() {
    this.submitBtnTarget.disabled = !this._isEquitySelected();
  }

  onMatch(e) {
    const data = JSON.parse(e.detail[0].response);
    const flashMessage = data.flash_message;
    if (flashMessage) {
      flashAlert(flashMessage);
    }
    closeModal(this.element.id);
    const bankTransactionsPayload = data.bank_transactions_payload;
    updateBankTransactionsTableRows(bankTransactionsPayload);
  }

  _isEquitySelected() {
    return this.securityEquityTargets.filter((equity) => equity.checked).length > 0;
  }
}
