import { Controller } from "@hotwired/stimulus";
import { hideElements } from "../../utils/misc";

export default class extends Controller {
  static targets = ["specificInformationBlock"];

  locationTypeChanged(e) {
    this._hideSpecificInformationBlocks();

    if (this._selectedLocationHasSpecificInformation(e)) {
      const { specificInformationBlockId } = e.params;
      const blockToDisplay = this.specificInformationBlockTargets.find(
        (target) => target.id === specificInformationBlockId
      );
      this._displaySpecificInformationBlock(blockToDisplay);
    }
  }

  _hideSpecificInformationBlocks() {
    hideElements(this.specificInformationBlockTargets);
    this.specificInformationBlockTargets.forEach((block) =>
      [...block.querySelectorAll("input")].forEach((input) => (input.disabled = true))
    );
  }

  _displaySpecificInformationBlock(blockToDisplay) {
    blockToDisplay.classList.remove("hidden");
    [...blockToDisplay.querySelectorAll("input")].forEach((input) => (input.disabled = false));
  }

  _selectedLocationHasSpecificInformation(e) {
    return "specificInformationBlockId" in e.params;
  }
}
