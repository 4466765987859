import { Controller } from "@hotwired/stimulus";
import { dispatchFilterSelectedEvent, dispatchFilterUpdatedEvent } from "../../datatables/datatable_filters";
import { selectedOptionFor } from "../../utils/inputs_utils";

export default class extends Controller {
  static targets = ["selectInput"];

  filterChanged(event) {
    if (event.params.action === "update") {
      this._filterUpdated(event);
    } else {
      this._filterSelected(event);
    }
  }

  _filterSelected(event) {
    const { filterName, filterLabel } = this.element.dataset;
    const { id, value } = event.target;
    const filterData = [{ id: id, value: value }];

    dispatchFilterSelectedEvent({
      name: filterName,
      translatedValue: this._translatedValue(event.target),
      label: filterLabel,
      data: filterData,
    });
  }

  _filterUpdated(event) {
    const { filterName } = this.element.dataset;
    dispatchFilterUpdatedEvent({ name: filterName, translatedValue: this._translatedValue(event.target) });
  }

  _translatedValue(input) {
    return selectedOptionFor(input).innerText;
  }
}
