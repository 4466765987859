import { Controller } from "@hotwired/stimulus";
import { fillFlatpickrInput } from "../../utils/date_utils";
import { initDropzone, initDropzoneBasicEvents } from "../../components/dropzone";

const ACCEPTED_FORMATS = ".pdf";

export default class extends Controller {
  static targets = [
    "form",
    "shareAmountInput",
    "dateInput",
    "dragAndDropContainer",
    "uploadImgLabel",
    "filePreviewsContainer",
    "companyName",
  ];

  initialize() {
    this._initDropzone();

    window.$(this.element).on("show.bs.modal", (e) => {
      this._initForm(e);
    });
  }

  validateSubmission(e) {
    if (this._isValidForm()) {
      e.preventDefault();
      this.dropzone.processQueue();
    }
  }

  _isValidForm() {
    return this.dropzone.files.length === this.dropzone.options.maxFiles;
  }

  _initForm(e) {
    const { formUrl, companyName } = e.relatedTarget.dataset;
    this.formTarget.action = formUrl;
    this.dropzone.options.url = formUrl;
    this.companyNameTarget.innerHTML = companyName;
    this.shareAmountInputTarget.value = "";
    fillFlatpickrInput(this.dateInputTarget, "");
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.formTarget.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
      acceptedFiles: ACCEPTED_FORMATS,
    };
  }

  _initCustomEvents() {
    this.dropzone.on("success", () => {
      window.location.reload();
    });
  }
}
