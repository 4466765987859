import { Controller } from "@hotwired/stimulus";
import SubscriptionWorkflow from "../../subscription/subscription_workflow";

export default class extends Controller {
  static targets = ["price"];

  connect() {
    this._setUserSelectedPricing();
  }

  _setUserSelectedPricing() {
    const selectedPricing = new SubscriptionWorkflow().companyChoices.selected_pricing_id;
    if (!selectedPricing) {
      return;
    }

    const priceInput = this.priceTargets.find((price) => price.dataset.priceId === selectedPricing);
    if (!priceInput) {
      return;
    }

    priceInput.checked = true;
  }
}
