import { fileUploadFunctions } from "../components/file_upload_functions";

const initCompareInvoiceModal = (btn) => {
  const referenceSource = document.querySelector("#selected-purchase-document").dataset.source;
  const comparingSource = btn.dataset.source;
  initFileSource(referenceSource, document.getElementById("reference-file"));
  initFileSource(comparingSource, document.getElementById("comparing-file"));
};

const initFileSource = (source, fileContainer) => {
  const contentType = source.includes("pdf") ? "application/pdf" : "image";
  fileContainer.innerHTML = fileUploadFunctions().filePreview(source, contentType);
};

export { initCompareInvoiceModal };
