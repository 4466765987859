import { Controller } from "@hotwired/stimulus";
import { errorMessages, notifyError } from "../components/flash_alert";
import { updatePurchaseInvoicesTableRows } from "../datatables/purchase_invoices_datatable";
import { initBankTransactionsToBeMatchWithEquity } from "../components/equity_securities";
import { EQUITY_CURRENT_ACCOUNT_NATURE, SHAREHOLDERS_ACCOUNT_NATURE } from "../utils/constants";
import { handleHttpResponse } from "../utils/http_utils";
import { closeModal } from "../utils/misc";

export default class extends Controller {
  static values = {
    creditNote: String,
    todoId: Number,
  };

  updateBankTransactionCategory(event) {
    const status = event.detail[0].status;

    if (status === 403) {
      notifyError("Cette catégorie ne peut être affectée à un avoir d'achat.");
      return;
    } else if (status >= 300) {
      notifyError(errorMessages(status));
      return;
    }

    const response = event.detail[0].response;
    const hasMultipleShareholders = this._multipleShareholders(response);
    const bankTransactions = JSON.parse(response).bank_transactions;
    const categoryNature = this._itemCategoryNature(bankTransactions[0]);

    if (this._selectedItemCategoryIsShareholdersAccount(categoryNature) && hasMultipleShareholders) {
      const ids = bankTransactions.map((bankTransaction) => bankTransaction.id);
      document.querySelector("#select-shareholder-form-modal #ids").value = ids;
      window.$("#shareholdersAccountModal").modal("show", bankTransactions);
    } else if (this._selectedItemCategoryIsSubsidiaryAccount(categoryNature)) {
      window.$("#selectSubsidiaryAccountModal").modal("show");
      initBankTransactionsToBeMatchWithEquity(bankTransactions);
    }

    closeModal("updateCategoryModal");

    document.dispatchEvent(new CustomEvent("bankTransactionsCategoryUpdated", { detail: bankTransactions }));
  }

  updatePurchaseCategory(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        closeModal("updateCategoryModal");
        const purchaseInvoices = JSON.parse(response).purchase_invoices;
        updatePurchaseInvoicesTableRows(purchaseInvoices);
      },
    });
  }

  _itemCategoryNature(payload) {
    return payload.category.item_category.nature;
  }

  _multipleShareholders(payload) {
    return JSON.parse(payload).multiple_shareholders;
  }

  _selectedItemCategoryIsShareholdersAccount(nature) {
    return nature === SHAREHOLDERS_ACCOUNT_NATURE;
  }

  _selectedItemCategoryIsSubsidiaryAccount(nature) {
    return nature === EQUITY_CURRENT_ACCOUNT_NATURE;
  }
}
