import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitBtn", "form"];
  static values = {
    forceUserToConfirm: Boolean,
  };

  connect() {
    if ($(this.formTarget).parsley().isValid() && !this.forceUserToConfirmValue) {
      this.submitBtnTarget.click();
    } else {
      this.formTarget.classList.remove("hidden");
    }
  }
}
