import { animateProgressBar } from "../profiles/profile_forms";

const initProgressBarUpdate = () => {
  document.addEventListener("creation:percentageCompletionChanged", (e) => {
    const completionPercentage = e.detail;
    const mainProgressBar = document.querySelector(".block-bottom-progress-bar");

    if (mainProgressBar) {
      mainProgressBar.attributes.width.value = `${completionPercentage}%`;
      animateProgressBar(mainProgressBar);
    }

    const smallProgressBar = document.querySelector(".creation-progress-bar");
    if (smallProgressBar) {
      smallProgressBar.style.background = `linear-gradient(90deg, #6FCF97 ${completionPercentage}%, #E0E0E0 0%)`;
      smallProgressBar.innerText = `${completionPercentage}% complété`;
    }
  });
};

export { initProgressBarUpdate };
