const COUNTRIES_ELIGIBLE_FOR_FIXED_NUMBER_PORTABILITY = ["us", "ca"];

export const isMobileNumberValid = (itiObject) => {
  const isNumberValid = itiObject.isValidNumber();

  const isoCountryCode = itiObject.getSelectedCountryData().iso2;
  if (COUNTRIES_ELIGIBLE_FOR_FIXED_NUMBER_PORTABILITY.includes(isoCountryCode)) {
    return isNumberValid && itiObject.getNumberType() === intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE;
  }

  return isNumberValid && itiObject.getNumberType() === intlTelInputUtils.numberType.MOBILE;
};
