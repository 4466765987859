import { Controller } from "@hotwired/stimulus";
import { initDropzone, initDropzoneBasicEvents } from "../../components/dropzone";
import { isZipFile } from "../../utils/file_utils";
import { ZIP_ACCEPTED_FORMAT, FILE_ACCEPTED_FORMATS } from "../../utils/constants";
import { toggleClass } from "../../utils/misc";
import $ from "jquery";

const ACCEPTED_FORMATS = `${FILE_ACCEPTED_FORMATS}, ${ZIP_ACCEPTED_FORMAT}`;
const MAX_FILES = 12;

export default class extends Controller {
  static targets = [
    "filePreviewsContainer",
    "uploadImgLabel",
    "submitBtn",
    "dragAndDropContainer",
    "removeAllFilesBtn",
  ];

  connect() {
    this._initDropzone();
  }

  validateSubmission(e) {
    if (this._isValidForm()) {
      e.preventDefault();
      this.dropzone.processQueue();
      this.submitBtnTarget.disabled = true;
    }
  }

  _isValidForm() {
    return $(this.submitBtnTarget.form).parsley().isValid();
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.element.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
      clickable: `#${this.dragAndDropContainerTarget.id}`,
      paramName: "purchase_invoice[accounting_document_attributes][file]",
      acceptedFiles: ACCEPTED_FORMATS,
      maxFiles: MAX_FILES,
    };
  }

  removeAllFiles() {
    this.dropzone.removeAllFiles(true);
  }

  _initCustomEvents() {
    this.dropzone.on("processing", (file) => {
      this._defineDropzoneUrl(file);
      this.removeAllFilesBtnTarget.disabled = true;
    });

    this.dropzone.on("addedfile", () => {
      this._toggleUploadElements();
      this._defineOptions();
    });

    this.dropzone.on("removedfile", () => {
      this._toggleUploadElements();
      this._defineOptions();
    });

    this.dropzone.on("successmultiple", () => {
      location.reload();
    });
  }

  _toggleUploadElements() {
    const acceptedFiles = this.dropzone.files.length;
    const hasAcceptedFile = acceptedFiles > 0;
    const hasManyAcceptedFiles = acceptedFiles > 1;
    toggleClass(this.submitBtnTarget, "hidden", hasAcceptedFile);
    toggleClass(this.removeAllFilesBtnTarget, "hidden", hasManyAcceptedFiles);
  }

  _defineOptions() {
    let options = {};

    if (this.dropzone.files.length === 0) {
      options = {
        acceptedFiles: ACCEPTED_FORMATS,
        maxFiles: MAX_FILES,
        maxFilesize: 10,
      };
    } else if (isZipFile(this.dropzone.files[0])) {
      options = {
        acceptedFiles: ZIP_ACCEPTED_FORMAT,
        maxFiles: 1,
        maxFilesize: 100,
      };
    } else {
      options = {
        acceptedFiles: FILE_ACCEPTED_FORMATS,
        maxFiles: MAX_FILES,
        maxFilesize: 10,
      };
    }

    this.dropzone.options = { ...this.dropzone.options, ...options };
  }

  _defineDropzoneUrl(file) {
    if (isZipFile(file)) {
      this.dropzone.options.url = this.dropzone.element.dataset.zipUploadUrl;
    } else {
      this.dropzone.options.url = this.dropzone.element.dataset.fileUploadUrl;
    }
  }
}
