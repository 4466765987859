import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    if (!$(this.element).tooltip) {
      // Fallback for the front-office, due to Bootstrap conflicts
      // TODO: resolve Bootstrap conflicts between front and back office
      window.$(this.element).tooltip();
    } else {
      $(this.element).tooltip();
    }
  }
}
