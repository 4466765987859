import { Controller } from "@hotwired/stimulus";
import { initExcludedFromValidator } from "../../utils/parsley_validators_utils";
import { dropzoneElement } from "../../components/dropzone";

export default class extends Controller {
  static targets = ["fileSelectionForm", "fileUploadContent"];

  connect() {
    initExcludedFromValidator();

    window.$(this.element).on("show.bs.modal", () => {
      if (this.hasFileSelectionFormTarget) {
        this._resetModal();
      }
    });
  }

  _resetModal() {
    this.fileSelectionFormTarget.classList.remove("hidden");

    const dropzone = dropzoneElement(this.fileSelectionFormTarget);
    dropzone.removeAllFiles(true);
    dropzone.previewsContainer.innerHTML = "";

    this.fileUploadContentTarget.innerHTML = "";
  }

  filesSelected() {
    this.fileSelectionFormTarget.classList.add("hidden");
  }
}
