import { basicQuestionsModalValidation } from "./input_validations";

const initBasicForm = () => {
  const basicFormModal = document.querySelector("#basic_questions_modal");
  if (basicFormModal) {
    // Get step where user stopped
    const userStep = JSON.parse(basicFormModal.dataset.userStep);

    // Display right step with margin-left
    const firstSlide = document.querySelector("[data-step='0']");
    firstSlide.style.marginLeft = `-${userStep["real_step"]}00%`;

    // Display progress bar and buttons if user is not on step 0
    if (userStep["real_step"] != 0) {
      const basicProgressBar = document.getElementById("basic-questions-progress-bar");
      basicProgressBar.classList.remove("hidden");
      basicFormModal.querySelector(".previous-step-btn").classList.remove("hidden");

      document.querySelector(
        "#basic-questions-progress-bar .progress-active"
      ).style.left = `${userStep["progress_percent"]}%`;
      document.querySelector(`[data-target-step='${userStep["step"].split("-")[0]}']`).classList.add("active");

      // Remove active class
      firstSlide.classList.remove("active");
    }

    // Hide 'continuer' btn if userStep is bankConnection
    if (userStep["form"] === "bank_connection") {
      document.querySelector("#basic_questions_modal .global-submit-btn").classList.add("hidden");
    }

    // Change submit btn to point right form
    const submitBtn = basicFormModal.querySelector(".global-submit-btn");
    submitBtn.dataset.submitStep = userStep["step"];

    // Add active class to right slide
    const formToBeActive = document.querySelector(`.basic-questions-form[data-step='${userStep["step"]}']`);
    formToBeActive.classList.add("active");
    basicQuestionsModalValidation(formToBeActive);
  }
};

export { initBasicForm };
