import { dataTableFunctions } from "./datatable_functions";
import { initDatatable } from "./datatable_factory";
import { dateWithAbbrMonth } from "../utils/date_utils";

const initTable = (datatableElement) => {
  const columns = datatableColumns(datatableElement);
  const columnIndexes = dataTableFunctions().columnIndexes({ columns: columns });

  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[columnIndexes[columnNames.date_added], "desc"]],
    columns: columns,
    setRequestData: setRequestData(),
    onComplete: onComplete(datatableElement),
  };
  initDatatable(payload);
};

const columnNames = {
  name: "name",
  date_added: "date_added",
  type: "type",
};

const datatableColumns = () => {
  return [
    { data: columnNames.name },
    {
      data: columnNames.date_added,
      render: (data) => dateWithAbbrMonth(data),
    },
    { data: columnNames.type },
  ];
};

const setRequestData = () => {
  return (data) => {
    data.supporting_documents_search = document.getElementById("supporting-documents-search").value;
  };
};

const onComplete = (datatableElement) => {
  return () => {
    const search = document.getElementById("supporting-documents-search");
    dataTableFunctions().manageSearchBar(search, datatableElement.id);
  };
};

export { initTable };
