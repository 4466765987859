import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

/**
 * TODO(Jonathan Jalal): Merge this controller with the password_controller if / when all password inputs use parsley validation
 */
export default class extends Controller {
  static targets = ["input", "errorsContainer"];

  initialize() {
    this._setPasswordValidations();
    this._toggleErrors();
  }

  _setPasswordValidations() {
    window.Parsley.addValidator("minLowercase", {
      validateString: (value) => value.toUpperCase() !== value,
      messages: { fr: "contenir au moins %s minuscule" },
    });
    window.Parsley.addValidator("minUppercase", {
      validateString: (value) => value.toLowerCase() !== value,
      messages: { fr: "contenir au moins %s majuscule" },
    });
    window.Parsley.addValidator("minDigit", {
      validateString: (value) => /\d/.test(value),
      messages: { fr: "contenir au moins %s chiffre" },
    });
  }

  _toggleErrors() {
    $(this.inputTarget)
      .parsley()
      .on("field:success", () => {
        this.errorsContainerTarget.classList.add("hidden");
      });
    $(this.inputTarget)
      .parsley()
      .on("field:error", () => {
        this.errorsContainerTarget.classList.remove("hidden");
      });
  }
}
