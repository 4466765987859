import { Controller } from "@hotwired/stimulus";
import { initDropzone, initDropzoneBasicEvents } from "../../components/dropzone";
import { toggleClass } from "../../utils/misc";
import { FILE_ACCEPTED_FORMATS } from "../../utils/constants";

const ACCEPTED_FORMATS = `${FILE_ACCEPTED_FORMATS}, .csv`;

export default class extends Controller {
  static targets = [
    "filePreviewsContainer",
    "uploadImgLabel",
    "submitBtn",
    "dragAndDropContainer",
    "removeAllFilesBtn",
    "uploadForm",
  ];

  connect() {
    this._initDropzone();
  }

  validateSubmission(e) {
    if (this._isValidForm()) {
      e.preventDefault();
      this.dropzone.processQueue();
      this.submitBtnTarget.disabled = true;
    }
  }

  _isValidForm() {
    return this.submitBtnTarget.form.checkValidity();
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.element.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
      clickable: `#${this.dragAndDropContainerTarget.id}`,
      paramName: "document[aws_file]",
      maxFiles: 10,
      url: this.uploadFormTarget.dataset.fileUploadUrl,
      acceptedFiles: ACCEPTED_FORMATS,
    };
  }

  removeAllFiles() {
    this.dropzone.removeAllFiles(true);
  }

  _initCustomEvents() {
    this.dropzone.on("processing", () => {
      this.removeAllFilesBtnTarget.disabled = true;
    });

    this.dropzone.on("addedfile", () => {
      this._toggleUploadElements();
    });

    this.dropzone.on("removedfile", () => {
      this._toggleUploadElements();
    });

    this.dropzone.on("successmultiple", () => {
      location.reload();
    });
  }

  _toggleUploadElements() {
    const acceptedFiles = this.dropzone.files.length;
    const hasAcceptedFile = acceptedFiles > 0;
    const hasManyAcceptedFiles = acceptedFiles > 1;
    toggleClass(this.submitBtnTarget, "hidden", hasAcceptedFile);
    toggleClass(this.removeAllFilesBtnTarget, "hidden", hasManyAcceptedFiles);
  }
}
