import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";

export default class extends Controller {
  static targets = ["container", "loader"];

  onCashingPeriodUpdate(e) {
    Rails.fire(e.currentTarget.form, "submit");
    this._toggleLoader(this.loaderTarget, this.containerTarget);
  }

  reloadCustomersCashingStatus(e) {
    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: (response) => {
        this.containerTarget.innerHTML = response;
      },
      onComplete: () => {
        this._toggleLoader(this.loaderTarget, this.containerTarget);
      },
    });
  }

  _toggleLoader(loader, container) {
    loader.classList.toggle("hidden");
    container.classList.toggle("hidden");
  }
}
