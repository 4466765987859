import { Controller } from "@hotwired/stimulus";
import { initFrenchCalendarNoFuture } from "../components/init_french_calendar";

export default class extends Controller {
  static targets = ["input"];
  static values = { flatpickrOptions: Object };

  connect() {
    initFrenchCalendarNoFuture(`#${this.inputTarget.id}`, this.flatpickrOptionsValue);
  }

  fillDateInput(event) {
    this.inputTarget.value = event.currentTarget.dataset.date;
  }
}
