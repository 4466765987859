const refreshModalAfterSubmit = (currentTabNumber, htmlForm, formObjectIsValid) => {
  const form = document.querySelector(`.tab-content[data-tab='${currentTabNumber}'] form`);
  form.innerHTML = htmlForm;
  toggleLegalExtraInfo();
  toggleMeTaxExtraInfo();
  addDisabledToDateInputsForMe();
  manageInputsChanges();
  document.querySelector(`.tab[data-tab='${currentTabNumber}']`).classList.remove("changed");
  if (formObjectIsValid == "true") {
    document.querySelector(`.tab[data-tab='${currentTabNumber + 1}']`).click();
  }
};

const refreshAllProgressBars = (
  sideBarProgressBarHTML,
  modalHeaderProgressBarHTML,
  billingContentProgressBarHTML,
  billingContentCommentHTML,
  fulfillmentProgress
) => {
  const sideBarProgressBar = document.getElementById("sidebar-progress-bar");
  sideBarProgressBar.innerHTML = sideBarProgressBarHTML;

  const modalHeaderProgressBar = document.querySelector("#profile_fulfillment_modal .modal-header .tab-contents");
  modalHeaderProgressBar.innerHTML = modalHeaderProgressBarHTML;

  const billingContentProgressBar = document.querySelector("#billing-content-progress-bar > div:first-child");
  const billingContentComment = document.querySelector("#billing-content-progress-bar > .completion-comment");
  if (billingContentProgressBar) {
    billingContentProgressBar.innerHTML = billingContentProgressBarHTML;
    billingContentComment.innerHTML = billingContentCommentHTML;
  }

  if (fulfillmentProgress == "100") {
    const profileFulfillmentButton = document.querySelector("#user-infos-container .profile-fulfillment-button");
    if (profileFulfillmentButton) {
      profileFulfillmentButton.classList.add("hidden");
    }
  }
};

export { refreshModalAfterSubmit, refreshAllProgressBars };
