import { dataTableFunctions } from "./datatable_functions";
import { escapeHtml, htmlTextContent, isStringTrue } from "../utils/misc";
import { compiledTemplate, interpolate, setTemplatePartial } from "../utils/string_utils";
import { numberToCurrency, numericRange } from "../utils/number_utils";
import { dateWithAbbrMonth, formattedDate } from "../utils/date_utils";
import { initDatatable } from "./datatable_factory";
import { inputValueById, inputValueBySelector } from "../utils/inputs_utils";
import { amountFilterRange, computePeriodDateRange } from "./datatable_filters";

const MATCHING_STATUSES = {
  DOCUMENT_REQUIRED: "Document à fournir",
  LOST_RECEIPT: "Justificatif perdu",
};

const initBankTransactionsDatatable = (datatableElement) => {
  const columns = datatableColumns(datatableElement);
  const columnIndexes = dataTableFunctions().columnIndexes({ columns: columns });

  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[columnIndexes[columnNames.date], "desc"]],
    columns: columns,
    setRequestData: setRequestData(),
    onComplete: onComplete(datatableElement),
    onDraw: onDraw(),
    onCreatedRow: onCreatedRow(),
    exportable: true,
  };
  initDatatable(payload);
};

const updateBankTransactionsTableRows = (bankTransactions) => {
  document.dispatchEvent(new CustomEvent("datatable:reloadRows", { detail: bankTransactions }));
  document.dispatchEvent(new CustomEvent("datatable:refreshSidePanel"));
};

const manualMatchingResponse = () => {
  document.addEventListener("matching:updated", (e) => {
    const bankTransactions = e.detail.bank_transactions;
    updateBankTransactionsTableRows(bankTransactions);
  });
};

const onBankTransactionUpdated = () => {
  document.addEventListener("bankTransactionUpdated", (e) => {
    updateBankTransactionsTableRows(e.detail);
  });
};

const columnNames = {
  input: "input",
  id: "id",
  date: "date",
  description: "description",
  category: "category",
  bank_name: "bank_name",
  value: "value",
  matching_tag: "matching_tag",
  attachment: "attachment",
};

const datatableColumns = (datatableElement) => {
  const isBankAccountSelected = isStringTrue(datatableElement.dataset.bankAccountSelected);
  return [
    {
      data: columnNames.input,
      orderable: false,
      className: "select-column checkbox-element",
      render: function (data, type, row) {
        return interpolate(document.getElementById("select-item-checkbox-template"), {
          id: row.id,
        });
      },
    },
    {
      data: columnNames.description,
      orderable: true,
      className: "description-column",
      render: function (data, type, row) {
        setTemplatePartial("noteIcon", document.getElementById("note-icon-template"));

        return compiledTemplate(document.getElementById("bank-transaction-description-column-template"), {
          description: row.description,
          note: row.note,
          id: row.id,
        });
      },
    },
    {
      data: columnNames.category,
      orderable: true,
      className: "category-column",
      render: (data, type, row) => categoryCellContent(data, row),
    },
    { data: columnNames.date, orderable: true, className: "date-column", render: (data) => dateWithAbbrMonth(data) },
    { data: columnNames.bank_name, orderable: true, className: "bank-column", visible: !isBankAccountSelected },
    {
      data: columnNames.matching_tag,
      orderable: false,
      className: "status-column",
      render: function (data, type, row) {
        return compiledTemplate(document.getElementById("bank-transaction-status-column-template"), {
          document_required: data === MATCHING_STATUSES.DOCUMENT_REQUIRED,
          attachment_present: row.attachment_present,
          lost_receipt: data === MATCHING_STATUSES.LOST_RECEIPT,
        });
      },
    },
    {
      data: columnNames.value,
      orderable: true,
      className: "amount-column",
      render: (data, type, row) => numberToCurrency(data, { currency: row.currency }),
      createdCell: function (cell, cellData) {
        if (+cellData > 0) {
          cell.classList.add("color_green-alt");
        }
      },
    },
    {
      data: columnNames.attachment,
      orderable: false,
      className: "action-column",
      render: function (data, type, row) {
        if (!data.can_attach) {
          return "";
        }

        return interpolate(document.getElementById("bank-transaction-attachment-column-template"), {
          attach_url: data.attach_url,
          transaction: row,
          transaction_date: formattedDate(row.date),
          transaction_description: escapeHtml(row.description),
        });
      },
    },
    {
      data: columnNames.id,
      orderable: false,
      className: "ids-column",
      visible: false,
    },
  ];
};

const setRequestData = () => {
  return (data) => {
    const columnIndexes = dataTableFunctions().columnIndexes(data);

    dataTableFunctions().setColumnFilter(data, columnIndexes[columnNames.category], inputValueById("category-filter"));
    dataTableFunctions().setColumnFilter(data, columnIndexes[columnNames.value], amountFilter());

    data.period = computePeriodDateRange();
    data.tag = inputValueById("matching-tag-filter");
    data.attachment = inputValueBySelector("[name='file-filter']:checked");
    data.bank_search = document.getElementById("bank-search").value;

    const queryParams = new URLSearchParams(window.location.search);
    data.charges = queryParams.has("charges");

    if (document.getElementById("global-search-filtered-id").checked) {
      data.global_search_filtered_id = document.getElementById("global-search-filtered-id").value;
    }
  };
};

const amountFilter = () => {
  let value;
  const receiptFilter = document.getElementById("receipt-filter");
  const withdrawalFilter = document.getElementById("withdrawal-filter");
  if (receiptFilter && receiptFilter.checked) {
    value = numericRange(0);
  } else if (withdrawalFilter && withdrawalFilter.checked) {
    value = numericRange(-Number.MAX_SAFE_INTEGER, 0);
  }

  const rangeStartFilter = document.getElementById("bank-amount-min");
  if (!rangeStartFilter) {
    return value;
  } else {
    // NOTE(Jonathan Jalal): Amount filter takes priority over above receipt / withdrawal filter
    return amountFilterRange(
      {
        amountEqInput: document.getElementById("bank-amount-eq"),
        amountLtInput: document.getElementById("bank-amount-lt"),
        amountGtInput: document.getElementById("bank-amount-gt"),
        amountBwInput: document.getElementById("bank-amount-bw"),
      },
      document.getElementById("bank-amount-min").value,
      document.getElementById("bank-amount-max").value
    );
  }
};

const onComplete = (datatableElement) => {
  return () => {
    manualMatchingResponse();
    onBankTransactionUpdated();

    const search = document.getElementById("bank-search");
    dataTableFunctions().manageSearchBar(search, datatableElement.id);
  };
};

const onDraw = () => {
  return () => {
    dataTableFunctions().manageFilteredSelection();
    window.dispatchEvent(new Event("initTooltip"));
  };
};

const onCreatedRow = () => {
  return (row, data) => {
    $(row).addClass(`accounted-${data.accounted}`);
    $(row).addClass(`processed-${data.processed}`);
  };
};

const categoryCellContent = (data, row) => {
  const { item_category, category_to_display, locked_status: lockedStatus } = data;
  const lockedDescription = dataTableFunctions().lockedStatusDescription(lockedStatus, row.bank_name);
  const locked = lockedStatus || row.accounted || row.processed;
  const options = {
    prefix: category_to_display.prefix,
    item: {
      id: row.id,
      credit_note: row.credit_note,
      date: formattedDate(row.date),
      description: row.description,
      amount: numberToCurrency(row.value),
    },
    category: {
      ...item_category,
      ...category_to_display,
      title: htmlTextContent(category_to_display.category_name),
      locked: { status: locked, description: lockedDescription },
    },
  };
  setTemplatePartial("lockedCategory", document.getElementById("locked-category-template"));
  setTemplatePartial("displayedCategory", document.getElementById("displayed-category-template"));
  return compiledTemplate(document.getElementById("category-column-template"), options);
};

export { initBankTransactionsDatatable, updateBankTransactionsTableRows };
