import { Controller } from "@hotwired/stimulus";
import SalesInvoice from "../../sales_invoices/sales_invoice";
import { amountFormatted } from "../../utils/number_utils";
import { selectedOptionFor } from "../../utils/inputs_utils";

// Helps compute global totals for a sales invoice
export default class extends Controller {
  static targets = [
    "invoiceLine",
    "currency",
    "netTotalBeforeDiscount",
    "discountType",
    "currencyDiscountType",
    "discountValue",
    "netTotalAfterDiscount",
    "vatTotal",
    "grossTotal",
  ];

  initialize() {
    this._initializeEvents();
  }

  updateMainTotals() {
    document.dispatchEvent(new CustomEvent("salesInvoice:updateMainTotals"));
  }

  changeCurrencySymbol() {
    document.dispatchEvent(new CustomEvent("salesInvoice:updateLineTotals"));
  }

  _initializeEvents() {
    this._updateMainTotals();
    document.addEventListener("salesInvoice:updateMainTotals", () => {
      this._updateMainTotals();
    });
  }

  _updateMainTotals() {
    const salesInvoice = this._initSalesInvoice();
    const salesInvoiceLines = this._salesInvoiceLines();
    const currencySymbol = selectedOptionFor(this.currencyTarget).dataset.symbol;
    const netTotalBeforeDiscount = salesInvoice.netTotalBeforeDiscount(salesInvoiceLines);
    this.netTotalBeforeDiscountTarget.innerText = amountFormatted(netTotalBeforeDiscount, currencySymbol);
    this.currencyDiscountTypeTarget.innerText = currencySymbol;

    const netTotalAfterDiscount = salesInvoice.netTotalAfterDiscount(netTotalBeforeDiscount);
    this.netTotalAfterDiscountTarget.innerText = amountFormatted(netTotalAfterDiscount, currencySymbol);

    const vatTotal = salesInvoice.vatTotal(salesInvoiceLines);
    this.vatTotalTarget.innerText = amountFormatted(vatTotal, currencySymbol);

    const grossTotal = salesInvoice.grossTotal(salesInvoiceLines);
    this.grossTotalTarget.innerText = amountFormatted(grossTotal, currencySymbol);
  }

  _initSalesInvoice() {
    const invoice = new SalesInvoice();
    invoice.discountType = this._activeElement(this.discountTypeTargets).value || "";
    invoice.discountValue = +this.discountValueTarget.value;
    return invoice;
  }

  _salesInvoiceLines() {
    // TODO(Jonathan Jalal): See if it is possible to create a proper relationship between a sales invoice and its lines through OOP, rather than querying those lines this way
    // Retrieves the invoice lines DOM elements, which are outside the scope of this controller
    const invoiceLineElements = document.querySelectorAll("#invoice_lines_all .mandatory-sales-invoice-line");
    return Array.from(invoiceLineElements).reduce((acc, lineElement) => {
      const line = lineElement["sales-invoices--sales-invoice-line"]; // Calls the sales-invoice-line stimulus controller
      // A newly added invoice line is undefined and is not used in the sales invoice totals computation
      if (line) {
        acc.push(line._initSalesInvoiceLine());
      }
      return acc;
    }, []);
  }

  _activeElement(targets) {
    return targets.find((target) => target.classList.value.includes("active"));
  }
}
