import { Controller } from "@hotwired/stimulus";
import { radioButtonLabelFor, scrollToField } from "../../utils/misc";
import { notifyError } from "../../components/flash_alert";
import { fetchStream } from "../../utils/turbo_utils";
import SubscriptionWorkflow from "../../subscription/subscription_workflow";

/**
 * Actions associated to fiscal year choice and offer selection within the checkout page
 */
export default class extends Controller {
  static targets = [
    "price",
    "firstFiscalYearContainer",
    "fiscalYearOption",
    "invalidFiscalYearMsg",
    "loader",
    "turboFrameElement",
    "serviceInput",
  ];

  connect() {
    this.subscriptionWorkflow = new SubscriptionWorkflow();

    document.addEventListener("turbo:before-fetch-request", () => {
      this.turboFrameElementTarget.classList.remove("fade-in-right");
    });

    document.addEventListener("turbo:before-fetch-response", () => {
      this.turboFrameElementTarget.classList.add("fade-in-right");
      window.scrollTo(0, 0);
    });
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }

  validateEnterpriseOfferSelection(e) {
    if (this.hasFiscalYearOptionTarget && !this._selectedFiscalYear()) {
      e.preventDefault();
      this.invalidFiscalYearMsgTarget.classList.remove("hidden");
      scrollToField(radioButtonLabelFor(this.fiscalYearOptionTarget));
      return;
    }

    const submitButton = e.target;
    this._toggleSubmitBtn(submitButton);
    this.toggleLoader();
    fetch(submitButton.dataset.submitUrl, {
      method: "post",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_fiscal_year: this._selectedFiscalYear().value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error_message === null) {
          fetchStream(this._paymentUrl(submitButton.dataset.redirectionUrl));
        } else {
          this._toggleSubmitBtn(submitButton);
          this.toggleLoader();
          notifyError(data.error_message);
        }
      })
      .catch(() => {
        notifyError();
        this._toggleSubmitBtn(submitButton);
        this.toggleLoader();
      });
  }

  validateMicroOfferSelection(e) {
    this.toggleLoader();
    fetchStream(this._paymentUrl(e.target.dataset.redirectionUrl));
  }

  updatePreviousClosingExistence(e) {
    const form = e.currentTarget.form;
    fetch(form.action, {
      method: "post",
      headers: {
        accept: "text/html",
      },
      body: new FormData(form),
    })
      .then((response) => response.text())
      .then((data) => {
        this.firstFiscalYearContainerTarget.outerHTML = data;
      });
  }

  changeFiscalYear(e) {
    this.invalidFiscalYearMsgTarget.classList.add("hidden");
    // TODO(Jonathan Jalal): when re-designing the offer selection page, see if it is possible to move the FY selection input in a parent form to avoid manually checking it
    const fyInput = this.fiscalYearOptionTargets.find(
      (fiscalYear) => fiscalYear.value === e.target.getAttribute("value")
    );
    fyInput.checked = true;
  }

  storeSelectedService() {
    this.subscriptionWorkflow.storeSelectedService(this._selectedServiceId());
  }

  serviceSelectionSubmitted() {
    this.storeSelectedService();
    this.subscriptionWorkflow.storeServiceSelectionSubmission();
  }

  storeSelectedPricing() {
    this.subscriptionWorkflow.storeSelectedPricing(this._selectedPriceId());
  }

  pricingSelectionSubmitted() {
    this.storeSelectedPricing();
    this.subscriptionWorkflow.storePricingSelectionSubmission();
  }

  _selectedPriceId() {
    const selectedPrice = this.priceTargets.find((price) => price.checked);
    return selectedPrice.dataset.priceId;
  }

  _selectedServiceId() {
    const selectedService = this.serviceInputTargets.find((service) => service.checked);
    return selectedService.value;
  }

  _selectedFiscalYear() {
    return this.fiscalYearOptionTargets.find((fiscalYear) => fiscalYear.checked);
  }

  _toggleSubmitBtn(submitBtn) {
    submitBtn.disabled = !submitBtn.disabled;
  }

  _paymentUrl(baseUrl) {
    return `${baseUrl}?selected_pricing_id=${this._selectedPriceId()}`;
  }
}
