import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";
import { numberToCurrency } from "../../utils/number_utils";
import { formattedDate } from "../../utils/date_utils";

export default class extends Controller {
  static targets = [
    "vatContentContainer",
    "vatExemptCardContentTemplate",
    "socialContributionsContentContainer",
    "cashflowTaxesContentContainer",
    "cardContentTemplate",
  ];

  connect() {
    if (!this.hasVatContentContainerTarget) {
      return;
    }

    fetch(this.element.dataset.url, {
      headers: { accept: "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        this._fillVatCard(data.vat);
        this._fillGenericCard(data.social_contributions, this.socialContributionsContentContainerTarget);
        this._fillCashflowTaxesCard(data.taxes);
      });
  }

  _fillVatCard(data) {
    data.vat_exempt ? this._fillVatExemptCard(data) : this._fillGenericCard(data, this.vatContentContainerTarget);
  }

  _fillVatExemptCard(data) {
    const options = {
      vatExemptByNature: data.vat_exempt_by_nature,
      vatExemptByThreshold: !data.vat_exempt_by_nature,
      isNear: data.vat_threshold_status === "approaching_vat_threshold",
      isAbove: data.vat_threshold_status === "above_vat_threshold",
    };

    this.vatContentContainerTarget.innerHTML = interpolate(this.vatExemptCardContentTemplateTarget, options);
  }

  _fillGenericCard(data, cardContainer) {
    const totalAmount = data.total_amount === null ? "N/A" : numberToCurrency(data.total_amount, { decimals: 0 });
    const nextPayment = data.next_payment;
    let [nextAmount, nextDeadline] = [null, null];
    if (nextPayment) {
      nextAmount = numberToCurrency(nextPayment.amount, { decimals: 0 });
      nextDeadline = formattedDate(nextPayment.deadline);
    }
    const options = {
      total_amount: totalAmount,
      next_payment: nextPayment,
      next_amount: nextAmount,
      next_deadline: nextDeadline,
    };

    cardContainer.innerHTML = interpolate(this.cardContentTemplateTarget, options);
  }

  _fillCashflowTaxesCard(data) {
    if (data.micro_withholding_tax_option) {
      this.cashflowTaxesContentContainerTarget.innerHTML =
        "<div class='color_gray margin-top_5px'>Versement forfaitaire libératoire</div>";
    } else {
      this._fillGenericCard(data, this.cashflowTaxesContentContainerTarget);
    }
  }
}
