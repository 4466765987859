import { Controller } from "@hotwired/stimulus";

/*
 * NOTE(Jonathan Jalal): This controller is mainly used to allow the filter panel (for initial selection, or update) to be closed when clicking outside of it
 * This kind of behaviour is natively implemented in bootstrap's popover component, but I wasn't able to use it, so these panels are "custom-made" for now
 */
export default class extends Controller {
  static targets = ["panel", "trigger"];
  static classes = ["active"];

  connect() {
    document.addEventListener("pointerdown", (event) => {
      if (!this._isPanelOpen()) {
        return;
      }

      if (this._shouldClosePanel(event.target)) {
        this._closePanel();
      }
    });
  }

  _isPanelOpen() {
    return this.panelTarget.classList.contains("visible");
  }

  _closePanel() {
    this.panelTarget.classList.remove("visible");
    this.triggerTarget.classList.remove(this.activeClass);
  }

  _shouldClosePanel(target) {
    const isActivePanelClicked = this.element.contains(target);
    const isWithinSelect2Container = target.closest(".select2-container"); // specific case for select2 inputs that have search fields
    const isWithinCalendar = target.closest(".flatpickr-calendar");

    return !(isActivePanelClicked || isWithinSelect2Container || isWithinCalendar);
  }
}
