const addDisabledToDateInputsForMe = () => {
  const fulfillmentModal = document.getElementById("profile_fulfillment_modal");
  if (fulfillmentModal) {
    const userIsMe = fulfillmentModal.dataset.userIsMe;
    if (userIsMe === "true") {
      const idsToDisable = [
        "form_objects_profile_fulfillment_accounting_infos_first_acasi_fiscal_year_end_date_3i",
        "form_objects_profile_fulfillment_accounting_infos_first_acasi_fiscal_year_end_date_2i",
        "form_objects_profile_fulfillment_tax_infos_micro_vat_eligibility_date_3i",
      ];
      idsToDisable.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("disabled");
        }
      });
    }
  }
};

export { addDisabledToDateInputsForMe };
