import { manageTabOptionOnInput } from "components/profile_fulfillment/manage_tab_option_on_input";

const leftArrow = () => {
  const activeFormCont = document.querySelector(".form-container.active");
  const leftArrowBtnFirst = document.querySelector(".onboarding-nav-first");
  const leftArrowBtn = document.querySelector("[data-previous]");
  if (activeFormCont && leftArrowBtn) {
    if (activeFormCont.dataset.payslip !== "1") {
      leftArrowBtnFirst.classList.add("hidden");
      const leftArrowBtnClone = leftArrowBtn.cloneNode(true);
      // clone element to remove all eventListeners
      leftArrowBtn.parentNode.replaceChild(leftArrowBtnClone, leftArrowBtn);
      leftArrowBtnClone.classList.remove("hidden");
      leftArrowBtnClone.addEventListener("click", (e) => {
        e.preventDefault();
        showPreviousForm();
        rightArrow();
        payslipProgressionBar();
        displayAssociatedInputs();
        manageTabOptionOnInput();
      });
    }
  }
};

const showPreviousForm = () => {
  const firstFormCont = document.querySelector(".form-container");
  const leftArrowBtnFirst = document.querySelector(".onboarding-nav-first");
  if (firstFormCont) {
    const activeFormCont = document.querySelector(".form-container.active");
    const activeFormNumber = parseInt(activeFormCont.dataset.payslip);
    const targetFormCont = document.querySelector(`[data-payslip='${activeFormNumber - 1}']`);
    firstFormCont.style.marginLeft = `-${activeFormNumber - 2}00%`;
    removeActiveClassOnAllFromContainers();
    targetFormCont.classList.add("active");
    if (activeFormNumber === 2) {
      leftArrowBtnFirst.classList.remove("hidden");
      document.querySelector("[data-previous]").classList.add("hidden");
    }
  }
};

const removeActiveClassOnAllFromContainers = () => {
  const formConts = document.querySelectorAll(".form-container");
  if (formConts) {
    formConts.forEach((formCont) => {
      formCont.classList.remove("active");
    });
  }
};

const rightArrow = () => {
  bindRightArrowToFormSubmission();
  disableRightArrow();
};

const disableRightArrow = () => {
  const rightArrowBtn = document.querySelector("[data-next]");
  const checkedRadioInput = document.querySelector(".form-container [id^='employees_person']:checked");
  const inputs = document.querySelectorAll(
    ".form-container.active input[data-required='true'], .form-container.active select[data-required='true']"
  );
  if (inputs && rightArrowBtn) {
    disableRightArrowIfFormContainsEmptyInputs(rightArrowBtn, inputs);
    inputs.forEach((input) => {
      input.addEventListener("input", () => {
        disableRightArrowIfFormContainsEmptyInputs(rightArrowBtn, inputs);
      });
    });
  }

  if (rightArrowBtn) {
    if (checkedRadioInput) {
      rightArrowBtn.classList.remove("hidden");
    } else if (inputs.length === 0) {
      rightArrowBtn.classList.add("hidden");
    }
  }
};

const disableRightArrowIfFormContainsEmptyInputs = (rightArrowBtn, inputs) => {
  let count = 0;
  const inputsArr = Array.from(inputs);
  for (let i = 0; i < inputsArr.length; ++i) {
    if (inputsArr[i].value === "") {
      count++;
    }
  }

  if (count > 0) {
    rightArrowBtn.classList.add("hidden");
  } else {
    rightArrowBtn.classList.remove("hidden");
  }
};

const bindRightArrowToFormSubmission = () => {
  const rightArrowBtn = document.querySelector("[data-next]");
  const activeFormBtn = document.querySelector(
    ".form-container.active form.payslip-onboarding input[type='submit'], #owner-selection-form input[type='submit']"
  );
  if (rightArrowBtn && activeFormBtn) {
    const rightArrowBtnClone = rightArrowBtn.cloneNode(true);
    rightArrowBtn.parentNode.replaceChild(rightArrowBtnClone, rightArrowBtn);
    rightArrowBtnClone.addEventListener("click", (e) => {
      e.preventDefault();
      activeFormBtn.click();
    });
  }
};

const payslipProgressionBar = () => {
  const activeFormCont = document.querySelector(".form-container.active");
  if (activeFormCont) {
    const activeFormNumber = parseInt(activeFormCont.dataset.payslip);
    const progessionDivs = document.querySelectorAll(".payslip-progression > div");
    if ((activeFormNumber, progessionDivs)) {
      progessionDivs.forEach((div) => {
        div.classList.remove("approved", "stir-animation");
      });
      for (let i = 0; i < activeFormNumber; ++i) {
        progessionDivs[i].classList.add("approved");
      }
      progessionDivs[activeFormNumber - 1].classList.add("stir-animation");
    }
  }
};

const displayAssociatedInputs = () => {
  const triggerInputs = document.querySelectorAll("[data-associated-inputs-trigger]");
  if (triggerInputs) {
    triggerInputs.forEach((triggerInput) => {
      const associatedInputs = document.querySelector(
        `[data-associated-inputs='${triggerInput.dataset.associatedInputsTrigger}']`
      );
      triggerInput.addEventListener("change", () => {
        if (event.currentTarget.value === "oui") {
          associatedInputs.classList.remove("hidden");
          associatedInputs.querySelectorAll("input, select").forEach((el) => {
            el.dataset.required = "true";
          });
        } else {
          associatedInputs.classList.add("hidden");
          associatedInputs.querySelectorAll("input, select").forEach((el) => {
            el.dataset.required = "false";
          });
        }
        rightArrow();
      });
    });
  }
};

const selectBankAccount = () => {
  const bankAccountsSelect = document.querySelector("#bank-selection-form #bank_id");
  if (bankAccountsSelect) {
    $(bankAccountsSelect).on("change", function (e) {
      if (e.currentTarget.value === "") {
        document.querySelector("[data-next]").classList.add("hidden");
      } else {
        bankAccountsSelect.form.querySelector("input[type='submit']").click();
      }
    });
  }
};

const selectEmployee = () => {
  const ownersSelectionForm = document.getElementById("owner-selection-form");
  if (ownersSelectionForm) {
    window.addEventListener(
      "unload",
      function () {
        ownersSelectionForm.reset();
      },
      false
    );
    ownersSelectionForm.addEventListener("change", (e) => {
      if (e.target.checked) {
        ownersSelectionForm.submit();
      }
    });
  }
};

const launchQuestions = () => {
  const launchQuestionBtn = document.getElementById("go-to-questions");
  if (launchQuestionBtn) {
    launchQuestionBtn.addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(".onboarding-info").classList.add("hidden");
      document.querySelector(".onboarding-layout").classList.remove("hidden");
    });
  }
};

export {
  leftArrow,
  rightArrow,
  removeActiveClassOnAllFromContainers,
  payslipProgressionBar,
  disableRightArrowIfFormContainsEmptyInputs,
  displayAssociatedInputs,
  selectBankAccount,
  selectEmployee,
  launchQuestions,
};
