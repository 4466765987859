import { initDatatable } from "./datatable_factory";
import { dataTableFunctions } from "./datatable_functions";
import { interpolate } from "../utils/string_utils";

const CATALOG_ITEMS_DATATABLE_ID = "catalog_items_datatable";

const initCatalogItemsDatatable = () => {
  const datatableElement = document.getElementById(CATALOG_ITEMS_DATATABLE_ID);
  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[2, "asc"]],
    columns: datatableColumns(),
    setRequestData: setRequestData(),
    onComplete: onComplete(),
    onDraw: onDraw(),
    exportable: true,
  };
  initDatatable(payload);
};

const columnNames = {
  input: "input",
  id: "id",
  title: "title",
  reference: "reference",
  unit_price: "unit_price",
  vat_rate: "vat_rate",
  total_price: "total_price",
  unit: "unit",
};

const setRequestData = () => {
  return (data) => {
    data.catalog_items_search = document.getElementById("catalog-items-search").value;
  };
};

const datatableColumns = () => {
  return [
    {
      data: columnNames.input,
      orderable: false,
      className: "select-column checkbox-element",
      render: (data, type, row) => {
        return interpolate(document.getElementById("select-item-checkbox-template"), {
          id: row.id,
        });
      },
    },
    { data: columnNames.id, orderable: false, className: "ids-column", visible: false },
    {
      data: columnNames.title,
      orderable: true,
      searchable: true,
      className: "title-column",
      render: (data, type, row) => {
        return interpolate(document.getElementById("table-url-template"), {
          text: data,
          url: row.url,
        });
      },
    },
    { data: columnNames.reference, orderable: true, searchable: true, className: "ref-column" },
    { data: columnNames.unit_price, orderable: true, searchable: true, className: "ht-column" },
    { data: columnNames.vat_rate, orderable: true, searchable: true, className: "tva-column" },
    { data: columnNames.total_price, orderable: true, searchable: true, className: "ttc-column" },
    { data: columnNames.unit, orderable: false, searchable: true, className: "unit-column" },
  ];
};

const onComplete = () => {
  return () => {
    const search = document.getElementById("catalog-items-search");
    dataTableFunctions().manageSearchBar(search, CATALOG_ITEMS_DATATABLE_ID);
  };
};

const onDraw = () => {
  return () => {
    dataTableFunctions().manageFilteredSelection();
  };
};

export { initCatalogItemsDatatable };
