import { flashAlert } from "../components/flash_alert";

const problematicPurchasesFunctions = () => {
  const publicFunctions = {};

  const removePurchaseIssueTag = (elementToRemove) => {
    elementToRemove.remove();
    if (document.querySelectorAll(".purchase-error.tag").length === 0) {
      document.querySelector(".error-separator").insertAdjacentHTML("afterend", "Aucun problème détecté");
    }
  };

  publicFunctions.errorResolved = (errorType) => {
    const matchResolutionElement = document.getElementById(`${errorType}-purchase-resolution`);
    publicFunctions.removeResolutionElement(matchResolutionElement, "zoomOut");

    const issueTagToRemove = document.getElementById(`${errorType}-purchase-error`);
    removePurchaseIssueTag(issueTagToRemove);
  };

  publicFunctions.removeResolutionElement = (element, animationType) => {
    element.addEventListener("animationend", () => {
      element.remove();
    });
    element.classList.add("animate__animated", `animate__${animationType}`, "animate__slow");
  };

  publicFunctions.resolvedBeingPaidByShareholder = () => {
    window.$("#shareholdersAccountModal").modal("hide");
    flashAlert("Achat payé par compte perso/associé");
    publicFunctions.errorResolved("not_matched");
  };

  return publicFunctions;
};

export { problematicPurchasesFunctions };
