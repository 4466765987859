import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../../utils/http_utils";

export default class extends Controller {
  salesUpdated(e) {
    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: (response) => {
        const salesInvoices = JSON.parse(response).sales_invoices;
        document.dispatchEvent(new CustomEvent("salesInvoice:updated", { detail: { salesInvoices: salesInvoices } }));
      },
    });
  }
}
