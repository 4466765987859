import { flashAlert, notifyError } from "../flash_alert";
import { redirectTo } from "../../utils/misc";
import { PROCESSING_PAYMENT_INTENT_STATUS, SUCCEEDED_PAYMENT_INTENT_STATUS } from "../../utils/constants";

class FinalizeSubscription {
  constructor(paymentIntent, callbackUrl, redirectionUrl, loaderElement, submitBtn, paymentErrorMsg) {
    this.paymentIntent = paymentIntent;
    this.callbackUrl = callbackUrl;
    this.redirectionUrl = redirectionUrl;
    this.loaderElement = loaderElement;
    this.submitBtn = submitBtn;
    this.paymentErrorMsg = paymentErrorMsg;
  }

  call() {
    if (
      !this.paymentIntent ||
      this.paymentIntent.status === SUCCEEDED_PAYMENT_INTENT_STATUS ||
      this.paymentIntent.status === PROCESSING_PAYMENT_INTENT_STATUS
    ) {
      this._onSubscriptionPayment();
    } else {
      this._confirmStripePayment();
    }
  }

  _onSubscriptionPayment() {
    fetch(this.callbackUrl, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
    }).then(() => {
      flashAlert("Merci, votre paiement a bien été pris en compte !");
      redirectTo(this.redirectionUrl);
    });
  }

  _confirmStripePayment() {
    const stripe = Stripe(envVariables["stripe_pk"]);

    stripe
      .confirmCardPayment(this.paymentIntent.client_secret, {
        payment_method: this.paymentIntent.payment_method,
      })
      .then((result) => {
        if (result.error) {
          this._handleErrors(result.error.message);
        } else {
          if (result.paymentIntent.status === SUCCEEDED_PAYMENT_INTENT_STATUS) {
            this._onSubscriptionPayment();
          } else {
            this._handleErrors("Une erreur est survenue, veuillez réessayer ultérieurement.");
          }
        }
      });
  }

  _handleErrors(errorMsg) {
    notifyError("La tentative de paiement a échoué, veuillez réessayer ou contacter un membre de l'équipe Acasi.");
    this.submitBtn.classList.remove("hidden");
    this.loaderElement.classList.add("hidden");
    this.paymentErrorMsg.textContent = errorMsg;
  }
}

export default FinalizeSubscription;
