// TODO: (Victor Desjardins) => remove usage of current date input controller and rename this one as date input controller
import { Controller } from "@hotwired/stimulus";
import Inputmask from "inputmask";
import { French } from "flatpickr/dist/l10n/fr.js";
import flatpickr from "flatpickr";
import { flatpickrAltInput } from "../utils/date_utils";

export default class extends Controller {
  static values = { calendarAdditionalOptions: Object };

  connect() {
    flatpickr(this.element, this._calendarOptions());
    const altInput = flatpickrAltInput(this.element);
    Inputmask({ mask: "99/99/9999" }).mask(altInput);

    if ("altInputAttributes" in this.element.dataset) {
      const altInputAttributes = JSON.parse(this.element.dataset.altInputAttributes);
      Object.keys(altInputAttributes).forEach((attribute) => {
        altInput.setAttribute(attribute, altInputAttributes[attribute]);
      });
    }
  }

  _calendarOptions() {
    const basicOptions = {
      locale: French,
      dateFormat: "Y-m-d",
      altFormat: "d/m/Y",
      altInput: true,
      maxDate: "today",
      allowInput: true,
      onChange: () => {
        $(flatpickrAltInput(this.element)).parsley().validate();
      },
    };

    return { ...basicOptions, ...this.calendarAdditionalOptionsValue };
  }
}
