import { Controller } from "@hotwired/stimulus";
import { fetchStream } from "../../utils/turbo_utils";

export default class extends Controller {
  static targets = ["turboFrame"];

  initialize() {
    window.$(this.element).on("show.bs.modal", () => {
      fetchStream(this.turboFrameTarget.dataset.src);
    });
  }
}
