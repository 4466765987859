import { Controller } from "@hotwired/stimulus";
import { splitValuesByVariousSeparators, isValidEmail } from "../utils/misc";

export default class extends Controller {
  static targets = ["source", "error"];
  static classes = ["invalid"];

  check(event) {
    const value = this.sourceTarget.value;
    const emails = splitValuesByVariousSeparators(value);

    if (!emails.every(isValidEmail)) {
      event.preventDefault();

      this._displayError();
    } else {
      this._removeErrorDisplay();
    }
  }

  displayUnknownError() {
    this.errorTarget.innerText = "Une erreur est survenue, les invitations n'ont donc pas pu être envoyées.";
  }

  _displayError() {
    this.sourceTarget.classList.add(this.invalidClass);
    this.errorTarget.innerText = "Vous devez saisir une liste d'adresses e-mail valides séparées par des virgules.";
  }

  _removeErrorDisplay() {
    this.sourceTarget.classList.remove(this.invalidClass);
    this.errorTarget.innerText = "";
  }
}
