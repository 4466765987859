import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["passwordInput"];

  connect() {
    this._input().dataset.passwordTarget = "passwordInput";
  }

  togglePasswordDisplay(event) {
    if (!this.hasPasswordInputTarget) {
      return;
    }

    event.currentTarget.classList.toggle("fa-eye-slash");
    event.currentTarget.classList.toggle("fa-eye");
    this.passwordInputTarget.type === "password"
      ? (this.passwordInputTarget.type = "text")
      : (this.passwordInputTarget.type = "password");
  }

  _input() {
    return this.element.querySelector("input[type='password']");
  }
}
