import { interpolate } from "../utils/string_utils";

export default class MatchingBalance {
  constructor(bankTransactions, purchaseInvoices, salesInvoices) {
    this.bankTransactions = bankTransactions;
    this.purchaseInvoices = purchaseInvoices;
    this.salesInvoices = salesInvoices;
  }

  get currency() {
    let balanceCurrency = null;

    if (this.bankTransactions.length > 0) {
      balanceCurrency = this.bankTransactionsCurrency;
    } else if (this.purchaseInvoices.length > 0 || this.salesInvoices.length > 0) {
      /* Default currency as we always have the euro amount for both purchase invoices and sales invoices */
      balanceCurrency = "EUR";
    }

    return balanceCurrency;
  }

  get bankTransactionsCurrency() {
    const currencies = this.bankTransactions.map((transaction) => transaction.currency);
    const uniqCurrencies = [...new Set(currencies)];
    return uniqCurrencies.length === 1 ? this.bankTransactions[0].currency : null;
  }

  get bankTransactionsAmount() {
    if (!this._computableInMatchingCurrency(this.bankTransactions)) {
      return null;
    }

    return this._sum(this.bankTransactions);
  }

  get purchaseInvoicesAmount() {
    if (!this._computableInMatchingCurrency(this.purchaseInvoices)) {
      return null;
    }

    return this._sum(this.purchaseInvoices);
  }

  get salesInvoicesAmount() {
    if (!this._computableInMatchingCurrency(this.salesInvoices)) {
      return null;
    }

    return this._sum(this.salesInvoices);
  }

  get balanceAmount() {
    if (this._invalidCurrencies()) {
      return null;
    }

    return this.bankTransactionsAmount + this.purchaseInvoicesAmount - this.salesInvoicesAmount;
  }

  get balanceAmountClass() {
    return this.isBalanceAcceptable ? "color_green-alt" : "color_alt-red";
  }

  get badge() {
    if (this.balanceAmount === 0) {
      return "";
    }

    const badgeTemplate = document.getElementById("matching-balance-badge-template");

    if (this._invalidCurrencies()) {
      return interpolate(badgeTemplate, {
        badgeClass: "badge-warning",
        badgeLabel: "Devises différentes",
      });
    } else if (this.isBalanceAcceptable) {
      return interpolate(badgeTemplate, {
        badgeClass: "badge-success",
        badgeLabel: "Écart acceptable",
      });
    } else {
      return interpolate(badgeTemplate, {
        badgeClass: "badge-warning",
        badgeLabel: "Écart important",
      });
    }
  }

  get isBalanceAcceptable() {
    if (this._noBankTransactionSelected() || this._noCounterpartsSelected()) {
      return false;
    }

    if (this._invalidCurrencies()) {
      return false;
    }

    return Math.abs(this.balanceAmount) <= this._maxBalanceAmount();
  }

  _invalidCurrencies() {
    return this.purchaseInvoicesAmount === null || this.salesInvoicesAmount === null;
  }

  _maxBalanceAmount() {
    return Math.max(Math.abs(this.bankTransactionsAmount * 0.05), 3);
  }

  _sum(operations) {
    return operations.reduce((acc, operation) => {
      const value = this._isForeignCurrency() ? operation.original_amount : operation.amount;
      return acc + value;
    }, 0);
  }

  _isForeignCurrency() {
    return this.currency !== "EUR";
  }

  _noBankTransactionSelected() {
    return this.bankTransactions.length === 0;
  }

  _noCounterpartsSelected() {
    return this.purchaseInvoices.length === 0 && this.salesInvoices.length === 0;
  }

  _computableInMatchingCurrency(operations) {
    if (!this._isForeignCurrency()) {
      return true;
    }

    return operations.every((operation) => operation.currency === this.currency);
  }
}
