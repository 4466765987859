import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";
import { hideElements, scrollToField, showElements } from "../../utils/misc";

export default class extends Controller {
  static targets = ["tripInputs", "deleteButton", "trip"];

  addTrip() {
    const templateContent = interpolate(this.tripInputsTarget, {
      note: "",
      date: "",
      distance: "",
      startingLocation: "",
      endingLocation: "",
    });

    this.tripInputsTarget.insertAdjacentHTML("beforebegin", templateContent);
    scrollToField(this.tripTargets.at(-1));
  }

  deleteButtonTargetConnected() {
    if (this.deleteButtonTargets.length > 1) {
      showElements(this.deleteButtonTargets);
    }
  }

  deleteButtonTargetDisconnected() {
    if (this.deleteButtonTargets.length === 1) {
      hideElements(this.deleteButtonTargets);
    }
  }
}
