import { roundToTwoDecimals } from "../utils/number_utils";
export default class SalesInvoiceLine {
  set quantity(_quantity) {
    this._quantity = _quantity;
  }

  set unitPrice(_unitPrice) {
    this._unitPrice = _unitPrice;
  }

  set discountValue(_discountValue) {
    this._discountValue = _discountValue;
  }

  set discountType(_discountType) {
    this._discountType = _discountType;
  }

  set vatRate(vatRate) {
    this._vatRate = vatRate;
  }

  netTotal() {
    return roundToTwoDecimals(this._discountedTotal());
  }

  vatTotal() {
    return roundToTwoDecimals(this.netTotal() * parseFloat(this._vatRate));
  }

  grossTotal() {
    return this.vatTotal() + this.netTotal();
  }

  _discountedTotal() {
    if (this._discountType === "%") {
      return this._quantity * this._unitPrice * (1 - this._discountValue / 100);
    } else {
      return this._quantity * this._unitPrice - this._discountValue;
    }
  }
}
