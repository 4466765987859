import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { updateFiscalYearEndOptions } from "../../creation/fiscal_year_end_period";

/**
 * Enables initialization of the "first_fiscal_year_end_date" when loading the company creation workflow form
 * Once a period is selected, the "first_fiscal_year_end_date" options are updated
 * The "fiscal-year-end-period" is a select2 instance; however select2 doesn't trigger native browser events (e.g. change) which are used by stimulus
 * So only the initialization is done in this stimulus controller and the field update is handled in a custom JS function
 */
export default class extends Controller {
  static targets = ["fiscalYearEndPeriod", "fiscalYearEndDate"];

  connect() {
    const periodTarget = this.fiscalYearEndPeriodTarget;
    const dateTarget = this.fiscalYearEndDateTarget;

    updateFiscalYearEndOptions(periodTarget, dateTarget);

    $(periodTarget).on("select2:select", () => {
      updateFiscalYearEndOptions(periodTarget, dateTarget);
    });
  }
}
