import { fullMonthAndYear, nextMonth, twoDigitsMonth } from "../../utils/date_utils";

const INVALID_CHARACTERS_REGEX = /[^\w-{}]/g;
const MONTH_REGEX = /{MM}/g;
const YEAR_REGEX = /{AAAA}/g;
// opening bracket is not followed by MM} or YYYY} OR closing backet is not preceded by {MM or {YYYY
// This regex is not used anymore because Safari does not support it
// const PERIOD_FORMAT_WRONG_USAGE_REGEX = /({(?!(MM|AAAA)})|(?<!{(MM|AAAA))})/g;

class CustomNumbering {
  constructor(prefix, firstInvoiceNumber = 1, resetOnPeriodChange = true, type = "custom") {
    this.prefix = prefix.trim().toUpperCase();
    this.firstInvoiceNumber =
      !firstInvoiceNumber || parseInt(firstInvoiceNumber) < 1 ? 1 : parseInt(firstInvoiceNumber);
    this.resetOnPeriodChange = resetOnPeriodChange;
    this.type = type;
  }

  errors() {
    const err = [];
    if (!this.prefix && this.type === "custom") {
      err.push("prefix_not_present");
    }
    if (this.invalidCharacters().length > 0) {
      err.push("invalid_characters");
    }
    if (this._isMonthWithoutYear()) {
      err.push("month_without_year");
    }
    if (this._isMonthBeforeYear()) {
      err.push("month_before_year");
    }
    if (!this._isPeriodFormatValid()) {
      err.push("period_format_wrong_usage");
    }

    return err;
  }

  invalidCharacters() {
    return this.prefix.match(INVALID_CHARACTERS_REGEX) || [];
  }

  examples() {
    const currentPeriod = new Date();
    const currentPeriodExampleNumbers = [
      this.firstInvoiceNumber,
      this.firstInvoiceNumber + 1,
      this.firstInvoiceNumber + 2,
    ];
    const currentPeriodNumberings = currentPeriodExampleNumbers.map((invoiceNumber) =>
      this._formattedNumberFor(currentPeriod, invoiceNumber)
    );

    const beginningOfNextYear = new Date(currentPeriod.getFullYear() + 1, 0, 1);
    const nextPeriod = this._containsMonth() ? nextMonth() : beginningOfNextYear;
    const nextPeriodInvoiceNumber = this._isNumberingResetOnPeriodChange() ? 1 : currentPeriodExampleNumbers.at(-1) + 1;
    const nextPeriodNumberings = [this._formattedNumberFor(nextPeriod, nextPeriodInvoiceNumber)];

    let nextYear, nextYearNumberings;
    if (this._containsMonth()) {
      nextYear = fullMonthAndYear(beginningOfNextYear);
      const nextYearInvoiceNumber = this._isNumberingResetOnPeriodChange() ? 1 : nextPeriodInvoiceNumber + 1;
      nextYearNumberings = [this._formattedNumberFor(beginningOfNextYear, nextYearInvoiceNumber)];
    }

    return {
      currentPeriod: fullMonthAndYear(currentPeriod),
      currentPeriodNumberings: currentPeriodNumberings,
      nextPeriod: fullMonthAndYear(nextPeriod),
      nextPeriodNumberings: nextPeriodNumberings,
      nextYear: nextYear,
      nextYearNumberings: nextYearNumberings,
    };
  }

  containsPeriod() {
    return this._containsMonth() || this._containsYear();
  }

  isWithoutYearPrefix() {
    return !this._containsYear();
  }

  isWithYearAndMonthPrefix() {
    return this._containsYear() && this._containsMonth();
  }

  _isMonthWithoutYear() {
    return this._containsMonth() && !this._containsYear();
  }

  _isMonthBeforeYear() {
    if (!this._containsMonth()) {
      return false;
    }

    return this.prefix.search(MONTH_REGEX) < this.prefix.search(YEAR_REGEX);
  }

  _containsMonth() {
    return this.prefix.match(MONTH_REGEX);
  }

  _containsYear() {
    return this.prefix.match(YEAR_REGEX);
  }

  _isPeriodFormatValid() {
    const numberOfOpenedBracket = (this.prefix.match(/{/g) || []).length;
    const numberOfClosedBracket = (this.prefix.match(/}/g) || []).length;
    if (numberOfClosedBracket === 0 && numberOfOpenedBracket === 0) {
      return true;
    }

    // User can only have {AAAA} OR {AAAA}{MM}
    if (numberOfOpenedBracket > 2 || numberOfClosedBracket > 2 || numberOfClosedBracket !== numberOfOpenedBracket) {
      return false;
    }

    if (numberOfOpenedBracket === 1) {
      return this.containsPeriod();
    }

    return this.isWithYearAndMonthPrefix();
  }

  _formattedNumberFor(period, invoiceNumber) {
    let prefix = this.prefix.replace(YEAR_REGEX, period.getFullYear());
    prefix = prefix.replace(MONTH_REGEX, twoDigitsMonth(period));
    invoiceNumber = ("0000" + invoiceNumber).slice(-5);
    return `${prefix}${invoiceNumber}`;
  }

  _isNumberingResetOnPeriodChange() {
    return this.containsPeriod() && this.resetOnPeriodChange;
  }
}

export default CustomNumbering;
