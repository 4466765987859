import { toggleClass } from "../utils/misc";

/* Dropzone does not handle pdf preview, so we need to override the default Dropzone img */
const handlePdfUploadFilePreview = (dropzoneElement, file) => {
  const dropzoneContainer = dropzoneElement.element.querySelector(".dropzone");
  toggleClass(dropzoneContainer, "has-file", !file);
  if (!file) {
    return;
  }

  const preview = document.createElement("embed");
  preview.src = URL.createObjectURL(file);

  const dzImageContainer = file.previewElement.querySelector(".dz-image");
  dzImageContainer.classList.add("fade-in");

  const dzImage = dzImageContainer.querySelector("img");
  dzImage.replaceWith(preview);
};

export { handlePdfUploadFilePreview };
