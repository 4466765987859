import { Controller } from "@hotwired/stimulus";
import { toggleClass } from "../../utils/misc";

export default class extends Controller {
  static targets = ["todoCard", "todoCounterElement", "completeListElement", "emptyListElement", "searchInput"];
  static values = { initialNumberOfTodos: Number };

  connect() {
    this.numberOfTodos = this.initialNumberOfTodosValue;

    document.addEventListener("sidePanel:close", () => {
      this._unselectSelectedTodo();
    });
  }

  updateCounter() {
    this.numberOfTodos -= 1;
    const anyRemainingTodos = this.numberOfTodos !== 0;

    if (anyRemainingTodos) {
      const content = this.numberOfTodos === 1 ? "1 action restante" : `${this.numberOfTodos} actions restantes`;
      this.todoCounterElementTarget.innerHTML = content;
    }

    toggleClass(this.completeListElementTarget, "hidden", anyRemainingTodos);
    toggleClass(this.emptyListElementTarget, "hidden", !anyRemainingTodos);
    toggleClass(this.searchInputTarget, "hidden", anyRemainingTodos);
  }

  cardSelected(e) {
    const selectedCard = e.currentTarget;
    this._unselectSelectedTodo();
    selectedCard.classList.add("selected");
  }

  _unselectSelectedTodo() {
    const selectedTodo = this.todoCardTargets.find((card) => card.classList.contains("selected"));
    if (selectedTodo) {
      selectedTodo.classList.remove("selected");
    }
  }
}
