import { Controller } from "@hotwired/stimulus";
import { daysAgo } from "../../utils/date_utils";

const LAST_DISPLAYED_AT_STORAGE_KEY = "mileage_allowances_calendar_year_warning_displayed_at";
const WARNING_INTERVAL_NUMBER_OF_DAYS = 7;

export default class extends Controller {
  connect() {
    if (this._shouldOpenModal()) {
      window.$(this.element).modal("show");
      localStorage.setItem(LAST_DISPLAYED_AT_STORAGE_KEY, new Date());
    }
  }

  _shouldOpenModal() {
    const lastDisplayedAt = localStorage.getItem(LAST_DISPLAYED_AT_STORAGE_KEY);

    if (!lastDisplayedAt) {
      return true;
    }

    new Date(lastDisplayedAt) < daysAgo(WARNING_INTERVAL_NUMBER_OF_DAYS);
  }
}
