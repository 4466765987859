import { Controller } from "@hotwired/stimulus";
import { notifyError } from "../components/flash_alert";
import { appendUrlSearchParams } from "../utils/misc";
import { handleHttpResponse } from "../utils/http_utils";

export default class extends Controller {
  static targets = [
    "searchInput",
    "searchInputText",
    "loader",
    "resultsForm",
    "results",
    "resultsList",
    "siret",
    "pappersPayload",
    "sirenePayload",
    "companyNotFoundLink",
  ];

  static values = { companyFormUrl: String };

  onSearchStarted() {
    this.loaderTarget.classList.remove("hidden");
    this.resultsFormTarget.classList.add("hidden");
  }

  onSearchCompleted(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        this._displayCompanyResults(response);
        this.companyNotFoundLinkTarget.href = this.companyFormUrlValue;
      },
      onError: () => {
        this._handleErrorOnCompanySearch();
      },
    });
  }

  onResultSelected(event) {
    const siret = event.currentTarget.dataset.siretValue;
    const sireneData = event.currentTarget.dataset.companyDetails;
    const url = appendUrlSearchParams("/pappers_results", { siret: siret });

    if (this.element.dataset.disablePappers === "true") {
      this._submitWithSirenePayload(sireneData);
    } else {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.resetCompanyInformationForm();
          this.siretTarget.value = siret;
          this.pappersPayloadTarget.value = JSON.stringify(data);
          this._submitResultsForm();
        })
        .catch(() => {
          // This error may occur if the pappers service is not available
          // In that case, we submit the form with the data previously acquired through the DataSirene search
          this._submitWithSirenePayload(sireneData);
        });
    }
  }

  resetCompanyInformationForm() {
    if (this.element.dataset.localStorageItem) {
      localStorage.setItem(this.element.dataset.localStorageItem, JSON.stringify({}));
    }
  }

  _displayCompanyResults(results) {
    this.resultsTarget.innerHTML = results;
    this.loaderTarget.classList.add("hidden");
    if (this.hasSearchInputTextTarget) {
      this.searchInputTextTarget.textContent = this.searchInputTarget.value || "votre recherche";
    }
    this.resultsFormTarget.classList.remove("hidden");
    this.resultsFormTarget.classList.add("fade-in-top");
  }

  _handleErrorOnCompanySearch() {
    notifyError("Une erreur est survenue dans la recherche de votre entreprise");
    this.loaderTarget.classList.add("hidden");
  }

  _submitResultsForm() {
    // When submitting the company results form, we want to be able to navigate to the next step within the sign_up Turbo frame
    // However, with this JS submission we cannot use directly the submit() function, as Turbo does not pick up that event and the Turbo-Frame header is not added to the underlying HTTP request
    // So we submit the form via Turbo instead to allow navigation to work correctly
    // See this thread for more context : https://discuss.hotwired.dev/t/triggering-turbo-frame-with-js/1622
    Turbo.navigator.submitForm(this.resultsFormTarget);
  }

  _submitWithSirenePayload(sireneData) {
    this.resetCompanyInformationForm();
    this.sirenePayloadTarget.value = sireneData;
    this._submitResultsForm();
  }
}
