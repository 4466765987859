const cancelEditOnShowCustomer = () => {
  const showCustomer = document.querySelector(".show-customer");
  if (showCustomer) {
    const form = document.getElementById("form_update_main_infos");
    const cancelEditBtn = document.getElementById("btn-cancel-main-infos");
    cancelEditBtn.addEventListener("click", () => {
      initInputsValues(form, `customer_continent_child`);
    });
  }
};

const initInputsValues = (form, countryInputId) => {
  const inputs = form.querySelectorAll(".form-control");
  inputs.forEach((input) => {
    input.value = input.dataset.value;
    input.classList.remove("invalid");

    if (input.id === countryInputId) {
      $(form.querySelector(".country-select2")).val(input.dataset.value);
      $(form.querySelector(".country-select2")).trigger("change");
    }
  });
};

export { cancelEditOnShowCustomer };
