import { Controller } from "@hotwired/stimulus";
import { select2ContainerFor, isEscapeKey, selectOptionFor } from "../utils/inputs_utils";
import $ from "jquery";

export default class extends Controller {
  static targets = ["selectInput", "textInput", "textInputContainer"];

  connect() {
    this.textInputTarget.addEventListener("keyup", (e) => {
      if (isEscapeKey(e.keyCode)) {
        this.hideTextInput();
      }
    });
  }

  hideSelectInput() {
    if (this.selectInputTarget.value === "free" || this.selectInputTarget.value === "Autres (préciser)") {
      this._toggleInputs();
      this.textInputTarget.focus();
    }
  }

  hideTextInput() {
    this._toggleInputs();
    $(this.textInputTarget).parsley().reset();
  }

  _toggleInputs() {
    this.selectInputTarget.disabled = !this.selectInputTarget.disabled;
    this.selectInputTarget.classList.toggle("hidden");
    select2ContainerFor(this.selectInputTarget).classList.toggle("hidden");

    this.textInputTarget.disabled = !this.textInputTarget.disabled;
    this.textInputContainerTarget.classList.toggle("hidden");
    selectOptionFor(this.selectInputTarget, this.selectInputTarget.dataset.initialValue);
  }
}
