import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["invalid"];

  checkValidation(event) {
    const input = event.currentTarget;
    const validations = input.dataset.validations;
    const inputValid = this.inputValid(input.value.trim(), validations);
    this.updateInput(input, inputValid);
  }

  inputValid(inputValue, validations) {
    return validations.includes("not-empty") && this.inputNotEmpty(inputValue);
  }

  updateInput(input, inputValid) {
    inputValid ? input.classList.remove(this.invalidClass) : input.classList.add(this.invalidClass);
  }

  inputNotEmpty(inputValue) {
    return inputValue != "";
  }
}
