import { Controller } from "@hotwired/stimulus";
import { getSalariesFromRequestCards } from "../../employees/salary_change_request_utils";
import { interpolate } from "../../utils/string_utils";
import { numberToCurrency } from "../../utils/number_utils";
import { fullMonthAndYear } from "../../utils/date_utils";

export default class extends Controller {
  static targets = ["card", "summaryTemplate", "summaryContainer", "form"];

  nextStep() {
    if (this._isSummaryDisplayed()) {
      this._updateSummary();
    }
  }

  _updateSummary() {
    const { pastSalaries, currentSalary } = getSalariesFromRequestCards(this.cardTargets);
    const templateOptions = {
      salaryCatchUp: pastSalaries.length > 0,
      pastSalaryRequests: this._formattedSalaries(pastSalaries),
      currentSalary: this._formattedSalary(currentSalary),
    };
    const templateContent = interpolate(this.summaryTemplateTarget, templateOptions);
    this.summaryContainerTarget.innerHTML = templateContent;
  }

  _formattedSalaries(salaries) {
    return salaries.map((salary) => this._formattedSalary(salary));
  }

  _formattedSalary(salary) {
    salary.grossAmount = numberToCurrency(salary.grossAmount);
    salary.date = fullMonthAndYear(salary.date);
    return salary;
  }

  _isSummaryDisplayed() {
    if (!this._withSalaryCatchUp()) {
      return false;
    }

    return this.summaryContainerTarget.checkVisibility();
  }

  _withSalaryCatchUp() {
    return this.hasSummaryContainerTarget;
  }
}
