import { Controller } from "@hotwired/stimulus";
import FinalizeSubscription from "../../components/subscription/finalize_subscription";
import { notifyError, errorMessages } from "../../components/flash_alert";

/**
 * Actions associated to the form used to finalize a subscription within the checkout page
 */
export default class extends Controller {
  static targets = ["submitBtn", "loadingImg", "paymentErrorMsg"];

  onSubscriptionSubmit() {
    this._toggleLoader();
  }

  onSubscriptionResponse(e) {
    const status = e.detail[0].status;
    if (status > 299) {
      this._toggleLoader();
      notifyError(errorMessages(status));
      return;
    }

    const data = JSON.parse(e.detail[0].response);

    new FinalizeSubscription(
      data.payment_intent,
      data.callback_url,
      data.redirection_url,
      this.loadingImgTarget,
      this.submitBtnTarget,
      this.paymentErrorMsgTarget
    ).call();
  }

  _toggleLoader() {
    this.submitBtnTarget.classList.toggle("hidden");
    this.loadingImgTarget.classList.toggle("hidden");
    this.paymentErrorMsgTarget.textContent = "";
  }
}
