import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open(event) {
    const targetUrl = event.currentTarget.dataset.targetUrl;
    const width = event.currentTarget.dataset.width || 600;
    const height = event.currentTarget.dataset.height || 600;

    window.open(targetUrl, targetUrl, `width=${width},height=${height},top=50,left=50`);
  }
}
