import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  call() {
    const { className, target } = this.element.dataset;
    const targetElements = document.querySelectorAll(target);

    targetElements.forEach((targetElement) => targetElement.classList.toggle(className));
  }
}
