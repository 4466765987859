const preCompleteCompanyAddress = () => {
  // TODO: (Victor Desjardins) => To be remove if we keep the company before owners form in creation workflow
  // const creatorAddressHiddenDiv = document.getElementById('creator-address');
  // if (creatorAddressHiddenDiv) {
  //   const headQuarterLocationBtns = document.querySelectorAll('#headquarter-location-choice .choice-button');
  //   headQuarterLocationBtns.forEach((btn) => {
  //     btn.addEventListener('click', (e) => {
  //       if (btn.getAttribute('value') === 'home_office') {
  //         setAddressesValues(creatorAddressHiddenDiv.dataset.streetAddress, creatorAddressHiddenDiv.dataset.zipAddress, creatorAddressHiddenDiv.dataset.cityAddress, true);
  //       } else {
  //         setAddressesValues('', '', '', false);
  //       }
  //     })
  //   })
  // }
  // const setAddressesValues = (streetAddressValue, zipAddressValue, cityAddressValue, disability) => {
  //   const event = new Event('input')
  //   const streetAddress = document.getElementById('company-creation-workflow-company-street-address');
  //   const zipAddress = document.getElementById('company-creation-workflow-company-zip-address');
  //   const cityAddress = document.getElementById('company-creation-workflow-company-city-address');
  //   streetAddress.value = streetAddressValue;
  //   streetAddress.dispatchEvent(event);
  //   zipAddress.value = zipAddressValue;
  //   zipAddress.dispatchEvent(event);
  //   cityAddress.value = cityAddressValue;
  //   cityAddress.dispatchEvent(event);
  //   if (disability) {
  //     streetAddress.classList.add('disabled');
  //     zipAddress.classList.add('disabled');
  //     cityAddress.classList.add('disabled');
  //   } else {
  //     streetAddress.classList.remove('disabled');
  //     zipAddress.classList.remove('disabled');
  //     cityAddress.classList.remove('disabled');
  //   }
  // }
};

export { preCompleteCompanyAddress };
