import { Controller } from "@hotwired/stimulus";
import { compiledTemplate, interpolate } from "../../utils/string_utils";
import { numberToCurrency } from "../../utils/number_utils";
import { handleHttpResponse } from "../../utils/http_utils";
import { closeModal, isStringTrue, openModal, toggleClass } from "../../utils/misc";
import { matchingModalFunctions } from "../../matching_modal/matching_modal_functions";
import { errorMessages, notifyError } from "../../components/flash_alert";
import { i18nTranslations } from "../../utils/i18n_utils";
import { registerNumberToCurrencyHelper } from "../../utils/handlebars_helpers";
import MatchingBalance from "../../matching_modal/matching_balance";

export default class extends Controller {
  static targets = [
    "bankTransactionInput",
    "purchaseInvoiceInput",
    "salesInvoiceInput",
    "summaryContainer",
    "summaryTemplate",
    "matchingBalanceAmount",
    "balanceBadgeContainer",
    "chosenCandidatesList",
    "selectedForMatchingLineTemplate",
    "emptySelectedOperations",
    "operationSelectedForMatching",
    "errorContainer",
    "splitPaymentsContainer",
    "splitPaymentsCheckbox",
  ];

  connect() {
    this._toggleEmptySelectedOperations();
    this._updateSummary();
  }

  removeFromMatchingSelection(e) {
    if (!matchingModalFunctions().targetShouldToggleMatchingSelection(e.target)) {
      return;
    }

    const line = e.currentTarget;
    line.remove();
    const operationId = matchingModalFunctions().descriptionLine(line).dataset.operationId;
    const candidatesController = this._candidatesControllerFor(line);
    candidatesController.removeFromMatchingSelection(operationId);
    this._toggleEmptySelectedOperations();
    this._updateSummary();
  }

  addToMatchingSelection(transactionElement) {
    const lineTemplate = interpolate(this.selectedForMatchingLineTemplateTarget, {
      lineItem: transactionElement.outerHTML,
    });
    this.chosenCandidatesListTarget.insertAdjacentHTML("beforeend", lineTemplate);
    this._toggleEmptySelectedOperations();
    this._updateSummary();
  }

  onMatchingUpdated(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const data = JSON.parse(response);
        const { already_split_payments } = data.details;
        if (already_split_payments) {
          this._alertAsAlreadyPaidBySplitPayments(data);
        }
        const event = new CustomEvent("matching:updated", { detail: data });
        document.dispatchEvent(event);
        closeModal("matching-modal");
      },
      onError: (response, status) => {
        if (status === 422) {
          const parsedResponse = JSON.parse(response);
          this.errorContainerTarget.innerHTML = i18nTranslations().t(parsedResponse.error, {
            scope: "manual_matching.errors",
          });
        } else {
          notifyError(errorMessages(status));
        }
      },
    });
  }

  onSubmit() {
    if (this.splitPaymentsCheckboxTarget.checked === true) {
      this.element.action = this.element.dataset.createSplitPaymentsManualMatchingUrl;
    } else {
      this.element.action = this.element.dataset.createManualMatchingUrl;
    }
  }

  _updateSummary() {
    this.matching = new MatchingBalance(this.bankTransactions, this.purchaseInvoices, this.salesInvoices);
    this._updateSummaryTemplate();
    this._updateMatchingBalance();
    this._updateSplitPaymentsCheckbox();
  }

  get bankTransactions() {
    return this._formattedValues(this.bankTransactionInputTargets);
  }

  get purchaseInvoices() {
    return this._formattedValues(this.purchaseInvoiceInputTargets);
  }

  get salesInvoices() {
    return this._formattedValues(this.salesInvoiceInputTargets);
  }

  _updateSummaryTemplate() {
    const template = interpolate(this.summaryTemplateTarget, {
      selectedBankTransactionsCounter: this.bankTransactionInputTargets.length,
      selectedBankTransactionsAmount: this._formattedSummaryAmount(this.matching.bankTransactionsAmount),
      selectedPurchaseInvoicesCounter: this.purchaseInvoiceInputTargets.length,
      selectedPurchaseInvoicesAmount: this._formattedSummaryAmount(-this.matching.purchaseInvoicesAmount),
      selectedSalesInvoicesCounter: this.salesInvoiceInputTargets.length,
      selectedSalesInvoicesAmount: this._formattedSummaryAmount(this.matching.salesInvoicesAmount),
      balanceAmount: this._formattedSummaryAmount(this.matching.balanceAmount),
    });
    this.summaryContainerTarget.innerHTML = template;
  }

  _updateMatchingBalance() {
    this.matchingBalanceAmountTarget.classList.add(this.matching.balanceAmountClass);
    this.balanceBadgeContainerTarget.innerHTML = this.matching.badge;
    this.errorContainerTarget.innerHTML = "";
  }

  _toggleEmptySelectedOperations() {
    toggleClass(this.emptySelectedOperationsTarget, "hidden", this.operationSelectedForMatchingTargets.length === 0);
  }

  _candidatesControllerFor(line) {
    const controllerName = "matching-modal--candidates-list";
    const operationType = matchingModalFunctions().descriptionLine(line).dataset.operationType;
    const controllerElement = document.querySelector(
      `.candidates-list-container[data-candidate-type="${operationType}"]`
    );
    return this.application.getControllerForElementAndIdentifier(controllerElement, controllerName);
  }

  _formattedSummaryAmount(amount) {
    return amount === null ? "-" : numberToCurrency(amount, { currency: this.matching.currency });
  }

  _updateSplitPaymentsCheckbox() {
    const shouldDisplaySplitPaymentsContainer = this._shouldDisplaySplitPaymentsContainer();
    this.splitPaymentsCheckboxTarget.checked =
      this._isPurchaseInvoicePaidBySplitPayments() && shouldDisplaySplitPaymentsContainer;
    toggleClass(this.splitPaymentsContainerTarget, "hidden", shouldDisplaySplitPaymentsContainer);
  }

  _shouldDisplaySplitPaymentsContainer() {
    if (this.matching.salesInvoices.length > 0) {
      return false;
    }
    if (this._isDefaultMatchingBalanced()) {
      return false;
    }
    if (!this._isMatchingEditable()) {
      return false;
    }
    return this.matching.purchaseInvoices.length === 1;
  }

  _isDefaultMatchingBalanced() {
    if (this._isPurchaseInvoicePaidBySplitPayments()) {
      return false;
    }
    return this.matching.isBalanceAcceptable;
  }

  _isPurchaseInvoicePaidBySplitPayments() {
    return this.matching.purchaseInvoices.some((purchaseInvoice) => purchaseInvoice.paid_by_split_payments === true);
  }

  _isMatchingEditable() {
    return isStringTrue(this.element.dataset.editable);
  }

  _formattedValues(inputs) {
    return inputs.map((input) => JSON.parse(input.dataset.values));
  }

  _alertAsAlreadyPaidBySplitPayments(data) {
    registerNumberToCurrencyHelper();

    const { balance_amount, balance_currency } = data.details;
    const detailsTemplate = document.getElementById("split-payments-notice-modal-template");
    const detailsContainer = document.getElementById("split-payments-notice-details");

    const options = {
      purchase_invoice: data.purchase_invoices[0],
      bank_transactions: data.bank_transactions,
      balance_amount: balance_amount,
      balance_currency: balance_currency,
    };
    detailsContainer.innerHTML = compiledTemplate(detailsTemplate, options);
    openModal("split-payments-notice-modal");
  }
}
