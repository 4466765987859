import { Controller } from "@hotwired/stimulus";
import {
  toggleCtaButtons,
  observeCtaBarPosition,
  animateProgressBar,
  bottomProgressBarElement,
} from "../../profiles/profile_forms";

export default class extends Controller {
  connect() {
    this.form = this.element;
    animateProgressBar(bottomProgressBarElement(this.form));
    this.form.addEventListener("input", () => toggleCtaButtons(true, this.form));
    observeCtaBarPosition(this.form);

    this._spreadFormInputEvent();
  }

  _spreadFormInputEvent() {
    // This is needed becasuse we don't use proper html radio buttons
    // To remove once we refactor and use those kind of inputs
    const choiceButtonsInputs = document.querySelectorAll(".multi-choice-buttons input[type='text']");
    choiceButtonsInputs.forEach((input) => {
      input.addEventListener("input", () => this.form.dispatchEvent(new Event("input")));
    });
  }
}
