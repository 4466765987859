import { Controller } from "@hotwired/stimulus";
import { displayFilePreview, fileDropped, manageProfileDragHover } from "../profiles/profile_file_upload";

export default class extends Controller {
  static targets = ["fileInput", "dropArea", "noFileImage"];

  fileUploaded() {
    displayFilePreview(this.element, this.fileInputTarget);
  }

  fileDropped(e) {
    fileDropped(e, this.fileInputTarget, this.dropAreaTarget, this.noFileImageTarget);
  }

  dropAreaOver() {
    manageProfileDragHover(this.dropAreaTarget, this.noFileImageTarget, "hover");
  }

  dropAreaLeave() {
    manageProfileDragHover(this.dropAreaTarget, this.noFileImageTarget, "");
  }

  preventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }
}
