import { PAPPERS_URL } from "../utils/constants";

const validateInformation = () => {
  const divOnboardingInformation = document.getElementById("validate-information-page");

  if (divOnboardingInformation) {
    divOnboardingInformation.querySelector(".additional-info a").addEventListener("click", function (e) {
      const companyNameInput = divOnboardingInformation.querySelector("#company_name");
      const sirenInput = divOnboardingInformation.querySelector("#siret_number");
      if (!companyNameInput.validity.valid || !sirenInput.validity.valid) {
        e.target.setAttribute("href", PAPPERS_URL);
        return;
      }
      const companyName = companyNameInput.value.split(" ").join("-").toLowerCase();
      const siren = sirenInput.value.substring(0, 9);
      e.target.setAttribute("href", `${PAPPERS_URL}/entreprise/${companyName}-${siren}`);
    });
  }
};

export { validateInformation };
