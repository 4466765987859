import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";
import { resolveTodo } from "../../todo_list/todo_resolver";

export default class extends Controller {
  static values = {
    todoId: Number,
  };

  onCreatedMessage(e) {
    handleHttpResponse({
      payload: e.detail.fetchResponse.response,
      onSuccess: () => {
        setTimeout(() => {
          resolveTodo(this.todoIdValue);
        }, 1000);
      },
    });
  }
}
