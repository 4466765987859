import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("transitionend", (e) => {
      if (e.propertyName === "width") {
        document.dispatchEvent(new CustomEvent("mainContainer:resized"));
      }
    });
  }
}
