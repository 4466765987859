const manageCredentialModifications = () => {
  // Function on click on modify phone number
  const modifyNumberBtn = document.getElementById("modify_user_mobile");
  if (modifyNumberBtn) {
    modifyNumberBtn.addEventListener("click", () => {
      document.querySelector("[name='phone']").value = document.getElementById("mobile-number").innerText;
      hideItem("invalid-code-number");
      hideItem("check-sms-code");
    });
  }
};

const hideItem = (itemId) => {
  document.getElementById(itemId).classList.add("hidden");
};

export { manageCredentialModifications };
