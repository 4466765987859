import { Controller } from "@hotwired/stimulus";
import { initCardForm, initSEPAForm } from "../profiles/subscription_form";

export default class extends Controller {
  static targets = ["paymentMethodButton", "paymentMethodForm"];

  connect() {
    initCardForm();
    initSEPAForm();
  }

  show(event) {
    const button = event.target;
    this._updateActiveButtonStyle(button);
    this._updateActiveFormDisplay(button);
  }

  _updateActiveButtonStyle(button) {
    this.paymentMethodButtonTargets.forEach((btn) => btn.classList.remove("active"));
    button.classList.add("active");
  }

  _updateActiveFormDisplay(button) {
    this.paymentMethodFormTargets.forEach((targetButton) => {
      if (targetButton.dataset.paymentMethodType === button.dataset.paymentMethodType) {
        targetButton.classList.remove("hidden");
      } else {
        targetButton.classList.add("hidden");
      }
    });
  }
}
