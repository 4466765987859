import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../utils/http_utils";
import { updateBankTransactionsTableRows } from "../datatables/bank_transactions_datatable";
import { closeModal } from "../utils/misc";

export default class extends Controller {
  static targets = ["radioButton"];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      const { relatedTarget: bankTransactions } = event;

      this.radioButtonTargets.forEach((button) => {
        button.checked = this._preCheckShareholder(bankTransactions, button);
      });
    });
  }

  shareholderSelected(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const bankTransactions = JSON.parse(response).bank_transactions;

        closeModal(this.element.id);
        updateBankTransactionsTableRows(bankTransactions);
      },
    });
  }

  _preCheckShareholder(bankTransactions, button) {
    const hasUniqueShareholder = this._hasUniqueShareholder(bankTransactions);
    const shareholderIdsMatch = bankTransactions[0].shareholder_id === parseInt(button.value);

    return hasUniqueShareholder && shareholderIdsMatch;
  }

  _hasUniqueShareholder(bankTransactions) {
    const shareholdersIds = bankTransactions.map((bankTransaction) => bankTransaction.shareholder_id);

    return new Set(shareholdersIds).size === 1;
  }
}
