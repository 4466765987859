import { fileUploadFunctions } from "../components/file_upload_functions";
import { BANK_TRANSACTION_MAX_FILE_SIZE } from "../utils/constants";
import { bitsToMegabytes } from "../utils/number_utils";

const MAX_ATTACHMENT_COUNT = 1;

const isBankAttachmentValid = (files) => {
  let fileValid = false;

  if (!files || files.length === 0) {
    fileUploadFunctions().alertFileMissing();
  } else if (files.length > MAX_ATTACHMENT_COUNT) {
    fileUploadFunctions().alertInvalidFileNumber(MAX_ATTACHMENT_COUNT);
  } else if (!fileUploadFunctions().filesFormatValid([files[0].type])) {
    fileUploadFunctions().alertInvalidFileFormat();
  } else if (files[0].size > BANK_TRANSACTION_MAX_FILE_SIZE) {
    fileUploadFunctions().alertInvalidFileSize(bitsToMegabytes(BANK_TRANSACTION_MAX_FILE_SIZE));
  } else {
    fileValid = true;
  }

  return fileValid;
};

export { isBankAttachmentValid };
