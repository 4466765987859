const userAvatarFormSubmit = () => {
  const userAvatarInputs = document.querySelectorAll("#sidebar #user_logo, #avatar_form #user_logo");
  if (userAvatarInputs) {
    userAvatarInputs.forEach((input) => {
      input.addEventListener("change", (e) => {
        input.form.submit();
      });
    });
  }
};

export { userAvatarFormSubmit };
