import { Controller } from "@hotwired/stimulus";
import { updateTooltipTitle } from "../../utils/misc";
import { numberToCurrency } from "../../utils/number_utils";

export default class extends Controller {
  connect() {
    const companyId = this.element.dataset.companyId;
    App.cable.subscriptions.create(
      { channel: "PurchaseInvoiceCurrencyExchangeChannel", company_id: companyId },
      {
        received: (data) => {
          if (data.currency_exchange_done) {
            this._displayEuroAmount(data);
          } else {
            this._displayOngoingExchangeRate(data);
          }
        },
      }
    );
  }

  _displayEuroAmount(data) {
    const purchaseRow = this._purchaseRow(data.purchase_invoice_id);
    if (purchaseRow) {
      const spinner = purchaseRow.querySelector(".currency-exchange-spinner");
      if (spinner) {
        spinner.remove();
      }
      const tooltipElement = purchaseRow.querySelector(".euro-amount");
      updateTooltipTitle(tooltipElement, numberToCurrency(data.euro_amount));
      tooltipElement.classList.remove("hidden");
    }
  }

  _displayOngoingExchangeRate(data) {
    const spinner = `
        <i 
          class='fa fa-spinner fa-pulse fa-lg fa-fw acasi_tooltip currency-exchange-spinner' style='color: rgba(0,0,0,0.6)' 
          data-toggle='tooltip' data-placement='left' title='Recherche du taux de change'>
        </i>
    `;
    const purchaseRow = this._purchaseRow(data.purchase_invoice_id);
    if (purchaseRow) {
      purchaseRow.querySelector(".amount-column").insertAdjacentHTML("afterbegin", spinner);
    }
  }

  _purchaseRow(purchaseId) {
    return document.querySelector(`.row-${purchaseId}`);
  }
}
