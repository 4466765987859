import { Controller } from "@hotwired/stimulus";
import { salesPdfUploadDropzoneElement, submitDropzoneForm } from "../../../sales_invoices/pdf_upload_dropzone";
import { closeModal } from "../../../utils/misc";

export default class extends Controller {
  static targets = ["filePreviewsContainer", "uploadImgLabel", "dragAndDropContainer", "submitBtn"];

  connect() {
    this._initDropzone();
  }

  validateSubmission(e) {
    e.preventDefault();
    this.submitBtnTarget.disabled = true;

    submitDropzoneForm(this.element, this.dropzone);
  }

  _initDropzone() {
    this.dropzone = salesPdfUploadDropzoneElement(this.element, {
      filePreviewsContainer: this.filePreviewsContainerTarget,
      dragAndDropContainer: this.dragAndDropContainerTarget,
      uploadImgLabel: this.uploadImgLabelTarget,
    });
    this._initCustomEvents();
  }

  _initCustomEvents() {
    this.dropzone.on("addedfile", () => {
      this._setSubmitBtnAvailability();
    });

    this.dropzone.on("success", (e) => {
      closeModal("upload_single_sales_modal");
      const event = new CustomEvent("salesInvoice:pdfUploaded");
      document.dispatchEvent(event);

      const response = JSON.parse(e.xhr.response);
      if (this._isSalesInvoiceAttachedToBankTransaction(response)) {
        document.dispatchEvent(
          new CustomEvent("bankTransaction:updated", { detail: { bank_transaction: response.bank_transaction } })
        );
      }
    });
  }

  _isSalesInvoiceAttachedToBankTransaction(response) {
    return !!response.bank_transaction;
  }

  _setSubmitBtnAvailability() {
    this.submitBtnTarget.disabled = this.dropzone.files.length === 0;
  }
}
