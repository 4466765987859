import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["csvRecordLine"];

  connect() {
    const companyId = this.element.dataset.companyId;

    this._subscribeToHandleSalesCsvChannel(companyId);
  }

  _subscribeToHandleSalesCsvChannel(companyId) {
    App.cable.subscriptions.create(
      { channel: "HandleSalesCsvImportChannel", company_id: companyId },
      {
        received: (data) => {
          const csvImportLine = this.csvRecordLineTargets.find(
            (line) => line.dataset.csvImportId === data.id.toString()
          );
          csvImportLine.querySelector(".status").innerText = csvImportLine.dataset.importedLabel;
        },
      }
    );
  }
}
