import { Dropzone } from "dropzone";
import { errorMessages, notifyError } from "./flash_alert";
import { hideElements, showElements } from "../utils/misc";
import { FILE_ACCEPTED_FORMATS } from "../utils/constants";
import fileThumbnail from "../../assets/images/icons/file.svg";

const languageOptions = {
  dictCancelUpload: "Annuler",
  dictCancelUploadConfirmation: "Etes-vous sûr de vouloir annuler le chargement ?",
  dictDefaultMessage: "Déposez vos factures ou cliquez ici",
  dictFallbackMessage: "Votre navigateur ne supporte pas le drag & drop.",
  dictFileTooBig: "Le fichier est trop volumineux ({{filesize}}MB). Taille maximum: {{maxFilesize}}MB.",
  dictInvalidFileType: "Ce type de fichier n'est pas supporté.",
  dictMaxFilesExceeded: "Le nombre maximum de fichiers ({{maxFiles}}) a été dépassé.",
  dictRemoveFile: "Supprimer",
  dictResponseError: "Une erreur est survenue.",
  dictUploadCanceled: "Chargement annulé.",
  dictMissingFile: "Fichier manquant.",
};

const DEFAULT_MAX_FILE_SIZE = 10;
const DEFAULT_MAX_FILES = 1;

const computedOptions = (uploadOptions) => {
  const maxFiles = uploadOptions.maxFiles || DEFAULT_MAX_FILES;
  const acceptedFiles = uploadOptions.acceptedFiles || FILE_ACCEPTED_FORMATS;

  return {
    acceptedFiles: acceptedFiles,
    dictInvalidFileType: acceptedFormatsNotice(acceptedFiles.split(" ")),
    addRemoveLinks: true,
    autoProcessQueue: false,
    maxFilesize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: maxFiles,
    parallelUploads: maxFiles,
    uploadMultiple: maxFiles > DEFAULT_MAX_FILES,
    paramName: "file",
    clickable: ".dropzone",
    ...uploadOptions,
  };
};

const initDropzone = (dropzoneFormId, additionalLanguageOptions, uploadOptions) => {
  const options = computedOptions(uploadOptions);

  return new Dropzone(`#${dropzoneFormId}`, {
    ...languageOptions,
    ...options,
    ...additionalLanguageOptions,
  });
};

const initDropzoneBasicEvents = (dropzone, uploadImgLabel, dragAndDropContainer) => {
  dropzone.on("addedfile", (file) => {
    handleFilePreview(file);
    uploadImgLabel.classList.add("hidden");
    toggleDropzoneValidity(dropzone, true);
  });

  dropzone.on("addedfiles", () => {
    hideElements(progressBarElements(dropzone.element));
  });

  dropzone.on("removedfile", () => {
    if (dropzone.files.length === 0) {
      uploadImgLabel.classList.remove("hidden");
    }
    toggleDropzoneValidity(dropzone, true);
  });

  dropzone.on("processing", () => {
    showElements(progressBarElements(dropzone.element));
    hideElements(cancelBtnElements(dropzone.element));
  });

  dropzone.on("error", function (file, data, xhr) {
    dropzone.removeFile(file);

    if (xhr) {
      handleServerSideError(xhr);
    } else {
      handleClientSideError(dropzone, data);
    }
  });

  dropzone.on("dragover", (e) => {
    if (e.target.dataset.hoverable) {
      activateDropzoneContainer(dragAndDropContainer);
    }
  });

  dropzone.on("dragleave", () => {
    deactivateDropzoneContainer(dragAndDropContainer);
  });

  dropzone.on("drop", () => {
    deactivateDropzoneContainer(dragAndDropContainer);
  });
};

const handleServerSideError = (serverResponse) => {
  const errorMessage = JSON.parse(serverResponse.response).error_message;
  if (errorMessage) {
    notifyError(errorMessage);
  } else {
    notifyError(errorMessages(serverResponse.status));
  }
};

const handleClientSideError = (dropzone, data) => {
  const errorContainer = dropzone.element.querySelector(".dropzone-errors");
  errorContainer.innerHTML = data;
};

const toggleDropzoneValidity = (dropzone, isValid) => {
  const errorContainer = dropzone.element.querySelector(".dropzone-errors");

  if (isValid) {
    errorContainer.innerHTML = "";
  } else {
    errorContainer.innerHTML = languageOptions.dictMissingFile;
  }
};

const handleFilePreview = (file) => {
  file.previewElement.querySelector(".dz-image img").setAttribute("src", fileThumbnail);
};

const progressBarElements = (dropzoneElement) => dropzoneElement.querySelectorAll(".dz-progress");

const cancelBtnElements = (dropzoneElement) => dropzoneElement.querySelectorAll(".dz-remove");

const dropzoneElement = (element) => Dropzone.forElement(`#${element.id}`);

const acceptedFormatsNotice = (array) => {
  const isSingleFormat = array.length === 1;

  return isSingleFormat
    ? `Seul le format ${array[0]} est accepté.`
    : `Seuls les formats ${array.slice(0, array.length - 1).join(" ")} et ${array.at(-1)} sont acceptés.`;
};

const activateDropzoneContainer = (container) => {
  container.classList.add("dragging");
};

const deactivateDropzoneContainer = (container) => {
  container.classList.remove("dragging");
};

const completeProgrammaticUpload = (dropzoneElement, file) => {
  hideElements(progressBarElements(dropzoneElement.element));
  dropzoneElement.files.push(file);
};

export {
  acceptedFormatsNotice,
  initDropzone,
  dropzoneElement,
  progressBarElements,
  cancelBtnElements,
  initDropzoneBasicEvents,
  toggleDropzoneValidity,
  completeProgrammaticUpload,
};
