import { Controller } from "@hotwired/stimulus";
import { TURBO_STREAM_ACCEPT_HEADER } from "../utils/constants";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    window.addEventListener("turbo:before-fetch-request", this._appendTurboToHeaders);
  }

  disconnect() {
    window.removeEventListener("turbo:before-fetch-request", this._appendTurboToHeaders);
  }

  _appendTurboToHeaders(event) {
    const { headers } = event.detail.fetchOptions || {};

    // Force turbo-stream Accept header on GET requests initiated by link_to elements within the main frame
    // Turbo doesn't have those headers for links within a frame, see here for more context : https://github.com/hotwired/turbo-rails/issues/120
    if (headers) {
      headers.Accept = [TURBO_STREAM_ACCEPT_HEADER, headers.Accept].join(", ");
    }
  }

  submit() {
    Turbo.navigator.submitForm(this.formTarget);
  }
}
