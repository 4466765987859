const submitFormOnOwnerShareholderNotComplete = () => {
  const notCompleteBtn = document.querySelector(".completeness-choice-buttons > input[value='false']");
  if (notCompleteBtn) {
    notCompleteBtn.addEventListener("click", () => {
      const form = notCompleteBtn.closest("form");
      document.querySelector(".creation-container .action-buttons").classList.add("hidden");
      Rails.fire(form, "submit");
    });
  }
};

export { submitFormOnOwnerShareholderNotComplete };
