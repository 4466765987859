import { Controller } from "@hotwired/stimulus";
import { closeSidePanel } from "../../utils/misc";
import { removeTableRow } from "../../components/tables/remove_table_row";
import { notifyUpdate } from "../../components/flash_alert";

export default class extends Controller {
  attachmentTypeChanged(event) {
    const deletedInvoiceId = event.target.dataset.accountableId;
    removeTableRow(deletedInvoiceId);
    notifyUpdate();
    closeSidePanel();
    document.dispatchEvent(new CustomEvent("accountable:typeChanged"));
  }
}
