import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../../utils/http_utils";
import { interpolate, renderMustacheTemplate } from "../../../utils/string_utils";
import { i18nTranslations } from "../../../utils/i18n_utils";

export default class extends Controller {
  static targets = [
    "registrationLoader",
    "submitBtn",
    "registrationErrorContainer",
    "registrationError",
    "registrationStatus",
    "redirectionBtnContainer",
    "redirectionBtnTemplate",
  ];

  connect() {
    App.cable.subscriptions.create(
      { channel: "QontoAutomaticCapitalDepositChannel", company_id: this.element.dataset.companyId },
      {
        received: (automaticCapitalDepositWorkflow) => {
          if (automaticCapitalDepositWorkflow.workflow_status === "documents_submitted") {
            this._displayRegistrationRedirectionBtn(automaticCapitalDepositWorkflow.redirection_url);
          }
        },
      }
    );
  }

  startRegistration() {
    this._toggleSubmissionLoader();
    this.registrationErrorContainerTarget.classList.add("hidden");
    renderMustacheTemplate("qonto-sending-informations", this.registrationStatusTarget);
  }

  registrationInitialized(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const data = JSON.parse(response);
        if (data.error) {
          this._displayRegistrationError(data.error);
        } else {
          renderMustacheTemplate("qonto-sending-documents", this.registrationStatusTarget);
          /* A job is running to send documents to Qonto */
          /* This timeout is here in case of job too long, as we can handle automatic capital deposit without documents */
          setTimeout(() => {
            this._displayRegistrationRedirectionBtn(data.redirection_url);
          }, 20000);
        }
      },
      onError: () => {
        this._displayRegistrationError("standard");
      },
    });
  }

  _displayRegistrationRedirectionBtn(url) {
    if (!this.redirectionBtnContainerTarget.classList.contains("hidden")) {
      return;
    }

    renderMustacheTemplate("qonto-retrieving-data", this.registrationStatusTarget);
    setTimeout(() => {
      this.redirectionBtnContainerTarget.innerHTML = interpolate(this.redirectionBtnTemplateTarget, { url: url });
      this.redirectionBtnContainerTarget.classList.remove("hidden");
      this.registrationLoaderTarget.classList.add("hidden");
    }, 1500);
  }

  _displayRegistrationError(error) {
    this.registrationErrorContainerTarget.classList.remove("hidden");
    this.registrationErrorTarget.innerText = i18nTranslations().t(error, {
      scope: "creation_workflow.automatic_capital_deposit.errors",
    });
    this._toggleSubmissionLoader();
  }

  _toggleSubmissionLoader() {
    this.registrationLoaderTarget.classList.toggle("hidden");
    this.submitBtnTarget.classList.toggle("hidden");
  }
}
