import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cursor", "leftValue", "rightValue", "priceAmount"];

  choosePlan(event) {
    this.cursorTarget.classList.toggle("switched");
    this.leftValueTarget.classList.toggle("on");
    this.rightValueTarget.classList.toggle("on");
    const selectedPlan = this.leftValueTarget.classList.contains("on") ? this.leftValueTarget : this.rightValueTarget;
    this.priceAmountTarget.innerText = selectedPlan.dataset.amount;

    const selectedPlanInputId = event.currentTarget.dataset.planInputId;
    const selectedPlanInput = document.getElementById(selectedPlanInputId);
    selectedPlanInput.value = selectedPlan.dataset.planId;
  }
}
