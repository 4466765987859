import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["monetaryInputs"];

  convertToCents() {
    this.monetaryInputsTargets.forEach(function (element) {
      element.value = element.value * 100;
    });
  }
}
