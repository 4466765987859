import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";
import { dateRangeLabel, dispatchFilterPreselectedEvent } from "../../datatables/datatable_filters";
import { dataTableFunctions } from "../../datatables/datatable_functions";

export default class extends Controller {
  static targets = ["container", "filter", "selectedFilter", "filterTemplate", "updatePanel", "updatePanelContent"];
  static values = {
    preselectedFilters: Object,
  };

  connect() {
    document.addEventListener("datatable:filterPreselected", (event) => {
      this.filterSelected(event);
    });

    this._buildPreselectedFilters();

    document.addEventListener("datatable:filterSelected", (event) => {
      this.filterSelected(event);
    });

    document.addEventListener("datatable:filterUpdated", (event) => {
      this.filterUpdated(event);
    });
  }

  _buildPreselectedFilters() {
    // TODO(Jonathan Jalal): Building these "preselected filters" sets the filter panel inputs before the datatable is initialized,
    // but this "assumes" the datatable has not been initialized yet (as there is a HTTP request to fetch the data before initializing it).
    // Ideally, we should find a way to delay the initialization of the datatable until these selected filters (if any) are built.
    const categoryFilter = this.preselectedFiltersValue.category;
    if (categoryFilter) {
      dispatchFilterPreselectedEvent({
        name: "category",
        translatedValue: categoryFilter.value,
        label: categoryFilter.label,
        data: [{ id: categoryFilter.input_id, value: categoryFilter.value }],
      });
    }

    const matchingTagFilter = this.preselectedFiltersValue.matching_tag;
    if (matchingTagFilter) {
      dispatchFilterPreselectedEvent({
        name: "matching_tag",
        translatedValue: matchingTagFilter.value,
        label: matchingTagFilter.label,
        data: [{ id: matchingTagFilter.input_id, value: matchingTagFilter.value }],
      });
    }

    const startDateFilter = this.preselectedFiltersValue.start_date;
    const endDateFilter = this.preselectedFiltersValue.end_date;
    if (startDateFilter || endDateFilter) {
      const startDate = startDateFilter.value;
      const endDate = endDateFilter.value;

      dispatchFilterPreselectedEvent({
        name: "date",
        translatedValue: dateRangeLabel(startDate, endDate),
        label: startDateFilter.label,
        data: [
          { id: startDateFilter.input_id, value: startDateFilter.value },
          { id: endDateFilter.input_id, value: endDateFilter.value },
        ],
      });
    }
  }

  filterSelected(event) {
    const { name, translatedValue, label, data } = event.detail;
    const templateContent = interpolate(this.selectedFilterTarget, {
      filter_name: name,
      translated_filter_value: translatedValue,
      filter_label: label,
    });

    this.containerTarget.insertAdjacentHTML("beforeend", templateContent);

    this._initializeFilterPanel(name, data);
  }

  _initializeFilterPanel(name, data) {
    const selectedFilter = this._selectedFilter(name);
    const updatePanelContent = this.updatePanelContentTargets.find((target) => selectedFilter.contains(target));
    const selectedFilterTemplate = this.filterTemplateTargets.find((panel) => panel.dataset.filterName === name);
    updatePanelContent.innerHTML = interpolate(selectedFilterTemplate, { action: "update" });

    this._setFilterPanelValues(data);
  }

  _setFilterPanelValues(data) {
    data.forEach(({ id, value }) => {
      const input = document.getElementById(id);
      if (["checkbox", "radio"].includes(input.type)) {
        input.checked = true;
      } else {
        input.value = value;
      }
    });
  }

  filterUpdated(event) {
    const { name, translatedValue } = event.detail;
    const activeSelectedFilter = this._selectedFilter(name);

    if (!translatedValue) {
      this._removeSelectedFilter(activeSelectedFilter, name);
      return;
    }

    const translatedFilterValueElement = activeSelectedFilter.querySelector(".translated-filter-value");

    translatedFilterValueElement.innerText = translatedValue;
    translatedFilterValueElement.setAttribute("title", translatedValue);

    this._closeUpdatePanel();
  }

  filterCleared(event) {
    const datatableId = this._datatableId();
    const { filter } = event.params;

    const activeFilter = this._selectedFilter(filter);
    this._removeSelectedFilter(activeFilter, filter);
    dataTableFunctions().reloadTable(datatableId);
  }

  openUpdatePanel(event) {
    const { filterName } = event.currentTarget.dataset;
    this._closeUpdatePanel();

    const selectedFilter = this._selectedFilter(filterName);
    selectedFilter.classList.add("active");

    const updatePanel = this.updatePanelTargets.find((target) => selectedFilter.contains(target));
    updatePanel.classList.add("visible");
  }

  _closeUpdatePanel() {
    const activeFilter = this.filterTargets.find((target) => target.classList.contains("active"));
    if (activeFilter) {
      activeFilter.classList.remove("active");
      const updatePanel = this.updatePanelTargets.find((target) => activeFilter.contains(target));
      updatePanel.classList.remove("visible");
    }
  }

  _selectedFilter(name) {
    return this.filterTargets.find((filter) => filter.dataset.filterName === name);
  }

  _datatableId() {
    return this.element.dataset.datatableId;
  }

  _removeSelectedFilter(selectedFilterHtml, filterName) {
    selectedFilterHtml.remove();
    document.dispatchEvent(
      new CustomEvent("datatable:filterCleared", { detail: { id: this._datatableId(), name: filterName } })
    );
  }
}
