import { Controller } from "@hotwired/stimulus";
import { amountFormatted } from "../../utils/number_utils";
import {
  formattedInstalment,
  formattedQuote,
  handlePartialInvoiceGenerationFromQuoteResponse,
} from "../../sales_invoices/utils";

export default class extends Controller {
  static targets = [
    "quoteId",
    "quoteNetTotal",
    "quoteVatTotal",
    "quoteGrossTotal",
    "quoteSettlementAmount",
    "instalmentReference",
    "instalmentGrossTotal",
  ];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      const details = event.relatedTarget.dataset.details;
      this.quote = formattedQuote(details);
      this.instalment = formattedInstalment(details);
      this._initModal(event);
    });
  }

  _initModal() {
    this._setQuoteDetails();
    this._setInstalmentDetails();
  }

  onFormSubmit(event) {
    const payload = event.detail[0];
    const modalId = this.element.id;
    handlePartialInvoiceGenerationFromQuoteResponse(payload, modalId);
  }

  _setQuoteDetails() {
    this.quoteIdTarget.value = this.quote.id;
    this.quoteGrossTotalTarget.innerText = amountFormatted(this.quote.grossTotal, this.quote.currencySymbol);
    this.quoteVatTotalTarget.innerText = amountFormatted(this.quote.vatTotal, this.quote.currencySymbol);
    this.quoteNetTotalTarget.innerText = amountFormatted(this.quote.netTotal, this.quote.currencySymbol);
    this.quoteSettlementAmountTarget.innerText = amountFormatted(
      this.quote.settlementAmount,
      this.quote.currencySymbol
    );
  }

  _setInstalmentDetails() {
    this.instalmentReferenceTarget.innerText = this.instalment.reference;
    this.instalmentGrossTotalTarget.innerText = amountFormatted(this.instalment.grossTotal, this.quote.currencySymbol);
  }
}
