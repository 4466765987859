import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    if (!this.inputTarget.value) {
      this.element.classList.remove("hidden");
    }
  }
}
