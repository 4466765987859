import { Controller } from "@hotwired/stimulus";
import { isAjaxErrorEvent, redirectTo } from "../utils/misc";
import { flashAlert, notifyError } from "../components/flash_alert";

/**
 * This controller is meant to handle typical actions associated to ajax requests in remote forms.
 * Any number of actions may be associated to a given form.
 */
export default class extends Controller {
  redirect() {
    const redirectUrl = this.element.dataset.redirectUrl;
    if (redirectUrl) {
      redirectTo(redirectUrl);
    }
  }

  dispatchCustomEvent() {
    const { eventName } = event.params;
    document.dispatchEvent(new CustomEvent(eventName));
  }

  displayServerSideFlash(event) {
    const payload = event.detail[0];
    const { message } = payload;

    this._displayFlash(event, message);
  }

  displayClientSideFlash(event) {
    const { message } = event.params;

    this._displayFlash(event, message);
  }

  _displayFlash(event, message) {
    if (isAjaxErrorEvent(event)) {
      notifyError(message);
    } else {
      flashAlert(message);
    }
  }
}
