import { Controller } from "@hotwired/stimulus";

const DISCUSSION_SELECTED_CLASS = "selected";

export default class extends Controller {
  static targets = ["discussionCard"];

  onDiscussionCreated() {
    this._unselectDiscussion();
  }

  discussionSelected(e) {
    this._unselectDiscussion();
    e.currentTarget.classList.add(DISCUSSION_SELECTED_CLASS);
  }

  _unselectDiscussion() {
    this.discussionCardTargets.forEach((card) => card.classList.remove(DISCUSSION_SELECTED_CLASS));
  }
}
