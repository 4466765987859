import { isInputFilled, isValidEmail } from "../utils/misc";

const inputIsValid = (input) => {
  const validations = input.dataset && input.dataset.validation?.split(" ");
  if (validations === undefined) return true;
  if (validations.includes("max") && maxValueNotValid(input)) return false;
  if (validations.includes("min") && minValueNotValid(input)) return false;
  if (validations.includes("shares-validation") || validations.includes("number-of-shares-validation")) {
    if (!sharesInputsValid(input)) return false;
  }
  if (validations.includes("not-empty") && input.value.replace(/\s/g, "") === "") return false;
  if (validations.includes("is-email") && !isValidEmail(input.value)) return false;
  if (validations.includes("first-day-of-month") && input.value.split("-")[2] !== "01") return false;

  const digitValidation = validations.filter((validation) => validation.includes("digits"))[0];
  if (digitValidation) {
    const regex = new RegExp("^(\\d{" + digitValidation.split("_")[0] + "})$");
    return regex.test(input.value);
  }

  return true;
};

const allInputsWithValidation = (form) => {
  return form.querySelectorAll(".input-with-validation");
};

/* TODO(Jonathan Jalal): Refactor this function (remove "must-be-filled" occurrences to use "not-empty" instead) */
const inputMustBeFilled = (input) => {
  if (input.dataset && !input.dataset.validation?.split(" ").includes("must-be-filled")) return false;

  const inputIsDisplayed = input.closest(".toggle-div");
  if (inputIsDisplayed && !inputIsDisplayed.classList.contains("show-div")) return false;

  return input.value === "";
};

const sharesInputsValid = (input) => {
  const inputForm = input.closest("form");
  const inputsToControl = Array.from(
    inputForm.querySelectorAll(
      "[data-validation='shares-validation not-empty'], [data-validation='number-of-shares-validation not-empty']"
    )
  );

  if (
    !inputsToControl.every(isInputFilled) ||
    (document.getElementById("shareholders-completeness") &&
      document.getElementById("shareholders-completeness").value !== "true")
  )
    return true;

  const sharesValuesArray = createArrayOfHashes(inputsToControl);
  // [input1Hash, input2Hash,...,inputNHash] where inputNHash looks like {sharePercentage: XX, numberOfShares: YY}
  const sharePercentagePerShareholder = sharesValuesArray.map((hash) => hash["sharePercentage"]);
  const sharesAmountPerShareholder = sharesValuesArray.map((hash) => hash["numberOfShares"]);
  const companyNumberOfShares = Number(document.getElementById("legal-data-number-of-shares")?.value);
  const sharesInputsControl =
    totalSharePercentageEqual100(sharePercentagePerShareholder) &&
    totalShareholderSharesAmountEqualsCompanyShares(sharesAmountPerShareholder, companyNumberOfShares) &&
    numberOfSharesMatchWithPercentage(sharesValuesArray, companyNumberOfShares);

  if (sharesInputsControl) {
    inputsToControl.forEach((input) => input.classList.remove("not-valid"));
  }

  return sharesInputsControl;
};

const createArrayOfHashes = (inputsToControl) => {
  return inputsToControl
    .filter((input) => input.getAttribute("data-validation") === "shares-validation not-empty")
    .map((input) => {
      const hash = {};
      hash["sharePercentage"] = Number(input.value);
      if (input.dataset.associatedNumberOfShares) {
        hash["numberOfShares"] = Number(document.getElementById(`${input.dataset.associatedNumberOfShares}`).value);
      }
      return hash;
    });
};

const totalSharePercentageEqual100 = (sharePercentagePerShareholder) => {
  return sumOfValuesEqualSpecificValue(sharePercentagePerShareholder, 100);
};

const totalShareholderSharesAmountEqualsCompanyShares = (sharesAmountPerShareholder, companyNumberOfShares) => {
  if (sharesAmountPerShareholder[0] === undefined) return true;

  return sumOfValuesEqualSpecificValue(sharesAmountPerShareholder, companyNumberOfShares);
};

const numberOfSharesMatchWithPercentage = (sharesValuesArray, companyNumberOfShares) => {
  if (sharesValuesArray[0]["numberOfShares"] === undefined) return true;

  return sharesValuesArray.every(
    (sharesValue) => (sharesValue["sharePercentage"] / 100) * companyNumberOfShares === sharesValue["numberOfShares"]
  );
};

const sumOfValuesEqualSpecificValue = (array, specificValue) => {
  if (array.length === 0) return true;

  return array.reduce((a, b) => a + b) === specificValue;
};

const creationSharesValid = (input) => {
  const inputForm = input.closest("form");
  const inputsToControl = Array.from(inputForm.querySelectorAll("[data-parsley-creation-shares-validation='true']"));

  if (
    !inputsToControl.every(isInputFilled) ||
    (document.getElementById("shareholders-completeness") &&
      document.getElementById("shareholders-completeness").value !== "true")
  )
    return true;

  const capitalAmount = Number(document.getElementById("capital_amount").value);
  const arrayOfSharesAmount = inputsToControl.map((input) => Number(input.value));
  return sumOfValuesEqualSpecificValue(arrayOfSharesAmount, capitalAmount);
};

const maxValueNotValid = (input) => {
  const maxValue = Number(input.dataset.maxValue);
  return maxValue <= Number(input.value);
};

const minValueNotValid = (input) => {
  const minValue = Number(input.dataset.minValue);
  return minValue > Number(input.value);
};

export { inputIsValid, inputMustBeFilled, allInputsWithValidation, creationSharesValid };
