import { Controller } from "@hotwired/stimulus";
import { initCompareInvoiceModal } from "../../purchase_invoice/init_compare_invoice_modal";

export default class extends Controller {
  static targets = ["purchaseInvoice", "resolveDuplicateBtn"];

  fillModal(event) {
    initCompareInvoiceModal(event.currentTarget);
  }

  onPurchaseSelectionChange() {
    const selectedPurchases = this.purchaseInvoiceTargets.filter((target) => target.checked);
    if (selectedPurchases.length > 0) {
      this.resolveDuplicateBtnTarget.removeAttribute("disabled");
    } else {
      this.resolveDuplicateBtnTarget.setAttribute("disabled", true);
    }
  }
}
