import { Controller } from "@hotwired/stimulus";
import { roundToTwoDecimals } from "../../../utils/number_utils";

export default class extends Controller {
  static targets = ["lineNetTotal", "lineVatRate", "lineGrossTotal", "destroyInput", "totalsContainer"];

  computeLineTotal() {
    const lineGrossTotalValue = roundToTwoDecimals(
      +this.lineNetTotalTarget.value * (1 + parseFloat(+this.lineVatRateTarget.value))
    );
    this.lineGrossTotalTarget.value = lineGrossTotalValue;

    document.dispatchEvent(new CustomEvent("salesInvoice:lineUpdated"));
  }

  removeLine() {
    this.destroyInputTarget.value = "true";
    this.totalsContainerTarget.remove();
    document.dispatchEvent(new CustomEvent("salesInvoice:lineUpdated"));
  }
}
