import { Controller } from "@hotwired/stimulus";
import Inputmask from "inputmask";

const config = {
  alias: "decimal",
  groupSeparator: " ",
  autoGroup: true,
  radixPoint: ",",
  autoUnmask: true,
  groupSize: 3,
  digits: 2,
  removeMaskOnSubmit: true,
  placeholder: "",
  unmaskAsNumber: true,
  onBeforeMask: (value) => {
    if ([null, undefined].includes(value)) {
      // Fixes this error in the inputmask library for null values : https://github.com/RobinHerbots/Inputmask/issues/1485#issuecomment-805743647
      // See the discussion in this PR for more context : https://github.com/acasi-io/acasi/pull/3059
      return " ";
    }
    return value.toString().replace(".", ",");
  },
};

export default class extends Controller {
  static targets = [];

  connect() {
    Inputmask(config).mask(this.element);
  }
}
