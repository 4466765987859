import { daysAgo } from "../utils/date_utils";
import Mustache from "mustache";

const SUBSCRIPTION_LOCAL_STORAGE_KEY = "subscription_choices";
const CHOICES_EXPIRY_NB_OF_DAYS = 30;
const STEPS = {
  PAYMENT_STEP: "payment_step",
  PRICING_STEP: "pricing_step",
  SERVICE_STEP: "service_step",
};

export default class SubscriptionWorkflow {
  constructor(urlsTemplateMapping = {}) {
    this.urlsTemplateMapping = urlsTemplateMapping;
    this.companyId = window.globals.currentCompanyId;
  }

  get storedChoices() {
    const storedItem = localStorage.getItem(SUBSCRIPTION_LOCAL_STORAGE_KEY);
    if (!storedItem) {
      return {};
    }

    return JSON.parse(storedItem);
  }

  get companyChoices() {
    const choices = this.storedChoices[this.companyId];
    if (!choices) {
      return {};
    }

    return choices;
  }

  redirectionUrl() {
    if (this._choicesExpired()) {
      this._resetExistingChoices();
    }

    let urlTemplate,
      selectedId = "";

    if (this.companyChoices.current_step === STEPS.PAYMENT_STEP) {
      urlTemplate = this.urlsTemplateMapping.payment_url;
      selectedId = this.companyChoices.selected_pricing_id;
    } else if (this.companyChoices.current_step === STEPS.PRICING_STEP) {
      urlTemplate = this.urlsTemplateMapping.pricing_selection_url;
      selectedId = this.companyChoices.selected_service_id;
    } else {
      urlTemplate = this.urlsTemplateMapping.service_selection_url;
      selectedId = this.companyChoices.selected_service_id;
    }

    return Mustache.render(urlTemplate, { id: selectedId });
  }

  storeSelectedService(serviceId) {
    const dataToStore = {
      ...this.companyChoices,
      selected_service_id: serviceId,
      creation_date: new Date(),
      current_step: STEPS.SERVICE_STEP,
    };
    this._storeItem(dataToStore);
  }

  storeServiceSelectionSubmission() {
    const dataToStore = { ...this.companyChoices, current_step: STEPS.PRICING_STEP };
    this._storeItem(dataToStore);
  }

  storeSelectedPricing(pricingId) {
    const dataToStore = {
      ...this.companyChoices,
      selected_pricing_id: pricingId,
      creation_date: new Date(),
      current_step: STEPS.PRICING_STEP,
    };
    this._storeItem(dataToStore);
  }

  storePricingSelectionSubmission() {
    const dataToStore = { ...this.companyChoices, current_step: STEPS.PAYMENT_STEP };
    this._storeItem(dataToStore);
  }

  _storeItem(item) {
    const dataToStore = { ...this.storedChoices, [this.companyId]: item };
    localStorage.setItem(SUBSCRIPTION_LOCAL_STORAGE_KEY, JSON.stringify(dataToStore));
  }

  _resetExistingChoices() {
    const remainingChoices = this.storedChoices;
    delete remainingChoices[this.companyId];
    localStorage.setItem(SUBSCRIPTION_LOCAL_STORAGE_KEY, JSON.stringify(remainingChoices));
  }

  _choicesExpired() {
    if (!this.companyChoices.creation_date) {
      return false;
    }

    return new Date(this.companyChoices.creation_date) < daysAgo(CHOICES_EXPIRY_NB_OF_DAYS);
  }
}
