import { Controller } from "@hotwired/stimulus";
import { fetchStream } from "../utils/turbo_utils";

export default class extends Controller {
  static targets = ["loader"];

  refreshAwaitingUserActionStep() {
    this.loaderTarget.classList.remove("hidden");
    fetchStream(this.element.dataset.balanceSheetWorkflowUrl);
  }
}
