import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "note"];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this.formTarget.action = event.relatedTarget.dataset.url;
      this.noteTarget.value = event.relatedTarget.dataset.note;
    });
  }
}
