import { Controller } from "@hotwired/stimulus";
import CustomNumbering from "../../components/sales_invoices/custom_numbering";
import Inputmask from "inputmask";
import { toggleClass } from "../../utils/misc";
import { compiledTemplate } from "../../utils/string_utils";
import { i18nTranslations } from "../../utils/i18n_utils";

export default class extends Controller {
  static targets = [
    "customNumberingWrapper",
    "standardNumberingInformation",
    "customPrefixInput",
    "customNumberingErrors",
    "numberingExample",
    "firstInvoiceNumber",
    "resetOnPeriodChange",
    "resetOnPeriodChangeWrapper",
    "examplesWrapper",
    "numberingTypeInput",
    "resetNumberingLabel",
  ];

  connect() {
    Inputmask({ mask: "99999" }).mask(this.firstInvoiceNumberTarget);
    this._displayErrors(this._errors());
    this.displayExample();
    this.toggleResetOnPeriodChangeInput();

    this._setNumberingWithoutYearPrefixEvent();
  }

  onNumberingTypeChange(e) {
    if (e.currentTarget.value === "custom") {
      this.customNumberingWrapperTarget.classList.remove("hidden");
      this.standardNumberingInformationTarget.classList.add("hidden");
    } else {
      this.customNumberingWrapperTarget.classList.add("hidden");
      this.standardNumberingInformationTarget.classList.remove("hidden");
    }
  }

  validateNumberingSubmission(e) {
    this.customNumberingErrorsTarget.innerHTML = "";

    const errors = this._errors();
    if (errors.length !== 0) {
      e.preventDefault();
      this._displayErrors(errors);
    } else if (this._customNumber().isWithoutYearPrefix()) {
      e.preventDefault();
      document.dispatchEvent(
        new CustomEvent("salesInvoicesNumbering:noYearPrefixSelected", {
          detail: {
            prefix: this.customPrefixInputTarget.value,
            form: this.element,
            invoiceType: this.element.dataset.invoiceType,
          },
        })
      );
    }
  }

  displayExample() {
    if (this._errors().length === 0) {
      const { currentPeriod, currentPeriodNumberings, nextPeriod, nextPeriodNumberings, nextYear, nextYearNumberings } =
        this._customNumber().examples();
      const { invoiceType } = this.element.dataset;
      this.numberingExampleTarget.innerHTML = compiledTemplate(document.getElementById("numbering-examples-template"), {
        current_period: currentPeriod,
        current_period_numberings: currentPeriodNumberings,
        next_period: nextPeriod,
        next_period_numberings: nextPeriodNumberings,
        next_year: nextYear,
        next_year_numberings: nextYearNumberings,
        invoice_text: i18nTranslations().t("first_invoice", { scope: `invoice_numbering.${invoiceType}`, count: 1 }),
        invoices_text: i18nTranslations().t("first_invoice", { scope: `invoice_numbering.${invoiceType}`, count: 2 }),
      });
      this.examplesWrapperTarget.classList.remove("hidden");
    } else {
      this.examplesWrapperTarget.classList.add("hidden");
    }
  }

  toggleResetOnPeriodChangeInput() {
    const prefixContainsPeriod = this._customNumber().containsPeriod();
    if (prefixContainsPeriod) {
      const period = this._customNumber().isWithYearAndMonthPrefix() ? "month" : "year";
      this.resetNumberingLabelTargets.forEach((target) => {
        toggleClass(target, "hidden", target.dataset.period === period);
      });
    }
    toggleClass(this.resetOnPeriodChangeWrapperTarget, "hidden", prefixContainsPeriod);
  }

  _setNumberingWithoutYearPrefixEvent() {
    const numberingForm = this.element;
    numberingForm.addEventListener("salesInvoicesNumbering:noYearPrefixConfirmed", () => {
      numberingForm.submit();
    });
  }

  _displayErrors(errors) {
    errors.forEach((error) => {
      const errorHtml = `<li>${this._translatedError(error)}</li>`;
      this.customNumberingErrorsTarget.insertAdjacentHTML("beforeend", errorHtml);
    });
  }

  _translatedError(error) {
    const customNumber = this._customNumber();
    const translations = {
      invalid_characters: `Les caractères ${customNumber
        .invalidCharacters()
        .map((character) => `"${character}"`)
        .join(", ")} ne sont pas valides`,
      month_without_year:
        "Le mois {MM} ne peut pas être utilisé seul, sans être précédé de l’année {AAAA} dans la numérotation.",
      month_before_year: "Le mois {MM} ne peut pas précéder l’année {AAAA} dans la numérotation.",
      period_format_wrong_usage:
        "Les caractères {} ne peuvent pas être utilisés (peut-être souhaitez vous utiliser {AAAA} ou {MM} ?)",
      prefix_not_present: "Le préfixe doit être présent",
    };

    return translations[error];
  }

  _errors() {
    return this._customNumber().errors();
  }

  _customNumber() {
    const customPrefix = this.customPrefixInputTarget.value;
    const firstInvoiceNumber = this.firstInvoiceNumberTarget.value;
    const resetOnPeriodChange = !!this.resetOnPeriodChangeTarget.checked;
    const type = this.numberingTypeInputTarget.value;
    return new CustomNumbering(customPrefix, firstInvoiceNumber, resetOnPeriodChange, type);
  }
}
