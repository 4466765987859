import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    todoId: Number,
  };

  connect() {
    const todoCard = document.querySelector(`[data-todo-id='${this.todoIdValue}']`);
    todoCard.dispatchEvent(new CustomEvent("todo:done"));
  }
}
