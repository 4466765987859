import { Controller } from "@hotwired/stimulus";
import { initDatatable } from "../../datatables/datatable_factory";
import { numberToCurrency } from "../../utils/number_utils";
import { interpolate } from "../../utils/string_utils";
import { dataTableFunctions, isDatatableUninitialized } from "../../datatables/datatable_functions";

export default class extends Controller {
  static targets = ["datatableElement", "actionColumnTemplate"];

  initialize() {
    if (isDatatableUninitialized(this.datatableElementTarget)) {
      this._initDatatable();

      document.addEventListener("datatable:reload", () => {
        dataTableFunctions().reloadTable(this.dataTable[0].id);
      });
    }
  }

  _initDatatable() {
    const payload = {
      datatableElement: this.datatableElementTarget,
      orderColumns: [[0, "desc"]],
      columns: this._datatableColumns(),
      onCreatedRow: this._onCreatedRow(),
    };
    this.dataTable = initDatatable(payload);
  }

  _datatableColumns() {
    return [
      { data: "period", orderable: true, className: "period-column" },
      { data: "amount", orderable: true, className: "amount-column", render: (data) => numberToCurrency(data) },
      {
        data: "shareholder",
        orderable: false,
        className: "shareholder-column",
        visible: this.element.dataset.multipleShareholdersCompany === "true",
        createdCell: function (cell, _cellData, rowData) {
          if (!rowData.shareholder_id) {
            cell.innerText = "À préciser";
            cell.classList.add("color_alt-red");
          }
        },
      },
      {
        data: "actions",
        orderable: false,
        className: "actions-column",
        render: (data, type, row) =>
          interpolate(this.actionColumnTemplateTarget, {
            id: row.id,
            mileage_allowance_accounted: row.accounted === "true",
          }),
      },
    ];
  }

  _onCreatedRow() {
    return (row, data) => {
      row.setAttribute("data-toggle", "modal");
      row.setAttribute("data-target", "#mileage-allowance-form-modal");
      row.setAttribute("data-source-url", data.form_url);
    };
  }
}
