import $ from "jquery";
import { formattedDate, jsFormattedDate, oneYearAfter } from "../utils/date_utils";

export const computeFiscalYearEndPeriodOptions = (selectedMonth) => {
  const currentYear = new Date().getFullYear();
  const endOfMonth = new Date(currentYear, selectedMonth, 0);
  const oneYearAfterEndOfMonth = oneYearAfter(endOfMonth);

  return endOfMonth >= new Date()
    ? [
        [recommendedDateLabel(formattedDate(oneYearAfterEndOfMonth)), jsFormattedDate(oneYearAfterEndOfMonth)],
        [formattedDate(endOfMonth), jsFormattedDate(endOfMonth)],
      ]
    : [[formattedDate(oneYearAfterEndOfMonth), jsFormattedDate(oneYearAfterEndOfMonth)]];
};

const recommendedDateLabel = (optionLabel) => `${optionLabel} (recommandée)`;

export const updateFiscalYearEndOptions = (periodTarget, dateTarget) => {
  const selectedMonth = parseInt(periodTarget.value);
  const persistedEndPeriod = dateTarget.dataset.defaultValue;

  $(dateTarget).empty().trigger("change");

  computeFiscalYearEndPeriodOptions(selectedMonth).forEach((option) => {
    const [displayedDate, value] = option;
    $(dateTarget).append(new Option(displayedDate, value, false, value === persistedEndPeriod));
  });
};
