import { Controller } from "@hotwired/stimulus";
import { initLessThanOrEqualToValidator } from "../../utils/parsley_validators_utils";
import { initDropzone, initDropzoneBasicEvents } from "../../components/dropzone";

const ACCEPTED_FORMATS = ".pdf";

export default class extends Controller {
  static targets = ["filePreviewsContainer", "uploadImgLabel", "dragAndDropContainer", "submitBtn"];

  initialize() {
    initLessThanOrEqualToValidator();
    this.submitBtnTarget.disabled = true;
    this._initDropzone();
  }

  validateSubmission(e) {
    e.preventDefault();
    if (this._isValidForm()) {
      this.dropzone.processQueue();
      this.submitBtnTarget.disabled = true;
    }
  }

  _isValidForm() {
    return this.dropzone.files.length === this.dropzone.options.maxFiles;
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.element.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
      acceptedFiles: ACCEPTED_FORMATS,
    };
  }

  _initCustomEvents() {
    this.dropzone.on("success", () => {
      window.location.reload();
    });

    this.dropzone.on("addedfile", () => {
      this._setSubmitBtnAvailability();
    });

    this.dropzone.on("removedfile", () => {
      this._setSubmitBtnAvailability();
    });
  }

  _setSubmitBtnAvailability() {
    this.submitBtnTarget.disabled = this.dropzone.files.length === 0;
  }
}
