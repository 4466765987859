import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../../utils/string_utils";
import { flashAlert } from "../../../components/flash_alert";
import { initDropzone, initDropzoneBasicEvents } from "../../../components/dropzone";
import { formattedDate } from "../../../utils/date_utils";
import { handleHttpResponse } from "../../../utils/http_utils";
import { toggleClass } from "../../../utils/misc";

const ACCEPTED_FORMATS = ".pdf";

export default class extends Controller {
  static targets = [
    "noDocumentsMessage",
    "documentsTable",
    "documentsList",
    "documentLine",
    "documentLineTemplate",
    "uploadForm",
    "filePreviewsContainer",
    "uploadImgLabel",
    "submitBtn",
    "dragAndDropContainer",
    "removeAllFilesBtn",
  ];

  connect() {
    const companyId = this.element.dataset.companyId;
    App.cable.subscriptions.create(
      { channel: "AccountingDocumentUpdateChannel", company_id: companyId },
      {
        received: (accountingDocument) => {
          const lineElement = this._lineElementFor(accountingDocument);
          if (lineElement) {
            lineElement.outerHTML = this._documentLineTemplateFor(accountingDocument);
          } else {
            this.documentsListTarget.insertAdjacentHTML(
              "afterbegin",
              this._documentLineTemplateFor(accountingDocument)
            );
          }
        },
      }
    );

    this._initDropzone();
  }

  validateSubmission(e) {
    e.preventDefault();
    this.dropzone.processQueue();
  }

  invoiceDeleted(e) {
    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: (response) => {
        const message = JSON.parse(response).flash_message;
        flashAlert(message);

        const line = e.target.closest("[data-sales-invoices--le-cab-pdf--create-target='documentLine']");
        line.classList.add("removing");
        line.addEventListener("animationend", () => {
          line.remove();
        });
      },
    });
  }

  _documentLineTemplateFor(accountingDocument) {
    return interpolate(this.documentLineTemplateTarget, {
      processing_status: accountingDocument.ocr_processing_status,
      date: formattedDate(accountingDocument.created_at),
      reference: accountingDocument.reference || "-",
      id: accountingDocument.id,
      view_file_url: accountingDocument.file_url,
      download_file_url: accountingDocument.download_url,
    });
  }

  _lineElementFor(accountingDocument) {
    return this.documentLineTargets.find((line) => line.dataset.id === `${accountingDocument.id}`);
  }

  _displaySuccessFlashMessage(createdDocuments) {
    const message = createdDocuments.length > 1 ? "fichiers .pdf importés" : "fichier .pdf importé";
    flashAlert(`${createdDocuments.length} ${message}`);
  }

  _insertNewDocumentsInTable(createdDocuments) {
    createdDocuments.forEach((accountingDocument) => {
      const lineElement = this._lineElementFor(accountingDocument);
      if (!lineElement) {
        this.documentsListTarget.insertAdjacentHTML("afterbegin", this._documentLineTemplateFor(accountingDocument));
      }
    });
  }

  _unhideTableOnFirstInsert(createdDocuments) {
    const existingDocumentsCount = this.documentLineTargets.length;
    const isFirstDocumentInserted = existingDocumentsCount === createdDocuments.length;
    if (isFirstDocumentInserted) {
      this.noDocumentsMessageTarget.classList.add("hidden");
      this.documentsTableTarget.classList.remove("hidden");
    }
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.uploadFormTarget.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
      paramName: "files",
      acceptedFiles: ACCEPTED_FORMATS,
      maxFiles: 10,
    };
  }

  removeAllFiles() {
    this.dropzone.removeAllFiles(true);
  }

  _initCustomEvents() {
    this.dropzone.on("success", (file, createdDocuments) => {
      this._insertNewDocumentsInTable(createdDocuments);
      this._unhideTableOnFirstInsert(createdDocuments);
      this._displaySuccessFlashMessage(createdDocuments);
      this.dropzone.removeAllFiles();
    });

    this.dropzone.on("addedfiles", () => {
      this._toggleUploadElements();
    });

    this.dropzone.on("removedfile", () => {
      this._toggleUploadElements();
    });
  }

  _toggleUploadElements() {
    const acceptedFiles = this.dropzone.files.length;
    const hasAcceptedFile = acceptedFiles > 0;
    const hasManyAcceptedFiles = acceptedFiles > 1;
    toggleClass(this.submitBtnTarget, "hidden", hasAcceptedFile);
    toggleClass(this.removeAllFilesBtnTarget, "hidden", hasManyAcceptedFiles);
  }
}
