import { Controller } from "@hotwired/stimulus";
import { swal } from "../../components/init_sweet_alert";
import { interpolate } from "../../utils/string_utils";
import { isStringFalse, isStringTrue, isSwalConfirmed } from "../../utils/misc";
import { errorMessages, notifyError } from "../../components/flash_alert";
import { handleHttpResponse } from "../../utils/http_utils";
import { focusField } from "../../utils/inputs_utils";

export default class extends Controller {
  static targets = [
    "title",
    "titleTemplate",
    "operationIds",
    "searchInput",
    "category",
    "categoryForm",
    "categoryInput",
  ];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this._fillModalTitle(event.relatedTarget);
      this._initCategories(event.relatedTarget);
      this._restrictCategoriesInvalidForBtCreditNote();
    });

    window.$(this.element).on("shown.bs.modal", () => {
      focusField(this.searchInputTarget);
    });
  }

  _fillModalTitle(eventTrigger) {
    const { opIds, amount, date, supplier } = eventTrigger.dataset;

    let options = { date: date, amount: amount, description: supplier };
    const numberOfOperations = opIds.split(",").length;
    if (numberOfOperations > 1) {
      options = { date: "", amount: "", description: `${numberOfOperations} opérations sélectionnées` };
    } else if (!date && !amount && !supplier) {
      options = { date: "", amount: "", description: "1 opération sélectionnée" };
    }

    this.titleTarget.innerHTML = interpolate(this.titleTemplateTarget, options);
  }

  _initCategories(eventTrigger) {
    const { opIds, catId, supraName } = eventTrigger.dataset;
    this.operationIdsTarget.value = opIds;
    this.dispatch("init_categories", {
      detail: {
        numberOfSelectedItems: opIds.split(",").length,
        catId: catId,
        supraName: supraName,
      },
    });
  }

  _restrictCategoriesInvalidForBtCreditNote() {
    const categoriesToRestrict = this.categoryTargets.filter((target) =>
      isStringFalse(target.dataset.validForBtCreditNote)
    );
    if (isStringTrue(this.creditNoteValue)) {
      categoriesToRestrict.forEach((category) => category.classList.add("disabled"));
    } else {
      categoriesToRestrict.forEach((category) => category.classList.remove("disabled"));
    }
  }

  updateSelectedCategory(event) {
    this.categoryInputTarget.value = event.currentTarget.dataset.catId;

    if (this.operationIdsTarget.value.split(",").length > 1) {
      swal({
        title: "Voulez-vous vraiment changer la catégorie pour les opérations sélectionnées ?",
        buttons: { non: true, oui: true },
        className: "custom-swal-modal",
      }).then((value) => {
        if (isSwalConfirmed(value)) {
          this._onCategorySelected();
        }
      });
    } else {
      this._onCategorySelected();
    }
  }

  _onCategorySelected() {
    this.searchInputTarget.value = "";
    Rails.fire(this.categoryFormTarget, "submit");
  }

  categoryUpdating() {
    this.element.classList.add("processing");
  }

  categoryUpdated(e) {
    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: () => {},
      onError: (response, status) => {
        notifyError(errorMessages(status));
        window.$(`#${this.element.id}`).modal("hide");
      },
      onComplete: () => {
        this.element.classList.remove("processing");
      },
    });
  }
}
