import { initDatatable } from "./datatable_factory";
import { dataTableFunctions } from "./datatable_functions";
import { interpolate } from "../utils/string_utils";
import { numberToCurrency } from "../utils/number_utils";
import { inputValueById, inputValueBySelector } from "../utils/inputs_utils";

const CUSTOMERS_DATATABLE_ID = "clients_datatable";

const initCustomersDatatable = () => {
  const datatableElement = document.getElementById(CUSTOMERS_DATATABLE_ID);
  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[2, "asc"]],
    columns: datatableColumns(),
    setRequestData: setRequestData(),
    onComplete: onComplete(),
    onDraw: onDraw(),
    exportable: true,
  };
  initDatatable(payload);
};

const columnNames = {
  input: "input",
  id: "id",
  name: "name",
  address: "address",
  email: "email",
  phone: "phone",
  status: "status",
};

const setRequestData = () => {
  return (data) => {
    const columnIndexes = dataTableFunctions().columnIndexes(data);

    dataTableFunctions().setColumnFilter(
      data,
      columnIndexes[columnNames.email],
      inputValueBySelector("[name='email-filter']:checked")
    );
    dataTableFunctions().setColumnFilter(
      data,
      columnIndexes[columnNames.phone],
      inputValueBySelector("[name='phone-filter']:checked")
    );
    dataTableFunctions().setColumnFilter(
      data,
      columnIndexes[columnNames.address],
      inputValueBySelector("[name='address-filter']:checked")
    );

    data.customer_search = document.getElementById("customer-search").value;
    data.status = inputValueById("status-filter");
  };
};

const datatableColumns = () => {
  return [
    {
      data: columnNames.input,
      orderable: false,
      className: "select-column checkbox-element",
      render: (data, type, row) => {
        return interpolate(document.getElementById("select-item-checkbox-template"), {
          id: row.id,
        });
      },
    },
    { data: columnNames.id, name: columnNames.id, orderable: false, className: "ids-column", visible: false },
    {
      data: columnNames.name,
      orderable: true,
      searchable: true,
      className: "name-column",
      render: (data, type, row) => {
        return interpolate(document.getElementById("table-url-template"), {
          text: data,
          url: row.url,
        });
      },
    },
    { data: columnNames.address, orderable: false, searchable: true, className: "address-column" },
    { data: columnNames.email, orderable: false, searchable: true, className: "email-column" },
    { data: columnNames.phone, orderable: false, searchable: true, className: "phone-column" },
    {
      data: columnNames.status,
      orderable: false,
      className: "status-column",
      render: (data) => {
        return interpolate(document.getElementById("customer-cash-status-badge-template"), {
          badge_class: data.badge_class,
          amount: numberToCurrency(data.amount),
        });
      },
    },
  ];
};

const onComplete = () => {
  return () => {
    const search = document.getElementById("customer-search");
    dataTableFunctions().manageSearchBar(search, CUSTOMERS_DATATABLE_ID);
  };
};

const onDraw = () => {
  return () => {
    dataTableFunctions().manageFilteredSelection();
  };
};

export { initCustomersDatatable };
