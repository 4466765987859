const customerEditValidation = () => {
  // Validation on customer show edit form
  const showCustomer = document.querySelector(".show-customer");
  if (showCustomer) {
    const inputIdsToValidate = [
      "customer_name",
      "customer_address_line_1",
      "customer_zip_code",
      "customer_city",
      "customer_continent_child",
    ];

    const updateBtnShow = document.querySelector(".update-customer-input-button");
    checkValidation(updateBtnShow, inputIdsToValidate);
  }
};

const isValid = (currentValue) => {
  const element = document.getElementById(currentValue).value;
  return element.replace(/\s/g, "").length !== 0;
  // return element != '';
};

const checkValidation = (btn, idsToValidate) => {
  btn.addEventListener("click", (e) => {
    if (idsToValidate.every(isValid) === false) {
      e.preventDefault();

      idsToValidate.forEach((id) => {
        if (isValid(id) === false) {
          const input = document.getElementById(id);
          input.classList.add("invalid");

          // check if input is associated to select2
          const inputSelect2 = input.nextElementSibling;
          if (inputSelect2 && inputSelect2.classList.contains("select2-container")) {
            inputSelect2.querySelector(".select2-selection").classList.add("invalid");
          }
        } else {
          const input = document.getElementById(id);
          input.classList.remove("invalid");

          // check if input is associated to select2
          const inputSelect2 = input.nextElementSibling;
          if (inputSelect2 && inputSelect2.classList.contains("select2-container")) {
            inputSelect2.querySelector(".select2-selection").classList.remove("invalid");
          }
        }
      });
    }
  });
};

export { customerEditValidation };
