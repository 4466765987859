import { Controller } from "@hotwired/stimulus";
import { errorMessages, notifyError } from "../../components/flash_alert";
import { swal } from "../../components/init_sweet_alert";
import { isInputFilled, isSwalConfirmed, isValidEmail, toggleClass } from "../../utils/misc";

export default class extends Controller {
  static targets = ["spinner", "modalContainer", "emailModal", "form", "email", "emailErrorMessage"];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      const url = event.relatedTarget.dataset.url;
      this._fillModal(url);
    });
  }

  onEmailBlur() {
    if (!isInputFilled(this.emailTarget)) {
      return;
    }
    this._toggleErrorMessage(this._isEmailInputValid());
  }

  handleEmailSubmit() {
    const isEmailValid = this._isEmailInputValid();
    this._toggleErrorMessage(isEmailValid);
    const controller = this;
    if (isEmailValid) {
      swal({
        title: "Êtes-vous sûr de vouloir envoyer cet e-mail ?",
        buttons: { non: true, oui: true },
        className: "custom-swal-modal",
      }).then((value) => {
        if (isSwalConfirmed(value)) {
          if (controller.formTarget.dataset.remote === "true") {
            Rails.fire(controller.formTarget, "submit");
          } else {
            controller.formTarget.submit();
          }
          controller.modalContainerTarget.classList.add("opaque");
          controller.spinnerTarget.classList.remove("hidden");
        }
      });
    } else {
      this.emailTarget.focus();
    }
  }

  handleEmailSendingError(e) {
    const status = e.detail[2].status;
    this._displayError(status);
  }

  _isEmailInputValid() {
    const emails = this.emailTarget.value.split(",");
    return emails.every(isValidEmail);
  }

  _toggleErrorMessage(isInputValid) {
    toggleClass(this.emailTarget, "border-red", isInputValid);
    toggleClass(this.emailErrorMessageTarget, "hidden", !isInputValid);
  }

  _fillModal(url) {
    fetch(url, {
      method: "get",
      headers: { accept: "text/html_fragment" },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          this._displayError(response.status);
        }
      })
      .then((data) => {
        this.modalContainerTarget.innerHTML = data || "";
        window.dispatchEvent(new Event("initTooltip"));
      });
  }

  _displayError(status) {
    notifyError(errorMessages(status));
    this.spinnerTarget.classList.add("hidden");
    this.modalContainerTarget.classList.remove("opaque");
    window.$(`#${this.emailModalTarget.id}`).modal("hide");
  }
}
