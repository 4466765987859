import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";
import { addNewOptionToSelect } from "../../utils/inputs_utils";
import { mostRecent } from "../../utils/date_utils";

export default class extends Controller {
  static targets = ["cardTemplate", "card", "salaryRequestDate", "form"];
  static values = { allowedDates: Array };

  connect() {
    this.nextSalaryRequestIndex = this.cardTargets.length;
  }

  addRequest() {
    const isFormValid = $(this.formTarget).parsley().validate();
    if (!isFormValid) {
      return;
    }

    this._insertNewRequestCard();
    this.nextSalaryRequestIndex += 1;
    this.formTarget.dispatchEvent(new CustomEvent("multiStepForm:inputAdded"));
  }

  _insertNewRequestCard() {
    const templateContent = interpolate(this.cardTemplateTarget, { index: this.nextSalaryRequestIndex });
    this.cardTargets.at(-1).insertAdjacentHTML("afterEnd", templateContent);
    this._updateNewRequestDateInput(this.salaryRequestDateTargets.at(-1));
  }

  _updateNewRequestDateInput(dateInput) {
    const lastRequestDate = this._retrieveLastRequestDate();
    const allowedDates = this.allowedDatesValue.filter((dateOption) => new Date(dateOption.value) > lastRequestDate);
    allowedDates.forEach((dateOption) => addNewOptionToSelect(dateInput, dateOption));
  }

  _retrieveLastRequestDate() {
    const requestedDates = this.salaryRequestDateTargets
      .filter((dateInput) => dateInput.value)
      .map((dateInput) => new Date(dateInput.value));
    return mostRecent(requestedDates);
  }
}
