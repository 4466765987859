import { Controller } from "@hotwired/stimulus";
import { notifyUpdate } from "../../components/flash_alert";

export default class extends Controller {
  onSubmitEnd() {
    window.$(this.element).modal("hide");
    notifyUpdate();
    document.dispatchEvent(new CustomEvent("creation:percentageCompletionChanged", { detail: 100 }));
  }
}
