import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";

export default class extends Controller {
  static targets = ["submitBtn", "radioInput"];

  checkFormSubmission() {
    this.submitBtnTarget.disabled = this.radioInputTargets.filter((input) => input.checked).length !== 1;
  }

  onCancelledSalesAssociated(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: () => {
        const event = new CustomEvent("cancelledSales:associatedWithCreditNote");
        document.dispatchEvent(event);
      },
    });
  }
}
