import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hideLogoBtn", "showLogoBtn", "logoWrapper", "logoForm", "logoInput"];

  get hasLogoField() {
    // NOTE(Jonathan Jalal): This field cannot be a target of this controller, because it is generated dynamically in the form defined in app/views/sales_invoices/_new_form.html.erb
    // Because this field is generated this way, we have to set its innerText instead of its value via this controller
    return document.getElementById("sale_invoice_form_has_logo");
  }

  showLogo() {
    this._toggleLogo();
    this.hasLogoField.innerText = true;
  }

  hideLogo() {
    this._toggleLogo();
    this.hasLogoField.innerText = false;
  }

  addLogo() {
    this.logoInputTarget.click();
  }

  _toggleLogo() {
    this.logoWrapperTarget.classList.toggle("hidden");
    this.showLogoBtnTarget.classList.toggle("hidden");
  }
}
