const FOREIGN_COUNTRY_ZIPCODE = 99000;
const COUNTRY_FRANCE = "FR";

export const isZipCodeValidForCountry = (country, zipCode) => {
  return (
    (country === COUNTRY_FRANCE && zipCode !== FOREIGN_COUNTRY_ZIPCODE) ||
    (country !== COUNTRY_FRANCE && zipCode === FOREIGN_COUNTRY_ZIPCODE)
  );
};

export const checkValidation = (countryWrapper, zipCodeWrapper) => {
  const countryTarget = countryWrapper.find("select");
  const zipCodeTarget = zipCodeWrapper.querySelector("input");

  const country = countryTarget.val();
  const zipCode = zipCodeTarget.value;
  if (!country) {
    return;
  }

  if (isZipCodeValidForCountry(country, +zipCode)) {
    if (zipCode.length === 5) {
      zipCodeWrapper.querySelector(".valid-input-icon").classList.remove("hidden");
    }

    zipCodeTarget.classList.remove("invalid");
    $(countryTarget.data("select2").$container).removeClass("invalid");
    $(countryWrapper.find(".valid-input-icon")).removeClass("hidden");
  } else {
    zipCodeTarget.classList.add("invalid");
    zipCodeWrapper.querySelector(".valid-input-icon").classList.add("hidden");

    $(countryTarget.data("select2").$container).addClass("invalid");
    $(countryWrapper.find(".valid-input-icon")).addClass("hidden");
  }
};
