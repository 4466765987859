import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["displayable"];

  displayableTargetConnected(element) {
    this._display(element);
  }

  _display(element) {
    if (this._displayable(element)) {
      element.classList.remove("hidden");
    }
  }

  _displayable(element) {
    const currentContext = this.element.dataset.contextValue;

    return this._displayableContexts(element).includes(currentContext);
  }

  _displayableContexts(element) {
    return element.dataset.displayableContexts.split(" ");
  }
}
