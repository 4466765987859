import { Controller } from "@hotwired/stimulus";
import { fileUploadFunctions } from "../components/file_upload_functions";

export default class extends Controller {
  static targets = ["previewContainer", "previewLoader"];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this._toggleClasses();
      const sourceUrl = event.relatedTarget.dataset.sourceUrl;
      $.get(sourceUrl, (data) => {
        const documentPreview = fileUploadFunctions().filePreview(
          `${data.attachment.url}#toolbar=0`,
          data.attachment.type
        );
        this._toggleClasses();
        this.previewContainerTarget.innerHTML = documentPreview;
      });
    });
  }

  _toggleClasses() {
    this.previewLoaderTarget.classList.toggle("hidden");
    this.previewContainerTarget.classList.toggle("hidden");
  }
}
