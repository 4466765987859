import { initDatatable } from "./datatable_factory";
import { dataTableFunctions } from "./datatable_functions";
import { interpolate } from "../utils/string_utils";
import { isStringTrue } from "../utils/misc";
import { jsFormattedDate, lastDateOfMonth } from "../utils/date_utils";

const initTripsDatatable = (datatableElement) => {
  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[1, "desc"]],
    columns: datatableColumns(datatableElement),
    setRequestData: setRequestData(datatableElement),
    onComplete: onComplete(datatableElement),
  };
  initDatatable(payload);
};

const columnNames = {
  input: "input",
  date: "date",
  shareholder: "shareholder",
  trip: "trip",
  distance: "distance",
  note: "note",
};

const datatableColumns = (datatableElement) => {
  return [
    {
      data: columnNames.input,
      orderable: false,
      className: "select-column checkbox-element",
      render: (data, type, row) => {
        return interpolate(document.getElementById("select-item-checkbox-template"), { id: row.id });
      },
    },
    { data: columnNames.date, orderable: true, className: "date-column" },
    {
      data: columnNames.shareholder,
      orderable: false,
      className: "shareholder-column",
      visible: multipleShareholders(datatableElement),
    },
    {
      data: columnNames.trip,
      orderable: false,
      className: "trip-column",
      render: (data) =>
        interpolate(document.getElementById("trip-column-template"), {
          starting_location: data.start,
          ending_location: data.end,
        }),
    },
    { data: columnNames.distance, orderable: true, class: "distance-column", render: (data) => `${data} KM` },
    { data: columnNames.note, orderable: false, class: "note-column" },
  ];
};

const setRequestData = (datatableElement) => {
  return (data) => {
    const columnIndexes = dataTableFunctions().columnIndexes(data);

    dataTableFunctions().setColumnFilter(data, columnIndexes[columnNames.date], dateRangeRequest());
    if (multipleShareholders(datatableElement)) {
      dataTableFunctions().setColumnFilter(
        data,
        columnIndexes[columnNames.shareholder],
        document.getElementById("filtered_shareholder").value
      );
    }
  };
};

const multipleShareholders = (datatableElement) => {
  return isStringTrue(datatableElement.dataset.multipleShareholdersCompany);
};

const dateRangeRequest = () => {
  const year = document.getElementById("filtered_year").value;
  const month = document.getElementById("filtered_month").value;
  let startDate = new Date(year, 0, 1);
  let endDate = new Date(year, 11, 31);

  if (month) {
    startDate = new Date(year, month - 1, 1);
    endDate = lastDateOfMonth(month - 1, year);
  }

  return `${jsFormattedDate(startDate)}:${jsFormattedDate(endDate)}`;
};

const onComplete = (datatableElement) => {
  return () => {
    const inputArray = ["filtered_year", "filtered_month", "filtered_shareholder"];
    inputArray.forEach((input) => {
      dataTableFunctions().manageInputChange(input, datatableElement.id, "input");
    });
  };
};

export { initTripsDatatable };
