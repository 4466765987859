const profileModalTabs = () => {
  const tabs = document.querySelectorAll("#profile_fulfillment_modal .tab");
  const firstTabContent = document.querySelector("#profile_fulfillment_modal .tab-content");
  if (tabs && firstTabContent) {
    tabs.forEach((tab) => {
      tab.addEventListener("click", (event) => {
        tabs.forEach((tab) => {
          tab.classList.remove("active");
        });
        event.currentTarget.classList.add("active");
        const marginLeft = `-${event.currentTarget.dataset.tab}00%`;
        firstTabContent.style.marginLeft = marginLeft;
      });
    });
  }
};

export { profileModalTabs };
