import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    if ("OTPCredential" in window) {
      this.inputTarget.value = true;
    }
  }
}
