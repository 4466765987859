import { Controller } from "@hotwired/stimulus";
import { initTable } from "../../datatables/supporting_documents_datatable";
import { isDatatableUninitialized } from "../../datatables/datatable_functions";

export default class extends Controller {
  initialize() {
    if (isDatatableUninitialized(this.element)) {
      initTable(this.element);
    }
  }
}
