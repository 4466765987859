import { Controller } from "@hotwired/stimulus";
import { fetchStream } from "../../utils/turbo_utils";
import SubscriptionWorkflow from "../../subscription/subscription_workflow";

export default class extends Controller {
  connect() {
    const urlsMapping = JSON.parse(this.element.dataset.funnelUrls);
    const currentUrl = new SubscriptionWorkflow(urlsMapping).redirectionUrl();
    fetchStream(currentUrl);
  }
}
