import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["emailInput", "submitBtn", "editBtn", "cancelEditBtn"];

  enableEmailEditing() {
    this.submitBtnTarget.classList.remove("hidden");
    this.cancelEditBtnTarget.classList.remove("hidden");
    this.editBtnTarget.classList.add("hidden");
    this.emailInputTarget.classList.remove("disabled");
    this.emailInputTarget.classList.remove("invalid");
  }

  disableEmailEditing() {
    this.submitBtnTarget.classList.add("hidden");
    this.cancelEditBtnTarget.classList.add("hidden");
    this.editBtnTarget.classList.remove("hidden");
    this.emailInputTarget.classList.add("disabled");
    this.emailInputTarget.value = this.emailInputTarget.dataset.initialValue || "";
    $(this.emailInputTarget).parsley().reset();
  }

  validateForm(e) {
    if (this.emailInputTarget.value === this.emailInputTarget.dataset.initialValue) {
      e.preventDefault();
      this.disableEmailEditing();
      return;
    }
  }
}
