import { Controller } from "@hotwired/stimulus";
import { isBankAttachmentValid } from "../../bank_accounts/attachment_upload";

export default class extends Controller {
  static targets = ["receiptUploadForm"];

  handleReceiptUpload(event) {
    const receipt = isBankAttachmentValid(event.target.files);
    if (receipt) {
      Rails.fire(this.receiptUploadFormTarget, "submit");
    }
  }
}
