const managePictoOnHover = () => {
  const togglePictoHover = (btn) => {
    btn.addEventListener("mouseover", () => {
      btn.querySelector(".main").classList.add("hidden");
      btn.querySelector(".hover").classList.remove("hidden");
    });

    btn.addEventListener("mouseout", () => {
      btn.querySelector(".main").classList.remove("hidden");
      btn.querySelector(".hover").classList.add("hidden");
    });
  };

  const btns = document.querySelectorAll(".action-button.picto");
  btns.forEach((btn) => {
    togglePictoHover(btn);
  });
};

export { managePictoOnHover };
