const manageMonthYearInput = () => {
  const fiscalYearEndInputs = document.querySelectorAll(".fiscal-year-end-input-numbers [type='number']");
  const fiscalYearEndRealInput = document.getElementById("accounting_data_fiscal_year_end_period");
  if (fiscalYearEndInputs.length > 0) {
    manageInputs(fiscalYearEndInputs, fiscalYearEndRealInput);
  }

  const microAccreDateInputs = document.querySelectorAll(".micro-accre-eligibility-date-input-numbers [type='number']");
  const microAccreDateRealInput = document.getElementById("tax_data_micro_accre_date");
  if (microAccreDateInputs.length > 0) {
    manageInputs(microAccreDateInputs, microAccreDateRealInput);
  }
};

const manageInputs = (inputs, realInput) => {
  inputs.forEach((input) => {
    input.addEventListener("keyup", (e) => {
      if (e.keyCode === 8 && input.value === "") {
        let previousInput = input.previousElementSibling;
        if (previousInput) {
          if (previousInput.innerText === "/") {
            previousInput = previousInput.previousElementSibling;
          }
          previousInput.focus();
        }
      }
    });

    input.addEventListener("input", (e) => {
      manageInputNavigation(input, e);
      manageInputFormat(input, e);
      fillRealInput(realInput, inputs);
    });
  });
};

const manageInputNavigation = (input, event) => {
  if (event.data === "e" || event.data === null) {
    // Manage keypress on 'e' or remove value
    input.value = "";
  } else {
    let nextInput = input.nextElementSibling;
    if (nextInput) {
      if (nextInput.innerText === "/") {
        nextInput = nextInput.nextElementSibling;
      }
      nextInput.focus();
    }
  }
};

const manageInputFormat = (input) => {
  if (input.value.length > 1) {
    input.value = input.value[0];
  }
};

const fillRealInput = (realInput, inputs) => {
  if (Array.from(inputs).every(inputFilled)) {
    // fill real input only if all inputs are filled correctly
    const array = [];
    inputs.forEach((input) => {
      array.push(input.value);
    }); // looks like ['3', '0', '1', '2']

    array.splice(2, 0, "/");
    if (inputs.length === 6) {
      array.splice(5, 0, "/");
    }
    realInput.value = array.join("");
  } else {
    realInput.value = "";
  }

  realInput.dispatchEvent(new Event("change"));
};

const inputFilled = (currentValue) => {
  return currentValue.value.length === 1;
};

export { manageMonthYearInput, manageInputs };
