import { Controller } from "@hotwired/stimulus";
import { appendUrlSearchParams } from "../utils/misc";

export default class extends Controller {
  static targets = ["parentSelect", "childSelectContainer"];

  change() {
    const url = appendUrlSearchParams(this.parentSelectTarget.dataset.url, {
      parent_folder: this.parentSelectTarget.value,
    });

    fetch(url, {
      method: "get",
      headers: { accept: "text/html_fragment" },
    })
      .then((response) => response.text())
      .then((data) => {
        this.childSelectContainerTarget.innerHTML = data;
      });
  }
}
