// generic function that enable to display content according to input's value
// prerequisite =>
// - input should have 'toggle-div-input' class
// - data looking like 'data-div-to-toggle' (if several options should look like 'data-div-to-toggle-one / two etc.')
// - each option is associated to a data-display-value which is the value of input for which content is displayed.
// Same thing => if only one option, two attributes : data-div-to-toggle AND data-display-value
// if several options : data-div-to-toggle-one AND data-display-value-one + data-div-to-toggle-two AND data-display-value-two etc.

const manageConditionnalInputDisplay = () => {
  const triggerDivInputs = document.querySelectorAll(".toggle-div-input");
  if (triggerDivInputs.length) {
    triggerDivInputs.forEach((input) => {
      const inputAttributes = Array.from(input.attributes);
      const divToToggleAttributes = inputAttributes.filter((attr) => attr.nodeName.includes("data-div-to-toggle"));

      input.addEventListener("input", (e) => {
        const displayDivStatus = defineDivToDisplay(input, divToToggleAttributes);
        toggleDivClasses(displayDivStatus);
      });
    });
  }

  const defineDivToDisplay = (input, divToToggleAttributes) => {
    let divToShow = undefined;
    const divToNotShow = [];

    divToToggleAttributes.forEach((attr) => {
      const targetDiv = document.getElementById(attr.nodeValue);
      const option = attr.nodeName.split("-")[4];
      const optionValue = (
        input.getAttribute(`data-display-value-${option}`) || input.getAttribute(`data-display-value`)
      )?.split(" ");

      if ((input.type === "file" && input.value != "") || (input.type != "file" && optionValue.includes(input.value))) {
        divToShow = targetDiv;
      } else {
        divToNotShow.push(targetDiv);
      }
    });

    return { divToShow: divToShow, divToNotShow: divToNotShow };
  };

  const toggleDivClasses = (divStatus) => {
    if (divStatus["divToShow"]) {
      divStatus["divToShow"].classList.add("show-div");
      divStatus["divToShow"].querySelectorAll("input").forEach((input) => (input.disabled = false));
    }

    divStatus["divToNotShow"].forEach((div) => {
      div.classList.remove("show-div");
      div.querySelectorAll("input").forEach((input) => (input.disabled = true));
    });
  };
};

export { manageConditionnalInputDisplay };
