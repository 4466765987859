import { Controller } from "@hotwired/stimulus";
import { dataTableFunctions } from "../../datatables/datatable_functions";

export default class extends Controller {
  static values = {
    tableId: String,
  };

  reload() {
    dataTableFunctions().reloadTable(this.tableIdValue);
  }
}
