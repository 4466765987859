import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  enlarge() {
    const { textareaEnlargedHeight } = this.element.dataset;
    this._resize(this.element, textareaEnlargedHeight);
  }

  minimize() {
    const { textareaMinHeight } = this.element.dataset;
    this._resize(this.element, textareaMinHeight);
  }

  _resize(target, height) {
    target.style.height = `${height}px`;
  }
}
