import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["todoCta", "validIcon", "invalidIcon"];

  connect() {
    this.element.addEventListener(
      "todo:done",
      () => {
        this.remove();
      },
      { once: true }
    );
  }

  remove() {
    this.todoCtaTarget.classList.add("hidden");
    this.validIconTarget.classList.remove("hidden");
    setTimeout(() => {
      this.element.classList.add("removing");
      this.element.addEventListener("transitionend", () => {
        this.element.remove();
      });
      document.dispatchEvent(new CustomEvent("todo:updateCounter"));
    }, 1000);
  }
}
