import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["candidatesContainer"];

  toggleCandidates(e) {
    this.candidatesContainerTargets.forEach((candidateContainer) => candidateContainer.classList.add("hidden"));
    const selectedCandidates = this.candidatesContainerTargets.find(
      (candidateContainer) => candidateContainer.dataset.candidateType === e.currentTarget.value
    );
    selectedCandidates.classList.remove("hidden");
  }
}
