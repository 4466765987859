import { Controller } from "@hotwired/stimulus";
import { nextMonth, fullMonthAndYear } from "../../utils/date_utils";
import { capitalize } from "../../utils/misc";

export default class extends Controller {
  static targets = ["firstInternalSalaryWarning", "firstInternalSalaryMonth"];

  dateSelected(e) {
    const selectedDate = new Date(e.currentTarget.value);
    const firstInternalSalaryDate = nextMonth(selectedDate);
    this._displayFirstInternalSalary(firstInternalSalaryDate);
  }

  _displayFirstInternalSalary(firstInternalSalaryDate) {
    const translatedDate = fullMonthAndYear(firstInternalSalaryDate);
    this.firstInternalSalaryMonthTarget.innerText = capitalize(translatedDate);
    this.firstInternalSalaryWarningTarget.classList.remove("hidden");
  }
}
