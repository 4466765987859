import { Controller } from "@hotwired/stimulus";
import { initBankTransactionsDatatable } from "../../datatables/bank_transactions_datatable";
import { isDatatableUninitialized } from "../../datatables/datatable_functions";

export default class extends Controller {
  initialize() {
    if (isDatatableUninitialized(this.element)) {
      initBankTransactionsDatatable(this.element);
    }
  }
}
