import { changeStep } from "./change_step";

const manageBasicQuestionsFormSubmit = () => {
  const submitBtn = document.querySelector(".basic-questions-container .submit-btn");
  if (submitBtn) {
    submitBtn.addEventListener("click", () => {
      if (submitBtn.dataset.submitStep === "0") {
        changeStep("next");
      } else {
        const step = submitBtn.dataset.submitStep;
        const form = document.querySelector(`[data-step='${step}']`);
        // form.submit();
        Rails.fire(form, "submit");

        submitBtn.classList.add("hidden");
        submitBtn.nextElementSibling.classList.remove("hidden");
      }
    });
  }
};

export { manageBasicQuestionsFormSubmit };
