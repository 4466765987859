import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formContainer", "loader"];

  onSubmit() {
    this.formContainerTarget.classList.add("hidden");
    this.loaderTarget.classList.remove("hidden");
  }
}
