import { Controller } from "@hotwired/stimulus";
import { fetchStream } from "../../utils/turbo_utils";
import { debounce } from "../../utils/misc";
import { matchingModalFunctions } from "../../matching_modal/matching_modal_functions";

export default class extends Controller {
  static targets = ["form", "listItem", "loader"];

  connect() {
    fetchStream(this.formTarget.action).then(() => this.loaderTarget.classList.add("hidden"));
  }

  searchFilter = debounce(() => {
    return this.refreshList();
  });

  orderCandidates(e) {
    /* Needed to prevent double click when clicked on label of the checkbox */
    e.preventDefault();

    const radioBtnToCheck = e.currentTarget.querySelector(".order-radio-btn:not(:checked)");
    radioBtnToCheck.checked = true;
    this.refreshList();
  }

  refreshList() {
    this.loaderTarget.classList.remove("hidden");
    this.formTarget.requestSubmit();
  }

  listRefreshed() {
    this.loaderTarget.classList.add("hidden");
  }

  addToMatchingSelection(e) {
    if (!matchingModalFunctions().targetShouldToggleMatchingSelection(e.target)) {
      return;
    }

    const line = e.currentTarget;
    const descriptionLine = matchingModalFunctions().descriptionLine(line);
    this._formController().addToMatchingSelection(descriptionLine);
    line.classList.add("hidden");
  }

  removeFromMatchingSelection(operationId) {
    const line = this.listItemTargets.find((item) => item.dataset.operationId === operationId);
    if (!line) {
      return;
    }

    line.classList.remove("hidden");
  }

  _formController() {
    return this.application.getControllerForElementAndIdentifier(
      matchingModalFunctions().formElement(),
      "matching-modal--form"
    );
  }
}
