import { allInputsWithValidation, inputIsValid, inputMustBeFilled } from "./input_validation";

const initAllInputValidation = (form) => {
  allInputsWithValidation(form).forEach(function (element) {
    toggleInputValidationIcon(element);
    if (
      (element.nodeName === "INPUT" && element.value !== "" && !inputIsValid(element)) ||
      inputMustBeFilled(element)
    ) {
      element.classList.add("not-valid");
    }
    element.addEventListener("input", function (e) {
      toggleInputValidationIcon(e.target);
      element.classList.remove("not-valid");
    });
  });
};

const toggleInputValidationIcon = (element) => {
  const enclosingDiv = element.closest(".div-input-label, .select-container");

  if (enclosingDiv === null) {
    return;
  }

  const classLists = enclosingDiv.querySelector(".valid-input-icon").classList;
  if (inputIsValid(element) && !inputMustBeFilled(element)) {
    classLists.remove("hidden");
  } else {
    classLists.add("hidden");
  }
};

export { initAllInputValidation, toggleInputValidationIcon };
