const VAT_DELEGATION_LOCAL_STORAGE_KEY = "vat_delegation_workflow_choices";

export default class VatDelegationWorkflow {
  constructor(steps, formContainer) {
    this.steps = steps;
    this.formContainer = formContainer;
  }

  get initialStepId() {
    return this.steps.account_creation.sub_steps.no_procedure;
  }

  get saveStepUrl() {
    return this.formContainer.dataset.saveStepUrl;
  }

  currentStepId(savedStep) {
    return savedStep ? this.savedStepId(savedStep) : this.storedStepId();
  }

  savedStepId(savedStep) {
    const step = Object.values(this.steps).find((step) => step.order === savedStep);
    const savedStepId = this._initialStepIdFor(step);
    this.storeStep(savedStepId);
    return savedStepId;
  }

  storedStepId() {
    const storedStepId = localStorage.getItem(VAT_DELEGATION_LOCAL_STORAGE_KEY);
    return storedStepId || this.initialStepId;
  }

  saveStep() {
    const mainStepNumber = this.mainStepNumber();

    fetch(this.saveStepUrl, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ main_step_number: mainStepNumber }),
    });
  }

  storeStep(stepId) {
    localStorage.setItem(VAT_DELEGATION_LOCAL_STORAGE_KEY, stepId);
  }

  mainStepNumber() {
    const currentMainStep = Object.values(this.steps).find((value) =>
      Object.values(value.sub_steps).includes(this.currentStepId())
    );
    return currentMainStep.order;
  }

  _initialStepIdFor(step) {
    switch (step.order) {
      case 1:
        return step.sub_steps.no_procedure;
      case 2:
        return step.sub_steps.no_procedure;
      case 3:
        return step.sub_steps.unconfirmed;
    }
  }
}
