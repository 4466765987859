import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mobileValidationForm"];

  onPhoneCodeSent() {
    this.mobileValidationFormTarget.classList.remove("hidden");
    this.mobileValidationFormTarget.classList.add("fade-in-top");
  }
}
