const changePageCounter = (numberToSubstract, paginationId) => {
  numberToSubstract = parseInt(numberToSubstract);

  const pagination = document.getElementById(paginationId);

  if (pagination) {
    const paginationArray = pagination.innerText.split(" ");
    // looks like ["1", "-", "9", "sur", "9"]
    const totalItems = parseInt(paginationArray[4]) - numberToSubstract;

    const currentPageNumber = parseInt(paginationArray[2]);
    if (totalItems >= 25) {
      pagination.innerText = `${paginationArray[0]} - ${
        parseInt(paginationArray[2]) - numberToSubstract
      } sur ${totalItems}`;
    } else {
      pagination.innerText = `1 - ${totalItems} sur ${totalItems}`;
    }
  }
};

export { changePageCounter };
