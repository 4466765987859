const displayContentOnLoad = () => {
  window.addEventListener("load", function () {
    displayContent();
  });

  const displayContent = () => {
    document.getElementById("page-content-wrapper").classList.remove("hidden");
    $("#div_waiting_image").fadeOut("slow");
  };
};

export { displayContentOnLoad };
