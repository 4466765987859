import { Controller } from "@hotwired/stimulus";
import { checkValidation } from "../../creation/validate_zipcode_for_birthcountry";

/**
 * Controller used to check whether the birth zipcode is relevant with respect to the birth country
 * Checks events fired from the zipcode input (via the checkZipCodeForCountry method) and from the country select2 field (via the connect method)
 */
export default class extends Controller {
  static targets = ["zipcode"];
  static values = { countryId: String };

  connect() {
    $(`#${this.countryIdValue}`).on("select2:select", () => {
      this.checkZipCodeForCountry();
    });
  }

  checkZipCodeForCountry() {
    checkValidation($(`#${this.countryIdValue}`).parent(), this.zipcodeTarget.parentNode);
  }
}
