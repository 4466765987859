import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert";

export default class extends Controller {
  onFormSubmit() {
    swal({
      title: this.element.dataset.alertText,
      buttons: { non: true, oui: true },
      className: "custom-swal-modal",
    }).then((value) => {
      if (value === "oui") {
        this.element.submit();
        swal({
          title: this.element.dataset.loadingText,
          buttons: false,
          className: "form-submission-confirmation-modal",
          content: {
            element: "img",
            attributes: {
              className: "waiting-dots",
            },
          },
        });
      }
    });
  }
}
