import { Controller } from "@hotwired/stimulus";
import { normalize } from "../../utils/string_utils";
import { showElements, toggleClass } from "../../utils/misc";

export default class extends Controller {
  static targets = ["searchInput", "noResults", "supra", "category"];

  initCategories(event) {
    const { numberOfSelectedItems, catId, supraName } = event.detail;
    this._unselectItem(this.supraTargets);
    this._unselectItem(this.categoryTargets);
    if (numberOfSelectedItems === 1) {
      const catCard = this.categoryTargets.find((target) => target.dataset.catId === catId);

      if (catCard) {
        if (this.hasSupraTarget) {
          const catSupra = this.supraTargets.find((target) => target.dataset.supraName === supraName);
          this._selectItem(catSupra);
          this._scrollToSupra(catSupra);
          this._filterCategoriesBySupra(supraName);
        }
        this._selectItem(catCard);
      }
    }
  }

  _scrollToSupra(supra) {
    setTimeout(function () {
      supra.scrollIntoView({ block: "nearest", inline: "nearest" });
    }, 500);
  }

  _selectItem(element) {
    element.classList.add("selected");
  }

  _unselectItem(elements) {
    elements.forEach((target) => target.classList.remove("selected"));
  }

  _filterCategoriesBySupra(supraName) {
    this._toggleCategories((target) => target.dataset.supraName === supraName);
  }

  filterCategoriesByUsage(event) {
    this._toggleSupraAndSearch(event.currentTarget);
    const mostUsedCategories = JSON.parse(event.currentTarget.dataset.mostUsedCategories);
    this._toggleCategories((target) => mostUsedCategories.includes(+target.dataset.catId));
  }

  filterCategoriesByName(event) {
    const searchInput = event.target.value;
    this._unselectItem(this.supraTargets);

    if (searchInput) {
      const remainingCategories = this.categoryTargets.reduce((acc, target) => {
        const isCategoryDisplayed = normalize(target.dataset.catName).includes(normalize(searchInput));
        toggleClass(target, "hidden", isCategoryDisplayed);
        if (isCategoryDisplayed) {
          acc++;
        }
        return acc;
      }, 0);
      toggleClass(this.noResultsTarget, "hidden", remainingCategories === 0);
    } else {
      showElements(this.categoryTargets);
      this.noResultsTarget.classList.add("hidden");
    }
  }

  updateSelectedSupra(event) {
    this._toggleSupraAndSearch(event.currentTarget);

    const { supraName } = event.currentTarget.dataset;
    this._filterCategoriesBySupra(supraName);
  }

  resetSupra(event) {
    this._toggleSupraAndSearch(event.currentTarget);
    this._toggleCategories(() => true);
  }

  _toggleSupraAndSearch(supraToSelect) {
    this._unselectItem(this.supraTargets);
    this._selectItem(supraToSelect);

    this.searchInputTarget.value = "";
    this.noResultsTarget.classList.add("hidden");
  }

  _toggleCategories(displayCondition) {
    this.categoryTargets.forEach((target) => {
      const isTargetDisplayed = displayCondition(target);

      // Here we use a JS animation rather than a CSS transition / animation
      // See this PR comment for more context : https://github.com/acasi-io/acasi/pull/2755#discussion_r953611895
      target.animate({ opacity: [1, 0] }, { duration: 200 }).onfinish = () =>
        toggleClass(target, "hidden", isTargetDisplayed);
    });
  }
}
