import { Controller } from "@hotwired/stimulus";
import { appendUrlSearchParams, toggleClass } from "../utils/misc";
import { initDropzone, initDropzoneBasicEvents } from "../components/dropzone.js";

export default class extends Controller {
  static targets = [
    "scheduledPayments",
    "selectedYear",
    "uploadForm",
    "filePreviewsContainer",
    "uploadImgLabel",
    "submitBtn",
    "dragAndDropContainer",
  ];

  static values = {
    url: String,
  };

  connect() {
    if (this.hasUploadFormTarget) {
      this._initDropzone();
    }
  }

  loadScheduledPayments() {
    const selectedCalendarYear = this.selectedYearTarget.value;
    const url = appendUrlSearchParams(this.urlValue, { calendar_year: selectedCalendarYear });

    fetch(url, {
      method: "get",
      headers: { accept: "text/html_fragment" },
    })
      .then((response) => response.text())
      .then((data) => {
        this.scheduledPaymentsTarget.innerHTML = data;
        if (this.hasUploadFormTarget) {
          this._initDropzone();
        }
      })
      .catch(() => {
        this.scheduledPaymentsTarget.innerHTML = `Nous avons rencontré un problème pour charger votre calendrier de paiement ${selectedCalendarYear}.`;
      });
  }

  validateSubmission(e) {
    e.preventDefault();
    this.dropzone.processQueue();
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.uploadFormTarget.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
      paramName: "social_contribution_document[document_attributes][aws_file]",
    };
  }

  _initCustomEvents() {
    this.dropzone.on("addedfiles", () => {
      this._toggleSubmitBtn();
    });

    this.dropzone.on("removedfile", () => {
      this._toggleSubmitBtn();
    });

    this.dropzone.on("success", () => {
      setTimeout(() => {
        this.loadScheduledPayments();
      }, 3000);
    });
  }

  _toggleSubmitBtn() {
    const hasAcceptedFiles = this.dropzone.files.length > 0;
    toggleClass(this.submitBtnTarget, "hidden", hasAcceptedFiles);
  }
}
