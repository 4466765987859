const resolveTodo = (todoId) => {
  const sidePanel = document.getElementById("side-panel");
  const todoCard = document.querySelector(`[data-todo-id='${todoId}']`);

  /* todoCard might not be present if side panel is opened on page load while the card is not present in the DOM */
  /* Due to infinite scroll behaviour */
  if (todoCard) {
    sidePanel.addEventListener(
      "transitionend",
      () => {
        todoCard.dispatchEvent(new CustomEvent("todo:done"));
      },
      { once: true }
    );
  }

  document.dispatchEvent(new CustomEvent("sidePanel:close"));
};

export { resolveTodo };
