import { Controller } from "@hotwired/stimulus";
import { initDropzone, initDropzoneBasicEvents } from "../../../components/dropzone";

const ACCEPTED_FORMATS = ".pdf";

export default class extends Controller {
  static targets = [
    "filePreviewsContainer",
    "uploadImgLabel",
    "dragAndDropContainer",
    "numberOfFilesInput",
    "submitBtn",
  ];

  connect() {
    this._initDropzone();

    document.addEventListener("salesInvoice:pdfUpload:formReady", this.setFilePreview);
  }

  disconnect() {
    document.removeEventListener("salesInvoice:pdfUpload:formReady", this.setFilePreview);
  }

  _initDropzone() {
    this.dropzone = initDropzone(this.element.id, {}, this._uploadOptions());
    initDropzoneBasicEvents(this.dropzone, this.uploadImgLabelTarget, this.dragAndDropContainerTarget);
    this._initCustomEvents();
  }

  _uploadOptions() {
    return {
      previewsContainer: this.filePreviewsContainerTarget,
      paramName: "file",
      acceptedFiles: ACCEPTED_FORMATS,
      maxFiles: 12,
    };
  }

  _initCustomEvents() {
    this.dropzone.on("addedfile", () => {
      this._setSubmitBtnAvailability();
    });

    this.dropzone.on("removedfile", () => {
      this._setSubmitBtnAvailability();
    });

    this.dropzone.on("addedfiles", () => {
      this.numberOfFilesInputTarget.value = this.dropzone.files.length;
    });
  }

  _setSubmitBtnAvailability() {
    this.submitBtnTarget.disabled = this.dropzone.files.length === 0;
  }

  setFilePreview = (event) => {
    const sourceDropzoneElement = this.element.dropzone;
    const selectedFile = sourceDropzoneElement.files[event.detail.index];

    const targetDropzoneElement = event.detail.dropzoneElement;
    targetDropzoneElement.emit("addedfile", selectedFile, true);
  };
}
