import { rightArrow } from "./payslip_onboarding";

const initSocialSelect2 = () => {
  const socialPage = document.querySelector(".div_grid_social");
  if (socialPage) {
    $(".dropdown-select2").select2({
      minimumResultsForSearch: -1,
      // placeholder: "Sélectionner un champs",
      // allowClear: true
    });

    const bankInput = document.getElementById("form_objects_social_summary_bank_id");
    if (bankInput) {
      bankInput.nextElementSibling.classList.add("hidden");
    }

    $(".dropdown-select2").on("select2:open", function (e) {
      // console.log(this.nextElementSibling);
      document.querySelector(".select2-results__options").style.borderRadius = "8px";
      document.querySelector(".select2-dropdown").style.borderRadius = "8px";
    });
  }
};

const initSocialOnboardSelect2 = () => {
  $(".social-onboard-select2").select2({
    minimumResultsForSearch: -1,
    // placeholder: "Sélectionner un champs",
    // allowClear: true
  });

  $(".social-onboard-select2").on("change", function (e) {
    const triggerInput = this.dataset.associatedInputsTrigger;
    if (triggerInput) {
      const associatedInputs = document.querySelector(`[data-associated-inputs='${triggerInput}']`);
      if (associatedInputs) {
        if (this.value === "oui") {
          associatedInputs.classList.remove("hidden");
          associatedInputs.querySelectorAll("input, select").forEach((el) => {
            el.dataset.required = "true";
          });
        } else {
          associatedInputs.classList.add("hidden");
          associatedInputs.querySelectorAll("input, select").forEach((el) => {
            el.dataset.required = "false";
          });
        }
      }
      rightArrow();
    }
  });

  const triggerInputs = document.querySelectorAll("[data-associated-inputs-trigger]");
  if (triggerInputs) {
    triggerInputs.forEach((triggerInput) => {
      const associatedInputs = document.querySelector(
        `[data-associated-inputs='${triggerInput.dataset.associatedInputsTrigger}']`
      );
      triggerInput.addEventListener("change", (e) => {
        if (event.currentTarget.value == "oui") {
          associatedInputs.classList.remove("hidden");
          associatedInputs.querySelectorAll("input, select").forEach((el) => {
            el.dataset.required = "true";
          });
        } else {
          associatedInputs.classList.add("hidden");
          associatedInputs.querySelectorAll("input, select").forEach((el) => {
            el.dataset.required = "false";
          });
        }
        rightArrow();
      });
    });
  }
};

export { initSocialSelect2, initSocialOnboardSelect2 };
