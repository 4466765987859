import { Controller } from "@hotwired/stimulus";
import { isSiretValid } from "../utils/business_utils";

export default class extends Controller {
  static targets = ["siretNumber"];

  pasteSiretNumber(event) {
    const siretNumber = event.clipboardData.getData("text");
    if (!isSiretValid(siretNumber)) {
      return;
    }

    if (this._isMobileSiretInput()) {
      event.target.value = siretNumber;
    } else {
      siretNumber.split("").forEach((number, index) => {
        this.siretNumberTargets[index].value = number;
      });
    }
  }

  _isMobileSiretInput() {
    return !this.hasSiretNumberTarget;
  }
}
