import { Controller } from "@hotwired/stimulus";
import { isValidEmail, isInputFilled, isPasswordValid } from "../utils/misc";
import { INVALID_INPUT_CLASS_NAME } from "../utils/constants";

export default class extends Controller {
  static targets = ["emailInput", "passwordInput", "newPasswordConfirmationInput", "newPasswordInput"];

  validateForm(e) {
    if (this.hasEmailInputTarget && !isValidEmail(this.emailInputTarget.value)) {
      e.preventDefault();
      this._displayInvalidInput(this.emailInputTarget);
    }

    if (this.hasPasswordInputTarget && !isInputFilled(this.passwordInputTarget)) {
      e.preventDefault();
      this._displayInvalidInput(this.passwordInputTarget);
    }
  }

  validateSignupForm(e) {
    if (!isValidEmail(this.emailInputTarget.value)) {
      e.preventDefault();
      this._displayInvalidInput(this.emailInputTarget);
    }

    if (!isPasswordValid(this.passwordInputTarget.value)) {
      e.preventDefault();
      this._displayInvalidInput(this.passwordInputTarget);
    }
  }

  validatePasswordUpdateForm(e) {
    if (!isInputFilled(this.passwordInputTarget)) {
      e.preventDefault();
      this._displayInvalidInput(this.passwordInputTarget);
    }

    [this.newPasswordInputTarget, this.newPasswordConfirmationInputTarget].forEach((input) => {
      if (!isPasswordValid(input.value)) {
        e.preventDefault();
        this._displayInvalidInput(input);
      }
    });
  }

  resetInputValidity(e) {
    e.currentTarget.classList.remove(INVALID_INPUT_CLASS_NAME);
  }

  _displayInvalidInput(input) {
    input.classList.add(INVALID_INPUT_CLASS_NAME);
  }
}
