const multiChoiceButtons = (formContent) => {
  let divMultiChoices = document.querySelectorAll(".multi-choice-buttons");
  if (formContent) {
    divMultiChoices = formContent.querySelectorAll(".multi-choice-buttons");
  }

  // init multi choice value, select value
  divMultiChoices.forEach(function (element) {
    const multiChoiceValue = element.querySelector("input").value;
    if (multiChoiceValue) {
      element.querySelector(`div[value="${multiChoiceValue}"]`).classList.add("selected");
    }
  });

  // lors du click sur les button pour les input a choix multiples
  divMultiChoices.forEach(function (el) {
    const buttonsMultiChoice = el.querySelectorAll(".choice-button");
    buttonsMultiChoice.forEach(function (element) {
      element.addEventListener("click", function (event) {
        const divMultichoices = element.closest(".multi-choice-buttons");
        const prevSelectedElememnt = divMultichoices.querySelector(".selected");
        if (!element.classList.contains("disabled")) {
          divMultichoices.querySelector("input").value = element.innerText;
          if (prevSelectedElememnt) {
            prevSelectedElememnt.classList.remove("selected");
          }
          element.classList.add("selected");
          divMultichoices.querySelector("input").value = element.attributes["value"].value;
          divMultichoices.querySelector("input").dispatchEvent(new Event("input"));
        }
      });
    });
  });
};

export { multiChoiceButtons };
