import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";
import { dataTableFunctions } from "../../datatables/datatable_functions";

export default class extends Controller {
  static targets = ["addFilterBtn", "menuItem", "filterTemplate", "panel", "filterLabel", "panelContent"];

  connect() {
    document.addEventListener("datatable:filterPreselected", (event) => {
      this.filterPreselected(event);
    });

    document.addEventListener("datatable:filterSelected", (event) => {
      this.filterSelected(event);
    });

    document.addEventListener("datatable:filterUpdated", () => {
      this.filterUpdated();
    });

    document.addEventListener("datatable:filterCleared", (event) => {
      const { datatableId } = this.element.dataset;

      if (event.detail.id === datatableId) {
        this.filterCleared(event);
      }
    });
  }

  toggleMenu(event) {
    if (this.panelTarget.classList.contains("visible")) {
      // Prevent filters menu from being displayed if panel is already open
      event.stopImmediatePropagation();
    }
  }

  toggleFilterPanel(event) {
    this.addFilterBtnTarget.classList.add("active");
    this._selectTargetFilter(event.target);
  }

  filterCanceled(event) {
    event.stopImmediatePropagation(); // Necessary so bootstrap dropdown doesn't close the dropdown automatically (see this issue for more context: https://stackoverflow.com/questions/25089297/avoid-dropdown-menu-close-on-click-inside/25196101#25196101)
    this._closeFilterPanel();

    this.addFilterBtnTarget.classList.remove("active");
    window.$(`#${this.addFilterBtnTarget.id}`).dropdown("toggle"); // Allows to trigger the filter dropdown menu from an external element that is not the "normal" trigger
  }

  filterPreselected(event) {
    this._disableSelectedFilter(event.detail.name);
  }

  filterSelected(event) {
    const { datatableId } = this.element.dataset;
    const { name } = event.detail;

    dataTableFunctions().reloadTable(datatableId);
    this.panelContentTarget.innerHTML = "";

    this._closeFilterPanel();
    this._disableSelectedFilter(name);
    this.addFilterBtnTarget.classList.remove("active");
  }

  filterUpdated() {
    const { datatableId } = this.element.dataset;
    dataTableFunctions().reloadTable(datatableId);
  }

  filterCleared(event) {
    const { name } = event.detail;
    this.menuItemTargets.find((menu) => menu.dataset.filterName === name).classList.remove("selected");
  }

  _selectTargetFilter(targetMenuItem) {
    const { filterName, filterLabel } = targetMenuItem.dataset;

    const filterTemplate = this.filterTemplateTargets.find((menu) => menu.dataset.filterName === filterName);
    this.filterLabelTarget.innerText = filterLabel;
    this.panelContentTarget.innerHTML = interpolate(filterTemplate, { action: "select" });
    this.panelTarget.classList.add("visible");
  }

  _disableSelectedFilter(name) {
    const activeMenuItem = this.menuItemTargets.find((menu) => menu.dataset.filterName === name);
    activeMenuItem.classList.add("selected");
  }

  _closeFilterPanel() {
    this.panelTarget.classList.remove("visible");
  }
}
