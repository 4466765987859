const manageInputsChanges = () => {
  const modalInputs = document.querySelectorAll(
    "#profile_fulfillment_modal .form-group input, #profile_fulfillment_modal .form-group select, #profile_fulfillment_modal .radio-collection-btn input[type='radio']"
  );
  modalInputs.forEach((el) => {
    setColorFromElementValue(el);
    el.addEventListener("change", (e) => {
      if (el.id !== "form_objects_profile_fulfillment_company_infos_social_form") {
        e.stopImmediatePropagation();
      }
      setColorFromElementValue(e.currentTarget);
      const tabNumber = e.currentTarget.closest(".tab-content").dataset.tab;
      const input = e.currentTarget;
      if (e.currentTarget.id.includes("date")) {
        const dateGroup = e.currentTarget.parentNode;
        const day = dateGroup.childNodes[0].value;
        const month = dateGroup.childNodes[2].value;
        const year = dateGroup.childNodes[4].value;
        const inputDate = new Date(year, month, day);
        const inputData = e.currentTarget.dataset.value.split("-");
        const inputDataValue = new Date(inputData[0], inputData[1], inputData[2]);
        toggleChangedClass(inputDate.getTime() !== inputDataValue.getTime(), tabNumber, input);
      } else {
        toggleChangedClass(e.currentTarget.value !== e.currentTarget.dataset.value, tabNumber, input);
      }
    });
  });
};

const toggleChangedClass = (condition, tabNumber, input) => {
  // Set data-changes counter with condition to know if field has changed
  if (condition) {
    input.setAttribute("data-changes", "1");
  } else {
    if (input.id.includes("date")) {
      const dateInputs = input.parentNode.querySelectorAll("select");
      dateInputs.forEach((date) => {
        date.setAttribute("data-changes", "0");
      });
    } else if (input.type === "radio") {
      const radioInputs = input.parentNode.parentNode.parentNode.querySelectorAll("input[type='radio']");
      radioInputs.forEach((radio) => {
        radio.setAttribute("data-changes", "0");
      });
    } else {
      input.setAttribute("data-changes", "0");
    }
  }

  // Check if fields in selected Tab have changed and affect class
  const tabInputs = document.querySelectorAll(
    `.tab-content[data-tab='${tabNumber}'] .form-group input, .tab-content[data-tab='${tabNumber}'] .form-group select, .tab-content[data-tab='${tabNumber}'] .radio-collection-btn input[type='radio']`
  );
  const fieldsHaveNotChanged = Array.from(tabInputs).every(inputHaveChanged);

  if (fieldsHaveNotChanged) {
    document.querySelector(`.tab[data-tab='${tabNumber}']`).classList.remove("changed");
  } else {
    document.querySelector(`.tab[data-tab='${tabNumber}']`).classList.add("changed");
  }
};

const inputHaveChanged = (currentValue) => {
  return currentValue.dataset.changes === "0";
};

const setColorFromElementValue = (element) => {
  if (element.value !== "") {
    element.classList.add("document-missing");
  } else {
    element.classList.add("document-present");
  }
};

export { manageInputsChanges };
