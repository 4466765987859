import { Controller } from "@hotwired/stimulus";
import { flashAlert, notifyError } from "../components/flash_alert";

export default class extends Controller {
  static targets = ["submitBtn", "waitingGif", "file"];

  _toggleFormSubmitBtn() {
    this.submitBtnTarget.classList.toggle("hidden");
    this.waitingGifTarget.classList.toggle("hidden");
  }

  errorAlert() {
    notifyError();
  }

  onSubmission(event) {
    const maxFileSize = parseInt(this.fileTarget.dataset.maxFileSize);

    if (this.fileTarget.files[0].size > maxFileSize) {
      event.preventDefault();

      flashAlert("La taille du fichier ne doit pas dépasser 1Mo");
    } else {
      this._toggleFormSubmitBtn();
    }
  }
}
