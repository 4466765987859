import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "tabData"];

  toggleTabData(event) {
    if (!event.target.classList.contains("active")) {
      this.tabTargets.forEach((target) => target.classList.toggle("active"));
      this.tabDataTargets.forEach((target) => target.classList.toggle("hidden"));
    }
  }
}
