import { Controller } from "@hotwired/stimulus";
import { debounce } from "../../utils/misc";
import { errorMessages, notifyError } from "../../components/flash_alert";

export default class extends Controller {
  static targets = [
    "companiesList",
    "company",
    "customerName",
    "customerZipcode",
    "customerStreetAddress",
    "customerCity",
    "customerSiren",
    "customerVatNumber",
    "loadingGif",
  ];

  searchCompany = debounce((e) => {
    const input = e.target;
    const searchValue = input.value;

    if (searchValue === "") {
      this._resetCompaniesList();
      return;
    }

    const url = `${e.target.dataset.searchUrl}?query=${searchValue}`;
    this._fetchCompany(url);
  });

  _resetCompaniesList() {
    this.companiesListTarget.innerHTML = "";
  }

  _fetchCompany(url) {
    this.loadingGifTarget.classList.remove("hidden");
    this.companiesListTarget.classList.add("hidden");
    fetch(url, {
      method: "get",
      headers: { accept: "text/html" },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          notifyError(errorMessages(response.status));
        }
      })
      .then((data) => {
        if (data) {
          this.companiesListTarget.innerHTML = data;
        }
        this.loadingGifTarget.classList.add("hidden");
        this.companiesListTarget.classList.remove("hidden");
      });
  }

  fillCompanyDetails(e) {
    this.companyTargets.forEach((company) => company.classList.remove("selected-result"));
    e.currentTarget.classList.add("selected-result");

    const details = JSON.parse(e.currentTarget.dataset.details);
    this.customerNameTarget.value = details.company_name;
    this.customerZipcodeTarget.value = details.zip_address;
    this.customerStreetAddressTarget.value = details.street_address;
    this.customerCityTarget.value = details.city_address;
    this.customerSirenTarget.value = details.siren_number;
    this.customerVatNumberTarget.value = details.vat_number;
  }
}
