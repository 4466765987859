import { STANDARD_VAT_RATE } from "../utils/constants";
import { isSameSign } from "../utils/number_utils";

const isVatConsistent = (vatAmount, grossAmount) => {
  vatAmount = parseFloat(vatAmount) || 0;
  if (vatAmount === 0) {
    return true;
  }

  const netAmount = (parseFloat(grossAmount) || 0) - vatAmount;
  if (!isSameSign(vatAmount, netAmount)) {
    return false;
  }

  const maximumVatAmount = Math.ceil(netAmount * STANDARD_VAT_RATE * 100) / 100;

  return Math.abs(vatAmount) <= Math.abs(maximumVatAmount);
};

export { isVatConsistent };
