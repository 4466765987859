import { isEligibleForShareholders } from "../../utils/business_utils";

const toggleLegalExtraInfo = () => {
  const socialFormInput = document.getElementById("form_objects_profile_fulfillment_company_infos_social_form");
  const legalExtraInfos = document.querySelector(".legal-extra > div");
  if (socialFormInput) {
    showLegalExtraInfo(socialFormInput, legalExtraInfos);
    socialFormInput.addEventListener("change", (e) => {
      showLegalExtraInfo(socialFormInput, legalExtraInfos);
    });
  }
};

const showLegalExtraInfo = (socialFormInput, legalExtraInfos) => {
  const inputs = legalExtraInfos.querySelectorAll("input");
  if (isEligibleForShareholders(socialFormInput.value)) {
    legalExtraInfos.style.marginTop = "0";
    inputs.forEach((i) => {
      i.disabled = false;
    });
  } else {
    legalExtraInfos.style.marginTop = "-100%";
    inputs.forEach((i) => {
      i.disabled = true;
      i.value = "";
    });
  }
};

export { toggleLegalExtraInfo };
