const manageSubscriptionChangeForm = () => {
  const toggleFormBtn = document.querySelector(".change-subscription-btn");
  if (toggleFormBtn) {
    const form = document.getElementById("change-subscription-form");
    toggleFormBtn.addEventListener("click", () => {
      initChangeSubForm(toggleFormBtn, form);
    });
  }

  const cancelSubBtn = document.querySelector(".cancel-sub-btn");
  if (cancelSubBtn) {
    const cancelSubForm = document.getElementById("cancel-sub-form");
    const cancelSubFormBtn = document.querySelector(".cancel-sub-cta");
    [cancelSubFormBtn, cancelSubBtn].forEach((btn) => {
      btn.addEventListener("click", () => {
        initCancelPlanForm(cancelSubForm);
      });
    });
  }
};

const initChangeSubForm = (toggleFormBtn, form) => {
  const cancelSubForm = document.getElementById("cancel-sub-form");
  if (cancelSubForm && cancelSubForm.classList.contains("show")) {
    cancelSubForm.classList.remove("show");
  }
  toggleFormBtn.classList.toggle("show-form");
  form.classList.toggle("show");
};

const initCancelPlanForm = (cancelSubForm) => {
  const changeSubForm = document.getElementById("change-subscription-form");
  if (changeSubForm && changeSubForm.classList.contains("show")) {
    changeSubForm.classList.remove("show");
    document.querySelector(".change-subscription-btn").classList.remove("show-form");
  }
  cancelSubForm.classList.toggle("show");
};

export { manageSubscriptionChangeForm };
