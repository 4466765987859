const manageSelectedItemsPerPage = () => {
  // Manage number of items per page selection
  const manageSelectedItemsPerPage = (btnSelected, firstBtnUnselected, secondBtnUnselected) => {
    btnSelected.addEventListener("click", (e) => {
      if (firstBtnUnselected.parentNode.children[0].checked) {
        firstBtnUnselected.classList.remove("selected-number-per-page");
      } else if (secondBtnUnselected.parentNode.children[0].checked) {
        secondBtnUnselected.classList.remove("selected-number-per-page");
      }
      btnSelected.classList.add("selected-number-per-page");
    });
  };

  const results25 = document.querySelector(".results25");
  const results50 = document.querySelector(".results50");
  const results100 = document.querySelector(".results100");

  if (results25) {
    manageSelectedItemsPerPage(results25, results50, results100);
    manageSelectedItemsPerPage(results50, results25, results100);
    manageSelectedItemsPerPage(results100, results25, results50);
  }
};

export { manageSelectedItemsPerPage };
