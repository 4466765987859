import { currencyToNumber, isNumberInRange } from "../../utils/number_utils";

// Checking for field completion before form submission (a customer, at least 1 full line, and a valid discount if filled)
const validateFields = () => {
  const errors = [];
  const customerField = document.getElementById("invoice_collection_clients");
  const mandatoryLines = document.querySelectorAll("#invoice_lines_all .mandatory-sales-invoice-line");

  mandatoryLines.forEach(function (line) {
    const mandatoryInputSelectors = [
      ".sales_invoice_line_description",
      ".sales_invoice_line_quantity",
      ".sales_invoice_line_gross_unit_price",
      ".sales_invoice_line_vat_rate",
    ];
    mandatoryInputSelectors.forEach((inputSelector) => {
      const input = line.querySelector(inputSelector);
      if (input.value.length === 0) {
        errors.push(input);
      }
    });
  });
  if (document.getElementById("invalid-customer") || !customerField.value) {
    errors.push(customerField);
  }

  const discountInput = document.getElementById("totalDiscountValue");
  const isEmptyDiscount = discountInput.value.length === 0;

  removeErrorInFields();

  if (isEmptyDiscount) {
    return errors;
  }

  if (!validateDiscount()) {
    errors.push(discountInput);
  }

  return errors;
};

const validateMainTotalsConsistency = () => {
  const netTotal = document.getElementById("sales_invoice_net_total").innerHTML;
  const netTotalAfterDiscount = document.getElementById("sales_invoice_net_total_with_discount").innerHTML;
  const vatTotal = document.getElementById("sales_invoice_vat_total").innerHTML;
  const grossTotal = document.getElementById("sales_invoice_gross_total").innerHTML;

  return !(netTotal + netTotalAfterDiscount + vatTotal + grossTotal).includes("-");
};

const validateDiscount = () => {
  const netTotal = parseFloat(currencyToNumber(document.getElementById("sales_invoice_net_total").innerHTML));
  const isCurrencyDiscount = document.getElementById("totalDiscountTypeBtnEuro").classList.contains("active");
  const discount = parseFloat(document.getElementById("totalDiscountValue").value);

  return isCurrencyDiscount ? isNumberInRange(0, netTotal, discount) : isNumberInRange(0, 100, discount);
};

const removeErrorInFields = () => {
  document.querySelectorAll(".empty-field-error").forEach(function (input) {
    if (input.value.length > 0) {
      input.classList.remove("empty-field-error");
    }
  });
};

export { validateFields, validateMainTotalsConsistency };
