import { Controller } from "@hotwired/stimulus";
import { initTable } from "../../datatables/shareholders_accounts_datatable";

export default class extends Controller {
  initialize() {
    if (!$.fn.DataTable.isDataTable(this.element)) {
      initTable(this.element);
    }
  }
}
