import Mustache from "mustache";
import Handlebars from "handlebars";

export const interpolate = (fragment, options) => {
  return Mustache.render(fragment.innerHTML, options);
};

// NOTE(Jonathan Jalal): this is an alternative (using Handlebars.js) to our interpolate function (that uses Mustache)
export const compiledTemplate = (fragment, options) => {
  const template = Handlebars.compile(fragment.innerHTML);
  return template(options);
};

export const setTemplatePartial = (name, fragment) => {
  Handlebars.registerPartial(name, fragment.innerHTML);
};

String.prototype.pluralize = function (count, noun, suffix = "s") {
  return `${this}${count !== 1 ? suffix : ""}`;
};

export const pluralize = (count, noun, suffix = "s") => `${count} ${noun.pluralize(count, suffix)}`;

export const normalize = (str) => {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");
};

export const renderMustacheTemplate = (templateId, templateContainer) => {
  const template = interpolate(document.getElementById(templateId));
  templateContainer.innerHTML = template;
};
