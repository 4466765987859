import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "actionsMenu",
    "paymentMethodsList",
    "paymentMethodsLoading",
    "newCardForm",
    "newSepaForm",
    "showNewCardFormButton",
    "showNewSepaFormButton",
  ];

  hideMenus() {
    this.actionsMenuTargets.forEach((element) => {
      element.classList.add("hidden");
    });
  }

  displayLoaderOnAction() {
    this.paymentMethodsLoadingTarget.classList.remove("opacity_0");
    this.paymentMethodsListTarget.classList.add("opacity_20");
  }

  toggleCardForm() {
    this._toggleForm(this.showNewCardFormButtonTarget, this.newCardFormTarget);
  }

  toggleSepaForm() {
    this._toggleForm(this.showNewSepaFormButtonTarget, this.newSepaFormTarget);
  }

  _toggleForm(button, formTarget) {
    button.classList.toggle("hidden");
    formTarget.classList.toggle("hidden");
  }
}
