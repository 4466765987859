import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

const REDUCED_CLASS = "reduced";
const EXPANDED_HOVER_CLASS = "expand-hover";

export default class extends Controller {
  static targets = ["sidebar"];

  toggleCollapse() {
    this.element.classList.toggle(REDUCED_CLASS);
    this.element.classList.remove(EXPANDED_HOVER_CLASS);
    this._saveState();
  }

  expandHover() {
    if (this._isSidebarReduced()) {
      this.element.classList.add(EXPANDED_HOVER_CLASS);
      this.element.addEventListener("transitionend", () => this.element.classList.add("expanded"), { once: true });
    }
  }
  reduceHover() {
    this.element.classList.remove(EXPANDED_HOVER_CLASS);
    this.element.addEventListener("transitionend", () => this.element.classList.remove("expanded"), { once: true });
  }

  expandOnTouch() {
    if (this._isSidebarReduced() && this._isTouchInSidebar()) {
      event.preventDefault();
      this.toggleCollapse();
    }
  }

  _isTouchInSidebar() {
    return this.sidebarTarget.contains(event.targetTouches[0].target);
  }

  _isSidebarReduced() {
    return this.element.classList.contains(REDUCED_CLASS);
  }

  _saveState() {
    const key = this.element.dataset.sidebarCollapsedCookiesKey;
    if (this._isSidebarReduced()) {
      Cookies.set(key, true);
    } else {
      Cookies.remove(key);
    }
  }
}
