import { Controller } from "@hotwired/stimulus";
import { computeNumberOfFiles } from "../../documents/number_of_files_computations";
import { handleHttpResponse } from "../../utils/http_utils";
import { errorMessages, notifyError } from "../../components/flash_alert";

export default class extends Controller {
  onDestroyed(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: () => {
        this.element.remove();
        computeNumberOfFiles();
      },
      onError: (response, status) => {
        if (status === 422) {
          const jsonResponse = JSON.parse(response);
          notifyError(jsonResponse.message);
        } else {
          notifyError(errorMessages(status));
        }
      },
    });
  }
}
