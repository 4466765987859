const checkPasswordRequirement = () => {
  const checkChar = (psw) => {
    if (psw.length >= 8) {
      document.querySelector(".min-char").classList.add("green");
      document.querySelector(".min-char").classList.remove("red");
      document.querySelector(".min-char .init").classList.add("hidden");
      document.querySelector(".min-char .valid").classList.remove("hidden");
      document.querySelector(".min-char .invalid").classList.add("hidden");
    } else {
      document.querySelector(".min-char").classList.remove("green");
      document.querySelector(".min-char").classList.add("red");
      document.querySelector(".min-char .init").classList.add("hidden");
      document.querySelector(".min-char .valid").classList.add("hidden");
      document.querySelector(".min-char .invalid").classList.remove("hidden");
    }
  };

  const checkMinLowerCase = (psw) => {
    if (psw.toUpperCase() !== psw) {
      document.querySelector(".min-lower-case").classList.add("green");
      document.querySelector(".min-lower-case").classList.remove("red");
      document.querySelector(".min-lower-case .init").classList.add("hidden");
      document.querySelector(".min-lower-case .valid").classList.remove("hidden");
      document.querySelector(".min-lower-case .invalid").classList.add("hidden");
    } else {
      document.querySelector(".min-lower-case").classList.remove("green");
      document.querySelector(".min-lower-case").classList.add("red");
      document.querySelector(".min-lower-case .init").classList.add("hidden");
      document.querySelector(".min-lower-case .valid").classList.add("hidden");
      document.querySelector(".min-lower-case .invalid").classList.remove("hidden");
    }
  };

  const checkMinUpperCase = (psw) => {
    if (psw.toLowerCase() !== psw) {
      document.querySelector(".min-upper-case").classList.add("green");
      document.querySelector(".min-upper-case").classList.remove("red");
      document.querySelector(".min-upper-case .init").classList.add("hidden");
      document.querySelector(".min-upper-case .valid").classList.remove("hidden");
      document.querySelector(".min-upper-case .invalid").classList.add("hidden");
    } else {
      document.querySelector(".min-upper-case").classList.remove("green");
      document.querySelector(".min-upper-case").classList.add("red");
      document.querySelector(".min-upper-case .init").classList.add("hidden");
      document.querySelector(".min-upper-case .valid").classList.add("hidden");
      document.querySelector(".min-upper-case .invalid").classList.remove("hidden");
    }
  };

  const checkMinNumber = (psw) => {
    if (/\d/.test(psw)) {
      document.querySelector(".min-number").classList.add("green");
      document.querySelector(".min-number").classList.remove("red");
      document.querySelector(".min-number .init").classList.add("hidden");
      document.querySelector(".min-number .valid").classList.remove("hidden");
      document.querySelector(".min-number .invalid").classList.add("hidden");
    } else {
      document.querySelector(".min-number").classList.remove("green");
      document.querySelector(".min-number").classList.add("red");
      document.querySelector(".min-number .init").classList.add("hidden");
      document.querySelector(".min-number .valid").classList.add("hidden");
      document.querySelector(".min-number .invalid").classList.remove("hidden");
    }
  };

  const userPassword = document.querySelector("[data-password-requirement-check]");
  userPassword.addEventListener("input", function (event) {
    if (event.target.value.length > 0) {
      checkChar(event.target.value);
      checkMinLowerCase(event.target.value);
      checkMinUpperCase(event.target.value);
      checkMinNumber(event.target.value);
    } else {
      initCheckPasswordCard();
    }
  });
};

const initCheckPasswordCard = () => {
  document.querySelector(".min-char").classList.remove("green");
  document.querySelector(".min-char").classList.remove("red");
  document.querySelector(".min-char .init").classList.remove("hidden");
  document.querySelector(".min-char .valid").classList.add("hidden");
  document.querySelector(".min-char .invalid").classList.add("hidden");

  document.querySelector(".min-lower-case").classList.remove("green");
  document.querySelector(".min-lower-case").classList.remove("red");
  document.querySelector(".min-lower-case .init").classList.remove("hidden");
  document.querySelector(".min-lower-case .valid").classList.add("hidden");
  document.querySelector(".min-lower-case .invalid").classList.add("hidden");

  document.querySelector(".min-upper-case").classList.remove("green");
  document.querySelector(".min-upper-case").classList.remove("red");
  document.querySelector(".min-upper-case .init").classList.remove("hidden");
  document.querySelector(".min-upper-case .valid").classList.add("hidden");
  document.querySelector(".min-upper-case .invalid").classList.add("hidden");

  document.querySelector(".min-number").classList.remove("green");
  document.querySelector(".min-number").classList.remove("red");
  document.querySelector(".min-number .init").classList.remove("hidden");
  document.querySelector(".min-number .valid").classList.add("hidden");
  document.querySelector(".min-number .invalid").classList.add("hidden");
};

export { checkPasswordRequirement, initCheckPasswordCard };
