import { Controller } from "@hotwired/stimulus";
import { flashAlert } from "../components/flash_alert";
import { updateTooltipTitle } from "../utils/misc";

export default class extends Controller {
  static targets = ["source", "tooltipElement"];

  _copy() {
    this.sourceTarget.select();
    document.execCommand("copy");

    if (document.selection) {
      document.selection.empty();
    } else if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  }

  copyWithAlert(event) {
    this._copy();
    const successMessage = event.currentTarget.dataset.successMessage;
    setTimeout(function () {
      flashAlert(successMessage);
    }, 100);
  }

  copyWithTooltip(event) {
    this._copy();
    const successMessage = event.currentTarget.dataset.successMessage;
    this._updateTooltip(successMessage);
  }

  _updateTooltip(successMessage) {
    const tooltipElement = this.tooltipElementTarget;
    const initialTitle = tooltipElement.dataset.title;
    updateTooltipTitle(tooltipElement, successMessage);
    window.$(tooltipElement).tooltip("show");

    setTimeout(function () {
      updateTooltipTitle(tooltipElement, initialTitle);
      window.$(tooltipElement).tooltip("hide");
    }, 500);
  }
}
