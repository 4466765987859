const manageTabOptionOnInput = () => {
  $(".no-tab-option-true").on("keydown", function (e) {
    if (e.keyCode === 9) {
      e.preventDefault();
    }
  });

  const paySlipAllPageNoTab = ["4", "5", "6", "7", "8"];

  paySlipAllPageNoTab.forEach((page) => {
    document.querySelector(`[data-payslip='${page}']`).addEventListener("keydown", (e) => {
      if (e.keyCode === 9) {
        e.preventDefault();
      }
    });
  });
};

export { manageTabOptionOnInput };
