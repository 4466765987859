const checkPreviousBlockFilled = () => {
  const editAdvancedPage = document.querySelector(".edit-advanced");
  if (editAdvancedPage) {
    const blockHundredPercent = (currentValue) => {
      return currentValue === 100;
    };

    const getPreviousElementSibling = (block) => {
      return block.previousElementSibling;
    };

    const toggleBlockClasses = (block, previousBlocksPercent) => {
      if (previousBlocksPercent.every(blockHundredPercent)) {
        block.classList.remove("disabled");
      } else {
        block.classList.add("disabled");
      }
    };

    const manageBlockPermission = (block) => {
      let previousBlock = getPreviousElementSibling(block);
      const previousBlocksPercent = [];

      while (previousBlock) {
        const previousBlockProgress =
          parseInt(previousBlock.querySelector(".block-bottom-progress-bar").getAttribute("width").replace("%", "")) ||
          0;
        previousBlocksPercent.push(previousBlockProgress);
        previousBlock = getPreviousElementSibling(previousBlock);
      }

      toggleBlockClasses(block, previousBlocksPercent);
    };

    const blocks = document.querySelectorAll(".block.collapsable");
    blocks.forEach((block) => {
      manageBlockPermission(block);
    });
  }
};

export { checkPreviousBlockFilled };
