import { Controller } from "@hotwired/stimulus";
import { toggleSidePanel } from "../components/toggle_side_panel";
import { interpolate } from "../utils/string_utils";
import { postTurboRequest } from "../utils/http_utils";

export default class extends Controller {
  static targets = ["loaderTemplate", "content"];
  static values = { defaultOpenUrl: String };

  connect() {
    toggleSidePanel();

    if (this.defaultOpenUrlValue) {
      this._open(this.defaultOpenUrlValue);
    }

    document.addEventListener("sidePanel:open", (e) => {
      const { url, data = {} } = e.detail;
      this._open(url, data);
    });

    document.addEventListener("sidePanel:close", () => {
      this.close();
    });
  }

  collapse(event) {
    this.element.classList.add("reduced");
    event.stopImmediatePropagation(); // Prevents click event to be propagated to parent div, which would call the expand event below
  }

  expand() {
    this.element.classList.remove("reduced");
  }

  close() {
    this.element.classList.remove("show-side-panel", "reduced");
  }

  _open(url, data = {}) {
    this.contentTarget.insertAdjacentHTML("afterbegin", interpolate(this.loaderTemplateTarget));
    this.element.classList.add("show-side-panel");

    postTurboRequest(url, data);
  }
}
