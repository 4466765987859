import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert";
import { isSwalConfirmed } from "../utils/misc";

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      swal({
        title: this.element.dataset.confirm,
        buttons: { non: true, oui: true },
        className: "custom-swal-modal",
      }).then((value) => {
        if (isSwalConfirmed(value)) {
          this.element.nextElementSibling.click();
        }
      });
    });
  }
}
