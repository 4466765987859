import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";

export default class extends Controller {
  toggleLostReceipt(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        document.dispatchEvent(new CustomEvent("bankTransactionUpdated", { detail: JSON.parse(response) }));
      },
    });
  }
}
