import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

/**
 * This controller's sole objective is to call AJAX requests associated to JS responses, and it should only be used in that context
 * Note that we have to call $.ajax here; the fetch API doesn't work as it does not trigger javascript code in .js.erb responses
 * (for more details : https://stackoverflow.com/questions/55512407/is-there-a-way-to-render-a-js-erb-after-a-fetch-call)
 *
 * Later on, we could progressively replace .js.erb partials with HTML fragments or turbo to avoid using this kind of controller
 */
export default class extends Controller {
  fetch() {
    $.ajax({
      url: this.element.dataset.url,
      type: "get",
      contentType: "application/javascript",
    });
  }
}
