import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mtvCard"];

  displayPreviousMtv(e) {
    const activeMtv = e.currentTarget.closest(".mtv");
    const mtvToActivate = activeMtv.previousElementSibling;
    this._displayActiveMtv(mtvToActivate);
  }

  displayNextMtv(e) {
    const activeMtv = e.currentTarget.closest(".mtv");
    const mtvToActivate = activeMtv.nextElementSibling;
    this._displayActiveMtv(mtvToActivate);
  }

  displayUnpaidMtv(e) {
    const mtvId = e.currentTarget.dataset.mtvId;
    const mtvToActivate = this.mtvCardTargets.find((card) => card.dataset.mtvId === mtvId);
    this._displayActiveMtv(mtvToActivate);
  }

  _displayActiveMtv(mtvToDisplay) {
    this.mtvCardTargets.forEach((card) => card.classList.remove("active"));
    mtvToDisplay.classList.add("active");
  }
}
