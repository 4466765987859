// this controller aims to centralize select2 initialization
// For now the default select2 is intialized with a search option
// Other options is when select2 is displayed in a modal
// This can be improved according to needs
import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["selectInput"];
  static values = { modalSelect2: Boolean, modalId: String, escapeMarkup: Boolean, customOptions: Object };

  connect() {
    const input = this.selectInputTarget;
    $(input).select2(this._select2Options());
    this._addPlaceholderToSearch();
    $(input).on("select2:select select2:unselect", function () {
      input.dispatchEvent(new Event("input"));
    });
  }

  _select2Options() {
    let options = this._basicOptions();

    if (this.modalSelect2Value) {
      options = {
        ...options,
        ...{
          dropdownParent: $(`#${this.modalIdValue}`),
        } /* this value should not be the modal's root element ID if the entire modal is scrollable */,
      };
    }
    if (this.escapeMarkupValue) {
      options = {
        ...options,
        escapeMarkup: (m) => m,
      };
    }

    options = { ...options, ...this.customOptionsValue };

    return options;
  }

  _basicOptions() {
    /* We defined that if the number of options in select is above 20, we add by default a searchbar to the select2 */
    const searchBarValue = this.selectInputTarget.options.length >= 20 ? 1 : -1;

    return {
      language: {
        noResults: function () {
          return "Aucun résultat";
        },
      },
      /* You need to specify in the customOptionsValue that the placeholder is nil if you want select2 to display an option without value */
      placeholder: "Sélectionner",
      minimumResultsForSearch: searchBarValue,
    };
  }

  _addPlaceholderToSearch() {
    // select2 library does not seem to include an option for placeholder in search input (2021-12-06)
    const placeholderText = this.customOptionsValue.placeholderText || "Rechercher";
    $(this.selectInputTarget).one("select2:open", function () {
      $("input.select2-search__field").prop("placeholder", placeholderText);
    });
  }
}
