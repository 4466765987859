import { selectOptionFor } from "../utils/inputs_utils";
import { formattedDate, isFlatpickrInput, flatpickrAltInput, dynamicDateRange } from "../utils/date_utils";
import { numericRange } from "../utils/number_utils";

export const clearInputValue = (input) => {
  if (["checkbox", "radio"].includes(input.type)) {
    input.checked = false;
  } else if (isFlatpickrInput(input)) {
    input.value = "";
    flatpickrAltInput(input).value = "";
  } else if (input.type === "select-one") {
    selectOptionFor(input, null);
  } else {
    input.value = "";
  }
};

export const dispatchFilterPreselectedEvent = (payload) => {
  document.dispatchEvent(new CustomEvent("datatable:filterPreselected", { detail: payload }));
};

export const dispatchFilterSelectedEvent = (payload) => {
  document.dispatchEvent(new CustomEvent("datatable:filterSelected", { detail: payload }));
};

export const dispatchFilterUpdatedEvent = (payload) => {
  document.dispatchEvent(new CustomEvent("datatable:filterUpdated", { detail: payload }));
};

export const dateRangeLabel = (startDate, endDate) => {
  let value = `Du ${formattedDate(startDate)} au ${formattedDate(endDate)}`;
  if (!startDate) {
    value = `Jusqu'au ${formattedDate(endDate)}`;
  } else if (!endDate) {
    value = `Depuis le ${formattedDate(startDate)}`;
  }

  return value;
};

const periodFilter = () => {
  let period;
  const selectedPeriodInput = document.querySelector("[name='period-filter']:checked");

  if (selectedPeriodInput) {
    period = selectedPeriodInput.value;
  }
  return period;
};

export const computePeriodDateRange = () => {
  let period = periodFilter();
  const startDateFilter = document.getElementById("start-date-filter");
  const endDateFilter = document.getElementById("end-date-filter");
  if ((startDateFilter && startDateFilter.value) || (endDateFilter && endDateFilter.value)) {
    period = dynamicDateRange([startDateFilter.value, endDateFilter.value]).join(":");
  }
  return period;
};

export const amountFilterRange = (amountInputs, rangeStart, rangeEnd) => {
  let range;
  const { amountEqInput, amountLtInput, amountGtInput, amountBwInput } = amountInputs;

  if (amountEqInput.checked) {
    range = numericRange(null, null, rangeStart);
  } else if (amountLtInput.checked) {
    range = numericRange(-Number.MAX_SAFE_INTEGER, rangeStart);
  } else if (amountGtInput.checked) {
    range = numericRange(rangeStart);
  } else if (amountBwInput.checked) {
    range = numericRange(rangeStart, rangeEnd);
  }
  return range;
};
