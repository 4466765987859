import { Controller } from "@hotwired/stimulus";
import { dispatchFilterSelectedEvent, dispatchFilterUpdatedEvent } from "../../datatables/datatable_filters";

export default class extends Controller {
  static targets = ["checkboxInput"];

  filterChanged(event) {
    if (event.params.action === "update") {
      this._filterUpdated();
    } else {
      this._filterSelected();
    }
  }

  _filterSelected() {
    const { filterName, filterLabel } = this.element.dataset;
    const selectedCheckboxes = this._selectedCheckboxes();
    const translatedValue = this._filterTranslatedValue(selectedCheckboxes);
    const filterData = selectedCheckboxes.map((checkbox) => {
      return { id: checkbox.id };
    });

    dispatchFilterSelectedEvent({
      name: filterName,
      translatedValue: translatedValue,
      label: filterLabel,
      data: filterData,
    });
  }

  _filterUpdated() {
    const { filterName } = this.element.dataset;
    const selectedCheckboxes = this._selectedCheckboxes();
    const translatedValue = this._filterTranslatedValue(selectedCheckboxes);

    dispatchFilterUpdatedEvent({ name: filterName, translatedValue: translatedValue });
  }

  _filterTranslatedValue(selectedCheckboxes) {
    if (selectedCheckboxes.length === 0) {
      return null;
    }

    const { filterLabel } = this.element.dataset;
    return `${filterLabel} : ${selectedCheckboxes.map((checkbox) => checkbox.dataset.translatedValue).join(", ")}`;
  }

  _selectedCheckboxes() {
    return this.checkboxInputTargets.filter((checkbox) => checkbox.checked);
  }
}
