import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";

export default class extends Controller {
  static targets = ["tripContainer", "startingLocation", "endingLocation", "note", "distance", "date"];

  duplicateTrip() {
    const templateContent = interpolate(document.getElementById("trip_inputs"), {
      note: this.noteTarget.value,
      date: this.dateTarget.value,
      distance: this.distanceTarget.value,
      startingLocation: this.startingLocationTarget.value,
      endingLocation: this.endingLocationTarget.value,
    });

    this.tripContainerTarget.insertAdjacentHTML("afterend", templateContent);
  }

  destroyTrip() {
    this.tripContainerTarget.remove();
  }

  switchLocations() {
    const startingLocation = this.startingLocationTarget.value;
    const endingLocation = this.endingLocationTarget.value;

    this.startingLocationTarget.value = endingLocation;
    this.endingLocationTarget.value = startingLocation;
  }
}
