import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";

export default class extends Controller {
  static targets = ["descriptionLineTemplate"];
  connect() {
    document.addEventListener("salesInvoice:catalogItemFilled", (event) => {
      const { invoiceLineContainer, catalogItemDescription } = event.detail;
      this._updateDescriptionLine(invoiceLineContainer, catalogItemDescription);
    });
  }

  _updateDescriptionLine(invoiceLineContainer, description) {
    if (this._descriptionLineAlreadyExists(invoiceLineContainer)) {
      this._overwriteExistingLine(invoiceLineContainer, description);
      return;
    }

    if (this._selectedDescriptionEmpty(description)) {
      return;
    }

    this._addNewLine(invoiceLineContainer, description);
  }

  _descriptionLineAlreadyExists(invoiceLineContainer) {
    const hasDescriptionContainer = !!invoiceLineContainer.nextElementSibling;
    if (!hasDescriptionContainer) {
      return false;
    }
    const descriptionContainerNotEmpty = this._descriptionLineTextArea(invoiceLineContainer);
    return descriptionContainerNotEmpty;
  }

  _overwriteExistingLine(invoiceLineContainer, description) {
    this._descriptionLineTextArea(invoiceLineContainer).innerText = description;
  }

  _descriptionLineTextArea(invoiceLineContainer) {
    return invoiceLineContainer.nextElementSibling.querySelector(".invoice_line_base_description textarea");
  }

  _selectedDescriptionEmpty(description) {
    return description === null || description === "";
  }

  _addNewLine(invoiceLineContainer, description) {
    invoiceLineContainer.insertAdjacentHTML(
      "afterend",
      interpolate(this.descriptionLineTemplateTarget, { description: description })
    );
  }
}
