import { Controller } from "@hotwired/stimulus";
import { select2ContainerFor, selectedOptionFor, selectOptionFor } from "../../utils/inputs_utils";
import { nanoid } from "nanoid";
import { toggleClass } from "../../utils/misc";

export default class extends Controller {
  static targets = [
    "vatRates",
    "unit",
    "customUnit",
    "reference",
    "referenceContainer",
    "unitPrice",
    "description",
    "quantity",
    "catalogItemId",
    "catalogItemSelect",
  ];

  initialize() {
    this._initializeEvents();
  }

  disconnect() {
    document.removeEventListener("salesInvoice:catalogItemSelected", this._prefillEvent);
  }

  catalogItemSelectTargetConnected(target) {
    this._setSelect2CustomId(target);
  }

  get standardSalesInvoiceLineDataDiv() {
    return document.getElementById("invoice_lines_all");
  }

  get standardUnits() {
    return JSON.parse(this.standardSalesInvoiceLineDataDiv.dataset.units);
  }

  get catalogItems() {
    return JSON.parse(this.standardSalesInvoiceLineDataDiv.dataset.catalogItems);
  }

  catalogItemChanged(event) {
    const selectedOption = selectedOptionFor(event.target);
    const catalogItemId = parseInt(selectedOption.dataset.catalogItemId);
    const catalogItem = this.catalogItems.find((item) => item.value.id === catalogItemId).value;
    document.dispatchEvent(
      new CustomEvent("salesInvoice:catalogItemSelected", {
        detail: { lineId: this.element.dataset.lineId, ...catalogItem },
      })
    );
  }

  _initializeEvents() {
    document.addEventListener("salesInvoice:catalogItemSelected", this._prefillEvent);
  }

  _prefillEvent = (event) => {
    const clickedLineId = event.detail.lineId;
    if (!this._isClickedSalesInvoiceLine(clickedLineId)) {
      return;
    }
    const catalogItem = event.detail;
    this._prefillSalesInvoiceLine(catalogItem);
    document.dispatchEvent(new CustomEvent("salesInvoice:updateLineTotals"));
    document.dispatchEvent(
      new CustomEvent("salesInvoice:catalogItemFilled", {
        detail: { invoiceLineContainer: this.element, catalogItemDescription: catalogItem.description },
      })
    );
  };

  _isClickedSalesInvoiceLine(clickedLineId) {
    return clickedLineId === this.element.dataset.lineId;
  }

  _prefillSalesInvoiceLine(catalogItem) {
    if (this._isRealEstateCompany()) {
      this.catalogItemIdTarget.value = catalogItem.id;
    }

    if (!this._isRealEstateCompany()) {
      this.descriptionTarget.value = catalogItem.title;
    }

    this.unitPriceTarget.value = catalogItem.unit_price;

    this.quantityTarget.value = 1;

    this._prefillReference(catalogItem);
    this._prefillUnit(catalogItem);
    this._prefillVatRate(catalogItem);
  }

  _prefillReference(catalogItem) {
    toggleClass(this.referenceContainerTarget, "hidden", catalogItem.reference !== "");
    this.referenceTarget.value = catalogItem.reference;
  }

  _prefillUnit(catalogItem) {
    const selectedUnit = catalogItem.unit;

    if (this.standardUnits.includes(selectedUnit)) {
      this._prefillStandardUnit(selectedUnit);
      this._resetCustomUnit();
    } else {
      this._prefillCustomUnit(selectedUnit);
      this._resetStandardUnit();
    }
  }

  _prefillStandardUnit(selectedUnit) {
    this.unitTarget.value = selectedUnit;
    selectOptionFor(this.unitTarget, selectedUnit);
    select2ContainerFor(this.unitTarget).classList.remove("hidden");
  }

  _resetStandardUnit() {
    this.unitTarget.value = "free";
    this.unitTarget.name = "";
    select2ContainerFor(this.unitTarget).classList.add("hidden");
  }

  _prefillCustomUnit(selectedUnit) {
    this.customUnitTarget.classList.remove("hidden");
    this.customUnitTarget.value = selectedUnit;
    this.customUnitTarget.name = "unit";
  }

  _resetCustomUnit() {
    this.customUnitTarget.classList.add("hidden");
    this.customUnitTarget.value = "";
  }

  _prefillVatRate(catalogItem) {
    const selectedCatalogItemVatRate = catalogItem.vat_rate;
    this.vatRatesTarget.value = selectedCatalogItemVatRate;
    this.vatRatesTarget.name = "vat_rate";
    select2ContainerFor(this.vatRatesTarget).classList.remove("hidden");
    selectOptionFor(this.vatRatesTarget, selectedCatalogItemVatRate);
  }

  _isRealEstateCompany() {
    return this.hasCatalogItemIdTarget;
  }

  _setSelect2CustomId(select) {
    // Setting a uniq custom ID to current select2 element, so that multiple
    // select2 elements can be instantiated and coexist in the DOM
    select.id = nanoid();
  }
}
