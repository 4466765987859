import { Controller } from "@hotwired/stimulus";
import { openModal } from "../../utils/misc";
import { i18nTranslations } from "../../utils/i18n_utils";

export default class extends Controller {
  static targets = ["prefix", "invoiceText"];

  connect() {
    document.addEventListener("salesInvoicesNumbering:noYearPrefixSelected", (event) => {
      const { prefix, form, invoiceType } = event.detail;

      this.prefixTarget.innerText = prefix;
      this.form = form;

      const invoiceText = i18nTranslations().t("first_invoice", {
        scope: `invoice_numbering.${invoiceType}`,
        count: 1,
      });
      this.invoiceTextTargets.forEach((target) => (target.innerText = invoiceText));

      openModal(this.element.id);
    });
  }

  confirmNumberingWithoutYearPrefix() {
    this.form.dispatchEvent(new CustomEvent("salesInvoicesNumbering:noYearPrefixConfirmed"));
  }
}
