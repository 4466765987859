import { Controller } from "@hotwired/stimulus";
import { updateBankTransactionsTableRows } from "../../datatables/bank_transactions_datatable";
import { handleHttpResponse } from "../../utils/http_utils";
import { errorMessages, notifyError } from "../../components/flash_alert";

export default class extends Controller {
  attachmentDeleted(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const bankTransactionsToUpdate = JSON.parse(response).bank_transactions;
        updateBankTransactionsTableRows(bankTransactionsToUpdate);
      },
      onError: (response, status) => {
        if (status === 422) {
          const error = JSON.parse(response).error;
          notifyError(error);
        } else {
          notifyError(errorMessages(status));
        }
      },
    });
  }
}
