import { handlePurchaseDatatableRow } from "../purchase_invoices/purchase_invoice_channel/datatable_row_processing";
import { handlePurchaseMobileCard } from "../purchase_invoices/purchase_invoice_channel/mobile_card_processing";

const purchaseInvoiceChannel = () => {
  const purchasePage = document.getElementById("purchases_table");

  if (purchasePage) {
    App.cable.subscriptions.create(
      {
        channel: "PurchaseInvoicesChannel",
        company_id: purchasePage.dataset.companyId,
      },
      {
        received: (data) => {
          if (document.getElementById("mobile_purchase_invoices")) {
            handlePurchaseMobileCard(data);
          } else {
            handlePurchaseDatatableRow(data);
          }
        },
      }
    );
  }
};

export { purchaseInvoiceChannel };
