const initBankTransactionsToBeMatchWithEquity = (bankTransactions) => {
  const inputTemplate = document.getElementById("subsidiary_bank_transaction_input_template");
  const divToBeFilled = document.getElementById("bank_transactions_inputs");
  divToBeFilled.innerHTML = "";
  bankTransactions.forEach((bankTransaction) => {
    const newInput = inputTemplate.cloneNode();
    newInput.value = bankTransaction.id;
    divToBeFilled.append(newInput);
  });
};

export { initBankTransactionsToBeMatchWithEquity };
