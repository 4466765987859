import { Controller } from "@hotwired/stimulus";
import { initCustomersDatatable } from "../../datatables/customers_datatable";
import { isDatatableUninitialized } from "../../datatables/datatable_functions";

export default class extends Controller {
  initialize() {
    if (isDatatableUninitialized(this.element)) {
      initCustomersDatatable(this.element);
    }
  }
}
