import { Controller } from "@hotwired/stimulus";
import { debounce } from "../../utils/misc";

export default class extends Controller {
  static targets = ["form"];

  searchFilter = debounce(() => {
    return this.refreshList();
  });

  refreshList() {
    this.formTarget.requestSubmit();
  }
}
