import { Controller } from "@hotwired/stimulus";
import { selectOptionFor } from "../../utils/inputs_utils";

export default class extends Controller {
  static targets = ["vatRates"];
  static values = { customerVatExempted: Boolean };

  connect() {
    document.addEventListener("salesInvoice:customerChanged", (e) => {
      this.customerVatExemptedValue = e.detail.vatExemptedCustomer;
      if (this.customerVatExemptedValue) {
        this._setInvoiceAsVatExempt();
        this._updateInvoiceTotals();
      }
    });
  }

  vatRatesTargetConnected(target) {
    if (this.customerVatExemptedValue) {
      this._setInvoiceLineAsVatExempt(target);
    }
  }

  _setInvoiceAsVatExempt() {
    this.vatRatesTargets.forEach((target) => this._setInvoiceLineAsVatExempt(target));
  }

  _setInvoiceLineAsVatExempt(vatRatesInput) {
    selectOptionFor(vatRatesInput, "0");
  }

  _updateInvoiceTotals() {
    document.dispatchEvent(new CustomEvent("salesInvoice:updateMainTotals"));
  }
}
