const dismissProfileFulfillmentMessage = () => {
  const fulfillmentMessage = document.querySelector(".profile-fulfillment-info");
  const closeButton = document.querySelector(".profile-fulfillment-info button");
  if ((fulfillmentMessage, closeButton)) {
    closeButton.addEventListener("click", (e) => {
      fulfillmentMessage.style.display = "none";
    });
  }
};

export { dismissProfileFulfillmentMessage };
