import { Controller } from "@hotwired/stimulus";
import { toggleClass } from "../utils/misc";

const SELECTED_ROW_CLASS = "row-selected";
const SELECTED_ITEM_CLASS = "selected";

export default class extends Controller {
  static targets = ["row", "selectedIdsInput", "selectAllRowsCheckbox"];
  static values = { actionsUrl: String };

  connect() {
    this.element[this.identifier] = this;

    document.addEventListener("datatable:sidePanelClosed", () => {
      this._cancelSelection();
    });

    document.addEventListener("datatable:refreshSidePanel", () => {
      // When refreshing the side panel, we need to update the list of selected item IDs (via the _storeSelectedIds() function)
      // because some may have been deleted since the last time the side panel was opened / closed
      this._handleSidePanel();
    });
  }

  keepSelectedRowsOnTableReload() {
    const selectedIds = this._selectedIds();
    if (selectedIds.length === 0) {
      return;
    }

    const selectedRowsOnPage = this.rowTargets.filter((row) => selectedIds.includes(row.dataset.id));
    selectedRowsOnPage.length === selectedIds.length
      ? selectedRowsOnPage.forEach((row) => this._selectRowInMultiSelection(row))
      : this._cancelSelection();
  }

  handleRowSelection(e) {
    const row = e.currentTarget;
    if (this._isNotClickable(e.target)) {
      return;
    } else if (this._isCheckboxCell(e.target)) {
      this._handleMultiRowSelection(row);
    } else {
      this._handleSingleRowSelection(row);
    }

    this._unselectGlobalCheckbox();
    this._handleSidePanel();
  }

  handleAllRowsSelection() {
    this.selectAllRowsCheckboxTarget.classList.toggle(SELECTED_ITEM_CLASS);

    this.selectAllRowsCheckboxTarget.classList.contains(SELECTED_ITEM_CLASS)
      ? this.rowTargets.forEach((row) => this._selectRowInMultiSelection(row))
      : this.rowTargets.forEach((row) => this._unselectRow(row));

    this._handleSidePanel();
  }

  _cancelSelection() {
    this.rowTargets.forEach((row) => this._unselectRow(row));
    this._unselectGlobalCheckbox();
    this._handleSidePanel();
  }

  _checkboxElement(row) {
    return row.querySelector(".checkbox-element .table-checkbox");
  }

  _handleSingleRowSelection(row) {
    this.rowTargets.forEach((rowElement) => {
      if (this._isRowSelected(rowElement) && rowElement !== row) {
        this._unselectRow(rowElement);
      }
    });
    const rowCheckbox = this._checkboxElement(row);
    rowCheckbox.classList.remove(SELECTED_ITEM_CLASS);
    row.classList.toggle(SELECTED_ROW_CLASS);
  }

  _handleMultiRowSelection(row) {
    const rowInSingleSelection = this.rowTargets.find((rowElement) => this._isInSingleSelection(rowElement));
    if (rowInSingleSelection) {
      this._unselectRow(rowInSingleSelection);
    }
    this._toggleRowSelection(row);
  }

  _selectRowInMultiSelection(row) {
    const rowCheckbox = this._checkboxElement(row);
    rowCheckbox.classList.add(SELECTED_ITEM_CLASS);
    row.classList.add(SELECTED_ROW_CLASS);
  }

  _unselectRow(row) {
    const rowCheckbox = this._checkboxElement(row);
    rowCheckbox.classList.remove(SELECTED_ITEM_CLASS);
    row.classList.remove(SELECTED_ROW_CLASS);
  }

  _toggleRowSelection(row) {
    const rowCheckbox = this._checkboxElement(row);
    rowCheckbox.classList.toggle(SELECTED_ITEM_CLASS);

    const isRowSelected = rowCheckbox.classList.contains(SELECTED_ITEM_CLASS);
    toggleClass(row, SELECTED_ROW_CLASS, !isRowSelected);
  }

  _isCheckboxCell(clickedElement) {
    return clickedElement.closest(".checkbox-element");
  }

  _isNotClickable(clickedElement) {
    return clickedElement.classList.contains("no-selection");
  }

  _isRowSelected(row) {
    return row.classList.contains(SELECTED_ROW_CLASS);
  }

  _isInSingleSelection(row) {
    return this._isRowSelected(row) && !this._checkboxElement(row).classList.contains(SELECTED_ITEM_CLASS);
  }

  _handleSidePanel() {
    this._storeSelectedIds();
    const selectedIds = this._selectedIds();
    selectedIds.length > 0 ? this._displayAvailableActions(selectedIds) : this._hideSidePanel();
  }

  _unselectGlobalCheckbox() {
    this.selectAllRowsCheckboxTarget.classList.remove(SELECTED_ITEM_CLASS);
  }

  _selectedIds() {
    return this.selectedIdsInputTarget.value.split(",").filter((id) => id);
  }

  _storeSelectedIds() {
    const selectedRowsIds = this.rowTargets.reduce((filtered, row) => {
      if (this._isRowSelected(row)) {
        filtered.push(row.dataset.id);
      }
      return filtered;
    }, []);
    this.selectedIdsInputTarget.value = selectedRowsIds;
  }

  _displayAvailableActions(selectedIds) {
    document.dispatchEvent(
      new CustomEvent("sidePanel:open", { detail: { url: this.actionsUrlValue, data: { ids: selectedIds } } })
    );
  }

  _hideSidePanel() {
    document.dispatchEvent(new CustomEvent("sidePanel:close"));
  }
}
