import { appendUrlSearchParams } from "../utils/misc";

const GLOBAL_SEARCH_MIN_CHAR_NUMBER = 2;

const initGlobalSearch = (searchInput) => {
  const input = $(searchInput);

  const options = {
    getValue: (element) => element.name,
    url: (phrase) => appendUrlSearchParams(searchInput.form.dataset.jsonUrl, { q: phrase }),
    requestDelay: 500,
    minCharNumber: GLOBAL_SEARCH_MIN_CHAR_NUMBER,
    categories: [
      {
        listLocation: "more_results",
        header: "",
      },
      {
        listLocation: "bank_transactions",
        header: `<div class='global_search_autocomplete_header'><img src='${window.globals.imgPath["sidebar_bank_accounts_active.png"]}'><div class='title_name'>Transactions</div></div>`,
      },
      {
        listLocation: "purchase_invoices",
        header: `<div class='global_search_autocomplete_header'><img src='${window.globals.imgPath["sidebar_purchase_invoices_active.png"]}'><div class='title_name'>Achats</div></div>`,
      },
      {
        listLocation: "customers",
        header: `<div class='global_search_autocomplete_header'><img src='${window.globals.imgPath["sidebar_customers_active.png"]}'><div class='title_name'>&nbsp;Clients</div></div>`,
      },
      {
        listLocation: "documents",
        header: `<div class='global_search_autocomplete_header'><img src='${window.globals.imgPath["sidebar_documents_active.png"]}'><div class='title_name'>Documents</div></div>`,
      },
    ],
    template: {
      type: "custom",
      method: function (value, item) {
        if ([null, undefined].includes(item.total_nb_of_results)) {
          return `<div class='global_search_autocomplete_element'>
              <div class='element_name'>${value}</div>
              <div class='element_value'>${item.value}</div>
              <div class='element_date'>${item.date}</div>
            </div>`;
        } else {
          return `<div class='global_search_autocomplete_element'>
              <div class='element_name all_results_element'>
                Voir tous les résultats <div class='all_result_count'>${item.total_nb_of_results} élément(s) trouvé(s)</div>
              </div>
            </div>`;
        }
      },
    },
    list: {
      maxNumberOfElements: 15,
      onChooseEvent: () => {
        const url = input.getSelectedItemData().url;
        searchInput.value = "";
        window.location.href = url;
      },
    },
  };

  input.easyAutocomplete(options);
};

export { initGlobalSearch, GLOBAL_SEARCH_MIN_CHAR_NUMBER };
