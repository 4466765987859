const toggleMeTaxExtraInfo = () => {
  // Extra info for Accre
  const trueOptionAccre = document.querySelector(
    "label[for='form_objects_profile_fulfillment_tax_infos_micro_accre_eligibility_true']"
  );
  const falseOptionAccre = document.querySelector(
    "label[for='form_objects_profile_fulfillment_tax_infos_micro_accre_eligibility_false']"
  );
  const accreExtraInfos = document.querySelector("#accre-extra-info > div");
  if (trueOptionAccre) {
    [trueOptionAccre, falseOptionAccre].forEach((option) => {
      option.addEventListener("click", (e) => {
        showAccreExtraInfo(option, accreExtraInfos);
      });
    });
  }

  // Extra info for Vat
  const monthOptionVat = document.querySelector("label[for='form_objects_profile_fulfillment_tax_infos_vat_period_1']");
  const quarterOptionVat = document.querySelector(
    "label[for='form_objects_profile_fulfillment_tax_infos_vat_period_3']"
  );
  const simplifiedOptionVat = document.querySelector(
    "label[for='form_objects_profile_fulfillment_tax_infos_vat_period_12']"
  );
  const exemptionOptionVat = document.querySelector(
    "label[for='form_objects_profile_fulfillment_tax_infos_vat_period_0']"
  );
  const vatExtraInfos = document.querySelector("#vat-extra-info > div");
  if (monthOptionVat) {
    [monthOptionVat, quarterOptionVat, simplifiedOptionVat, exemptionOptionVat].forEach((option) => {
      option.addEventListener("click", (e) => {
        showVatExtraInfo(option, vatExtraInfos);
      });
    });
  }
};

const showAccreExtraInfo = (option, accreExtraInfos) => {
  if (option.querySelector("input").value === "false") {
    accreExtraInfos.style.marginTop = "-100%";
  } else if (option.querySelector("input").value === "true") {
    accreExtraInfos.style.marginTop = "0";
  }
};

const showVatExtraInfo = (option, vatExtraInfos) => {
  const inputs = option.querySelectorAll("input");
  inputs.forEach((input) => {
    if (option.querySelector("input").value === "0") {
      vatExtraInfos.style.marginTop = "-100%";
    } else {
      vatExtraInfos.style.marginTop = "0";
    }
  });
};

export { toggleMeTaxExtraInfo };
