const manageImageHover = () => {
  const hoverableImages = Array.from(document.querySelectorAll(".img-hoverable"));
  const hoverableClasses = hoverableImages.map((image) => image.dataset.hoverableClass).filter(onlyUnique);
  const hoverableDivs = hoverableClasses.map((classes) => document.querySelectorAll(`.${classes}`));
  hoverableDivs.forEach((divs) => {
    divs.forEach((div) => {
      let image = "";
      if (div.classList.contains("img-hoverable")) {
        image = div;
      } else {
        image = div.querySelector(".img-hoverable");
      }

      div.addEventListener("mouseover", (e) => {
        image.src = image.dataset.hoverImageSrc;
      });

      div.addEventListener("mouseout", (e) => {
        image.src = image.dataset.classicImageSrc;
      });
    });
  });
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export { manageImageHover };
