import { Controller } from "@hotwired/stimulus";
import { toggleClass } from "../utils/misc";

export default class extends Controller {
  static targets = ["tab", "tabContent", "tabPanel", "tabAsset"];

  connect() {
    const anchor = window.location.hash;
    if (anchor) {
      this._redirectToTab(anchor);
    }
  }

  _redirectToTab(anchor) {
    const tabName = anchor.substring(1);
    const selectedTab = this.tabTargets.find((tab) => tab.getAttribute("aria-controls") === tabName);
    if (!selectedTab) {
      return;
    }

    window.$(selectedTab).tab("show");
    this._toggleSelectedMobileTab(tabName);
  }

  tabChanged(event) {
    this._toggleSelectedMobileTab(this._tabName(event));
    this._refreshTabAsset(event);
  }

  _refreshTabAsset(event) {
    this.tabAssetTargets.forEach((target) => {
      toggleClass(target, "hidden", target.dataset.name === this._tabName(event));
      toggleClass(target, "fade-in", target.dataset.name !== this._tabName(event));
    });
  }

  _toggleSelectedMobileTab(tabName) {
    this.tabPanelTargets.forEach((target) => toggleClass(target, "hidden", target.id === tabName));
    this.tabContentTarget.classList.add("show");
  }

  closeMobileTab() {
    this.tabContentTarget.animate({ opacity: [1, 0] }, { duration: 300 }).onfinish = () =>
      this.tabContentTarget.classList.remove("show");
  }

  _tabName(event) {
    return event.currentTarget.attributes["aria-controls"].value; // Using currentTarget to make sure we select data from the anchor, and not the child span
  }
}
