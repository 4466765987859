import { Controller } from "@hotwired/stimulus";
import { errorMessages, flashAlert, notifyError } from "../../components/flash_alert";
import { handleHttpResponse } from "../../utils/http_utils";
import { problematicPurchasesFunctions } from "../../purchase_invoice/problematic_purchases_functions";

// TODO(Jonathan Jalal): move some of these methods with duplicated logic outside of this controller, possibly reusing the remote-form controller
export default class extends Controller {
  static values = {
    error: String,
    redirectionUrl: String,
  };

  updateIncompletePurchase(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: () => {
        flashAlert("Informations mises à jour");
      },
    });
  }

  updateInvalidDate(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: () => {
        flashAlert("La date de l'achat a été modifiée");
      },
    });
  }

  markAsCurrentAccount() {
    problematicPurchasesFunctions().resolvedBeingPaidByShareholder();
  }

  deletePurchase(event) {
    const { status, response } = event.detail[0];
    handleHttpResponse({
      payload: { status, response },
      onSuccess: () => {
        document.location.href = this.redirectionUrlValue;
      },
      onError: () => {
        this._customErrorFlash(status, "Cet achat ne peut être supprimé");
      },
    });
  }

  deleteAssociatedDuplicate(event) {
    const { status, response } = event.detail[0];
    handleHttpResponse({
      payload: { status, response },
      onSuccess: () => {
        const deletedItem = event.currentTarget.closest(".item");
        problematicPurchasesFunctions().removeResolutionElement(deletedItem, "backOutRight");
      },
      onError: () => {
        this._customErrorFlash(status, "Cet achat ne peut être supprimé");
      },
    });
  }

  updateDiscrepancyReason(event) {
    const { status, response } = event.detail[0];
    handleHttpResponse({
      payload: { status, response },
      onSuccess: () => {
        problematicPurchasesFunctions().errorResolved(this.errorValue);
      },
      onError: () => {
        this._customErrorFlash(status, "Vous ne pouvez pas modifier ce rapprochement");
      },
    });
  }

  unmatchPurchaseInvoice(event) {
    const { status, response } = event.detail[0];
    handleHttpResponse({
      payload: { status, response },
      onSuccess: () => {
        flashAlert("Rapprochement supprimé");
      },
      onError: () => {
        this._customErrorFlash(status, "Vous ne pouvez pas supprimer ce rapprochement");
      },
    });
  }

  validateFile(event) {
    const { status, response } = event.detail[0];
    handleHttpResponse({
      payload: { status, response },
      onSuccess: () => {
        flashAlert("Vous venez d'indiquer que ce justificatif est le bon.");
      },
      onError: () => {
        this._customErrorFlash(status, "Ce justificatif n'a pas à être validé.");
      },
    });
  }

  editPurchaseMatching() {
    flashAlert("Nouveau rapprochement effectué");
    problematicPurchasesFunctions().errorResolved(this.errorValue);
  }

  _customErrorFlash(status, customMessage) {
    if (status === 403) {
      notifyError(customMessage);
    } else {
      notifyError(errorMessages(status));
    }
  }
}
