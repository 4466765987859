import { Controller } from "@hotwired/stimulus";
import { completeProgrammaticUpload } from "../../../components/dropzone";
import { dataTableFunctions } from "../../../datatables/datatable_functions";
import { salesPdfUploadDropzoneElement, submitDropzoneForm } from "../../../sales_invoices/pdf_upload_dropzone";

export default class extends Controller {
  static targets = ["filePreviewsContainer", "uploadImgLabel", "dragAndDropContainer", "submitBtn", "successMessage"];

  connect() {
    this._initDropzone();

    document.dispatchEvent(
      new CustomEvent("salesInvoice:pdfUpload:formReady", {
        detail: { index: this.element.dataset.fileIndex, dropzoneElement: this.dropzone },
      })
    );
  }

  validateSubmission(e) {
    e.preventDefault();

    submitDropzoneForm(this.element, this.dropzone);
  }

  _initDropzone() {
    this.dropzone = salesPdfUploadDropzoneElement(this.element, {
      filePreviewsContainer: this.filePreviewsContainerTarget,
      dragAndDropContainer: this.dragAndDropContainerTarget,
      uploadImgLabel: this.uploadImgLabelTarget,
    });
    this._initCustomEvents();
  }

  _initCustomEvents() {
    this.dropzone.on("addedfile", (file, addedProgrammatically) => {
      if (addedProgrammatically) {
        completeProgrammaticUpload(this.dropzone, file);
      }
    });

    this.dropzone.on("success", () => {
      dataTableFunctions().reloadTable(this.element.dataset.datatableId);
      this.submitBtnTarget.classList.add("hidden");
      this.successMessageTarget.classList.remove("hidden");
    });
  }
}
