import { Controller } from "@hotwired/stimulus";
import { toggleClass } from "../utils/misc";

export default class extends Controller {
  static targets = ["trigger", "element"];
  static classes = ["active"];

  connect() {
    this._unhideDefaultElement();

    if (this.hasActiveClass) {
      this._activateDefaultTrigger();
    }

    document.addEventListener("tabs:manuallyUpdate", (e) => {
      const tabName = e.detail.tabName;
      this._toggle(tabName);
    });
  }

  toggle(event) {
    const triggerId = event.target.dataset.id;

    this._toggle(triggerId);
  }

  _toggle(tabName) {
    this.elementTargets.forEach((element) => {
      const elementId = element.dataset.id;
      const shouldActivateElement = tabName === elementId;

      this._unhideMatchingElement(element, shouldActivateElement);

      if (this.hasActiveClass) {
        this._activateMatchingTrigger(elementId, shouldActivateElement, this.activeClass);
      }
    });
  }

  _isDefaultTarget(target) {
    return target.dataset.id === this.element.dataset.defaultId;
  }

  _activateDefaultTrigger() {
    this.triggerTargets.find((trigger) => this._isDefaultTarget(trigger)).classList.add(this.activeClass);
  }

  _activateMatchingTrigger(elementId, shouldActivateElement, activeClass) {
    const element = this.triggerTargets.find((target) => target.dataset.id === elementId);
    toggleClass(element, activeClass, !shouldActivateElement);
  }

  _unhideDefaultElement() {
    this.elementTargets.forEach((element) => {
      toggleClass(element, "hidden", this._isDefaultTarget(element));
    });
  }

  _unhideMatchingElement(element, shouldActivateElement) {
    toggleClass(element, "hidden", shouldActivateElement);
  }
}
