import { launchASweetAlert } from "./init_sweet_alert";
import { isSafari } from '../utils/browser_detector_utils'

const fileUploadFunctions = () => {
  const publicFunctions = {};

  const formatValid = (currentValue) => {
    return ["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(currentValue);
  };

  publicFunctions.alertFileMissing = () => {
    launchASweetAlert({
      title: "Merci de sélectionner un fichier à importer",
    });
  };

  publicFunctions.numberOfFilesValid = (maxNumber, numberOfFiles) => {
    return numberOfFiles <= maxNumber;
  };

  publicFunctions.alertInvalidFileNumber = (maxNumber) => {
    const fileWording = parseInt(maxNumber) > 1 ? "fichiers" : "fichier";
    launchASweetAlert({
      title: `Vous ne pouvez pas ajouter plus de ${maxNumber} ${fileWording}`,
    });
  };

  publicFunctions.filesFormatValid = (filesFormat) => {
    return filesFormat.every(formatValid);
  };

  publicFunctions.alertInvalidFileFormat = () => {
    launchASweetAlert({
      title: `Les formats acceptés sont .jpeg, .jpg, .png et .pdf`,
    });
  };

  publicFunctions.filePreview = (fileUrl, fileType, classes = "") => {
    let html = "";
    // There is a bug with Safari using object/embed tags. I couldn't find the exact root cause
    // but even if it may work on the first display of the document (when using a modal), the second display
    // almost always freezes the page (https://acasi.atlassian.net/browse/ACA-3026)
    // So we use the img tag in Safari, as described here: https://programming.bogdanbucur.eu/loading-a-pdf-file-in-safari/
    if (fileType === "application/pdf" && !isSafari()) {
      html = `
        <object data=${fileUrl} type='application/pdf' class='${classes}'>
            <embed src=${fileUrl} type='application/pdf' class='${classes}' />
        </object>
      `;
    } else {
      html = `<img src=${fileUrl} class='${classes}'>`;
    }
    return html;
  };

  publicFunctions.fileLabelText = (numberOfFiles) => {
    return numberOfFiles > 0 ? "Modifier" : "Charger le document";
  };

  publicFunctions.alertInvalidFileSize = (maxSize) => {
    launchASweetAlert({
      title: `Le fichier ne doit pas dépasser ${maxSize} Mo`,
    });
  };

  return publicFunctions;
};

export { fileUploadFunctions };
