import { Controller } from "@hotwired/stimulus";
import { isDatatableUninitialized, dataTableFunctions } from "../../datatables/datatable_functions";
import { initTripsDatatable } from "../../datatables/trips_datatable";

export default class extends Controller {
  static targets = ["datatableElement"];

  initialize() {
    if (isDatatableUninitialized(this.datatableElementTarget)) {
      initTripsDatatable(this.datatableElementTarget);

      document.addEventListener("datatable:reload", () => {
        dataTableFunctions().reloadTable(this.datatableElementTarget.dataset.tableId);
      });
    }
  }
}
