import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  call() {
    const { className, selector } = this.element.dataset;
    const selectedElements = document.querySelectorAll(selector);

    selectedElements.forEach((element) => element.classList.add(className));
  }
}
