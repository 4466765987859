import "select2";
import "select2/dist/css/select2.css";

const initCurrentUserSelect2 = () => {
  $(".current-user-select2").on("select2:select", function (e) {
    document.getElementById("btn-current-user-change").href = `/back_office/users/${e.params.data.id}/impersonate`;
    document.getElementById("btn-current-user-change").click();
  });
};

export { initCurrentUserSelect2 };
