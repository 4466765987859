import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["ibanInput", "bicInput"];

  autofillBankAccountDetails(e) {
    const bankAccountInput = e.currentTarget;
    const selectedOption = bankAccountInput.options[bankAccountInput.selectedIndex];

    this.ibanInputTarget.value = selectedOption.dataset.iban || "";
    this.bicInputTarget.value = selectedOption.dataset.bic || "";
  }
}
