import { Controller } from "@hotwired/stimulus";
import { errorMessages, flashAlert, notifyError } from "../../components/flash_alert";
import { updateBankTransactionsTableRows } from "../../datatables/bank_transactions_datatable";

export default class extends Controller {
  markAsCreditNote(event) {
    this._manageCreditNoteReponse(event, "Transaction(s) bancaire(s) marquée(s) comme avoir");
  }

  markAsNotCreditNote(event) {
    this._manageCreditNoteReponse(event, "Transaction(s) bancaire(s) marquée(s) comme n'étant pas un avoir");
  }

  _manageCreditNoteReponse(event, alertText) {
    const status = event.detail[0].status;
    if (status < 300) {
      const bankTransactionsUpdated = JSON.parse(event.detail[0].response);
      flashAlert(alertText);

      updateBankTransactionsTableRows(bankTransactionsUpdated);
    } else {
      notifyError(errorMessages(status));
    }
  }
}
