import { Controller } from "@hotwired/stimulus";
import { matchingModalFunctions } from "../../matching_modal/matching_modal_functions";

export default class extends Controller {
  static targets = ["line"];
  static values = { operationType: String };

  connect() {
    const selectedOperations = this._selectedOperations();
    this._hideOperationsSelectedForMatching(selectedOperations);
  }

  _hideOperationsSelectedForMatching(selectedOperations) {
    selectedOperations.forEach((operation) => {
      const id = operation.id;
      const line = this.lineTargets.find((line) => parseInt(line.dataset.operationId) === id);
      line?.classList?.add("hidden");
    });
  }

  _formController() {
    return this.application.getControllerForElementAndIdentifier(
      matchingModalFunctions().formElement(),
      "matching-modal--form"
    );
  }

  _selectedOperations() {
    switch (this.operationTypeValue) {
      case "purchase_invoices":
        return this._formController().purchaseInvoices;
      case "sales_invoices":
        return this._formController().salesInvoices;
      case "bank_transactions":
        return this._formController().bankTransactions;
      default:
        return [];
    }
  }
}
