import { Controller } from "@hotwired/stimulus";
import { closeModal, openModal } from "../utils/misc";

export default class extends Controller {
  close(event) {
    closeModal(event.params.id);
  }

  open(event) {
    openModal(event.params.id);
  }
}
