import { showElements } from "../../utils/misc";
import { numberToCurrency } from "../../utils/number_utils";

export const handlePurchaseMobileCard = (data) => {
  const row = document.getElementById(`purchase-invoice-${data.id}`);

  if (row && !data.error) {
    updatePurchase(data, row);
    markPurchaseAsProcessed(row);
  }
};

const updatePurchase = (data, row) => {
  const { supplier, category, amounts } = data;

  row.querySelector(".item-name").innerText = supplier;
  row.querySelector(".item-category").innerText = category.name;

  const itemAmount = row.querySelector(".item-amount");
  const itemVat = row.querySelector(".item-vat");
  itemAmount.innerText = numberToCurrency(amounts.gross_amount, { currency: amounts.currency });
  itemVat.innerText = `TVA ${numberToCurrency(amounts.vat)}`;

  //TODO(Jean Guillet): Why do we hide itemAmount and itemVat by default? Need to check if still relevant
  showElements([itemAmount, itemVat]);
};

const markPurchaseAsProcessed = (row) => {
  row.remove();
  const processedPurchasesDiv = document.getElementById("processed-purchases");
  processedPurchasesDiv.classList.remove("hidden");
  processedPurchasesDiv.insertAdjacentHTML("beforeend", row.outerHTML);
};
