import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";
import { closeModal, toggleClass } from "../../utils/misc";
import $ from "jquery";

export default class extends Controller {
  static targets = ["loader", "connectErrorMessage", "validConnectionIcon", "submitBtn"];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this.connectionTriggerButton = event.relatedTarget;
    });
  }

  onConnect() {
    if ($(this.submitBtnTarget.form).parsley().validate()) {
      this._toggleLoader();
      this._toggleErrorMessage(false);
    }
  }

  onConnectResponse(e) {
    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: () => {
        this._setModalContent();
        this._setCardContent();
        setTimeout(() => {
          closeModal(this.element.id);
        }, 2000);
      },
      onError: () => {
        this._toggleErrorMessage(true);
      },
      onComplete: () => {
        this._toggleLoader();
      },
    });
  }

  _toggleLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }

  _toggleErrorMessage(shouldDisplay) {
    toggleClass(this.connectErrorMessageTarget, "hidden", shouldDisplay);
  }

  _setModalContent() {
    this.validConnectionIconTarget.classList.remove("hidden");
    this.submitBtnTarget.classList.add("hidden");
    this.connectionTriggerButton.classList.add("hidden");
  }

  _setCardContent() {
    const { activeConnectionNoticeElementId, deleteConnectionElementId } = this.element.dataset;
    [activeConnectionNoticeElementId, deleteConnectionElementId].forEach((element) => {
      document.getElementById(element).classList.remove("hidden");
    });
  }
}
