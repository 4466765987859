import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { interpolate } from "../../../utils/string_utils";
import { roundToTwoDecimals, sum } from "../../../utils/number_utils";

export default class extends Controller {
  static targets = ["lineNetTotal", "lineGrossTotal", "netTotal", "vatTotal", "grossTotal", "lineTemplate", "line"];

  connect() {
    this.nextLineIndex = this.lineTargets.length;

    document.addEventListener("salesInvoice:lineUpdated", () => {
      this._computeInvoiceTotal();
    });
  }

  addLine() {
    const templateContent = interpolate(this.lineTemplateTarget, { index: this.nextLineIndex });
    this.lineTargets.at(-1).insertAdjacentHTML("afterEnd", templateContent);
    this.nextLineIndex += 1;
  }

  _computeInvoiceTotal() {
    const netTotal = roundToTwoDecimals(sum(this.lineNetTotalTargets));
    const grossTotal = roundToTwoDecimals(sum(this.lineGrossTotalTargets));
    const vatTotal = roundToTwoDecimals(grossTotal - netTotal);

    this.netTotalTarget.value = netTotal;
    this.grossTotalTarget.value = grossTotal;
    this.vatTotalTarget.value = vatTotal;

    [this.netTotalTarget, this.grossTotalTarget, this.vatTotalTarget].forEach((target) =>
      $(target).parsley().validate()
    );
  }
}
