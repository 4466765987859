import { addDaysToDate, jsFormattedDate } from "../utils/date_utils";

export const computeCancellationLetterDate = (dateType, date = null) => {
  switch (dateType) {
    case "closing":
      if (!date) {
        return "";
      }
      const firstYearStartDate = new Date(date);
      const closingDate = addDaysToDate(firstYearStartDate, -1);
      return jsFormattedDate(closingDate);
    case "today":
      return jsFormattedDate(new Date());
    default:
      return "";
  }
};
