import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["connectorName", "disconnectConfirmationForm"];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this._setModalContent(event);
    });
  }

  _setModalContent(event) {
    this.connectorNameTarget.innerText = event.relatedTarget.dataset.connectorName;
    this.disconnectConfirmationFormTarget.action = event.relatedTarget.dataset.deletionUrl;
  }
}
