import { Controller } from "@hotwired/stimulus";
import { swalLinkFor } from "../utils/misc";
import SpinnerIcon from "../../assets/images/spinner_blue.gif";

export default class extends Controller {
  connect() {
    swalLinkFor(this.element).addEventListener("click", () => {
      this.element.querySelector("img").setAttribute("src", SpinnerIcon);
    });
  }
}
