import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../utils/http_utils";
import { interpolate } from "../utils/string_utils";
import { dataTableFunctions } from "../datatables/datatable_functions";
import { escapeHtml } from "../utils/misc";

export default class extends Controller {
  deleted(event) {
    const accountableId = event.currentTarget.dataset.accountableId;
    this._deleteNoteIcon(accountableId);
    dataTableFunctions().refreshSidePanel();
  }

  updated(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const data = JSON.parse(response);
        const { note, accountable_id } = data;
        this._updateNoteIcon(note, accountable_id);
        dataTableFunctions().refreshSidePanel();
      },
    });
  }

  _deleteNoteIcon(accountableId) {
    const noteIcon = this._noteIconContainer(accountableId).querySelector(".note-icon");
    noteIcon.remove();
  }

  _updateNoteIcon(note, accountableId) {
    const iconElement = interpolate(document.getElementById("note-icon-template"), {
      note: { id: note.id, content: escapeHtml(note.content) },
    });
    const iconContainer = this._noteIconContainer(accountableId);
    iconContainer.innerHTML = iconElement;
  }

  _noteIconContainer(accountableId) {
    return document.querySelector(`[data-note-icon-container-accountable-id='${accountableId}']`);
  }
}
