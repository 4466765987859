import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdownList"];
  static classes = ["visible"];

  connect() {
    window.$(this.element).on("show.bs.dropdown", () => {
      this.dropdownListTarget.classList.add(this.visibleClass);
    });

    window.$(this.element).on("hide.bs.dropdown", () => {
      this.dropdownListTarget.classList.remove(this.visibleClass);
    });
  }
}
