export default class {
  constructor() {
    this.provider = typeof analytics === "undefined" ? null : analytics;
  }

  page() {
    this.provider?.page();
  }

  track(eventName) {
    this.provider?.track(eventName);
  }

  identify(params) {
    const [id, genericParams, integrationSpecificParams] = this._formatParams(params);

    if (id) {
      this.provider?.identify(id, genericParams, { integrations: integrationSpecificParams });
      return;
    }

    this.provider?.identify(genericParams, { integrations: integrationSpecificParams });
  }

  enabled() {
    return this.provider !== null;
  }

  _formatParams(params) {
    const id = params.id;

    let integrationSpecificParams = {};
    if (params.intercomUserHash) {
      integrationSpecificParams = {
        Intercom: {
          userHash: params.intercomUserHash,
        },
      };
    }

    const genericParams = { ...params };
    delete genericParams.intercomUserHash;

    return [id, genericParams, integrationSpecificParams];
  }
}
