import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";
import { flashAlert, notifyError } from "../../components/flash_alert";

export default class extends Controller {
  onUpdateResponse(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onError: (response) => {
        notifyError(JSON.parse(response).error_message);
      },
      onSuccess: () => {
        flashAlert("Informations mises à jour");
        window.$(this.element.closest(".modal")).modal("hide");
        document.dispatchEvent(new CustomEvent("datatable:reload"));
        document.dispatchEvent(new CustomEvent("datatable:refreshSidePanel"));
      },
    });
  }
}
