import $ from "jquery";
import { standardErrorsContainer } from "../utils/parsley_validators_utils";
import { scrollToField } from "../utils/misc";

const displayCreationFormInvalidInputs = (invalidInputs, globalErrorMessageElement) => {
  globalErrorMessageElement.classList.remove("hidden");
  invalidInputs.forEach((input) =>
    $(input)
      .parsley({ ...standardErrorsContainer() })
      .validate()
  );

  let scrollToInput = invalidInputs[0];
  if (scrollToInput.closest(".profile-file-card") !== null) {
    scrollToInput = scrollToInput.closest(".profile-file-card");
  } else if (scrollToInput.type === "radio") {
    scrollToInput = scrollToInput.closest(".parsley-error");
  } else if (scrollToInput.closest(".multi-choice-buttons") !== null) {
    scrollToInput = document.querySelector(`label[for='${scrollToInput.id}']`);
  }

  scrollToField(scrollToInput);
};

export { displayCreationFormInvalidInputs };
