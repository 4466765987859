import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["displayedYear", "startDateInput", "sourceViewInput", "todoIdInput"];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this._fillModal(event.relatedTarget.dataset);
    });
  }

  _fillModal(data) {
    const { startDate, sourceView, todoId } = data;

    this.displayedYearTarget.innerText = new Date(startDate).getFullYear();
    this.startDateInputTarget.value = startDate;
    this.sourceViewInputTarget.value = sourceView;
    this.todoIdInputTarget.value = todoId;
  }
}
