import { Controller } from "@hotwired/stimulus";
import { cancelOpenModalEvent, isInputFilled, isStringTrue, toggleClass } from "../../utils/misc";
import { roundToTwoDecimals } from "../../utils/number_utils";
import { notifyError } from "../../components/flash_alert";
import { formattedQuote, handlePartialInvoiceGenerationFromQuoteResponse } from "../../sales_invoices/utils";

export default class extends Controller {
  static targets = [
    "percentTypeRadioButton",
    "instalmentRawValue",
    "instalmentCurrencySymbol",
    "instalmentComputedGrossAmount",
    "instalmentComputedGrossAmountText",
    "instalmentType",
    "instalmentNote",
    "quoteId",
  ];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      const details = event.relatedTarget.dataset.details;
      this.quote = formattedQuote(details);
      this._initModal(event);
    });
  }

  _initModal(event) {
    if (isStringTrue(this.quote.hasInstalmentInvoices)) {
      notifyError("Un acompte a déjà été facturé pour ce devis.");
      cancelOpenModalEvent(event);
      return;
    }

    this._resetInputs();
    this._setQuoteId();
    this._setQuoteNotes();
    this._setCurrencySymbol();
  }

  onFormSubmit(event) {
    const payload = event.detail[0];
    const modalId = this.element.id;
    handlePartialInvoiceGenerationFromQuoteResponse(payload, modalId);
  }

  computeInstalmentGrossAmount() {
    this.instalmentComputedGrossAmountTarget.value = this._instalmentGrossAmount(this.quote.grossTotal);

    toggleClass(this.instalmentComputedGrossAmountTextTarget, "hidden", this._isPercentType());
  }

  _instalmentGrossAmount(quoteAmount) {
    if (!isInputFilled(this.instalmentRawValueTarget)) {
      return 0;
    }

    const instalmentRawValue = this.instalmentRawValueTarget.value;
    let instalmentComputedGrossAmount = instalmentRawValue;

    if (this._isPercentType()) {
      instalmentComputedGrossAmount = this._percentValue(quoteAmount, instalmentRawValue);
    }

    return instalmentComputedGrossAmount;
  }

  _isPercentType() {
    return this.percentTypeRadioButtonTarget.checked === true;
  }

  _percentValue(quoteAmount, inputValue) {
    return roundToTwoDecimals(quoteAmount * (parseFloat(inputValue) / 100));
  }

  _resetInputs() {
    this.percentTypeRadioButtonTarget.checked = true;

    this.instalmentComputedGrossAmountTarget.value = "0";

    [this.instalmentRawValueTarget].forEach((target) => (target.value = ""));
  }

  _setQuoteId() {
    this.quoteIdTarget.value = this.quote.id;
  }

  _setQuoteNotes() {
    this.instalmentNoteTarget.value = this.quote.notes;
  }

  _setCurrencySymbol() {
    this.instalmentCurrencySymbolTargets.forEach((target) => {
      target.innerText = this.quote.currencySymbol;
    });
  }
}
