import { initDateBeforeOrEqualToValidator } from "../utils/parsley_validators_utils";
import { creationSharesValid } from "../components/input_validation";

const initCreationCustomFormValidators = () => {
  initDateBeforeOrEqualToValidator();
  initSharesValidation();
  initFileValidation();
};

const initSharesValidation = () => {
  window.Parsley.addValidator("creationSharesValidation", {
    validateString: function (value, requirement, options) {
      return creationSharesValid(options.element);
    },
    priority: 32,
  });
};

const initFileValidation = () => {
  window.Parsley.addValidator("creationFileValidation", {
    validateString: function (value) {
      return value === "true";
    },
    messages: {
      fr: "Vous devez sélectionner un fichier",
    },
    priority: 32,
  });
};

export { initCreationCustomFormValidators };
