import { errorMessages, notifyError } from "../components/flash_alert";
import { post } from "@rails/request.js";

// NOTE(Jonathan Jalal): This generic function will only work as expected for ajax:complete events, not other ajax:* events
export const handleHttpResponse = (data) => {
  const { status, response } = data.payload;
  if (status < 300) {
    data.onSuccess(response);
  } else {
    if ("onError" in data) {
      data.onError(response, status);
    } else {
      notifyError(errorMessages(status));
    }
  }

  if ("onComplete" in data) {
    data.onComplete();
  }
};

export const postTurboRequest = (url, body) => {
  post(url, {
    responseKind: "turbo-stream",
    body: body,
  });
};
