import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import intlTelInput from "intl-tel-input";
import Inputmask from "inputmask";
import { isMobileNumberValid } from "../users/mobile_number_validation";

const UTILS_SCRIPT = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js";
const DEFAULT_COUNTRY = "fr";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    this._setStandardMobileInputValidator();
    this.itiObject = this._initIntlTelInputObject();
    this._initCountryChangedEvent();
  }

  _setStandardMobileInputValidator() {
    window.Parsley.addValidator("standardMobileInput", {
      validateString: () => {
        if (isMobileNumberValid(this.itiObject)) {
          return true;
        } else {
          return $.Deferred().reject("Le numéro n'est pas valide");
        }
      },
    });
  }

  _initIntlTelInputObject() {
    const input = this.inputTarget;
    const itiObject = intlTelInput(input, {
      initialCountry: DEFAULT_COUNTRY,
      utilsScript: UTILS_SCRIPT,
      hiddenInput: input.dataset.hiddenInputName,
    });
    itiObject.promise.then(() => {
      this._setInputMask(input.placeholder);
    });
    return itiObject;
  }

  _initCountryChangedEvent() {
    this.inputTarget.addEventListener("countrychange", (e) => {
      this._setInputMask(e.currentTarget.placeholder);
    });
  }

  _setInputMask(placeholder) {
    const selectedCountryMask = placeholder.replace(/\d/g, "9");
    Inputmask({ mask: selectedCountryMask }).mask(this.inputTarget);
  }
}
