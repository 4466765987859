import { Controller } from "@hotwired/stimulus";
import { inputToValidate } from "../utils/inputs_utils";

export default class extends Controller {
  validateInput(event) {
    const inputIds = event.target.dataset.triggeredInputs.split(" ");
    const inputs = inputIds.map((id) => document.getElementById(id));

    inputs.forEach((input) => {
      if (input.value) {
        $(inputToValidate(input)).parsley().validate();
      }
    });
  }
}
