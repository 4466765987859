import { Controller } from "@hotwired/stimulus";
import { computeCancellationLetterDate } from "../profiles/compute_cancellation_letter_date";
import { flatpickrAltInput, fillFlatpickrInput } from "../utils/date_utils";

export default class extends Controller {
  static targets = ["selectedDate"];
  static values = {
    fiscalYearStartDate: String,
  };

  onDateTypeUpdate(event) {
    const date = computeCancellationLetterDate(event.target.value, this.fiscalYearStartDateValue);
    flatpickrAltInput(this.selectedDateTarget).classList.remove("not-valid");
    fillFlatpickrInput(this.selectedDateTarget, date);
  }
}
