const toggleAcreDateWithAcreEligibility = () => {
  const acreEligibilityInput = document.getElementById("form_objects_social_summary_acre_eligibility");
  const acreDate = document.querySelector(".form_objects_social_summary_acre_date_started");
  if ((acreEligibilityInput, acreDate)) {
    manageHiddenClass(acreEligibilityInput, acreDate);
    acreEligibilityInput.addEventListener("change", (e) => {
      manageHiddenClass(acreEligibilityInput, acreDate);
    });
  }
};

const manageHiddenClass = (acreEligibilityInput, acreDate) => {
  if (acreEligibilityInput.value == "oui") {
    acreDate.classList.remove("hidden");
    if (acreEligibilityInput.classList.contains("hidden")) {
      acreDate.nextElementSibling.classList.remove("hidden");
    } else {
      acreDate.nextElementSibling.classList.add("hidden");
    }
  } else {
    acreDate.classList.add("hidden");
    acreDate.nextElementSibling.classList.add("hidden");
  }
};

const changeSocialBankAccount = () => {
  const bankAccountSelect = document.getElementById("form_objects_social_summary_bank_id");
  const hiddenBankForm = document.getElementById("hidden-bank-form");
  if ((bankAccountSelect, hiddenBankForm)) {
    bankAccountSelect.addEventListener("change", (e) => {
      swal({
        title: "Changement de compte bancaire pour le prélèvement des cotisations",
        text: "Veuillez noter que le changement ne sera effectif que dans 2 mois.",
        className: "custom-swal-modal",
      }).then((value) => {
        if (value) {
          const bankId = bankAccountSelect.value;
          hiddenBankForm.querySelector("#form_objects_social_summary_bank_id").value = bankId;
          hiddenBankForm.querySelector("input[type='submit']").click();
        }
      });
    });
  }
};

export { toggleAcreDateWithAcreEligibility, changeSocialBankAccount };
