import $ from "jquery";
import { FRANCE_COUNTRY } from "../utils/constants";

export const CUSTOMER_TYPE = {
  INDIVIDUAL: "individual",
  BUSINESS: "business",
};

export const COMPANY_BUSINESS_TYPE = {
  SERVICES: "1",
  TRADE: "2",
};

export const CUSTOMER_LOCATION = {
  FRANCE: "1",
  EUROPE: "2",
  NON_EUROPE: "3",
};

export const setNewCountryList = (url, location) => {
  $.ajax({
    url: url,
    type: "get",
    data: { location: parseInt(location) },
    success: function () {},
  });
};

export const isProfessionalCustomerSubjectedToTax = (country, location, vatNumber) => {
  if (country === FRANCE_COUNTRY) {
    return false;
  }

  if (location === CUSTOMER_LOCATION.NON_EUROPE) {
    return true;
  }

  return vatNumber !== "";
};

export const vatLegalTextOption = (location, businessType) => {
  if (location === CUSTOMER_LOCATION.FRANCE) {
    return "";
  }

  // NOTE: These returned values need to be kept in sync with the DEFAULT_VAT_TERMS ruby variable
  if (location === CUSTOMER_LOCATION.EUROPE) {
    if (businessType === COMPANY_BUSINESS_TYPE.TRADE) {
      return "1";
    } else if (businessType === COMPANY_BUSINESS_TYPE.SERVICES) {
      return "3";
    }
  } else if (location === CUSTOMER_LOCATION.NON_EUROPE) {
    if (businessType === COMPANY_BUSINESS_TYPE.TRADE) {
      return "8";
    } else if (businessType === COMPANY_BUSINESS_TYPE.SERVICES) {
      return "9";
    }
  }
};
