import { Controller } from "@hotwired/stimulus";
import { INVALID_INPUT_CLASS_NAME } from "../utils/constants";
import { initCheckPasswordCard } from "../onboarding/check_password_requirement";

export default class extends Controller {
  static targets = ["emailInput", "passwordInput", "newPasswordConfirmationInput", "newPasswordInput"];

  connect() {
    window.$(this.element).on("show.bs.modal", () => {
      if (this.hasEmailInputTarget) {
        this._initEmailInput();
      }

      this._initPasswordInput(this.passwordInputTarget);

      if (this.hasNewPasswordInputTarget) {
        [this.newPasswordInputTarget, this.newPasswordConfirmationInputTarget].forEach((input) =>
          this._initPasswordInput(input)
        );
        initCheckPasswordCard();
      }
    });
  }

  _initEmailInput() {
    this._initInputClass(this.emailInputTarget);
    this.emailInputTarget.value = this.emailInputTarget.dataset.value;
  }

  _initInputClass(input) {
    input.classList.remove(INVALID_INPUT_CLASS_NAME);
  }

  _initPasswordInput(input) {
    this._initInputClass(input);
    input.value = "";
  }
}
