import { Controller } from "@hotwired/stimulus";
import { toggleClass } from "../../utils/misc";
import { selectOptionFor } from "../../utils/inputs_utils";

export default class extends Controller {
  static targets = [
    "selectedTransactionsCounter",
    "formWrapper",
    "selectedTransactionsInput",
    "justificationTypeInput",
    "selectShareholderInput",
    "selectCategoryInput",
    "submitButton",
  ];

  connect() {
    this.selectedTransactionsIds = [];
  }

  transactionSelected(e) {
    const input = this._checkBoxFor(e.currentTarget);
    input.checked = !input.checked;
    this._updateSelectedTransactions(input);
    this._updateForm(this.selectedTransactionsIds.length);
  }

  justificationSelected(e) {
    const isShareholdersAccountSelected = e.target.value === "shareholders_account";
    const form = e.target.form;
    const formUrl = isShareholdersAccountSelected
      ? form.dataset.updateShareholdersAccountUrl
      : form.dataset.updateCategoryUrl;
    form.action = formUrl;

    this._toggleSelectionInput(this.selectShareholderInputTarget, isShareholdersAccountSelected);
    this._toggleSelectionInput(this.selectCategoryInputTarget, !isShareholdersAccountSelected);
    this.submitButtonTarget.classList.remove("hidden");
  }

  justificationFormSubmitted() {
    this.selectedTransactionsInputTarget.value = this.selectedTransactionsIds;
  }

  resetForm() {
    this.selectedTransactionsIds = [];
    this._updateForm(this.selectedTransactionsIds.length);
  }

  _updateSelectedTransactions(input) {
    const transactionId = input.dataset.id;
    if (input.checked) {
      this.selectedTransactionsIds.push(transactionId);
    } else {
      const index = this.selectedTransactionsIds.indexOf(transactionId);
      this.selectedTransactionsIds.splice(index, 1);
    }
  }

  _toggleSelectionInput(selectInput, isVisible) {
    toggleClass(selectInput, "hidden", isVisible);
    selectInput.disabled = !isVisible;
    selectOptionFor(selectInput, "");

    if (!isVisible) {
      $(this.submitButtonTarget.form).parsley().reset();
    }
  }

  _updateForm(numberOfTransactionsSelected) {
    toggleClass(this.formWrapperTarget, "hidden", numberOfTransactionsSelected > 0);
    this.selectedTransactionsCounterTarget.innerHTML = numberOfTransactionsSelected;

    if (numberOfTransactionsSelected > 0) {
      return;
    }

    this.justificationTypeInputTargets.forEach((input) => (input.checked = false));
    this.submitButtonTarget.classList.add("hidden");
    [this.selectShareholderInputTarget, this.selectCategoryInputTarget].forEach((selectInput) =>
      this._toggleSelectionInput(selectInput, false)
    );
  }

  _checkBoxFor(transactionRow) {
    return transactionRow.querySelector(".transaction-checkbox");
  }
}
