import { Controller } from "@hotwired/stimulus";
import { dropzoneElement, acceptedFormatsNotice } from "../../components/dropzone";
import { FILE_ACCEPTED_FORMATS } from "../../utils/constants";

export default class extends Controller {
  connect() {
    this.dropzone = dropzoneElement(this.element);
    this._initCustomEvents();
  }

  _initCustomEvents() {
    this.dropzone.on("drop", () => {
      this._defineOptions();
    });

    this.dropzone.on("addedfile", () => {
      this._defineOptions();
    });
  }

  _defineOptions() {
    this.dropzone.options = {
      ...this.dropzone.options,
      acceptedFiles: FILE_ACCEPTED_FORMATS,
      dictInvalidFileType: acceptedFormatsNotice(FILE_ACCEPTED_FORMATS.split(" ")),
    };
  }
}
