import { Controller } from "@hotwired/stimulus";
import { resolveTodo } from "../../todo_list/todo_resolver";
import { refreshTodoSidePanel } from "../../todo_list/refresh_todo_side_panel";

export default class extends Controller {
  connect() {
    const companyId = this.element.dataset.companyId;

    this._subscribeToHandleTodoChannel(companyId);
  }

  _subscribeToHandleTodoChannel(companyId) {
    App.cable.subscriptions.create(
      { channel: "HandleTodoChannel", company_id: companyId },
      {
        received: (data) => {
          if (data.processed) {
            resolveTodo(data.id);
          } else {
            refreshTodoSidePanel(data.id);
          }
        },
      }
    );
  }
}
