import { Controller } from "@hotwired/stimulus";
import { errorMessages, notifyError } from "../components/flash_alert";

const scrollTopMinLevel = 2 * window.screen.height;

export default class extends Controller {
  static targets = ["entries", "pagination", "container", "loader", "backToTop"];

  onScroll() {
    if (this.hasPaginationTarget) {
      const url = this.paginationTarget.href;
      const height = this.containerTarget.scrollHeight - this.containerTarget.clientHeight;
      if (this.containerTarget.scrollTop >= height - 150) {
        this.loadMore(url);
      }
    }
    if (this.hasBackToTopTarget) {
      this._handleScrollBackToTopButton();
    }
  }

  scrollToTop() {
    this.containerTarget.scrollTop = 0;
  }

  loadMore(url) {
    this.loaderTarget.classList.remove("hidden");
    this.paginationTarget.remove();
    fetch(url, {
      method: "get",
      headers: { accept: "text/html" },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          notifyError(errorMessages(response.status));
        }
      })
      .then((data) => {
        this.loaderTarget.classList.add("hidden");
        if (data) {
          this.entriesTarget.insertAdjacentHTML("beforeend", data);
        }
      });
  }

  _handleScrollBackToTopButton() {
    if (this.containerTarget.scrollTop >= scrollTopMinLevel) {
      this._showScrollTopButton();
    } else if (this.containerTarget.scrollTop === 0) {
      this._hideScrollTopButton();
    }
  }

  _showScrollTopButton() {
    this.backToTopTarget.classList.remove("hidden");
  }

  _hideScrollTopButton() {
    this.backToTopTarget.classList.add("hidden");
  }
}
