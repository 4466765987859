import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert";
import { isCheckbox } from "../utils/inputs_utils";

export default class extends Controller {
  submit(e) {
    const form = e.currentTarget.form;

    if (e.params.confirmMessage) {
      this._submitWithConfirmation(form, e.currentTarget, e.params.confirmMessage);
      return;
    }

    this._submitForm(form);
  }

  _submitWithConfirmation(form, formSubmissionElement, confirmMessage) {
    swal({
      title: confirmMessage,
      buttons: { non: true, oui: true },
      className: "custom-swal-modal",
    }).then((value) => {
      if (value === "oui") {
        this._submitForm(form);
      } else {
        this._resetSubmissionElement(formSubmissionElement);
      }
    });
  }

  _resetSubmissionElement(element) {
    if (isCheckbox(element)) {
      element.checked = !element.checked;
    }
  }

  _submitForm(form) {
    Rails.fire(form, "submit");
  }
}
