import { Controller } from "@hotwired/stimulus";
import { fillFlatpickrInput } from "../../utils/date_utils";

export default class extends Controller {
  static targets = ["form", "title", "dateInput"];
  connect() {
    window.$(this.element).on("show.bs.modal", (e) => {
      const url = e.relatedTarget.dataset.url;
      const title = e.relatedTarget.dataset.title;
      this.titleTarget.innerHTML = title;
      this.formTarget.action = url;
      fillFlatpickrInput(this.dateInputTarget, "");
    });
  }
}
