import { isValidEmail, scrollToField, isInputFilled, toggleClass } from "../../utils/misc";
import { select2ContainerFor } from "../../utils/inputs_utils";

const initSalesEmailInputValidation = (emailInput) => {
  emailInput.addEventListener("input", () => {
    isSalesEmailInputValid(emailInput);
  });
};

const isSalesEmailInputValid = (emailInput) => {
  const select2Container = select2ContainerFor(emailInput);
  const isInputValid = !isInputFilled(emailInput) || isValidEmail(emailInput.value);
  toggleClass(select2Container, "invalid", isInputValid);
  if (!isInputValid) {
    scrollToField(emailInput);
  }
  return isInputValid;
};

export { isSalesEmailInputValid, initSalesEmailInputValidation };
