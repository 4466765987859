import { basicQuestionsModalValidation } from "./input_validations";

// order can be 'next' or 'previous'
const changeStep = (order) => {
  const firstStep = document.querySelector(".basic-questions-form[data-step='0']"); // first slide which margin-left changes to display the right slide
  const activeStep = document.querySelector(".basic-questions-form.active"); // current active step
  let stepToActivate = ""; // next active step
  if (order === "next") {
    stepToActivate = activeStep.nextElementSibling;
  } else {
    stepToActivate = activeStep.previousElementSibling;
  }

  displayStep(firstStep, activeStep, stepToActivate);
  changeBasiQuestionsProgressBar(stepToActivate);
  // Each time we change step, we need to update data-submit-step of submit btn
  // As it is the same submit btn for all forms, the submit btn will trigger the right form submission
  changeSubmitBtnStep(stepToActivate.dataset.step);
  displayHideBtns(stepToActivate);
  basicQuestionsModalValidation(stepToActivate);
};

const managePreviousStep = () => {
  const previousStepBtn = document.querySelector(".previous-step-btn");
  if (previousStepBtn) {
    previousStepBtn.addEventListener("click", () => {
      changeStep("previous");
    });
  }
};

const displayHideBtns = (stepToActivate) => {
  // Toggle hidden on 'continuer' btn if last step or not
  const submitBtn = document.querySelector(".basic-questions-container .submit-btn");
  const microQuestions = document.querySelector(".progress-active.micro");
  const progressBar = document.getElementById("basic-questions-progress-bar");
  const trialPeriodStartBanner = document.getElementById("trial-period-start-banner");
  let lastStep = "4";
  if (microQuestions) {
    lastStep = "5";
  }
  const firstStep = "0";

  if (stepToActivate.dataset.step === lastStep || (microQuestions && stepToActivate.dataset.step === lastStep)) {
    submitBtn.classList.add("hidden");
  } else {
    submitBtn.classList.remove("hidden");
  }

  // Toggle hidden on 'précédent' btn if first step or not
  const previousStepBtn = document.querySelector(".previous-step-btn");
  if (stepToActivate.dataset.step === firstStep) {
    previousStepBtn.classList.add("hidden");
    progressBar.classList.add("hidden");
  } else {
    previousStepBtn.classList.remove("hidden");
    progressBar.classList.remove("hidden");
  }

  if (!trialPeriodStartBanner) {
    return;
  }

  if (stepToActivate.dataset.step === lastStep) {
    trialPeriodStartBanner.classList.remove("hidden");
    progressBar.classList.add("hidden");
  } else {
    trialPeriodStartBanner.classList.add("hidden");

    if (stepToActivate.dataset.step !== firstStep) {
      progressBar.classList.remove("hidden");
    }
  }
};

const displayStep = (firstStep, activeStep, stepToActivate) => {
  // Set margin left to first slide thanks to 'real step' dataset
  const newStepNumber = parseInt(stepToActivate.dataset.realStep);
  const marginPercent = `-${newStepNumber}00%`;
  firstStep.style.marginLeft = marginPercent;

  // toggle active class
  activeStep.classList.remove("active");
  stepToActivate.classList.add("active");
};

const changeBasiQuestionsProgressBar = (activeStep) => {
  const basicQuestionsProgressBar = document.querySelector("#basic-questions-progress-bar .progress-active");
  const progressPercent = activeStep.dataset.progressPercent;
  basicQuestionsProgressBar.style.left = `${progressPercent}%`;

  document.querySelectorAll(".progress-bar-item").forEach((item) => item.classList.remove("active"));
  const activeStepNumber = activeStep.dataset.step;
  const progressItemActive = document.querySelector(`[data-target-step='${activeStepNumber.split("-")[0]}']`);
  if (progressItemActive) {
    progressItemActive.classList.add("active");
  }
};

const changeSubmitBtnStep = (step) => {
  const submitBtn = document.querySelector(".basic-questions-container .submit-btn");
  submitBtn.dataset.submitStep = step;
};

export { managePreviousStep, changeStep };
