import swal from "sweetalert";

const launchASweetAlert = (options = {}) => {
  options.className = "custom-swal-modal";
  swal(options);
};

const initSweetalert = (selector) => {
  const swalButton = document.querySelector(selector);
  if (swalButton) {
    swalButton.addEventListener("click", () => {
      launchASweetAlert();
    });
  }
};

export { swal, initSweetalert, launchASweetAlert };
