import $ from "jquery";

import { toggleClass } from "../utils/misc";
import { i18nTranslations } from "../utils/i18n_utils";

export const isDatatableUninitialized = (element) => !$.fn.DataTable.isDataTable(element);

const LOCKING_SOURCE = {
  algorithm: "algorithm",
  coach: "coach",
};

const dataTableFunctions = () => {
  const publicFunctions = {};
  let timeout = null;

  const removeParamsInUrl = () => {
    let newPath = window.location.pathname;

    if (window.location.search.includes("?f=1")) {
      newPath = window.location.pathname + "?f=1";
    }
    window.history.replaceState({}, document.title, newPath);
  };

  const removeValueOfParamsInput = () => {
    const inputs = document.querySelectorAll(".redirection-inputs");
    if (inputs.length > 0) {
      inputs.forEach((input) => {
        input.checked = false;
      });
    }
  };

  publicFunctions.lockedStatusDescription = (lockedStatus, bankName) => {
    let lockedDescription;
    if (lockedStatus === LOCKING_SOURCE.algorithm) {
      lockedDescription = i18nTranslations().t(bankName.toLowerCase(), {
        scope: `flow.lock_action.${LOCKING_SOURCE.algorithm}`,
      });
    } else if (lockedStatus === LOCKING_SOURCE.coach) {
      lockedDescription = i18nTranslations().t(LOCKING_SOURCE.coach, {
        scope: "flow.lock_action",
      });
    } else {
      lockedDescription = i18nTranslations().t("default", {
        scope: "flow.lock_action",
      });
    }
    return lockedDescription;
  };

  publicFunctions.manageInputChange = (inputId, tableId, event) => {
    const input = document.getElementById(inputId);
    if (!input) {
      return;
    }
    input.addEventListener(event, () => {
      removeParamsInUrl();
      removeValueOfParamsInput;
      const table = $("#" + tableId).dataTable();
      const settings = table.fnSettings();
      let timeout = 0;

      if (inputId === "25-results") {
        settings._iDisplayLength = 25;
        table.fnDraw();
      } else if (inputId === "50-results") {
        settings._iDisplayLength = 50;
        table.fnDraw();
      } else if (inputId === "100-results") {
        settings._iDisplayLength = 100;
        table.fnDraw();
      } else {
        if (event === "input") {
          clearTimeout(timeout);
          timeout = setTimeout(function () {
            publicFunctions.reloadTable(tableId);
          }, 300);
        } else {
          publicFunctions.reloadTable(tableId);
        }
      }
    });
  };

  publicFunctions.manageFilteredSelection = () => {
    const exportForm = document.getElementById("export-to-csv");
    const idsContainer = exportForm.querySelector("#export-ids");
    const ids = [...idsContainer.querySelectorAll("[name='ids[]']")].map((input) => input.value);

    toggleClass(exportForm, "hidden", ids);
  };

  publicFunctions.setExportIds = (exportIds) => {
    const exportForm = document.getElementById("export-to-csv");
    const idsContainer = exportForm.querySelector("#export-ids");
    idsContainer.innerHTML = "";

    exportIds.split(",").forEach((id) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "ids[]";
      input.value = id;
      idsContainer.appendChild(input);
    });
  };

  publicFunctions.manageSearchBar = (search, datatableId) => {
    search.addEventListener("input", () => {
      removeParamsInUrl();
      removeValueOfParamsInput();
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        publicFunctions.reloadTable(datatableId);
      }, 300);
    });
  };

  publicFunctions.setRadioFilters = (data, filterMappings) => {
    Object.entries(filterMappings).forEach(([filterName, filterId]) => {
      if (document.getElementById(filterId).checked) {
        data[filterName] = true;
      }
    });
  };

  publicFunctions.columnIndexes = (data) => {
    return data.columns.reduce((acc, column, index) => {
      acc[column.data] = index;
      return acc;
    }, {});
  };

  publicFunctions.setColumnFilter = (data, index, value) => {
    data.columns[index].search.value = value;
  };

  publicFunctions.datatablesController = () => {
    return document.querySelector("[data-controller^='datatables']")?.datatables;
  };

  publicFunctions.refreshSidePanel = () => {
    document.dispatchEvent(new CustomEvent("datatable:refreshSidePanel"));
  };

  publicFunctions.reloadTable = (tableId) => {
    $("#" + tableId)
      .DataTable()
      .ajax.reload(null, true);
  };

  publicFunctions.reloadRows = (tableId, records) => {
    const table = $("#" + tableId).DataTable();
    records.forEach((record) => {
      const row = document.querySelector(`.item-row[data-id='${record.id}']`);
      table.row(row).data(record);

      reloadDatatableRowEvents(row);
    });
  };

  publicFunctions.loadDatatableFromUrl = (table, url) => {
    const datatableElement = $(`#${table}`).DataTable();
    datatableElement.ajax.url(url).load();
  };

  const reloadDatatableRowEvents = (row) => {
    // NOTE: This function contains all events that need to be set again after the datatable row is reloaded

    $(row).on("mouseenter", ".acasi_tooltip", function () {
      window.$(this).tooltip("show");
    });
  };

  return publicFunctions;
};

export { dataTableFunctions };
