import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { isInputFilled, toggleClass } from "../../utils/misc";
import { flatpickrAltInput, isFlatpickrInput } from "../../utils/date_utils";

export default class extends Controller {
  static targets = ["input", "validationIcon"];

  connect() {
    this._validateInput();

    this.inputTarget.addEventListener("input", () => {
      this._validateInput();
    });
  }

  _validateInput() {
    let inputToCheck = this.inputTarget;
    if (isFlatpickrInput(this.inputTarget)) {
      inputToCheck = flatpickrAltInput(this.inputTarget);
    }

    let isInputValid = false;
    if (isInputFilled(inputToCheck)) {
      isInputValid = $(inputToCheck).parsley().isValid();
    }

    toggleClass(this.validationIconTarget, "hidden", isInputValid);
  }
}
