import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hideDetailsBtn", "showDetailsBtn", "loader"];

  toggleDetailsBtns() {
    this.hideDetailsBtnTarget.classList.toggle("hidden");
    this.showDetailsBtnTarget.classList.toggle("hidden");
    // A loader is displayed as an http request is made when clicking on button to show details
    this.loaderTarget.classList.toggle("show");
  }
}
