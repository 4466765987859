import { Controller } from "@hotwired/stimulus";
import { handleHttpResponse } from "../../utils/http_utils";
import { errorMessages, flashAlert, notifyError } from "../../components/flash_alert";
import { dataTableFunctions } from "../../datatables/datatable_functions";

export default class extends Controller {
  afterDestroy(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: () => {
        flashAlert("Trajet(s) supprimé(s)");
        document.dispatchEvent(new CustomEvent("sidePanel:close"));
        dataTableFunctions().reloadTable("mileage_allowances_datatable");
      },
      onError: (response, status) => {
        if (status === 422) {
          const parsedResponse = JSON.parse(response);
          notifyError(parsedResponse.error);
          return;
        }

        notifyError(errorMessages(status));
      },
    });
  }
}
