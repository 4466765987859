import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";
import VatDelegationWorkflow from "../../todo_list/vat_delegation_workflow";
import { isSwalConfirmed } from "../../utils/misc";
import { swal } from "../../components/init_sweet_alert";

export default class extends Controller {
  static targets = ["form", "step", "progressBar", "progressBarTemplate"];
  static values = {
    todoId: Number,
  };

  connect() {
    const { steps, savedStep } = this.element.dataset;
    this.workflow = new VatDelegationWorkflow(JSON.parse(steps), this.element);
    this._openCurrentStep(parseInt(savedStep));
    this._updateProgressBar();
  }

  previousStep(event) {
    const { previousStepId } = event.currentTarget.dataset;

    this._updateStep(previousStepId);
  }

  nextStep(event) {
    const { nextStepId } = event.currentTarget.dataset;

    this._updateStep(nextStepId);
  }

  confirmDelegation() {
    swal({
      title: "Confirmez-vous avoir délégué vos formalités à Acasi ?",
      buttons: { non: true, oui: true },
      className: "custom-swal-modal",
    }).then((value) => {
      if (isSwalConfirmed(value)) {
        Rails.fire(this.formTarget, "submit");
      }
    });
  }

  resolveTodo() {
    const todoCard = document.querySelector(`[data-todo-id='${this.todoIdValue}']`);
    todoCard.dispatchEvent(new CustomEvent("todo:done"));

    this._updateStep(this.element.dataset.successStep);

    this._closeSidePanel();
  }

  _updateStep(stepId) {
    const currentStepId = this.workflow.currentStepId();
    const currentStep = this._stepFor(currentStepId);

    this.workflow.storeStep(stepId);
    this.workflow.saveStep();

    const nextStep = this._stepFor(stepId);
    currentStep.classList.remove("current");
    nextStep.classList.add("current");

    this._updateProgressBar();
  }

  _closeSidePanel() {
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("sidePanel:close"));
    }, 3000);
  }

  _openCurrentStep(savedStep) {
    const currentStepId = this.workflow.currentStepId(savedStep);
    this._stepFor(currentStepId).classList.add("current");
  }

  _stepFor(stepId) {
    return this.stepTargets.find((step) => step.dataset.stepId === stepId);
  }

  _updateProgressBar() {
    this.progressBarTarget.innerHTML = interpolate(this.progressBarTemplateTarget, {
      stepNumber: this.workflow.mainStepNumber(),
    });
  }
}
