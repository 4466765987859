import { initDatatable } from "./datatable_factory";
import { dataTableFunctions } from "./datatable_functions";
import { compiledTemplate, interpolate, setTemplatePartial } from "../utils/string_utils";
import { dateWithAbbrMonth } from "../utils/date_utils";
import { amountCellContent } from "./purchase_invoices_datatable";
import { registerNumberToCurrencyHelper } from "../utils/handlebars_helpers";

const initProblematicPurchasesDatatable = () => {
  const datatableElement = document.getElementById("problematic_purchases_datatable");
  const columns = datatableColumns(datatableElement);
  const columnIndexes = dataTableFunctions().columnIndexes({ columns: columns });

  registerNumberToCurrencyHelper();

  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[columnIndexes[columnNames.date], "desc"]],
    columns: datatableColumns(),
    onDraw: onDraw(),
    onComplete: onComplete(),
    setRequestData: setRequestData(),
  };
  initDatatable(payload);
};

const columnNames = {
  input: "input",
  id: "id",
  supplier: "supplier",
  date: "date",
  amount: "amount",
  error: "error",
  resolve: "resolve",
};

const onDraw = () => {
  return () => {
    window.dispatchEvent(new Event("initTooltip"));
  };
};

const onComplete = () => {
  return () => {
    const inputsThatReactToDatatable = [
      "incomplete_purchases_filter",
      "duplicated_purchases_filter",
      "mismatched_purchases_filter",
      "not_matched_purchases_filter",
      "date_invalid_purchases_filter",
      "file_invalid_purchases_filter",
    ];
    inputsThatReactToDatatable.forEach((input) => {
      const event = "change";
      dataTableFunctions().manageInputChange(input, "problematic_purchases_datatable", event);
    });
  };
};

const setRequestData = () => {
  return (data) => {
    dataTableFunctions().setRadioFilters(data, {
      incomplete: "incomplete_purchases_filter",
      duplicated: "duplicated_purchases_filter",
      mismatched: "mismatched_purchases_filter",
      not_matched: "not_matched_purchases_filter",
      date_invalid: "date_invalid_purchases_filter",
      file_invalid: "file_invalid_purchases_filter",
    });
  };
};

const datatableColumns = () => {
  return [
    {
      data: columnNames.input,
      orderable: false,
      className: "select-column checkbox-element",
      render: (data, type, row) => {
        return interpolate(document.getElementById("select-item-checkbox-template"), {
          id: row.id,
        });
      },
    },
    { data: columnNames.id, orderable: false, className: "ids-column", visible: false },
    {
      data: columnNames.supplier,
      orderable: true,
      className: "supplier-column",
      render: (data, type, row) => {
        setTemplatePartial("noteIcon", document.getElementById("note-icon-template"));

        return compiledTemplate(document.getElementById("purchase-invoice-supplier-column-template"), {
          supplier: row.supplier,
          note: row.note,
          id: row.id,
          displayed_content: row.supplier,
        });
      },
    },
    {
      data: columnNames.date,
      orderable: true,
      className: "date-column",
      render: (data) => {
        if (!data) {
          return "";
        }
        return dateWithAbbrMonth(data);
      },
    },
    {
      data: columnNames.amount,
      orderable: true,
      className: "amount-column",
      render: (data, type, row) => amountCellContent(row),
    },
    {
      data: columnNames.error,
      orderable: false,
      className: "error",
      render: (data) => {
        return compiledTemplate(document.getElementById("purchase-invoice-error-column-template"), {
          issues: data,
        });
      },
    },
    {
      data: columnNames.resolve,
      orderable: false,
      className: "resolve",
      render: (data) => {
        return interpolate(document.getElementById("purchase-invoice-resolve-column-template"), {
          resolve_url: data,
        });
      },
    },
  ];
};

export { initProblematicPurchasesDatatable };
