import { inputIsValid, inputMustBeFilled, allInputsWithValidation } from "../components/input_validation";
import { initAllInputValidation } from "../components/init_input_validation";
import { isStringTrue, toggleClass } from "../utils/misc";

const profileForms = () => {
  const divProfilBeforeSub = document.querySelector(".profile-completion");
  if (divProfilBeforeSub) {
    // click sur les titre des blocs toggle collapse
    $(".collapsable .block-top-part").click(function () {
      $(this.closest(".block").querySelector(".block-content")).slideToggle(1000);
      this.querySelector(".block-chevron").classList.toggle("rotate90");
      if (this.closest(".block").querySelector(".block-percent-completed")) {
        const that = this;
        setTimeout(function () {
          that.closest(".block").querySelector(".block-description").classList.toggle("hidden");
          that.closest(".block").querySelector(".block-percent-completed").classList.toggle("hidden");
          if (bottomProgressBarElement(that).attributes["width"].value === "100%") {
            that.closest(".block").querySelector(".checked-image").classList.toggle("hidden");
          }
          that.closest(".block").querySelector(".block-percent-completed").innerText =
            intOfCompletion(that).toString() + "% complété";
        }, 800);
      }
    });

    // init_form
    document.querySelectorAll(".profile-form-content").forEach(function (element) {
      initFormContent(element);
    });
  }
};

const initFormContent = (form) => {
  observeCtaBarPosition(form);
  animateProgressBar(bottomProgressBarElement(form));
  initCollapseStatus(form);
  initSiretFocus();
  const isMicro = document.getElementById("edit_advanced_micro_information");
  if (!isMicro) {
    manageCancellationLetterBox();
  }
  trackChanges(form);
  cancelBtnAction(form);
  saveBtnAction(form);
  initAllInputValidation(form);
  manageEnterKeyPressOnInput(form);
  unupdatableFields(form);
};

const initSiretFocus = () => {
  document.querySelectorAll(".siret-digit").forEach(function (element) {
    element.addEventListener("input", function () {
      if (/^\d$/.test(element.value)) {
        if (element.id !== "company_siret_14") {
          const digitPosition = parseInt(element.id.substring("company_siret_".length)) + 1;
          document.getElementById("company_siret_" + digitPosition.toString()).focus();
        }
      }
    });
  });
};

const unupdatableFields = (form) => {
  form.querySelectorAll(".clickable").forEach(function (field) {
    field.addEventListener("click", function (e) {
      if (!(e.target.classList.contains("siret-digit") && !e.target.disabled)) {
        document.getElementById("unupdatableFieldsModalTrigger").click();
      }
    });
  });
};

const observeCtaBarPosition = (form) => {
  window.addEventListener("scroll", function () {
    toggleCtaBarPosition(form);
  });
};

const toggleCtaBarPosition = (form) => {
  const referenceHeight = window.innerHeight;
  const targetHeight = form.parentElement.parentElement.getBoundingClientRect().bottom;
  const maxHeight = form.parentElement.parentElement.getBoundingClientRect().top;
  if (referenceHeight > targetHeight || referenceHeight < maxHeight) {
    form.querySelector(".action-buttons").classList.remove("fixed");
    form.querySelector(".action-buttons").classList.add("absolute");
  } else {
    form.querySelector(".action-buttons").classList.add("fixed");
    form.querySelector(".action-buttons").classList.remove("absolute");
  }
};

const initCollapseStatus = (form) => {
  if (form.closest(".block").querySelector(".block-percent-completed")) {
    if (
      (intOfCompletion(form) === 100 ||
        (form.closest(".block").classList.contains("disabled") &&
          !form.closest(".block").querySelector(".block-chevron").classList.contains("rotate90"))) &&
      typeof editMode === "undefined"
    ) {
      form.closest(".block").querySelector(".block-top-part").click();
    }
  }
};

const toggleCtaButtons = (state, form) => {
  if (form.querySelector(".action-buttons")) {
    if (state) {
      form.querySelector(".action-buttons").classList.remove("hidden");
      form.querySelector(".save-error-message").classList.add("hidden");
      form.closest(".block").querySelector(".block-content").style.paddingBottom = "100px";
      toggleCtaBarPosition(form);
    } else {
      form.querySelector(".action-buttons").classList.add("hidden");
      form.closest(".block").querySelector(".block-content").style.paddingBottom = "40px";
      toggleCtaBarPosition(form);
    }
  }
};

const trackChanges = (form) => {
  const initialFormValues = getConcatenatedValues(form);
  const allInputs = form.querySelectorAll("input, select, textarea");
  allInputs.forEach(function (input) {
    input.addEventListener("input", function () {
      toggleCtaButtons(initialFormValues !== getConcatenatedValues(form), form);
    });
  });
};

const getConcatenatedValues = (form) => {
  const allInputs = form.querySelectorAll("input, select");
  let concatenatedValues = "";
  allInputs.forEach(function (input) {
    concatenatedValues += input.value;
  });
  return concatenatedValues;
};

const intOfCompletion = (form) => {
  return parseInt(bottomProgressBarElement(form).attributes["width"].value.slice(0, -1));
};

const animateProgressBar = (progressBar) => {
  if (progressBar) {
    const percentOfCompletion = progressBar.attributes["width"].value;
    progressBar.animate({ width: [0, percentOfCompletion] }, { duration: 1000, fill: "forwards" });
  }
};

// gestion des actions du bouton cancel dans les forms
const cancelBtnAction = (form) => {
  form.querySelector(".button-cancel").addEventListener("click", function (event) {
    event.stopImmediatePropagation();
    event.target.closest(".action-buttons").querySelector(".cancel-form-change").click();
  });
};

const manageEnterKeyPressOnInput = (form) => {
  const realForm = form.closest("form");
  const realFormInputs = realForm.querySelectorAll("input");
  realFormInputs.forEach((input) => {
    input.addEventListener("keypress", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        const buttonSave = form.querySelector(".button-save");
        // Check if button save is not hidden
        const buttonSaveParent = buttonSave.closest(".action-buttons");
        if (buttonSaveParent && !buttonSaveParent.classList.contains("hidden")) {
          buttonSave.click();
        }
      }
    });
  });
};

const manageCancellationLetterBox = () => {
  const blockInformation = document.querySelector(".block.information");
  const accountantChoices = blockInformation
    .querySelector(".multi-choice-has-accountant")
    .querySelectorAll(".choice-button");
  accountantChoices.forEach(function (element) {
    element.addEventListener("click", function (event) {
      const shouldDisplayCancellationLetterBox = isStringTrue(event.target.getAttribute("value"));
      toggleClass(document.querySelector(".cancellation-letter-box"), 'hidden', shouldDisplayCancellationLetterBox);
    });
  });
};

// gestion des actions du bouton modifier dans les forms
const saveBtnAction = (form) => {
  form.querySelector(".button-save").addEventListener("click", function (event) {
    event.stopImmediatePropagation();
    if (allInputValid(form)) {
      event.target.closest(".action-buttons").querySelector(".input-submit-form").click();
    } else {
      initAllInputValidation(form);
      event.target.closest(".action-buttons").querySelector(".save-error-message").classList.remove("hidden");
    }
  });
};

const allInputValid = (form) => {
  let result = true;
  allInputsWithValidation(form).forEach(function (element) {
    if (!(inputIsValid(element) || element.value === "") || inputMustBeFilled(element)) {
      result = false;
    }
  });

  return result;
};

const bottomProgressBarElement = (form) => {
  return form.closest(".block").querySelector(".block-bottom-progress-bar");
};

export {
  profileForms,
  initFormContent,
  intOfCompletion,
  toggleCtaButtons,
  observeCtaBarPosition,
  animateProgressBar,
  bottomProgressBarElement,
};
